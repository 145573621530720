/** @format */

import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';

import { classNames, getActiveLangLabel } from 'helpers';
import config from '../../config';
import { t } from 'i18next';

const RequirementsField = ({
  show = true,
  password,
  className,
  onInit,
  onIsAllRequirementsPass,
  style = {},
  ...props
}) => {
  const [requirements, setRequirements] = useState(null);
  const [isAllRequirementsPass, setIsAllRequirementsPass] = useState(false);

  useEffect(() => {
    const rules = config.password_rules;
    if (rules?.length > 0) {
      setRequirements(rules.map(rule => ({ ...rule, pass: false })));
      if (typeof onInit === 'function') {
        onInit();
      }
    }
  }, []);

  useEffect(() => {
    if (requirements !== null) {
      setRequirements(list => {
        const newList = [];
        list.forEach(rule => {
          const pass = new RegExp(rule.pattern).test(password);
          newList.push({ ...rule, pass });
        });

        const passed = newList.every(rule => rule.pass);
        setIsAllRequirementsPass(passed);
        if (typeof onIsAllRequirementsPass === 'function') {
          onIsAllRequirementsPass(passed);
        }
        return newList;
      });
    }
  }, [password]);

  return (
    <Transition
      show={!!requirements && show && !isAllRequirementsPass}
      as={Fragment}
      enter='transition-all transform ease-in-out duration-500'
      enterFrom={'-translate-y-4 opacity-0'}
      enterTo={'translate-y-0 opacity-100'}
      leave='transition-all transform ease-in-out duration-500'
      leaveFrom={'translate-y-0 opacity-100'}
      leaveTo={'-translate-y-4 opacity-0'}
    >
      <div
        className={classNames('text-gray-700  text-sm leading-5', className)}
        style={style}
        {...props}
      >
        <p className='mb-2'>{t('Password requirements')}:</p>
        <ul className='space-y-2'>
          {requirements?.map((entry, idx) => (
            <li key={idx} className='flex items-center'>
              <CheckCircleIcon
                className={classNames(
                  'h-5 min-w-[25px]',
                  'mr-2',
                  entry.pass ? 'text-indigo-600' : 'text-gray-300'
                )}
              />
              <p>{entry.labels[getActiveLangLabel()]}</p>
            </li>
          ))}
        </ul>
      </div>
    </Transition>
  );
};

RequirementsField.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  password: PropTypes.string,
  onInit: PropTypes.func,
  onIsAllRequirementsPass: PropTypes.func,
  style: PropTypes.object,
};

export default RequirementsField;
