/**@format */
import { api } from 'services/bo/api';
import { clearCartStorage, setCartUUID } from 'store/cart/cartSlice';
import { CART_DATA } from 'constants/Constants';

const handleDeleteCartItem = async (_, { dispatch, queryFulfilled }) => {
  try {
    const { data: { data: cartData } = {} } = await queryFulfilled;
    if (!cartData?.item_count) {
      dispatch(clearCartStorage());
    }
  } catch (err) {
    console.log('error... ', err);
  }
};

const handleDeleteAllItemsCart = async (_, { dispatch, queryFulfilled }) => {
  try {
    await queryFulfilled;
    dispatch(clearCartStorage());
  } catch (err) {
    console.log('error... ', err);
  }
};

const invalidatesTags = _result => {
  const invalidatesTagsList = [];
  if (_result) {
    const cartData = localStorage.getItem(CART_DATA)
      ? JSON.parse(localStorage.getItem(CART_DATA))
      : {};
    (cartData?.items['media-activation'] || cartData?.items['media-balance-reload']) &&
      invalidatesTagsList.push('FrontdeskLookupMediaDetails');
    cartData?.items['media-balance-reload'] &&
      invalidatesTagsList.push({ type: 'FrontdeskCustomerFareCards', id: 'LIST' });
    cartData?.items['link-pass-to-media'] &&
      invalidatesTagsList.push({ type: 'MediaLookupPasses', id: 'LIST' });
  }
  return [...invalidatesTagsList];
};
export const frontdeskCartApi = api.injectEndpoints({
  endpoints: build => ({
    getCartSettingsClient: build.query({
      query: () => `carts/settings/client`,
      providesTags: ['FrontdeskCartSettingsClient'],
    }),
    getCartUUID: build.query({
      query: () => `carts`,
      providesTags: ['FrontdeskCartUUID'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: { data: cartUUID } = {} } = await queryFulfilled;
          if (cartUUID?.uuid) {
            dispatch(setCartUUID(cartUUID?.uuid));
          }
        } catch (err) {
          console.log('error... ', err);
        }
      },
    }),
    getCart: build.query({
      query: ({ cartUuid }) => `carts/${cartUuid}`,
      providesTags: () => [{ type: 'FrontdeskCart', id: 'LIST' }],
    }),
    addItemToCart: build.mutation({
      query: ({ body, cartUuid }) => ({
        url: `carts/${cartUuid}/items`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'FrontdeskCart', id: 'LIST' }],
    }),
    checkoutCart: build.mutation({
      query: ({ cartUuid, body }) => ({
        url: `carts/${cartUuid}`,
        method: 'POST',
        body,
      }),
      invalidatesTags,
      onQueryStarted: handleDeleteAllItemsCart,
    }),
    deleteCartItem: build.mutation({
      query: ({ cartUuid, cartItemId }) => ({
        url: `carts/${cartUuid}/items/${cartItemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'FrontdeskCart', id: 'LIST' }],
      onQueryStarted: handleDeleteCartItem,
    }),
    deleteAllItemsCartOneOperation: build.mutation({
      query: ({ cartUuid, operation }) => ({
        url: `carts/${cartUuid}/operations/${operation}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'FrontdeskCart', id: 'LIST' }],
      onQueryStarted: handleDeleteCartItem,
    }),
    deleteCart: build.mutation({
      query: ({ cartUuid }) => ({
        url: `carts/${cartUuid}`,
        method: 'DELETE',
      }),
      onQueryStarted: handleDeleteAllItemsCart,
    }),
  }),
});

export const {
  useGetCartSettingsClientQuery,
  useGetCartUUIDQuery,
  useGetCartQuery,
  useLazyGetCartUUIDQuery,
  useAddItemToCartMutation,
  useCheckoutCartMutation,
  useDeleteCartItemMutation,
  useDeleteAllItemsCartOneOperationMutation,
  useDeleteCartMutation,
} = frontdeskCartApi;
