/**@format */
import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from 'helpers';

const LoaderNavigationItemsView = ({ loadCount = 5, isDisclosure = false }) => (
  <div className='space-y-3'>
    {Array(loadCount)
      .fill({ value: 'nav' })
      ?.map((i, idx) => (
        <div
          key={`load-menu-panel-${i}-${idx}`}
          className={classNames(
            { 'w-[44px] h-[44px]': !isDisclosure },
            { 'w-full h-[40px]': isDisclosure },
            'rounded-md',
            'animate-pulse',
            'bg-gradient-to-r sm:bg-gradient-to-b from-blue-200 to-blue-400'
          )}
        />
      ))}
  </div>
);

LoaderNavigationItemsView.PropsTypes = {
  isDisclosure: PropTypes.bool,
  loadCount: PropTypes.number,
};
export default LoaderNavigationItemsView;
