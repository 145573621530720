import { createSlice } from '@reduxjs/toolkit'
import { PATRON_DATA } from "../../../../../pages/dashboard/Manager/Patrons/Patron/General/data";


export const patronSlice = createSlice({
    name: 'patron',
    initialState: {
        data: PATRON_DATA
    },
    reducers: {
        setPatronData: (state, action) => {
            state.data = action.payload
        },
        updatePatronData: (state, action) => {
            state.data = action.payload
        },
        clearPatronData: (state) => {
            return state.data;
        },
        deletePatron: (state, action) => {
            state.data = null
        },
    },
})
export const { updatePatronData, setPatronData, clearPatronData, deletePatron } = patronSlice.actions

export const selectPatron = (state) => state.dashboard.crm.patrons.patron.data

export default patronSlice.reducer
