/**@format */
import { api } from '../api';

export const meApi = api.injectEndpoints({
  endpoints: build => ({
    getUserPersonalData: build.query({
      query: params => {
        return {
          url: `users/me`,
          params,
          method: 'GET',
        };
      },
      providesTags: ['UserData'],
    }),
    editUserPersonalData: build.mutation({
      query: ({ body }) => ({
        url: `users/me`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['UserData', 'FrontdeskAgentsHistory', 'FrontdeskOperationsHistory'],
    }),
    getUserClientsData: build.query({
      query: () => `users/me/clients`,
      providesTags: [{ type: 'UserClients', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetUserPersonalDataQuery,
  useLazyGetUserPersonalDataQuery,
  useEditUserPersonalDataMutation,
  useLazyGetUserClientsDataQuery,
} = meApi;
