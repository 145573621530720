import { createSlice } from '@reduxjs/toolkit';
import { ALL_FEATURES_DATA, FAVORITES_DATA } from '../../data/data';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    featuresDataArr: ALL_FEATURES_DATA,
    favoritesDataArr: FAVORITES_DATA
  },
  reducers: {
    addFavoritesItemAC: (state, action) => {
      const findObjectFeaturesByID = state.featuresDataArr
        .filter(item => item.id === action.payload)
        .map(obj => ({
          ...obj,
          isFavorites: true,
        }));
      state.featuresDataArr = state.featuresDataArr.filter(
        item => item.id !== action.payload
      );
      state.favoritesDataArr = [
        ...state.favoritesDataArr,
        findObjectFeaturesByID[0]
      ].sort((a, b) => (a.id > b.id ? 1 : -1));
    },
    removeFavoritesItemAC: (state, action) => {
      const findObjectFavoriteItemByID = state.favoritesDataArr
        .filter(item => item.id === action.payload)
        .map(obj => ({
          ...obj,
          isFavorites: false,
        }));
      state.favoritesDataArr = state.favoritesDataArr.filter(
        item => item.id !== action.payload
      );
      state.featuresDataArr = [
        ...state.featuresDataArr,
        findObjectFavoriteItemByID[0]
      ].sort((a, b) => (a.id > b.id ? 1 : -1));
    },
  }
});
export const { addFavoritesItemAC, removeFavoritesItemAC, updateAllDataAC } = dashboardSlice.actions;

export const addFavoritesItemTC = (favoriteID) => (dispatch) => {
  dispatch(addFavoritesItemAC(favoriteID));
};

export const removeFavoritesItemTC = (removeID) => (dispatch) => {
  dispatch(removeFavoritesItemAC(removeID));
};

export const selectFeaturesItemsDashboard = state => state.dashboard.welcome.featuresDataArr;
export const selectFavoritesItemsDashboard = state=> state.dashboard.welcome.favoritesDataArr;
export default dashboardSlice.reducer;
