/**@format */
import { api } from 'services/bo/api';

const transformResponse = (response, meta) => {
  return {
    status: meta?.response?.status,
    statusText: meta?.response?.statusText,
    token: response?.data?.token,
  };
};

export const authApi = api.injectEndpoints({
  endpoints: build => ({
    loginUser: build.mutation({
      query: body => ({
        url: `auth/users`,
        method: 'POST',
        body,
      }),
      transformResponse,
    }),
    logoutUser: build.mutation({
      query: body => ({
        url: `auth/users`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useLoginUserMutation, useLogoutUserMutation } = authApi;
