/** @format */
export const CRM = 'CRM';
export const USERS = 'Users';
export const CLIENTS = 'Clients';
export const CONTROL_LISTS = 'Control Lists';
export const DEVICES = 'Devices';
export const FARES = 'Fares';
export const JOBS = 'Jobs';
export const MONITORING = 'Monitoring';
export const PARTNERS = 'Partners & Programs';
export const PASSES = 'Passes';
export const REVENUE = 'Revenue';
export const TICKETING = 'Ticketing';
export const TOPOLOGY = 'Topology';
export const DEPLOYMENTS = 'Deployments';
export const TELESCOPE = 'Telescope';
export const VOUCHERS = 'Vouchers';
export const MEDIA_MANGER = 'Media Manager';

//colors
export const COLOR_GREEN = 'green';
export const COLOR_LIGHT_GREEN = 'light-green';
export const COLOR_BLUE = 'blue';
export const COLOR_SKY = 'sky';
export const COLOR_YELLOW = 'yellow';
export const COLOR_LIGHT_YELLOW = 'light-yellow';
export const COLOR_RED = 'red';
export const COLOR_PINK = 'pink';
export const COLOR_INDIGO = 'indigo';
export const COLOR_ORANGE = 'orange';
export const COLOR_GRAY = 'gray';
export const COLOR_GREY = 'grey';
export const COLOR_PURPLE = 'purple';
export const COLOR_TRANSPARENT = 'transparent';
export const COLOR_AMBER = 'amber';

// store keys
export const TOKEN = 'TOKEN';
export const CLIENT = 'client';
export const STORE_CLIENTS = 'clients';
export const ME = 'me';
export const SLUGS = 'slugs';
export const PERMISSIONS = 'permissions';
export const TRANSLATIONS = 'translations';
export const LAST_TIME_TRANSLATIONS = 'lastTranslatesRequestTime';

//module name keys
export const CRM_MODULE = 'crm';
export const FRONTDESK_WEB_MODULE = 'frontdesk_web';
export const MEDIA_MANAGER_MODULE = 'media_manager';
export const PASS_INFORMATION = 'pass_information';

export const PRELOAD_SRC_LIST = [
  '/assets/icons/logo_flowbirdhub.svg',
  '/assets/flag-us.svg',
  '/assets/flag-es.svg',
  '/assets/flag-en-US.svg',
  '/assets/flag-fr.svg',
  '/assets/images/mastercard.png',
  '/assets/images/americanExpress.png',
  '/assets/images/visa.png',
  '/assets/images/location-marker.png',
  '/assets/icons/map-pin-blue.svg',
];

export const DEFAULT_HIDDEN_HEADER_BUTTON_PROPS = [
  {
    children: 'Hidden',
    className: 'invisible',
  },
];

//mask
export const CURRENCY_DOLLAR_MASK = [
  {
    mask: '',
  },
  {
    mask: '$num',
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        padFractionalZeros: true,
        scale: 2,
        radix: '.',
        mapToRadix: ['.'],
        max: 999999,
        min: 0,
      },
    },
  },
];

export const MASK_MINUTE = [
  { mask: '' },
  {
    mask: 'num min',
    lazy: false,
    expose: true,
    blocks: {
      num: {
        mask: Number,
        min: 0,
        max: 60,
      },
    },
  },
];

export const MASK_PERCENT = [
  { mask: '' },
  {
    mask: '%num',
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        min: 0,
        max: 100,
      },
    },
  },
];
export const MASK_POSITIVE_NUMBER = [
  { mask: '' },
  {
    mask: Number,
    from: 0,
  },
];

export const DEFAULT_PAYMENT_CARDS = [
  {
    value: 'visa',
    assetFileName: 'visa',
  },
  {
    value: 'master',
    assetFileName: 'mastercard',
  },
  {
    value: 'amex',
    assetFileName: 'americanExpress',
  },
  {
    value: 'discover',
    assetFileName: 'discoverCard',
  },
];

export const MASK_MEDIA_ID = '0000 0000 0000 0000';
export const MASK_SECURITY_CODE = '0000';

export const EMAIL_MIN_SEARCH_CHAR_LENGTH = 3;
export const MEDIA_MIN_SEARCH_CHAR_LENGTH = 4;

export const REGEXP_MASK_MEDIA_SEARCH = /^[0-9]\d{0,15}$/;

export const UUID_CART = 'cartUuid';
export const CART_DATA = 'cartData';
export const CART_RELOAD_VALUE = 'reloadValue';
export const CART_ADD_VALUE = 'addValue';
export const ACCESS_DENIED = 'accessDenied';

export const DEFAULT_BRAND_OPTIONS = [
  {
    value: 'visa',
    name: 'Visa',
  },
  {
    value: 'mastercard',
    name: 'Mastercard',
  },
  {
    value: 'amex',
    name: 'American Express',
  },
];
