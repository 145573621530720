/**@format */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CalendarIcon } from '@heroicons/react/solid';
import { IMaskInput, IMask } from 'react-imask';
import moment from 'moment-timezone';

import { classNames } from 'helpers';

import { datetimePickerModeEnum, datetimePickerPosition } from '../constants/enum';
import { PikadayDropdown } from './PickerComponents/PikadayDropdown';

export const DatepickerTextField = ({
  id,
  className,
  placeholder,
  required,
  value,
  onChange,
  onBlur,
  maxDate,
  minDate,
  isEndDay,
  hasTimePicker,
  positionX = datetimePickerPosition.LEFT,
  positionY = datetimePickerPosition.BOTTOM,
  type,
  overwrite,
  timezone,
  ...props
}) => {
  const maskTextFieldRef = useRef(null);
  const textFieldRef = useRef(null);
  const [currentMode, setCurrentMode] = useState(null);
  const [calendarButtonCooldown, setCalendarButtonCooldown] = useState(null);

  const iMaskPattern = hasTimePicker ? 'MM/DD/YYYY | hh:mm:ss A' : 'MM/DD/YYYY';
  let iMaskDateBlocks = {
    YYYY: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 9999,
      placeholderChar: 'Y',
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      placeholderChar: 'M',
    },
    DD: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      placeholderChar: 'D',
    },
  };
  let iMaskTimeBlocks = {
    hh: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 12,
      placeholderChar: 'h',
    },
    mm: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
      placeholderChar: 'm',
    },
    ss: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
      placeholderChar: 's',
    },
    A: {
      mask: IMask.MaskedEnum,
      enum: ['AM', 'PM'],
      placeholderChar: 'A',
    },
  };

  const handleTriggerDatepicker = e => {
    e.stopPropagation();
    if (currentMode) {
      setCurrentMode(null);
    } else setCurrentMode(datetimePickerModeEnum.DATE_PICKER);
  };
  const handleChange = e => {
    onChange && onChange(e);
  };
  const handleSwitchMode = useCallback(mode => {
    setCurrentMode(mode);
  }, []);
  const handleIMaskAccept = (_, mask) => {
    !mask.typedValue && !mask.unmaskedValue && handleChange({ target: { id, value: '' } });
  };
  const handleIMaskComplete = (_, mask) => {
    if (mask.typedValue) {
      handleChange({
        target: { id, value: mask.typedValue.valueOf().toString() },
      });
    }
  };
  const handleIMaskValidate = (value, mask) => {
    const limitLength = hasTimePicker ? 16 : 8;
    const parsedDate = moment(value, iMaskPattern);
    let isInputValid = true;
    if (mask.unmaskedValue?.length === limitLength) {
      isInputValid = parsedDate.isValid();
    }
    return isInputValid;
  };
  const handleBlur = e => {
    if (currentMode) {
      const wrapperEl = document.getElementById(`${id}-pika-wrapper`);
      if (!wrapperEl.contains(e.relatedTarget)) {
        setCurrentMode(null);
        /**Set calendar button disabled for a while
         * to prevent handleTriggerDatepicker() fired
         * if mouse pointed in this button when blur */
        setCalendarButtonCooldown(300);
      }
    }
    onBlur && onBlur(e);
  };

  useEffect(() => {
    if (value) {
      /**newMaskValue mush be same value with IMaskInput 'format' prop below */
      const newMaskValue = timezone
        ? moment.tz(+value, timezone).format(iMaskPattern)
        : moment(+value).format(iMaskPattern);
      maskTextFieldRef.current.maskValue !== newMaskValue &&
        (maskTextFieldRef.current.maskValue = newMaskValue);
    } else if (value?.length === 0) {
      maskTextFieldRef.current.maskValue = '';
    }
  }, [iMaskPattern, timezone, value]);
  useEffect(() => {
    if (typeof calendarButtonCooldown === 'number') {
      setTimeout(() => {
        setCalendarButtonCooldown(null);
      }, calendarButtonCooldown);
    }
  }, [calendarButtonCooldown]);

  return (
    <>
      <IMaskInput
        id={id}
        type='text'
        mask={Date}
        pattern={iMaskPattern}
        ref={maskTextFieldRef}
        inputRef={el => {
          textFieldRef.current = el;
        }}
        lazy={false}
        blocks={hasTimePicker ? { ...iMaskDateBlocks, ...iMaskTimeBlocks } : iMaskDateBlocks}
        format={date =>
          timezone
            ? moment.tz(date, timezone).format(iMaskPattern)
            : moment(date).format(iMaskPattern)
        }
        parse={str =>
          timezone ? moment.tz(str, iMaskPattern, timezone) : moment(str, iMaskPattern)
        }
        onAccept={handleIMaskAccept}
        className={classNames({ 'text-gray-400': !value }, className)}
        placeholder={placeholder}
        required={required}
        onBlur={handleBlur}
        overwrite={overwrite ?? 'shift'}
        validate={handleIMaskValidate}
        onComplete={handleIMaskComplete}
        {...props}
      />
      <div id={`${id}-pika-wrapper`}>
        <PikadayDropdown
          ref={textFieldRef}
          id={id}
          currentMode={currentMode}
          value={value}
          onChange={handleChange}
          isEndDay={isEndDay}
          maxDate={maxDate}
          minDate={minDate}
          hasTimePicker={hasTimePicker}
          positionX={positionX}
          positionY={positionY}
          onChangeCurrentMode={handleSwitchMode}
          type={type}
          timezone={timezone}
        />
      </div>
      <button
        type='button'
        className={classNames('absolute', 'inset-y-0 right-3', 'my-auto', 'h-fit', 'z-20')}
        disabled={!!calendarButtonCooldown || props.disabled}
        onClick={handleTriggerDatepicker}
      >
        <CalendarIcon className={classNames('fill-gray-400', 'w-5 h-5', 'hover:fill-indigo-600')} />
      </button>
    </>
  );
};
