/** @format */

import {
  BriefcaseIcon,
  CashIcon,
  ClipboardCheckIcon,
  ClipboardCopyIcon,
  ClipboardListIcon,
  DesktopComputerIcon,
  DeviceTabletIcon,
  EyeIcon,
  LightningBoltIcon,
  LocationMarkerIcon,
  TagIcon,
  TemplateIcon,
  TicketIcon,
  UsersIcon,
} from '@heroicons/react/outline';

import {
  USERS,
  CLIENTS,
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_GREEN,
  COLOR_INDIGO,
  COLOR_ORANGE,
  COLOR_PINK,
  COLOR_RED,
  COLOR_YELLOW,
  CONTROL_LISTS,
  CRM,
  DEPLOYMENTS,
  DEVICES,
  FARES,
  JOBS,
  MONITORING,
  PARTNERS,
  PASSES,
  REVENUE,
  TELESCOPE,
  TICKETING,
  TOPOLOGY,
  VOUCHERS,
  MEDIA_MANGER,
} from 'constants/Constants';
import { creditCardTypeEnum } from 'constants/enum';
import { DEFAULT_PHONE_COUNTRY_CODE } from 'components/TextFieldNumberPhone/Constants/Constants';

export const ALL_FEATURES_DATA = [
  {
    id: REVENUE,
    title: REVENUE,
    subTitle: 'Short description.',
    color: COLOR_BLUE,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: CashIcon,
    link: `dashboard/revenue/statistics`,
    isFavorites: false,
  },
  {
    id: USERS,
    title: USERS,
    subTitle: 'Short description.',
    color: COLOR_BLUE,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: UsersIcon,
    link: 'dashboard/users/inventory',
    isFavorites: false,
  },
  {
    id: MEDIA_MANGER,
    title: MEDIA_MANGER,
    subTitle: 'Short description.',
    color: COLOR_YELLOW,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: TemplateIcon,
    link: 'dashboard/media-manager/media-manager',
    isFavorites: false,
  },
  {
    id: CRM,
    title: CRM,
    subTitle: 'Short description.',
    color: COLOR_ORANGE,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: TemplateIcon,
    link: 'dashboard/crm/patrons',
    isFavorites: false,
  },
  {
    id: CLIENTS,
    title: CLIENTS,
    subTitle: 'Short description.',
    color: COLOR_GREEN,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: UsersIcon,
    link: 'dashboard/clients',
    isFavorites: false,
  },
  {
    id: CONTROL_LISTS,
    title: CONTROL_LISTS,
    subTitle: 'Short description.',
    color: COLOR_INDIGO,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: ClipboardCheckIcon,
    link: 'dashboard/control',
    isFavorites: false,
  },
  {
    id: DEVICES,
    title: DEVICES,
    subTitle: 'Short description.',
    color: COLOR_RED,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: DeviceTabletIcon,
    link: 'dashboard/devices',
    isFavorites: false,
  },
  {
    id: FARES,
    title: FARES,
    subTitle: 'Short description.',
    color: COLOR_PINK,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: TicketIcon,
    link: 'dashboard/fares',
    isFavorites: false,
  },
  {
    id: JOBS,
    title: JOBS,
    subTitle: 'Short description.',
    color: COLOR_YELLOW,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: ClipboardCopyIcon,
    link: 'dashboard/jobs',
    isFavorites: false,
  },
  {
    id: MONITORING,
    title: MONITORING,
    subTitle: 'Short description.',
    color: COLOR_INDIGO,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: DesktopComputerIcon,
    link: 'dashboard/monitoring',
    isFavorites: false,
  },
  {
    id: PARTNERS,
    title: PARTNERS,
    subTitle: 'Short description.',
    color: COLOR_BLUE,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: BriefcaseIcon,
    link: 'dashboard/partners',
    isFavorites: false,
  },
  {
    id: PASSES,
    title: PASSES,
    subTitle: 'Short description.',
    color: COLOR_YELLOW,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: TicketIcon,
    link: 'dashboard/passes/settings',
    isFavorites: false,
  },
  {
    id: TICKETING,
    title: TICKETING,
    subTitle: 'Short description.',
    color: COLOR_GRAY,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: ClipboardListIcon,
    link: 'dashboard/ticketing',
    isFavorites: false,
  },
  {
    id: TOPOLOGY,
    title: TOPOLOGY,
    subTitle: 'Short description.',
    color: COLOR_GRAY,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: LocationMarkerIcon,
    link: 'dashboard/topology',
    isFavorites: false,
  },
];

export const FAVORITES_DATA = [
  {
    id: DEPLOYMENTS,
    title: DEPLOYMENTS,
    subTitle: 'Short description.',
    color: COLOR_INDIGO,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: LightningBoltIcon,
    link: 'dashboard/deployments',
    isFavorites: true,
  },
  {
    id: TELESCOPE,
    title: TELESCOPE,
    subTitle: 'Short description.',
    color: COLOR_GRAY,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: EyeIcon,
    link: 'dashboard/telescope',
    isFavorites: true,
  },
  {
    id: VOUCHERS,
    title: VOUCHERS,
    subTitle: 'Short description.',
    color: COLOR_RED,
    message: 'Tristique sed turpis risus, auctor et. Et lectus tortor, a lectus pharetra.',
    icon: TagIcon,
    link: 'dashboard/vouchers',
    isFavorites: true,
  },
];

export const CREDITCARDS_DATA = [
  {
    id: 1,
    type: creditCardTypeEnum.MASTERCARD,
    name: 'Amex Biz',
    label: 'Lionel Deperrois',
    isDefault: true,
    cardNumber: '5416 5644 5511 5677',
    expDate: '12/28',
  },
  {
    id: 2,
    type: creditCardTypeEnum.AMERICAN_EXPRESS,
    name: 'AE Test',
    label: 'Lionel Deperrois',
    isDefault: false,
    cardNumber: '5416 5644 5512 5678',
    expDate: '12/28',
  },
  {
    id: 3,
    type: creditCardTypeEnum.VISA,
    name: 'Visa Test',
    label: 'Lionel Deperrois',
    isDefault: false,
    cardNumber: '5416 5644 5513 5679',
    expDate: '12/28',
  },
];

export const CMR_PATRONS_PROFILES_DATA = [
  {
    id: 1,
    partner: { id: 1, name: 'Some Partner 1' },
    isPartnerValid: true,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4858',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4859',
      },
      {
        id: 'm_03',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4860',
      },
    ],
    startDate: '1628184360000',
    endDate: '1628184360000',
  },
  {
    id: 2,
    partner: { id: 1, name: 'IndyGo' },
    isPartnerValid: true,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4861',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4862',
      },
      {
        id: 'm_03',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4863',
      },
    ],
    startDate: '1628184360000',
    endDate: '1628184360000',
  },
  {
    id: 3,
    partner: { id: 1, name: 'Some Partner 2' },
    isPartnerValid: true,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4864',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_03',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
    ],
    startDate: '1628184360000',
    endDate: '1628184360000',
  },
  {
    id: 4,
    partner: { id: 1, name: 'IndyGo' },
    isPartnerValid: true,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_03',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
    ],
    startDate: '1628184360000',
    endDate: '1628184360000',
  },
  {
    id: 5,
    partner: { id: 1, name: 'IndyGo' },
    isPartnerValid: true,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_03',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
    ],
    startDate: '1628184360000',
    endDate: '1628184360000',
  },
  {
    id: 6,
    partner: { id: 1, name: 'IndyGo' },
    isPartnerValid: true,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_03',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
    ],
    startDate: '1628184360000',
    endDate: '1628184360000',
  },
  {
    id: 7,
    partner: { id: 1, name: 'IndyGo' },
    isPartnerValid: true,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_03',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
    ],
    startDate: '1628184360000',
    endDate: '1628184360000',
  },
  {
    id: 8,
    partner: { id: 1, name: 'IndyGo' },
    isPartnerValid: true,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_03',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
    ],
    startDate: '1628184360000',
    endDate: '1628184360000',
  },
  {
    id: 9,
    partner: { id: 1, name: 'IndyGo' },
    isPartnerValid: true,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_03',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
    ],
    startDate: '1628184360000',
    endDate: '1628184360000',
  },
  {
    id: 10,
    partner: { id: 1, name: 'IndyGo' },
    isPartnerValid: true,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_03',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
    ],
    startDate: '1628184360000',
    endDate: '1628184360000',
  },
  {
    id: 11,
    partner: { id: 1, name: 'IndyGo' },
    isPartnerValid: true,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
      {
        id: 'm_03',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4868',
      },
    ],
    startDate: '1628184360000',
    endDate: '1628184360000',
  },
  {
    id: 12,
    partner: { id: 1, name: 'IndyGo' },
    isPartnerValid: true,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4841',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4840',
      },
      {
        id: 'm_03',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4839',
      },
    ],
    startDate: '1628184360000',
    endDate: '1628184360000',
  },
  {
    id: 13,
    partner: { id: 1, name: 'IndyGo' },
    isPartnerValid: false,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4838',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4837',
      },
      {
        id: 'm_03',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4836',
      },
    ],
    startDate: '1628184360000',
    endDate: '1628184360000',
  },
  {
    id: 14,
    partner: { id: 1, name: 'Partner 10 - Mike' },
    isPartnerValid: false,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 35',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4834',
      },
      {
        id: 'm_03',
        name: 'Media Name some',
        number: '4567 3857 6785 4833',
      },
    ],
    startDate: '1628184360000',
    endDate: '1628184360000',
  },
  {
    id: 15,
    partner: { id: 1, name: 'Some Partner 3' },
    isPartnerValid: false,
    program: { id: 1, name: 'IndyGo, Employee' },
    media: [
      {
        id: 'm_01',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4832',
      },
      {
        id: 'm_02',
        name: 'IndyGo, Employee',
        number: '4567 3857 6785 4833',
      },
      {
        id: 'm_03',
        name: 'Media Name 9',
        number: '4567 3857 6785 4834',
      },
    ],
    startDate: '1653049668000',
    endDate: '1653395268000',
  },
];

export const CMR_PATRONS_STORED_CARDS_DATA = [
  {
    id: 1,
    title: { id: 1, name: 'Ride Indy Easy!' },
    label: 'Activated',
    card: { id: 1, name: '** 5148', exp: ' exp.  01/24', type: 'master' },
    info: {
      id: 'card_01',
      cost: '$32.00',
      lastActivity: '1653545148000',
    },
    share: null,
    scan: true,
  },
  {
    id: 2,
    title: { id: 1, name: 'My son’s card' },
    label: 'Activated',
    card: { id: 1, name: '** 5156', exp: ' exp.  01/24', type: 'express' },
    info: {
      id: 'card_02',
      cost: '$10.00',
      lastActivity: '1653458748000',
    },
    share: 'Shared',
    scan: true,
  },
  {
    id: 3,
    title: { id: 1, name: 'MCTS Demo!' },
    label: 'Activated',
    card: { id: 1, name: '** 5132', exp: ' exp.  01/24', type: 'visa' },
    info: {
      id: 'card_03',
      cost: '$24.00',
      lastActivity: '1652940348000',
    },
    share: null,
    scan: true,
  },
];

export const CRM_NOTIFICATIONS_DATA = [
  {
    id: 1,
    name: 'Test',
    category: 'success',
    startDate: 1628184360000,
    endDate: 1628184360000,
    status: 'active',
  },
  {
    id: 2,
    name: 'Test',
    category: 'warning',
    startDate: 1628184360000,
    endDate: 1628184360000,
    status: 'inActive',
  },
  {
    id: 3,
    name: 'Test',
    category: 'info',
    startDate: 1628184360000,
    endDate: 1628184360000,
    status: 'pending',
  },
  {
    id: 4,
    name: 'Test',
    category: 'error',
    startDate: 1628184360000,
    endDate: 1628184360000,
    status: 'expired',
  },
];

export const CRM_PATRONS_PASS_INFORMATION_DATA = {
  id: 0,
  duration: '2h 26min',
  expiresDate: 'Fri, Aug 20th 2021 12:12 PM CDT',
  qr: 'some_qr',
  map: { lat: -25.363, lng: 131.044 },
  pass: [
    {
      id: 'product',
      title: 'Product',
      contain: '00 - Day pass resident',
    },
    {
      id: 'product_code',
      title: 'Product Code',
      contain: 'DP_CH',
    },
    {
      id: 'concession',
      title: 'Concession',
      contain: '02 - Child',
    },
    {
      id: 'price',
      title: 'Price',
      contain: '$2.00',
    },
  ],
};

export const MONITORING_NOTIFICATIONS_DATA = [
  {
    id: 1,
    name: 'Test',
    description: 'Fermentum, viverra lorem tempus mauris.',
    deviceType: 'Axio',
    subscribers: [
      {
        id: 1,
        name: 'subscriber no 1',
        email: 'subscriberNo1@email.com',
        phone: '(123) 456 789',
      },
    ],
    ofAlarms: [
      {
        id: 1,
        category: 'Barcode',
        level: 'error',
        label: 'Barcode communication error',
        description: 'Fermentum, viverra lorem tempus mauris.',
      },
      {
        id: 2,
        category: 'Barcode',
        level: 'error',
        label: 'Barcode communication error',
        description: 'Fermentum, viverra lorem tempus mauris.',
      },
    ],
    schedules: [
      {
        id: 1,
        name: 'schedule no 1',
        description: 'schedule no 1 description',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
    ],
    status: 'active',
  },
  {
    id: 2,
    name: 'Test',
    description: 'Fermentum, viverra lorem tempus mauris.',
    deviceType: 'Axio',
    subscribers: [],
    ofAlarms: [],
    schedules: [],
    status: 'inactive',
  },
  {
    id: 3,
    name: 'Test',
    description: 'Fermentum, viverra lorem tempus mauris.',
    deviceType: 'Axio',
    subscribers: [
      {
        id: 1,
        name: 'subscriber no 1',
        email: 'subscriberNo1@email.com',
        phone: '(123) 456 789',
      },
    ],
    ofAlarms: [
      {
        id: 1,
        category: 'Barcode',
        level: 'error',
        label: 'Barcode communication error',
        description: 'Fermentum, viverra lorem tempus mauris.',
      },
      {
        id: 2,
        category: 'Barcode',
        level: 'error',
        label: 'Barcode communication error',
        description: 'Fermentum, viverra lorem tempus mauris.',
      },
    ],
    schedules: [
      {
        id: 1,
        name: 'schedule no 1',
        description: 'schedule no 1 description',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
    ],
    status: 'active',
  },
  {
    id: 4,
    name: 'Test',
    description: 'Fermentum, viverra lorem tempus mauris.',
    deviceType: 'Axio',
    subscribers: [],
    ofAlarms: [],
    schedules: [],
    status: 'inactive',
  },
  {
    id: 5,
    name: 'Test',
    description: 'Fermentum, viverra lorem tempus mauris.',
    deviceType: 'Axio',
    subscribers: [
      {
        id: 1,
        name: 'subscriber no 1',
        email: 'subscriberNo1@email.com',
        phone: '(123) 456 789',
      },
    ],
    ofAlarms: [
      {
        id: 1,
        category: 'Barcode',
        level: 'error',
        label: 'Barcode communication error',
        description: 'Fermentum, viverra lorem tempus mauris.',
      },
      {
        id: 2,
        category: 'Barcode',
        level: 'error',
        label: 'Barcode communication error',
        description: 'Fermentum, viverra lorem tempus mauris.',
      },
    ],
    schedules: [
      {
        id: 1,
        name: 'schedule no 1',
        description: 'schedule no 1 description',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
    ],
    status: 'active',
  },
  {
    id: 6,
    name: 'Test',
    description: 'Fermentum, viverra lorem tempus mauris.',
    deviceType: 'Axio',
    subscribers: [],
    ofAlarms: [],
    schedules: [],
    status: 'inactive',
  },
  {
    id: 7,
    name: 'Test',
    description: 'Fermentum, viverra lorem tempus mauris.',
    deviceType: 'Axio',
    subscribers: [
      {
        id: 1,
        name: 'subscriber no 1',
        email: 'subscriberNo1@email.com',
        phone: '(123) 456 789',
      },
    ],
    ofAlarms: [
      {
        id: 1,
        category: 'Barcode',
        level: 'error',
        label: 'Barcode communication error',
        description: 'Fermentum, viverra lorem tempus mauris.',
      },
      {
        id: 2,
        category: 'Barcode',
        level: 'error',
        label: 'Barcode communication error',
        description: 'Fermentum, viverra lorem tempus mauris.',
      },
    ],
    schedules: [
      {
        id: 1,
        name: 'schedule no 1',
        description: 'schedule no 1 description',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
    ],
    status: 'active',
  },
  {
    id: 8,
    name: 'Test',
    description: 'Fermentum, viverra lorem tempus mauris.',
    deviceType: 'Axio',
    subscribers: [],
    ofAlarms: [],
    schedules: [],
    status: 'inactive',
  },
  {
    id: 9,
    name: 'Test',
    description: 'Fermentum, viverra lorem tempus mauris.',
    deviceType: 'Axio',
    subscribers: [
      {
        id: 1,
        name: 'subscriber no 1',
        email: 'subscriberNo1@email.com',
        phone: '(123) 456 789',
      },
    ],
    ofAlarms: [
      {
        id: 1,
        category: 'Barcode',
        level: 'error',
        label: 'Barcode communication error',
        description: 'Fermentum, viverra lorem tempus mauris.',
      },
      {
        id: 2,
        category: 'Barcode',
        level: 'error',
        label: 'Barcode communication error',
        description: 'Fermentum, viverra lorem tempus mauris.',
      },
    ],
    schedules: [
      {
        id: 1,
        name: 'schedule no 1',
        description: 'schedule no 1 description',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
    ],
    status: 'active',
  },
  {
    id: 10,
    name: 'Test',
    description: 'Fermentum, viverra lorem tempus mauris.',
    deviceType: 'Axio',
    subscribers: [],
    ofAlarms: [],
    schedules: [],
    status: 'inactive',
  },
  {
    id: 11,
    name: 'Test',
    description: 'Fermentum, viverra lorem tempus mauris.',
    deviceType: 'Axio',
    subscribers: [
      {
        id: 1,
        name: 'subscriber no 1',
        email: 'subscriberNo1@email.com',
        phone: '(123) 456 789',
      },
    ],
    ofAlarms: [
      {
        id: 1,
        category: 'Barcode',
        level: 'error',
        label: 'Barcode communication error',
        description: 'Fermentum, viverra lorem tempus mauris.',
      },
      {
        id: 2,
        category: 'Barcode',
        level: 'error',
        label: 'Barcode communication error',
        description: 'Fermentum, viverra lorem tempus mauris.',
      },
    ],
    schedules: [
      {
        id: 1,
        name: 'schedule no 1',
        description: 'schedule no 1 description',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
    ],
    status: 'active',
  },
  {
    id: 12,
    name: 'Test',
    description: 'Fermentum, viverra lorem tempus mauris.',
    deviceType: 'Axio',
    subscribers: [
      { id: 1, name: 'John Doe', email: 'example@mail.com', phoneNumber: '+1 (777) 886 6551' },
      { id: 2, name: 'John Doe', email: 'example@mail.com', phoneNumber: '+1 (777) 886 6551' },
      { id: 3, name: 'John Doe', email: 'example@mail.com', phoneNumber: '+1 (777) 886 6551' },
      { id: 4, name: 'John Doe', email: 'example@mail.com', phoneNumber: '+1 (777) 886 6551' },
      { id: 5, name: 'John Doe', email: 'example@mail.com', phoneNumber: '+1 (777) 886 6551' },
      { id: 6, name: 'John Doe', email: 'example@mail.com', phoneNumber: '+1 (777) 886 6551' },
      { id: 7, name: 'John Doe', email: 'example@mail.com', phoneNumber: '+1 (777) 886 6551' },
      { id: 8, name: 'John Doe', email: 'example@mail.com', phoneNumber: '+1 (777) 886 6551' },
      { id: 9, name: 'John Doe', email: 'example@mail.com', phoneNumber: '+1 (777) 886 6551' },
      { id: 10, name: 'John Doe', email: 'example@mail.com', phoneNumber: '+1 (777) 886 6551' },
      { id: 11, name: 'John Doe', email: 'example@mail.com', phoneNumber: '+1 (777) 886 6551' },
      { id: 12, name: 'John Doe', email: 'example@mail.com', phoneNumber: '+1 (777) 886 6551' },
    ],
    ofAlarms: [],
    schedules: [
      {
        id: 1,
        name: 'Schedule name',
        description: 'Some text for schedule description.',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
      {
        id: 2,
        name: 'Schedule name',
        description: 'Some text for schedule description.',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
      {
        id: 3,
        name: 'Schedule name',
        description: 'Some text for schedule description.',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
      {
        id: 4,
        name: 'Schedule name',
        description: 'Some text for schedule description.',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
      {
        id: 5,
        name: 'Schedule name',
        description: 'Some text for schedule description.',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
      {
        id: 6,
        name: 'Schedule name',
        description: 'Some text for schedule description.',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
      {
        id: 7,
        name: 'Schedule name',
        description: 'Some text for schedule description.',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
      {
        id: 8,
        name: 'Schedule name',
        description: 'Some text for schedule description.',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
      {
        id: 9,
        name: 'Schedule name',
        description: 'Some text for schedule description.',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
      {
        id: 10,
        name: 'Schedule name',
        description: 'Some text for schedule description.',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
      {
        id: 11,
        name: 'Schedule name',
        description: 'Some text for schedule description.',
        startTime: '10:15:00 AM',
        endTime: '11:15:00 AM',
        startDate: 1628184360000,
        endDate: 1628184360000,
        months: ['Mar', 'Apr', 'Jun'],
        days: ['Mon', 'Tue', 'Wed'],
      },
    ],
    exporters: [
      { id: 1, name: 'Exporter no1', dateAdded: 1628184360000 },
      { id: 2, name: 'Exporter no2', dateAdded: 1628184360000 },
      { id: 3, name: 'Exporter no3', dateAdded: 1628184360000 },
    ],
    status: 'inactive',
  },
];

export const MONITORING_ALARMS_AXIO_CATEGORIES = [
  { id: 1, name: 'Communications' },
  { id: 2, name: 'Barcode' },
  { id: 3, name: 'System' },
  { id: 4, name: 'Other' },
  { id: 5, name: 'Contactless' },
  { id: 6, name: 'Customer' },
  { id: 7, name: 'Internal Memory' },
  { id: 8, name: 'Validation' },
];

export const MONITORING_ALARMS_TVM_CATEGORIES = [
  { id: 1, name: 'Application' },
  { id: 2, name: 'Bank Note Acceptor' },
  { id: 3, name: 'Battery' },
  { id: 4, name: 'Card Reader' },
  { id: 5, name: 'Cash Collection' },
  { id: 6, name: 'Coin Box' },
  { id: 7, name: 'Coin Selector' },
  { id: 8, name: 'Coin System' },
  { id: 9, name: 'Communications' },
  { id: 10, name: 'Mainboard' },
];

export const MONITORING_ALARMS_AXIO_DATA = [
  {
    id: 1,
    alarmLabel: 'Communication Error',
    categoryId: 1,
    status: 'warning',
    assetId: 'ALB_0001',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 2,
    alarmLabel: 'No Data',
    categoryId: 3,
    status: 'error',
    assetId: 'ALB_0001',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 1,
  },
  {
    id: 3,
    alarmLabel: 'System Network Unavailable',
    categoryId: 3,
    status: 'error',
    assetId: 'ALB_0001',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 4,
    alarmLabel: 'Maestro Location Communication Error',
    categoryId: 3,
    status: 'error',
    assetId: 'ALB_0001',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 1,
  },
  {
    id: 5,
    alarmLabel: 'Communication Error',
    categoryId: 1,
    status: 'error',
    assetId: 'ALB_0001',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 6,
    alarmLabel: 'No Data',
    categoryId: 3,
    status: 'error',
    assetId: 'ALB_0001',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 7,
    alarmLabel: 'No Data',
    categoryId: 3,
    status: 'error',
    assetId: 'ALB_0001',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 8,
    alarmLabel: 'Maestro Location Communication Error',
    categoryId: 3,
    status: 'error',
    assetId: 'ALB_0001',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 9,
    alarmLabel: 'Communication Error',
    categoryId: 1,
    status: 'warning',
    assetId: 'ALB_0002',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 1,
  },
  {
    id: 10,
    alarmLabel: 'No Data',
    categoryId: 3,
    status: 'error',
    assetId: 'ALB_0002',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 1,
  },
  {
    id: 11,
    alarmLabel: 'System Network Unavailable',
    categoryId: 3,
    status: 'error',
    assetId: 'ALB_0002',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 12,
    alarmLabel: 'Maestro Location Communication Error',
    categoryId: 3,
    status: 'error',
    assetId: 'ALB_0002',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 13,
    alarmLabel: 'Communication Error',
    categoryId: 1,
    status: 'warning',
    assetId: 'ALB_0002',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 14,
    alarmLabel: 'No Data',
    categoryId: 3,
    status: 'error',
    assetId: 'ALB_0002',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 1,
  },
  {
    id: 15,
    alarmLabel: 'No Data',
    categoryId: 3,
    status: 'error',
    assetId: 'ALB_0002',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 16,
    alarmLabel: 'Maestro Location Communication Error',
    categoryId: 3,
    status: 'error',
    assetId: 'ALB_0002',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    software: '1.0.3',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
];

export const MONITORING_ALARMS_TVM_DATA = [
  {
    id: 1,
    alarmLabel: 'Communication Error',
    categoryId: 1,
    status: 'warning',
    assetId: '34569999',
    assetName: 'CRESTVIEW TVM 01',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 2,
    alarmLabel: 'No Data',
    categoryId: 3,
    status: 'error',
    assetId: '34569999',
    assetName: 'CRESTVIEW TVM 01',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 1,
  },
  {
    id: 3,
    alarmLabel: 'System Network Unavailable',
    categoryId: 3,
    status: 'error',
    assetId: '34569999',
    assetName: 'CRESTVIEW TVM 01',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 1,
  },
  {
    id: 4,
    alarmLabel: 'Maestro Location Communication Error',
    categoryId: 3,
    status: 'error',
    assetId: '34569999',
    assetName: 'CRESTVIEW TVM 01',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 5,
    alarmLabel: 'Communication Error',
    categoryId: 1,
    status: 'error',
    assetId: 'TVM_66547',
    assetName: 'FLOWBIRD TEST TVM 02',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 6,
    alarmLabel: 'No Data',
    categoryId: 3,
    status: 'error',
    assetId: 'TVM_66547',
    assetName: 'FLOWBIRD TEST TVM 02',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 7,
    alarmLabel: 'No Data',
    categoryId: 3,
    status: 'error',
    assetId: 'TVM_66547',
    assetName: 'FLOWBIRD TEST TVM 02',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 8,
    alarmLabel: 'Maestro Location Communication Error',
    categoryId: 3,
    status: 'error',
    assetId: 'TVM_66547',
    assetName: 'FLOWBIRD TEST TVM 02',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 9,
    alarmLabel: 'Communication Error',
    categoryId: 1,
    status: 'warning',
    assetId: '23456',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 1,
  },
  {
    id: 10,
    alarmLabel: 'No Data',
    categoryId: 3,
    status: 'error',
    assetId: '23456',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 1,
  },
  {
    id: 11,
    alarmLabel: 'System Network Unavailable',
    categoryId: 3,
    status: 'error',
    assetId: '23456',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 12,
    alarmLabel: 'Maestro Location Communication Error',
    categoryId: 3,
    status: 'error',
    assetId: '23456',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 13,
    alarmLabel: 'Communication Error',
    categoryId: 1,
    status: 'error',
    assetId: '23456',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 14,
    alarmLabel: 'No Data',
    categoryId: 3,
    status: 'error',
    assetId: '23456',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 2,
  },
  {
    id: 15,
    alarmLabel: 'No Data',
    categoryId: 3,
    status: 'error',
    assetId: '23456',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 1,
  },
  {
    id: 16,
    alarmLabel: 'Maestro Location Communication Error',
    categoryId: 3,
    status: 'error',
    assetId: '23456',
    assetName: 'AXIO LOC BUS 0001',
    assetLabel: 'Downtown tvm 01',
    parameters: '1.8.2',
    encryptionKeys: 'V2.0.2',
    location: 'BUS 0001',
    firstReported: 1628184360000,
    lastReported: 1628184360000,
    resetStatus: 3,
  },
];

export const VOUCHERS_AUDIT_DATA = [
  {
    id: 1,
    date_time: 1654519711140,
    uuid: '8BCA63858CBB',
    redeem_code: 'VOUCHERCODE',
    type: 'TVM',
    source: 36456788,
    operation: 'Voucher Redeemed',
    details: {
      amount: 7.0,
      balance: 0.0,
      user_count: 1,
      transaction: 123456,
    },
  },
  {
    id: 2,
    date_time: 1654117200000,
    uuid: '8BCA63858CBA',
    redeem_code: null,
    type: 'MKT',
    source: 36456788,
    operation: 'Voucher Redeemed',
    details: {
      amount: 7.0,
      balance: null,
      user_count: null,
      transaction: 123452,
    },
  },
  {
    id: 3,
    date_time: 1654519711140,
    uuid: '8BCA63858CBC',
    redeem_code: 'VOUCHERCODE',
    type: 'TVM',
    source: 36456788,
    operation: null,
    details: {
      amount: 7.0,
      balance: null,
      user_count: null,
      transaction: 123454,
    },
  },
  {
    id: 4,
    date_time: 1654519711140,
    uuid: '8BCA63858CBA',
    redeem_code: 'VOUCHERCODE',
    type: 'TVM',
    source: 36456789,
    operation: 'Voucher Redeemed',
    details: {
      amount: 8.0,
      balance: 1.0,
      user_count: 3,
      transaction: 123454,
    },
  },
];

export const CLIENTS_DATA = [
  {
    id: 3,
    name: 'Transit Demo 1 - USA',
    contactName: 'Tim',
    email: 'timothy.hindermyer@flowbird.group',
    timezone: 'America/New York',
    reference: '',
    loyaltyProgram: false,
    country: '',
    state: '',
    city: '',
  },
  {
    id: 9,
    name: 'Transit Demo 2 - USA',
    contactName: '',
    email: '',
    timezone: 'America/New York',
    reference: '',
    loyaltyProgram: false,
    country: '',
    state: '',
    city: '',
  },
  {
    id: 4,
    name: 'Transit Demo 3 - USA',
    contactName: '',
    email: '',
    timezone: 'America/New York',
    reference: '',
    loyaltyProgram: false,
    country: '',
    state: '',
    city: '',
  },
  {
    id: 2,
    name: 'Transit Demo 4 - USA',
    contactName: 'Tim',
    email: 'timothy.hindermyer@flowbird.group',
    timezone: 'America/Chicago',
    reference: '',
    loyaltyProgram: false,
    country: '',
    state: '',
    city: '',
  },
  {
    id: 8,
    name: 'Transit Demo 5 - USA',
    contactName: '',
    email: '',
    timezone: 'America/New York',
    reference: '',
    loyaltyProgram: false,
    country: '',
    state: '',
    city: '',
  },
  {
    id: 10,
    name: 'Transit Demo 6 - USA',
    contactName: 'Shawn Brown',
    email: 'shawn.brown@capmetro.org',
    timezone: 'America/Chicago',
    reference: '',
    loyaltyProgram: false,
    country: '',
    state: '',
    city: '',
  },
  {
    id: 1,
    name: 'Transit Demo 7 - USA',
    contactName: 'Tim',
    email: 'timothy.hindermyer@flowbird.group',
    timezone: 'America/Chicago',
    reference: '',
    loyaltyProgram: false,
    country: '',
    state: '',
    city: '',
  },
  {
    id: 7,
    name: 'Transit Demo 8 - USA',
    contactName: '',
    email: '',
    timezone: 'America/New York',
    reference: '',
    loyaltyProgram: false,
    country: '',
    state: '',
    city: '',
  },
  {
    id: 11,
    name: 'Transit Demo 9 - USA',
    contactName: '',
    email: '',
    timezone: 'America/New York',
    reference: '',
    loyaltyProgram: false,
    country: '',
    state: '',
    city: '',
  },
  {
    id: 6,
    name: 'Transit Demo 10 - USA',
    contactName: 'Tim',
    email: 'timothy.hindermyer@flowbird.group',
    timezone: 'America/Chicago',
    reference: '',
    loyaltyProgram: false,
    country: '',
    state: '',
    city: '',
  },
  {
    id: 5,
    name: 'Transit Demo 11 - USA',
    contactName: '',
    email: '',
    timezone: 'America/New York',
    reference: '',
    loyaltyProgram: false,
    country: '',
    state: '',
    city: '',
  },
  {
    id: 12,
    name: 'Transit Demo 12 - USA',
    contactName: 'Shawn Brown',
    email: '',
    timezone: 'America/New York',
    reference: '',
    loyaltyProgram: false,
    country: '',
    state: '',
    city: '',
  },
];

export const USERS_PERMISSIONS_GROUP_FEATURES_DATA = [
  {
    id: '0000 0000 0000 0001',
    name: 'Revenue',
  },
  {
    id: '0000 0000 0000 0002',
    name: 'Partners & Programs',
  },
  {
    id: '0000 0000 0000 0003',
    name: 'Monitoring',
  },
  {
    id: '0000 0000 0000 0004',
    name: 'Mobile & Web',
  },
];

export const USERS_PERMISSIONS_GROUP_MODULE_DATA = [
  {
    id: '0000 0000 0000 0001',
    name: 'Partners & Programs - All Permissions',
    description: 'All access to the Partners module.',
    featureId: '0000 0000 0000 0002',
    ofUsers: [1, 2],
    ofPermission: [1, 2, 4],
    ofGroups: ['0000 0000 0000 0001'],
  },
  {
    id: '0000 0000 0000 0002',
    name: 'Revenue - Full Access',
    description: 'Full access to the Revenue module.',
    featureId: '0000 0000 0000 0001',
    ofPermission: [2, 3],
    ofGroups: [
      '0000 0000 0000 0002',
      '0000 0000 0000 0002',
      '0000 0000 0000 0004',
      '0000 0000 0000 0005',
    ],
  },
  {
    id: '0000 0000 0000 0003',
    name: 'Mobile & Web - Full Access',
    description: 'Grants the user access to the Mobile & Web - Full Access',
    featureId: '0000 0000 0000 0004',
    ofPermission: [1, 2],
  },
  {
    id: '0000 0000 0000 0004',
    name: 'Monitoring - Full Access',
    description: 'Full access to the Monitoring module.',
    featureId: '0000 0000 0000 0003',
    ofPermission: [1, 2, 3],
    ofGroups: [
      '0000 0000 0000 0001',
      '0000 0000 0000 0002',
      '0000 0000 0000 0002',
      '0000 0000 0000 0004',
      '0000 0000 0000 0005',
    ],
  },
  {
    id: '0000 0000 0000 0005',
    name: 'Partners & Programs - All Permissions',
    description: 'All access to the Partners module.',
    featureId: '0000 0000 0000 0002',
    ofUsers: [1, 2],
    ofPermission: [4],
    ofGroups: ['0000 0000 0000 0001'],
  },
  {
    id: '0000 0000 0000 0006',
    name: 'Revenue - Full Access',
    description: 'Full access to the Revenue module.',
    featureId: '0000 0000 0000 0001',
    ofPermission: [1, 2, 3],
    ofGroups: [
      '0000 0000 0000 0002',
      '0000 0000 0000 0002',
      '0000 0000 0000 0004',
      '0000 0000 0000 0005',
    ],
  },
  {
    id: '0000 0000 0000 0007',
    name: 'Mobile & Web - Full Access',
    description: 'Grants the user access to the Mobile & Web - Full Access',
    featureId: '0000 0000 0000 0004',
    ofPermission: [1],
  },
  {
    id: '0000 0000 0000 0008',
    name: 'Monitoring - Full Access',
    description: 'Full access to the Monitoring module.',
    featureId: '0000 0000 0000 0003',
    ofPermission: [2],
    ofGroups: [
      '0000 0000 0000 0001',
      '0000 0000 0000 0002',
      '0000 0000 0000 0002',
      '0000 0000 0000 0004',
      '0000 0000 0000 0005',
    ],
  },
  {
    id: '0000 0000 0000 0009',
    name: 'Partners & Programs - All Permissions',
    description: 'All access to the Partners module.',
    featureId: '0000 0000 0000 0002',
    ofUsers: [1, 2],
    ofPermission: [1, 2, 3, 4],
    ofGroups: ['0000 0000 0000 0001'],
  },
  {
    id: '0000 0000 0000 0010',
    name: 'Revenue - Full Access',
    description: 'Full access to the Revenue module.',
    featureId: '0000 0000 0000 0001',
    ofPermission: [3],
    ofGroups: [
      '0000 0000 0000 0002',
      '0000 0000 0000 0002',
      '0000 0000 0000 0004',
      '0000 0000 0000 0005',
    ],
  },
];

export const USERS_PERMISSIONS_GROUP_PROFILES_DATA = [
  {
    id: '0000 0000 0000 0001',
    name: 'PRF - CSR / Limited',
    description: 'Access limited to CRM and Media Manager.',
    ofUsers: [4, 2],
    ofPermission: [
      '0000 0000 0000 0001',
      '0000 0000 0000 0002',
      '0000 0000 0000 0003',
      '0000 0000 0000 0004',
      '0000 0000 0000 0005',
    ],
  },
  {
    id: '0000 0000 0000 0002',
    name: 'PRF - Custom Admin',
    description: 'CRM, Media, Fares, Monitoring, Partners.',
    ofUsers: [3],
    ofPermission: ['0000 0000 0000 0005'],
  },
  {
    id: '0000 0000 0000 0003',
    name: 'PRF - Customer Support',
    description: 'Customer Support Representative.',
    ofUsers: [1, 2, 3, 4, 5, 6],
    ofPermission: [
      '0000 0000 0000 0001',
      '0000 0000 0000 0002',
      '0000 0000 0000 0003',
      '0000 0000 0000 0004',
      '0000 0000 0000 0005',
    ],
  },
  {
    id: '0000 0000 0000 0004',
    name: 'PRF - Treasury team',
    description: 'Maintenance and supervision.',
    ofUsers: [2, 3, 4],
    ofPermission: [
      '0000 0000 0000 0001',
      '0000 0000 0000 0002',
      '0000 0000 0000 0003',
      '0000 0000 0000 0004',
      '0000 0000 0000 0005',
    ],
  },
  {
    id: '0000 0000 0000 0005',
    name: 'PRF - Treasury team - Admin',
    ofUsers: [3],
    ofPermission: ['0000 0000 0000 0003', '0000 0000 0000 0004'],
  },
];

export const REVENUE_CASH_COLLECTION_DATA = [
  {
    id: 0,
    date: 1659332527418,
    cash_id: 8,
    status: 'OK',
    type: 'Coins',
    tvm_name: 'FLOWBIRD LAB',
    box_id: '41D79B2BD5880',
    amount: '$0.00',
  },
  {
    id: 1,
    date: 1659332527418,
    cash_id: 7,
    status: 'OK',
    type: 'Bills',
    tvm_name: 'TVM 9999',
    box_id: '41D79B2BD5860',
    amount: '$0.00',
  },
  {
    id: 2,
    date: 1659306022000,
    cash_id: 6,
    status: 'OK',
    type: 'Bills',
    tvm_name: 'FLOWBIRD LAB',
    box_id: '41D79B2BD5861',
    amount: '$0.00',
  },
  {
    id: 3,
    date: 1659133222000,
    cash_id: 5,
    status: 'OK',
    type: 'Bills',
    tvm_name: 'FLOWBIRD LAB',
    box_id: '41D79B2BD5861',
    amount: '$2.00',
  },
  {
    id: 4,
    date: 1659046822000,
    cash_id: 4,
    status: 'OK',
    type: 'Coins',
    tvm_name: 'FLOWBIRD LAB',
    box_id: '41D79B2BD5862',
    amount: '$3.00',
  },
  {
    id: 5,
    date: 1658960422000,
    cash_id: 3,
    status: 'OK',
    type: 'Bills',
    tvm_name: 'TVM 9999',
    box_id: '41D79B2BD5863',
    amount: '$3.00',
  },
  {
    id: 6,
    date: 1658874022000,
    cash_id: 2,
    status: 'OK',
    type: 'Bills',
    tvm_name: 'TVM 9999',
    box_id: '41D79B2BD5864',
    amount: '$4.00',
  },
  {
    id: 7,
    date: 1658787622000,
    cash_id: 1,
    status: 'OK',
    type: 'Bills',
    tvm_name: null,
    box_id: '41D79B2BD5864',
    amount: '$5.00',
  },
  {
    id: 8,
    date: 1658701222000,
    cash_id: 2,
    status: 'OK',
    type: 'Bills',
    tvm_name: null,
    box_id: '41D79B2BD5864',
    amount: '$6.00',
  },
  {
    id: 9,
    date: 1658614822000,
    cash_id: 3,
    status: 'OK',
    type: 'Bills',
    tvm_name: 'FLOWBIRD LAB',
    box_id: '41D79B2BD5864',
    amount: '$7.00',
  },
  {
    id: 10,
    date: 1658528422000,
    cash_id: 4,
    status: 'OK',
    type: 'Bills',
    tvm_name: 'FLOWBIRD LAB',
    box_id: '41D79B2BD5864',
    amount: '$8.00',
  },
  {
    id: 11,
    date: 1658442022000,
    cash_id: 5,
    status: 'OK',
    type: 'Bills',
    tvm_name: 'FLOWBIRD LAB',
    box_id: '41D79B2BD5864',
    amount: '$9.00',
  },
  {
    id: 12,
    date: 1658355622000,
    cash_id: 6,
    status: 'OK',
    type: 'Bills',
    tvm_name: 'FLOWBIRD LAB',
    box_id: '41D79B2BD5864',
    amount: '$10.00',
  },
  {
    id: 13,
    date: 1658269222000,
    cash_id: 7,
    status: 'OK',
    type: 'Bills',
    tvm_name: 'FLOWBIRD LAB',
    box_id: '41D79B2BD5864',
    amount: '$11.00',
  },
  {
    id: 14,
    date: 1658182822000,
    cash_id: 8,
    status: 'OK',
    type: 'Bills',
    tvm_name: 'FLOWBIRD LAB',
    box_id: '41D79B2BD5864',
    amount: '$12.00',
  },
  {
    id: 15,
    date: 1658096422000,
    cash_id: 9,
    status: 'OK',
    type: 'Bills',
    tvm_name: 'FLOWBIRD LAB',
    box_id: '41D79B2BD5864',
    amount: '$13.00',
  },
];

export const REVENUE_TRANSACTION_EXCEPTIONS_TVM_DATA = [
  {
    id: '0000 0000 0000 0001',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    device: {
      name: 'FLOWBIRD LAB',
      code: '36159998',
    },
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0002',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    device: {
      name: 'FLOWBIRD LAB',
      code: '36159998',
    },
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0003',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    device: {
      name: 'FLOWBIRD LAB',
      code: '36159998',
    },
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0004',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    device: {
      name: 'FLOWBIRD LAB',
      code: '36159998',
    },
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0005',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    device: {
      name: 'FLOWBIRD LAB',
      code: '36159998',
    },
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0006',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    device: {
      name: 'FLOWBIRD LAB',
      code: '36159998',
    },
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0007',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    device: {
      name: 'FLOWBIRD LAB',
      code: '36159998',
    },
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0008',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    device: {
      name: 'FLOWBIRD LAB',
      code: '36159998',
    },
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0009',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    device: {
      name: 'FLOWBIRD LAB',
      code: '36159998',
    },
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0010',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    device: {
      name: 'FLOWBIRD LAB',
      code: '36159998',
    },
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0011',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    device: {
      name: 'FLOWBIRD LAB',
      code: '36159998',
    },
    reason: 'Invalid Header',
  },
];

export const REVENUE_TRANSACTION_EXCEPTIONS_MOBILE_DATA = [
  {
    id: '0000 0000 0000 0001',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0002',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0003',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0004',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0005',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0006',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0007',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0008',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0009',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0010',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0011',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
];

export const REVENUE_TRANSACTION_EXCEPTIONS_HUB_DATA = [
  {
    id: '0000 0000 0000 0001',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    userName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0002',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    userName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0003',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    userName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0004',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    userName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0005',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    userName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0006',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    userName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0007',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    userName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0008',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    userName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0009',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    userName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0010',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    userName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0011',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    userName: 'Patron Name',
    reason: 'Invalid Header',
  },
];

export const REVENUE_TRANSACTION_EXCEPTIONS_WEB_DATA = [
  {
    id: '0000 0000 0000 0001',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0002',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0003',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0004',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0005',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0006',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0007',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0008',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0009',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0010',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0011',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    patronName: 'Patron Name',
    reason: 'Invalid Header',
  },
];

export const REVENUE_TRANSACTION_EXCEPTIONS_FRONTDESK_DATA = [
  {
    id: '0000 0000 0000 0001',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0002',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0003',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0004',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0005',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0006',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0007',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0008',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0009',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0010',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0011',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
];

export const REVENUE_TRANSACTION_EXCEPTIONS_HAWKEYE_DATA = [
  {
    id: '0000 0000 0000 0001',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0002',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0003',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0004',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0005',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0006',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0007',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0008',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0009',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0010',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
  {
    id: '0000 0000 0000 0011',
    receivedAt: '1655690111000',
    generatedAt: '1655690111000',
    operatorName: 'Operator Name',
    device: {
      name: 'TVM 9999',
      code: 'FD_6411',
    },
    sessionId: '5658',
    reason: 'Invalid Header',
  },
];

export const RETURN_POLICY_DATA = [
  {
    id: 0,
    request_date: 1659615638200,
    patron_name: 'Patron Name 1',
    transaction_link: '8BCA63858CBA',
    amount: 2.0,
    refund_date: 1659608564000,
    exchange_date: 1659435764000,
    action_type: 'action',
  },
  {
    id: 1,
    request_date: 1659522164000,
    patron_name: 'Patron Name 2',
    transaction_link: '7BCA63858CBA',
    amount: 1.0,
    refund_date: 16595221640002,
    exchange_date: 1659435764000,
    action_type: 'action',
  },
  {
    id: 2,
    request_date: 1659435764000,
    patron_name: 'Patron Name 3',
    transaction_link: '6BCA63858CBA',
    amount: 2.0,
    refund_date: 1659435764000,
    exchange_date: 1659435764000,
    action_type: 'action',
  },
  {
    id: 3,
    request_date: 1659349364000,
    patron_name: 'Patron Name 4',
    transaction_link: '5BCA63858CBA',
    amount: 3.0,
    refund_date: 1659349364000,
    exchange_date: 1659435764000,
    action_type: null,
  },
  {
    id: 4,
    request_date: 1659262964000,
    patron_name: 'Patron Name 5',
    transaction_link: '4BCA63858CBA',
    amount: 4.0,
    refund_date: 1659262964000,
    exchange_date: 1659435764000,
    action_type: null,
  },
  {
    id: 5,
    request_date: 1659176564000,
    patron_name: 'Patron Name 6',
    transaction_link: '3BCA63858CBA',
    amount: 5.0,
    refund_date: 1659176564000,
    exchange_date: 1659435764000,
    action_type: null,
  },
  {
    id: 6,
    request_date: 1659090164000,
    patron_name: 'Patron Name 7',
    transaction_link: '2BCA63858CBA',
    amount: 6.0,
    refund_date: 1659090164000,
    exchange_date: 1659435764000,
    action_type: null,
  },
  {
    id: 7,
    request_date: 1659003764000,
    patron_name: 'Patron Name 8',
    transaction_link: '1BCA63858CBA',
    amount: 7.0,
    refund_date: 1659003764000,
    exchange_date: 1659435764000,
    action_type: 'action',
  },
  {
    id: 8,
    request_date: 1658917364000,
    patron_name: 'Patron Name 9',
    transaction_link: '2BCA63858CBA',
    amount: 8.0,
    refund_date: 1658917364000,
    exchange_date: 1659435764000,
    action_type: 'action',
  },
  {
    id: 9,
    request_date: 1658830964000,
    patron_name: 'Patron Name 10',
    transaction_link: '3BCA63858CBA',
    amount: 9.0,
    refund_date: 1658830964000,
    exchange_date: 1659435764000,
    action_type: 'action',
  },
  {
    id: 10,
    request_date: 1658744564000,
    patron_name: 'Patron Name 11',
    transaction_link: '4BCA63858CBA',
    amount: 10.0,
    refund_date: 1658744564000,
    exchange_date: 1659435764000,
    action_type: 'action',
  },
  {
    id: 11,
    request_date: 1658658164000,
    patron_name: 'Patron Name 12',
    transaction_link: '5BCA63858CBA',
    amount: 11.0,
    refund_date: 1658658164000,
    exchange_date: 1659435764000,
    action_type: null,
  },
  {
    id: 12,
    request_date: 1658571764000,
    patron_name: 'Patron Name 13',
    transaction_link: '6BCA63858CBA',
    amount: 12.0,
    refund_date: 1658571764000,
    exchange_date: 1659435764000,
    action_type: null,
  },
];

export const TICKETING_FARES_DATA = [
  {
    id: 0,
    type: 'Standart',
    name: 'Mobile & Web',
    schedule: null,
    products: 3,
    deployment: '3/2',
    status: 'Active',
  },
  {
    id: 1,
    type: 'Standart',
    name: 'Regular Fare',
    schedule: null,
    products: 1,
    deployment: '3/2',
    status: 'Active',
  },
  {
    id: 1,
    type: 'Standart',
    name: 'Premium Fare',
    schedule: null,
    products: 2,
    deployment: '1/1',
    status: 'InActive',
  },
];
export const TICKETING_FARES_GENERAL_DATA = {
  name: 'Regular Fare',
  schedule: 'Schedule 2',
  description: 'Some description',
  event: true,
};
export const TICKETING_FARES_TRANSLATIONS_DATA = [
  {
    id: 0,
    language: 'us',
    flag: 'EN',
    isPrimary: true,
    display_label: 'Regular Fare',
    short_label: null,
    conditions_label: 'Regular routes',
  },
  {
    id: 1,
    language: 'es',
    flag: 'ES',
    isPrimary: false,
    display_label: 'Regular Fare in Spanish',
    short_label: null,
    conditions_label: 'Regular routes',
  },
];
export const TICKETING_FARES_PRODUCTS_DATA = [
  {
    id: 0,
    products: 'Single Ride - Regular',
    description: 90,
    schedule: null,
    status: 'Active',
  },
  {
    id: 1,
    products: 'Single Ride - Regular',
    description: 80,
    schedule: null,
    status: 'InActive',
  },
];
export const TICKETING_FARES_NOTIFICATIONS_DATA = [
  {
    id: 0,
    name: 'Fare Validity Regular',
    context: 'Fare Validity Regular',
    type: 'Info',
    message:
      'Fermentum fusce potenti ullamcorper interdum. Amet gravida quam eu potenti ante vestibulum.',
    schedule: null,
  },
  {
    id: 1,
    name: 'Fare Validity Regular 1',
    context: 'Fare Validity Regular 1',
    type: 'Info',
    message:
      'Fermentum fusce potenti ullamcorper interdum. Amet gravida quam eu potenti ante vestibulum. 1',
    schedule: null,
  },
];
export const TICKETING_FARES_LOCATIONS_DATA = [
  {
    id: 1,
    code: '30TH_ST',
    name: '30TH STREET STATION',
    description: 'Fermentum fusce potenti ullamcorper interdum',
    devices_location: 'Not Included',
  },
  {
    id: 2,
    code: 'CITY',
    name: 'CITY HALL STATION',
    description: 'Fermentum fusce potenti ullamcorper interdum',
    devices_location: 'Included',
  },
  {
    id: 3,
    code: 'GREYH',
    name: 'GREYHOUND TERMINAL',
    description: 'Fermentum fusce potenti ullamcorper interdum',
    devices_location: 'Included',
  },
];
export const TICKETING_FARES_DEVICES_DATA = [
  {
    id: 1,
    asset_id: 36159999,
    asset_type: 'Machine',
    asset_name: 'TVM 0001',
    location: null,
  },
  { id: 2, asset_id: 36159998, asset_type: 'Machine 1', asset_name: 'TVM 0002', location: null },
];
export const TICKETING_FARES_APP_DATA = [
  {
    id: 1,
    name: 'Transit Demo Mobile Android',
    package_app: 'com.flowbirdhub.polaris.android',
    version: '1.0.0',
    type: 'Android',
  },
  {
    id: 2,
    name: 'Transit Demo Mobile iOS',
    package_app: 'com.flowbirdhub.polaris.ios',
    version: '1.0.0',
    type: 'iOS',
  },
  {
    id: 3,
    name: 'Transit Demo Web',
    package_app: 'polaris.flowbirdhub.com',
    version: '1.0.0',
    type: 'Web',
  },
];

export const TICKETING_CONCESSIONS_DATA = [
  {
    id: 0,
    name: 'Adult Premium',
    code: 'AD_PREM',
    description: 'Good on all Freeway Flyer, UBUS, Ozaukee County Express and regular routes.',
    products: 5,
    status: 'Active',
  },
  {
    id: 1,
    name: 'Adult Regular',
    code: 'AD_REG',
    description: 'Ages 12-64 on regular routes.',
    products: 6,
    status: 'InActive',
  },
  {
    id: 3,
    name: 'Reduced Fare',
    code: 'RED',
    description: 'Riders 65+ & ages 6-11 with a qualifying disability.',
    products: 5,
    status: 'Active',
  },
];
export const TICKETING_CONCESSION_GENERAL_DATA = {
  name: 'Regular Fare',
  code: 'AD_PREM',
  description: 'Good on all Freeway Flyer, UBUS, Ozaukee County\n' + 'Express and regular routes.',
};
export const TICKETING_CONCESSION_TRANSLATIONS_DATA = [
  {
    id: 0,
    language: 'us',
    flag: 'EN',
    isPrimary: true,
    display_label: 'Adult Premium',
    short_label: 'AD_PREM',
    conditions_label: 'Good on all Freeway Flyer, UBUS, Ozaukee County Express and regular routes.',
  },
  {
    id: 1,
    language: 'es',
    flag: 'ES',
    isPrimary: false,
    display_label: 'Adult Premium in Spanish',
    short_label: 'AD_PREM in Spanish',
    conditions_label:
      'Good on all Freeway Flyer, UBUS, Ozaukee County Express and regular routes. in Spanish',
  },
];
export const TICKETING_CONCESSION_VALIDITY_DATA = { isValidity: true };
export const TICKETING_CONCESSION_TICKETS_DATA = { isOverride: true };
export const TICKETING_CONCESSION_PRODUCTS_DATA = [
  {
    id: 0,
    product: 'Single Ride',
    code: 'SR',
    partner: null,
    schedule: null,
    amount: 3.5,
  },
  {
    id: 1,
    product: 'Day Pass',
    code: '1D',
    partner: null,
    schedule: null,
    amount: 6,
  },
  {
    id: 2,
    product: '2022 Annual Pass',
    code: '365D',
    partner: null,
    schedule: null,
    amount: 3.5,
  },
  {
    id: 3,
    product: 'Day Pass - Premium',
    code: '1D',
    partner: null,
    schedule: null,
    amount: 27,
  },
];
export const CRM_STATISTICS_DATA = [
  {
    patronsStatistics: [500, 133, 60, 50],
  },
  {
    patronsStatistics: [500, 133, 60, 50],
  },
  {
    patronsStatistics: [2000, 400, 234, 155],
  },
  {
    patronsStatistics: [3541, 231, 313, 555],
  },
  {
    patronsStatistics: [500, 133, 60, 50],
  },
  {
    patronsStatistics: [500, 133, 60, 50],
  },
  {
    patronsStatistics: [4124, 422, 333, 441],
  },
  {
    patronsStatistics: [500, 133, 60, 50],
  },
  {
    patronsStatistics: [500, 133, 60, 50],
  },
  {
    patronsStatistics: [500, 133, 60, 50],
  },
];
export const TICKETING_SCHEDULES_DATA = [
  {
    id: 0,
    name: 'Schedule name1',
    description: 'Some text for schedule description.1',
    usages: 1,
    groups: 1,
    status: 'In Use',
  },
  {
    id: 1,
    name: 'Schedule name2',
    description: 'Some text for schedule description.2',
    usages: 2,
    groups: 2,
    status: 'In Use',
  },
  {
    id: 2,
    name: 'Schedule name3',
    description: 'Some text for schedule description.3',
    usages: 3,
    groups: 3,
    status: 'Not Used',
  },
  {
    id: 3,
    name: 'Schedule name4',
    description: 'Some text for schedule description.4',
    usages: 4,
    groups: 4,
    status: 'In Use',
  },
  {
    id: 4,
    name: 'Schedule name5',
    description: 'Some text for schedule description.5',
    usages: 5,
    groups: 5,
    status: 'Not Used',
  },
];

export const DEVICE_MANIFEST_DATA = [
  {
    id: 0,
    artifact: 'Application Parameters',
    current_version: '[321] 1.0.0 | Application Params w/OP\n - Demo axio 4 - Indygo',
    applied_at: 1661841649678,
    future_version: '[318] 1.3.3 | Axio - Release 1.3.3 (dev)',
    scheduled_at: 1661841649678,
  },
  {
    id: 1,
    artifact: 'Application Software',
    current_version: null,
    applied_at: 1661841649678,
    future_version: null,
    scheduled_at: 1661516410000,
  },
  {
    id: 2,
    artifact: 'Encryption Keys',
    current_version: '[319] 1.0.0 | Media encryption keys',
    applied_at: 1661841649678,
    future_version: null,
    scheduled_at: null,
  },
  {
    id: 3,
    artifact: 'Graphical Resources',
    current_version: '[320] 1.0.0 | Axio 4 Skin - Madison demo',
    applied_at: 1661602810000,
    future_version: null,
    scheduled_at: null,
  },
];
export const DEVICES_GENERAL_SETTINGS_DATA = {
  asset_id: 'ATB_US_0001',
  serial_number: '',
  asset_name: 'DEMO 0001',
  activation_code: 'XXXXXXXXXX',
  activation_date: null,
  manufactured_date: '1661929236940',
  installed_date: '1661929236940',
  delivered_date: '1661929236940',
  selected_date: '1661929236940',
  bus: 'Bus1',
  placement: 'Front',
  isTest_asset: false,
  isForce: false,
};

export const DEVICE_CONTROL_LIST_DATA = [
  {
    id: 0,
    requested_at: 1660295712000,
    downloaded_at: 1660209312000,
    applied_at: 1660209313000,
    list_type: COLOR_GRAY,
    fare: 'Regular Fare',
    version: 695,
    subversion: 1,
    record_count: 22,
  },
  {
    id: 1,
    requested_at: 1661159713000,
    downloaded_at: 1661159713000,
    applied_at: 1661159713000,
    list_type: COLOR_GREEN,
    fare: 'Regular Fare',
    version: 697,
    subversion: 2,
    record_count: 23,
  },
  {
    id: 1,
    requested_at: 1661159713000,
    downloaded_at: 1661159713000,
    applied_at: 1661159713000,
    list_type: COLOR_RED,
    fare: 'Regular Fare',
    version: 697,
    subversion: 2,
    record_count: 23,
  },
];

export const DEVICES_AGENTS = [
  {
    id: 0,
    name: 'Test User 1',
    email: 'test1@flowbird.group',
    phone: {
      phoneNumber: '(315) 556-4572',
      countryCode: DEFAULT_PHONE_COUNTRY_CODE,
    },
    employee_id: 23451,
    created_date: '1664193154064',
    status: 'active',
  },
  {
    id: 1,
    name: 'Test User 2',
    email: 'test2@flowbird.group',
    phone: {
      phoneNumber: '(315) 556-4582',
      countryCode: DEFAULT_PHONE_COUNTRY_CODE,
    },
    employee_id: 23452,
    created_date: '1664109204000',
    status: 'active',
  },
  {
    id: 2,
    name: 'Test User 3',
    email: 'test3@flowbird.group',
    phone: null,
    employee_id: 23453,
    created_date: '1663997604000',
    status: 'suspended',
  },
  {
    id: 3,
    name: 'Test User 4',
    email: 'test4@flowbird.group',
    phone: null,
    employee_id: 23454,
    created_date: '1663911204000',
    status: 'suspended',
  },
  {
    id: 4,
    name: 'Test User 5',
    email: 'test5@flowbird.group',
    phone: null,
    employee_id: 23455,
    created_date: '1663824804000',
    status: 'active',
  },
  {
    id: 5,
    name: 'Test User 6',
    email: 'test6@flowbird.group',
    phone: {
      phoneNumber: '(315) 556-5562',
      countryCode: DEFAULT_PHONE_COUNTRY_CODE,
    },
    employee_id: 23456,
    created_date: '1663734804000',
    status: 'active',
  },
  {
    id: 6,
    name: 'Test User 7',
    email: 'test7@flowbird.group',
    phone: {
      phoneNumber: '(315) 556-4582',
      countryCode: DEFAULT_PHONE_COUNTRY_CODE,
    },
    employee_id: 23457,
    created_date: '1663648403000',
    status: 'suspended',
  },
  {
    id: 7,
    name: 'Test User 8',
    email: 'test8@flowbird.group',
    phone: {
      phoneNumber: '(315) 556-4562',
      countryCode: DEFAULT_PHONE_COUNTRY_CODE,
    },
    employee_id: 23458,
    created_date: '1663558403000',
    status: 'suspended',
  },
  {
    id: 8,
    name: 'Test User 9',
    email: 'test9@flowbird.group',
    phone: {
      phoneNumber: '(315) 556-4562',
      countryCode: DEFAULT_PHONE_COUNTRY_CODE,
    },
    employee_id: 23459,
    created_date: '1663468403000',
    status: 'active',
  },
  {
    id: 9,
    name: 'Test User 10',
    email: 'test9@flowbird.group',
    phone: null,
    employee_id: 23429,
    created_date: '1663378403000',
    status: 'active',
  },
  {
    id: 10,
    name: 'Test User 11',
    email: 'test11@flowbird.group',
    phone: null,
    employee_id: 23430,
    created_date: '1663292003000',
    status: 'suspended',
  },
  {
    id: 11,
    name: 'Test User 12',
    email: 'test12@flowbird.group',
    phone: null,
    employee_id: 23435,
    created_date: '1663234403000',
    status: 'suspended',
  },
];

export const DEVICES_AGENTS_GENERAL_ITEM = {
  id: 0,
  name: 'Test User 12',
  email: 'test12@flowbird.group',
  phone: {
    phoneNumber: '(777) 886 6551',
    countryCode: 1,
  },
  employee_id: 23435,
  created_date: '1663234403000',
  status: 'suspended',
};

export const DEVICES_AGENTS_PERMISSIONS = [
  {
    id: 0,
    device: 'TVM access permissions',
    status: 'locked',
    expiration_date: 1664277814274,
    updated_at: 1657444924000,
    uuid: 'sd3234',
    start_date: '2023-12-16T07:00:00Z',
    end_date: '2023-12-18T07:00:00Z',
    tvm_access_id: 'TEST 3',
    isMaintenance: true,
    isCashCollection: false,
    isRequestCodes: false,
    isDownloadCodes: false,
    isResetCodes: false,
    password: '123456',
    devices: [
      { id: 1, name: 'ATB LDE 0001' },
      { id: 2, name: 'ATB LDE 0002' },
    ],
  },
  {
    id: 1,
    device: 'TVM access permissions_2',
    status: 'expired',
    expiration_date: 1657358524000,
    updated_at: 1657272124000,
    uuid: 'sd3234',
    start_date: '2023-12-14T07:00:00Z',
    end_date: '2023-12-15T07:00:00Z',
    tvm_access_id: 'TEST 3',
    isMaintenance: true,
    isCashCollection: false,
    isRequestCodes: false,
    isDownloadCodes: false,
    isResetCodes: false,
    password: '',
    devices: [
      { id: 1, name: 'ATB LDE 0001' },
      { id: 2, name: 'ATB LDE 0002' },
    ],
  },
  {
    id: 2,
    device: 'TVM access permissions_3',
    status: 'active',
    expiration_date: 1657185724000,
    updated_at: 1657185724000,
    uuid: 'sd3234',
    start_date: '2023-12-14T07:00:00Z',
    end_date: '2023-12-16T07:00:00Z',
    tvm_access_id: 'TEST 3',
    isMaintenance: true,
    isCashCollection: false,
    isRequestCodes: false,
    isDownloadCodes: false,
    isResetCodes: false,
    password: '123456',
    devices: [
      { id: 1, name: 'ATB LDE 0001' },
      { id: 2, name: 'ATB LDE 0002' },
    ],
  },
  {
    id: 3,
    device: 'TVM access permissions_4',
    status: 'locked',
    expiration_date: 1664277814274,
    updated_at: 1657444924000,
    uuid: 'sd3234',
    start_date: '2023-12-13T07:00:00Z',
    end_date: '2023-12-15T07:00:00Z',
    tvm_access_id: 'TEST 3',
    isMaintenance: true,
    isCashCollection: false,
    isRequestCodes: false,
    isDownloadCodes: false,
    isResetCodes: false,
    password: '',
    devices: [
      { id: 1, name: 'ATB LDE 0001' },
      { id: 2, name: 'ATB LDE 0002' },
    ],
  },
  {
    id: 4,
    device: 'TVM access permissions_5',
    status: 'expired',
    expiration_date: 1657358524000,
    updated_at: 1657272124000,
    uuid: 'sd3234',
    start_date: '2023-12-10T07:00:00Z',
    end_date: '2023-12-12T07:00:00Z',
    tvm_access_id: 'TEST 3',
    isMaintenance: true,
    isCashCollection: false,
    isRequestCodes: false,
    isDownloadCodes: false,
    isResetCodes: false,
    password: '123456',
    devices: [
      { id: 1, name: 'ATB LDE 0001' },
      { id: 2, name: 'ATB LDE 0002' },
    ],
  },
  {
    id: 5,
    device: 'TVM access permissions_6',
    status: 'active',
    expiration_date: 1657185724000,
    updated_at: 1657185724000,
    uuid: 'sd3234',
    start_date: '2023-12-18T07:00:00Z',
    end_date: '2023-12-19T07:00:00Z',
    tvm_access_id: 'TEST 3',
    isMaintenance: true,
    isCashCollection: false,
    isRequestCodes: false,
    isDownloadCodes: false,
    isResetCodes: false,
    password: '',
    devices: [
      { id: 1, name: 'ATB LDE 0001' },
      { id: 2, name: 'ATB LDE 0002' },
    ],
  },
  {
    id: 6,
    device: 'TVM access permissions_7',
    status: 'locked',
    expiration_date: 1664277814274,
    updated_at: 1657444924000,
    uuid: 'sd3234',
    start_date: '2023-12-13T07:00:00Z',
    end_date: '2023-12-15T07:00:00Z',
    tvm_access_id: 'TEST 3',
    isMaintenance: true,
    isCashCollection: false,
    isRequestCodes: false,
    isDownloadCodes: false,
    isResetCodes: false,
    password: '123456',
    devices: [
      { id: 1, name: 'ATB LDE 0001' },
      { id: 2, name: 'ATB LDE 0002' },
    ],
  },
  {
    id: 7,
    device: 'TVM access permissions_8',
    status: 'expired',
    expiration_date: 1657358524000,
    updated_at: 1657272124000,
    uuid: 'sd3234',
    start_date: '2023-12-11T07:00:00Z',
    end_date: '2023-12-12T07:00:00Z',
    tvm_access_id: 'TEST 3',
    isMaintenance: true,
    isCashCollection: false,
    isRequestCodes: false,
    isDownloadCodes: false,
    isResetCodes: false,
    password: '',
    devices: [
      { id: 1, name: 'ATB LDE 0001' },
      { id: 2, name: 'ATB LDE 0002' },
    ],
  },
  {
    id: 8,
    device: 'TVM access permissions_9',
    status: 'active',
    expiration_date: 1657185724000,
    updated_at: 1657185724000,
    uuid: 'sd3234',
    start_date: '2023-12-18T07:00:00Z',
    end_date: '2023-12-19T07:00:00Z',
    tvm_access_id: 'TEST 3',
    isMaintenance: true,
    isCashCollection: false,
    isRequestCodes: false,
    isDownloadCodes: false,
    isResetCodes: false,
    password: '123456',
    devices: [
      { id: 1, name: 'ATB LDE 0001' },
      { id: 2, name: 'ATB LDE 0002' },
    ],
  },
  {
    id: 9,
    device: 'TVM access permissions_10',
    status: 'locked',
    expiration_date: 1664277814274,
    updated_at: 1657444924000,
    uuid: 'sd3234',
    start_date: '2023-12-14T07:00:00Z',
    end_date: '2023-12-16T07:00:00Z',
    tvm_access_id: 'TEST 3',
    isMaintenance: true,
    isCashCollection: false,
    isRequestCodes: false,
    isDownloadCodes: false,
    isResetCodes: false,
    password: '',
    devices: [
      { id: 1, name: 'ATB LDE 0001' },
      { id: 2, name: 'ATB LDE 0002' },
    ],
  },
  {
    id: 10,
    device: 'TVM access permissions_11',
    status: 'expired',
    expiration_date: 1657358524000,
    updated_at: 1657272124000,
    uuid: 'sd3234',
    start_date: '2023-12-12T07:00:00Z',
    end_date: '2023-12-14T07:00:00Z',
    tvm_access_id: 'TEST 3',
    isMaintenance: true,
    isCashCollection: false,
    isRequestCodes: false,
    isDownloadCodes: false,
    isResetCodes: false,
    password: '123456',
    devices: [
      { id: 1, name: 'ATB LDE 0001' },
      { id: 2, name: 'ATB LDE 0002' },
    ],
  },
  {
    id: 11,
    device: 'TVM access permissions_12',
    status: 'active',
    expiration_date: 1657185724000,
    updated_at: 1657185724000,
    uuid: 'sd3234',
    start_date: '2023-12-17T07:00:00Z',
    end_date: '2023-12-18T07:00:00Z',
    tvm_access_id: 'TEST 3',
    isMaintenance: true,
    isCashCollection: false,
    isRequestCodes: false,
    isDownloadCodes: false,
    isResetCodes: false,
    password: '',
    devices: [
      { id: 1, name: 'ATB LDE 0001' },
      { id: 2, name: 'ATB LDE 0002' },
    ],
  },
];
