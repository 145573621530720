/**@format */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ChevronRightIcon, DotsHorizontalIcon, LinkIcon } from '@heroicons/react/solid';
import { Menu, Transition } from '@headlessui/react';

import { classNames } from '../../../helpers';

export const CollapsedBreadCrumb = ({ path, className }) => {
  const { t } = useTranslation();
  const collapseItems = path?.length > 0 ? path.filter((_, i) => i !== path.length - 1) : [];
  return (
    <div className={classNames('items-center', 'text-gray-500 text-sm font-medium', className)}>
      <Link to='/dashboard/welcome' className='text-gray-500 text-sm font-medium'>
        {t('Dashboard')}
      </Link>
      <div className='flex'>
        <ChevronRightIcon className='w-5 h-5 text-gray-400' />
      </div>
      <Menu as='div' className={classNames('relative', 'z-30', 'flex items-center')}>
        <>
          <Menu.Button
            className={classNames(
              'flex items-center',
              'rounded-full',
              'p-1',
              'focus-visible:outline-indigo-500'
            )}
          >
            <span className='sr-only'>Open BreadCrumb Collapsed Items</span>
            <DotsHorizontalIcon className='w-5 h5' />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              className={classNames(
                'absolute top-full',
                'w-48',
                'mt-2',
                'rounded-md shadow-xl border border-gray-300 py-1 bg-white',
                'origin-top-right focus:outline-none'
              )}
            >
              {collapseItems.map(item => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      to={item.url}
                      className={classNames(
                        'flex',
                        'gap-4',
                        'text-gray-500 text-sm font-medium',
                        'mb-0',
                        'px-4 py-2',
                        {
                          'bg-gray-100': active,
                        }
                      )}
                    >
                      <LinkIcon className='w-5' />
                      <span>{item.name}</span>
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      </Menu>
      <div className='flex'>
        <ChevronRightIcon className='w-5 h-5 text-gray-400' />
      </div>
      {/* Sample trick to handle text-ellipsis for div/td width in percent
            In future we need to make it show full value by popover or something similar */}
      <div className='table table-fixed w-full'>
        <span className={classNames('table-cell whitespace-nowrap overflow-hidden text-ellipsis')}>
          {path?.length > 0 && path[path.length - 1]?.name}
        </span>
      </div>
    </div>
  );
};
