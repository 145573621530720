/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../../helpers';
import {
  COLOR_TRANSPARENT,
  COLOR_GREEN,
  COLOR_INDIGO,
  COLOR_RED,
  COLOR_YELLOW,
  COLOR_GRAY,
} from '../../constants/Constants';

const Button = React.forwardRef(
  (
    {
      disabled,
      fullwidth,
      color = COLOR_INDIGO,
      type = 'button',
      outline,
      title,
      className,
      onClick,
      children,
      form,
      ...props
    },
    ref
  ) => (
    <button
      ref={ref}
      form={form}
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={classNames(
        className,
        'button-default',
        {
          'w-full': fullwidth,
        },
        {
          // Requires to define classes for Tailwind v3
          'bg-transparent text-gray-600 hover:bg-gray-100 disabled:text-gray-400 shadow-none':
            color === COLOR_TRANSPARENT && !outline,
          'bg-green-600 hover:bg-green-700 disabled:bg-green-300 disabled:text-white focus:ring-green-500':
            color === COLOR_GREEN && !outline,
          'bg-indigo-600 hover:bg-indigo-700 disabled:bg-indigo-300 disabled:text-white':
            color === COLOR_INDIGO && !outline,
          'bg-red-600 hover:bg-red-700 disabled:bg-red-300 disabled:text-white focus:ring-red-500':
            color === COLOR_RED && !outline,
          'bg-yellow-600 hover:bg-yellow-700 disabled:bg-yellow-300 disabled:text-white focus:ring-yellow-500':
            color === COLOR_YELLOW && !outline,
          'bg-gray-700 hover:bg-gray-800 disabled:bg-gray-400 disabled:text-white focus:ring-gray-500':
            color === COLOR_GRAY && !outline,
        },
        {
          // Requires to define classes for Tailwind v3
          'bg-white text-gray-600 border border-gray-300 hover:bg-gray-50 disabled:text-gray-400':
            color === COLOR_GRAY && outline,
          'bg-white text-green-600 border border-green-300 disabled:bg-green-300 disabled:text-white hover:bg-green-600 hover:text-white focus:ring-green-500':
            color === COLOR_GREEN && outline,
          'bg-white text-indigo-600 border border-indigo-300 disabled:bg-indigo-300 disabled:text-white hover:bg-indigo-600 hover:text-white':
            color === COLOR_INDIGO && outline,
          'bg-white text-yellow-600 border-yellow-300 disabled:bg-yellow-300 disabled:text-white hover:bg-yellow-600 hover:text-white focus:ring-yellow-500':
            color === COLOR_YELLOW && outline,
          'bg-white text-red-600 border border-red-300 disabled:bg-red-300 disabled:text-white hover:bg-red-600 hover:text-white focus:ring-red-500':
            color === COLOR_RED && outline,
        },
        'disabled:cursor-not-allowed',
        'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
      )}
      {...props}
    >
      {children}
      {title}
    </button>
  )
);

Button.propTypes = {
  disabled: PropTypes.bool,
  fullwidth: PropTypes.bool,
  color: PropTypes.oneOf([
    COLOR_GRAY,
    COLOR_GREEN,
    COLOR_INDIGO,
    COLOR_YELLOW,
    COLOR_RED,
    COLOR_TRANSPARENT,
  ]),
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  outline: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  form: PropTypes.any,
};

export default Button;
