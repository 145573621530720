/** @format */

import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { isOpenConfirm } from 'store/dashboard/globalConfirmSlice';

export const useComponentVisible = onActionFunc => {
  const isProcess = useSelector(isOpenConfirm);
  const focusRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (isProcess) return;

      if (focusRef.current && !focusRef.current.contains(event.target)) {
        onActionFunc();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isProcess, onActionFunc]);
  return focusRef;
};
