/** @format */
import { useEffect } from 'react';

export const useImagePreloader = (preloadSrcList = []) => {
  useEffect(() => {
    preloadSrcList.forEach(src => {
      const newImage = new Image();
      newImage.src = src;
      window[src] = newImage;
    });
  }, [preloadSrcList]);
};
