/**@format */
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { creditCardTypeEnum } from 'constants/enum';

export const creditCardsSlice = createSlice({
  name: 'creditCards',
  initialState: {
    cardsList: [],
    selectedCardID: 0,
    // cardsList: CREDITCARDS_DATA,
  },
  reducers: {
    deleteCreditCard: (state, { payload }) => {
      state.cardsList = state.cardsList.filter(item => item.id !== payload);
    },
    addNewCreditCard: (state, { payload }) => {
      if (payload.isDefaultSwOn)
        state.cardsList = state.cardsList.map(item => {
          item.isDefault = false;
          return item;
        });
      const id =
        state.cardsList.length === 0 ? 1 : state.cardsList[state.cardsList.length - 1].id + 1;
      state.cardsList.push({
        id,
        cardNumber: payload.cardNumber,
        name: payload.name,
        label: payload.cardLabel,
        expDate: payload.expiryDate,
        isDefault: payload.isDefaultSwOn,
        cvcCvv: payload.cvcCvv,
        /**Get random type */
        type: Object.values(creditCardTypeEnum)[
          Math.floor(Math.random() * Object.values(creditCardTypeEnum).length)
        ],
      });
      state.selectedCardID = id;
    },
    setDefaultCard: (state, { payload }) => {
      state.cardsList = state.cardsList.map(item => {
        if (item.id === payload) item.isDefault = true;
        else item.isDefault = false;
        return item;
      });
    },
    resetCurrentCard: (state, { payload }) => {
      state.cardsList = state.cardsList.filter(item => item.id !== payload);
    },
    removeCurrentID: state => {
      state.selectedCardID = null;
    },
  },
});

export const {
  deleteCreditCard,
  addNewCreditCard,
  setDefaultCard,
  resetCurrentCard,
  removeCurrentID,
} = creditCardsSlice.actions;

export const selectAllCreditCards = state => state.dashboard.crm.patrons.creditCards.cardsList;
export const selectedCardId = state => state.dashboard.crm.patrons.creditCards.selectedCardID;
export const selectCreditCardById = createSelector(
  [selectAllCreditCards, (state, cardId) => cardId],
  (creditCards, id) => creditCards?.find(item => item.id === id)
);

export default creditCardsSlice.reducer;
