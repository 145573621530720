/** @format */

import React, { useMemo } from 'react';
import { Listbox } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

import { classNames } from 'helpers';

import TextField from '../TextField/TextField';
import { COUNTRY_CODE_OPTIONS, DEFAULT_PHONE_COUNTRY_CODE } from './Constants/Constants';

const TextFieldNumberPhone = ({
  id,
  countryCodeId,
  onChange,
  onCountryCodeChange,
  defaultValue: { phoneNumber = '', countryCode = DEFAULT_PHONE_COUNTRY_CODE } = {},
  ...props
}) => {
  const { t } = useTranslation();

  const selectedCountryCode = useMemo(
    () =>
      COUNTRY_CODE_OPTIONS.find(item => item.value === (countryCode || DEFAULT_PHONE_COUNTRY_CODE)),
    [countryCode]
  );

  const handleCountryCodeChange = option => {
    const returnValue = {
      phoneNumber: phoneNumber,
      countryCode: option.value,
    };
    onChange && onChange(returnValue, id, true);
  };
  const handlePhoneNumberChange = (_, mask) => {
    const returnValue = {
      phoneNumber: mask.unmaskedValue ? mask.unmaskedValue : '',
      countryCode: selectedCountryCode.value,
    };
    onChange && onChange(returnValue, id, true);
  };

  return (
    <TextField
      mask={selectedCountryCode?.format?.replace(/#/g, '0')}
      lazy={false}
      id={id}
      label={t('Phone Number')}
      placeholderChar='#'
      onAccept={handlePhoneNumberChange}
      value={phoneNumber}
      leftComponent={
        <Listbox
          id={countryCodeId ?? `${id}-country-code`}
          as='div'
          className={classNames(
            'relative',
            'flex flex-none',
            'rounded-md rounded-r-none border-r border-gray-300',
            'w-[68px]',
            { 'pointer-events-none bg-gray-50 text-gray-500': !!props?.disabled }
          )}
          value={selectedCountryCode}
          onChange={handleCountryCodeChange}
        >
          <Listbox.Label className='sr-only'>{t('Country code')}</Listbox.Label>
          <Listbox.Button
            className={classNames(
              'flex items-center',
              'w-full',
              'text-sm leading-1 text-gray-700',
              'rounded-md',
              'focus:z-10',
              'px-2.5 py-[10px]'
            )}
          >
            +{selectedCountryCode?.value}
            <ChevronDownIcon
              className={classNames(
                'w-5 text-gray-400 pointer-events-none',
                'absolute top-1/2 transform -translate-y-2/4 right-1.5'
              )}
            />
          </Listbox.Button>
          <Listbox.Options
            className={classNames(
              'w-[220px]',
              'absolute z-50 top-full left-0 right-0',
              'bg-white',
              'rounded-md border border-gray-300',
              'mt-1.5',
              'overflow-hidden',
              'focus:outline-none'
            )}
          >
            {COUNTRY_CODE_OPTIONS?.map(code => (
              <Listbox.Option key={code.id} value={code}>
                {({ active }) => (
                  <button
                    type='button'
                    className={classNames('w-full', 'px-2.5 py-[10px]', 'text-sm text-gray-700', {
                      'bg-gray-100': active,
                    })}
                  >
                    {code?.children}
                  </button>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      }
      {...props}
    />
  );
};

export default TextFieldNumberPhone;
