/**@format */
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Listbox } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationCircleIcon,
  XIcon,
  MinusIcon,
} from '@heroicons/react/solid';

import { classNames, isEqual } from 'helpers';
import {
  chipSizeEnum,
  chipTypesEnum,
  labelErrorPositionEnum,
  selectOptionsPositionEnum,
} from 'constants/enum';

import { Chip } from '../Chip';
import { Tooltip } from '../Tooltip';

const AllSelectOption = ({ toggleSelectAll, selectedDropdownItems }) => {
  const isDeselect = selectedDropdownItems?.length > 0;
  const { t } = useTranslation();

  return (
    <>
      <li className='px-3 py-2.5 focus:outline-none' onClick={toggleSelectAll}>
        <button type='button' className={classNames('flex items-center', 'text-left', 'w-full')}>
          <div
            className={classNames('w-4 h-4', 'flex items-center justify-center', 'rounded mr-3', {
              'bg-indigo-600': isDeselect,
              'border border-gray-300': !isDeselect,
            })}
          >
            {isDeselect && <MinusIcon className={classNames('w-3 h-3', 'fill-white')} />}
          </div>
          <div className={classNames('flex flex-col')}>
            <span className={classNames('font-medium text-sm text-gray-400')}>
              {isDeselect ? t('Deselect') : t('Select all')}
            </span>
          </div>
        </button>
      </li>
    </>
  );
};

const NoneGroupOptions = ({
  id,
  selectedDropdownItems,
  options,
  showSelectAll = false,
  toggleSelectAll,
}) => {
  return (
    <>
      {showSelectAll && (
        <AllSelectOption
          toggleSelectAll={toggleSelectAll}
          selectedDropdownItems={selectedDropdownItems}
        />
      )}
      {!!selectedDropdownItems.length &&
        selectedDropdownItems?.map((item, itemIdx) => (
          <Listbox.Option
            className={({ active }) =>
              classNames(
                {
                  'border-b border-gray-200': itemIdx === selectedDropdownItems.length - 1,
                  'bg-gray-100': active,
                },
                'px-3 py-2.5',
                'focus:outline-none'
              )
            }
            key={`${id}-option-${item.id ?? itemIdx}`}
            value={item}
          >
            <button
              type='button'
              className={classNames('flex items-center', 'text-left', 'w-full')}
            >
              <input
                type='checkbox'
                checked
                readOnly
                className={classNames(
                  'border border-gray-300 rounded-[4px]',
                  'checked:text-indigo-600',
                  'mr-3'
                )}
              />
              <div className={classNames('flex flex-col', 'text-sm', 'gap-0.5')}>
                <span className={classNames('font-medium text-gray-700')}>{item?.name}</span>
                <span className={classNames('font-normal text-gray-500')}>{item?.number}</span>
              </div>
            </button>
          </Listbox.Option>
        ))}
      {!!options?.length &&
        options?.map((item, itemIdx) => {
          const isOptionSelected = !!selectedDropdownItems?.find(selectedItem =>
            isEqual(item, selectedItem)
          );
          if (!isOptionSelected)
            return (
              <Listbox.Option
                className={({ active }) =>
                  classNames(
                    {
                      'bg-gray-100': active,
                    },
                    'px-3 py-2.5',
                    'focus:outline-none'
                  )
                }
                key={`${id}-option-${item.id ?? itemIdx}`}
                value={item}
              >
                <button
                  type='button'
                  className={classNames('flex items-center', 'text-left', 'w-full')}
                >
                  <input
                    type='checkbox'
                    readOnly
                    className={classNames(
                      'border border-gray-300 rounded-[4px]',
                      'checked:text-indigo-600',
                      'mr-3'
                    )}
                  />
                  <div className={classNames('flex flex-col', 'text-sm', 'gap-0.5')}>
                    <span className={classNames('font-medium text-gray-700')}>{item?.name}</span>
                    <span className={classNames('font-normal text-gray-500')}>{item?.number}</span>
                  </div>
                </button>
              </Listbox.Option>
            );
          else return null;
        })}
    </>
  );
};

const GroupOptions = ({
  id,
  selectedDropdownItems,
  groupOptions,
  showSelectAll,
  toggleSelectAll,
}) => {
  return (
    <>
      {showSelectAll && (
        <AllSelectOption
          toggleSelectAll={toggleSelectAll}
          selectedDropdownItems={selectedDropdownItems}
        />
      )}
      {!!groupOptions?.length &&
        groupOptions?.map((group, groupIdx) => (
          <div
            className={classNames('flex flex-col', {
              'border-t border-gray-200 pt-[5px]': groupIdx,
            })}
            key={`${id}-group-options-${groupIdx}`}
          >
            <span className={classNames('text-xs font-semibold text-gray-500', 'px-3 py-2')}>
              {group.title}
            </span>
            {group.options?.map((option, optionIdx) => (
              <Listbox.Option
                className={({ active }) =>
                  classNames(
                    {
                      'bg-gray-100': active,
                    },
                    'px-3 py-2.5',
                    'focus:outline-none'
                  )
                }
                key={`${id}-option-${option.id ?? optionIdx}`}
                value={option}
              >
                <button
                  type='button'
                  className={classNames('flex items-center', 'text-left', 'w-full')}
                >
                  <input
                    type='checkbox'
                    readOnly
                    checked={!!selectedDropdownItems?.find(item => isEqual(item, option))}
                    className={classNames(
                      'border border-gray-300 rounded-[4px]',
                      'checked:text-indigo-600',
                      'mr-3'
                    )}
                  />
                  <div className={classNames('flex flex-col', 'text-sm')}>
                    <span className={classNames('font-medium text-gray-700')}>{option.name}</span>
                    <span className={classNames('font-normal text-gray-500')}>{option.number}</span>
                  </div>
                </button>
              </Listbox.Option>
            ))}
          </div>
        ))}
    </>
  );
};

export const MultipleSelect = ({
  id,
  className,
  classNameSelectedText,
  classNameWrap,
  required,
  title,
  placeholder,
  defaultValues = null,
  options,
  groupOptions,
  isError,
  lableError,
  onChange,
  labelErrorPosition = labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT,
  onBlur,
  dropdownDirection = selectOptionsPositionEnum.BOTTOM,
  disabled,
  isTruncate = true,
  showSelectAll = false,
  ...props
}) => {
  const labelErrorRef = useRef(null);
  const [selectedDropdownItems, setSelectedDropdownItems] = useState(defaultValues || []);
  const [isLabelErrorTruncated, setIsLabelErrorTruncated] = useState(false);

  const groupOptionList = useMemo(
    () => !!groupOptions?.length && groupOptions?.map(groups => [...groups?.options])?.flat(),
    [groupOptions]
  );

  const handleRemoveSelectedItem = (e, itemToRemove) => {
    e.stopPropagation();
    setSelectedDropdownItems(prevState => {
      const newSelectedItems = prevState.filter(item => !isEqual(item, itemToRemove));
      onChange && onChange(newSelectedItems, id);
      return newSelectedItems;
    });
  };

  const handleSelectOnchange = value => {
    setSelectedDropdownItems(value);
    onChange && onChange(value, id);
  };

  const handleToggleSelectAll = allOptions => {
    if (selectedDropdownItems?.length) {
      setSelectedDropdownItems([]);
      onChange && onChange([], id);
    } else {
      setSelectedDropdownItems(allOptions);
      onChange && onChange(allOptions, id);
    }
  };

  useEffect(() => {
    if (isError)
      setIsLabelErrorTruncated(
        labelErrorRef.current?.offsetWidth < labelErrorRef.current?.scrollWidth
      );
  }, [isError]);

  useEffect(() => {
    if (defaultValues && JSON.stringify(defaultValues) !== JSON.stringify(selectedDropdownItems)) {
      setSelectedDropdownItems(defaultValues);
    }
  }, [defaultValues]);

  return (
    <Listbox
      value={selectedDropdownItems}
      onChange={handleSelectOnchange}
      multiple
      disabled={disabled}
    >
      {({ open }) => (
        <div className={classNames('relative', classNameWrap)}>
          <Listbox.Button
            className={classNames(
              'w-full',
              'border rounded-md',
              {
                'border-gray-300': !isError,
                'border-red-300': isError,
              },
              'pb-2 px-3',
              selectedDropdownItems?.length === 0 ? 'pt-6' : 'pt-7',
              'flex',
              {
                'focus:ring-1': !className?.includes('focus:ring-'),
              },
              'ring-inset ring-blue-600 focus:border-blue-600 focus-visible:outline-none',
              'disabled:bg-gray-50',
              className
            )}
            onBlur={e => {
              !open && onBlur && onBlur({ ...e, target: { ...e.target, id } });
            }}
            {...props}
          >
            {({ open }) => (
              <>
                <Listbox.Label
                  className={classNames(
                    'absolute top-2 left-3',
                    'text-xs font-medium text-gray-900'
                  )}
                >
                  {title}
                  {required && <span className='text-red-500'>*</span>}
                </Listbox.Label>
                <div className={classNames('flex', 'min-w-0')}>
                  {selectedDropdownItems?.length === 0 && (
                    <span className={classNames('text-gray-400 text-sm font-normal')}>
                      {placeholder}
                    </span>
                  )}
                  <span
                    data-testid='listbox-selected'
                    className={classNames({
                      'truncate': isTruncate,
                      'flex flex-wrap w-full gap-y-2': !isTruncate && selectedDropdownItems.length,
                    })}
                  >
                    {!!selectedDropdownItems?.length &&
                      selectedDropdownItems?.map((item, itemIdx) => (
                        <Chip
                          key={`${id}-selected-chip-${item.id ?? itemIdx}`}
                          className={classNames(
                            'bg-gray-200 text-gray-700',
                            'rounded-[4px]',
                            'mr-1.5',
                            'inline-flex'
                          )}
                          classNameText={classNames(classNameSelectedText, 'font-normal')}
                          size={chipSizeEnum.MEDIUM}
                          type={chipTypesEnum.RECTANGLE}
                          text={item?.selectedName || item?.name}
                          leftIcon={
                            <XIcon
                              tabIndex={0}
                              onKeyDown={e => {
                                e.stopPropagation();
                                (e.code === 'Space' || e.code === 'Enter') &&
                                  handleRemoveSelectedItem(e, item);
                              }}
                              onClick={e => {
                                handleRemoveSelectedItem(e, item);
                              }}
                              className={classNames('w-4 h-4', 'order-1', 'ml-2', 'fill-gray-400')}
                            />
                          }
                        />
                      ))}
                  </span>
                </div>
                {!disabled ? (
                  open ? (
                    <ChevronUpIcon
                      className={classNames(
                        'w-5 h-5',
                        'absolute',
                        'inset-y-0',
                        'my-auto',
                        'right-3',
                        'fill-gray-400'
                      )}
                    />
                  ) : (
                    <ChevronDownIcon
                      className={classNames(
                        'w-5 h-5',
                        'absolute',
                        'inset-y-0',
                        'my-auto',
                        'right-3',
                        'fill-gray-400'
                      )}
                    />
                  )
                ) : null}
                {isError && (
                  <div
                    className={classNames('flex items-center', 'w-full', 'absolute z-20', {
                      'top-0 right-0 mt-2 mr-3':
                        labelErrorPosition === labelErrorPositionEnum.INSIDE_TOP_RIGHT,
                      '-bottom-5 left-0':
                        labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_LEFT,
                      '-bottom-5 right-0':
                        labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT,
                      '-top-5 left-0':
                        labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_LEFT,
                      '-top-5 right-0':
                        labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_RIGHT,
                    })}
                  >
                    <div className={classNames('relative', 'w-full', 'flex')}>
                      <p
                        ref={labelErrorRef}
                        className={classNames(
                          'grow',
                          'w-full',
                          'text-xs text-red-500 font-medium',
                          'whitespace-nowrap overflow-hidden text-ellipsis',
                          {
                            'text-right':
                              labelErrorPosition === labelErrorPositionEnum.INSIDE_TOP_RIGHT ||
                              labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT ||
                              labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_RIGHT,
                          }
                        )}
                      >
                        {lableError}
                      </p>
                      {isLabelErrorTruncated && (
                        <Tooltip
                          icon={<ExclamationCircleIcon className='w-4  fill-red-500' />}
                          text={lableError}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </Listbox.Button>
          <Listbox.Options
            className={classNames(
              'absolute left-0',
              'z-50',
              'w-full',
              'py-[5px]',
              'bg-white',
              'border border-gray-300 rounded-md',
              'shadow',
              'max-h-96 overflow-y-auto',
              'focus:outline-none',
              {
                'top-full mt-1.5': dropdownDirection === selectOptionsPositionEnum.BOTTOM,
                'bottom-full mb-1.5': dropdownDirection === selectOptionsPositionEnum.TOP,
              }
            )}
            unmount
          >
            {!groupOptions && (
              <NoneGroupOptions
                id={id}
                options={options}
                selectedDropdownItems={selectedDropdownItems}
                showSelectAll={showSelectAll}
                toggleSelectAll={() => handleToggleSelectAll(options)}
              />
            )}
            {groupOptions && (
              <GroupOptions
                id={id}
                groupOptions={groupOptions}
                selectedDropdownItems={selectedDropdownItems}
                showSelectAll={showSelectAll}
                toggleSelectAll={() => handleToggleSelectAll(groupOptionList)}
              />
            )}
          </Listbox.Options>
        </div>
      )}
    </Listbox>
  );
};

MultipleSelect.propTypes = {
  className: PropTypes.string,
  classNameSelectedText: PropTypes.string,
  classNameWrap: PropTypes.string,
  defaultValues: PropTypes.array,
  dropdownDirection: PropTypes.oneOf([...Object.values(selectOptionsPositionEnum)]),
  groupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      options: PropTypes.array,
    })
  ),
  id: PropTypes.string,
  isError: PropTypes.bool,
  labelErrorPosition: PropTypes.oneOf([...Object.values(labelErrorPositionEnum)]),
  lableError: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  title: PropTypes.string,
  showSelectAll: PropTypes.bool,
  disabled: PropTypes.bool,
  isTruncate: PropTypes.bool,
};
