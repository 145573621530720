/**@format */
import { createSlice } from '@reduxjs/toolkit';
import { CRM_NOTIFICATIONS_DATA } from '../../../../data/data';

export const notificationsSlice = createSlice({
  name: 'crmNotifications',
  initialState: {
    notificationsList: CRM_NOTIFICATIONS_DATA,
  },
  reducers: {
    addNewNotification: (state, { payload }) => {
      state.notificationsList.push({
        ...payload,
        id: state.notificationsList[state.notificationsList.length - 1].id + 1,
      });
    },
    removeNotifications: (state, { payload = [] }) => {
      state.notificationsList = state.notificationsList.filter(item => !payload.includes(item.id));
    },
    editNotification: (state, { payload }) => {
      state.notificationsList = state.notificationsList.map(item => {
        if (item.id === payload.id) return { ...item, ...payload };
        return item;
      });
    },
  },
});

export const { addNewNotification, removeNotifications, editNotification } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
