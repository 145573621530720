/**@format */

import { createSlice } from '@reduxjs/toolkit';
import { RETURN_POLICY_DATA } from 'data/data';

export const returnPolicy = createSlice({
  name: 'revenueReturnPolicy',
  initialState: RETURN_POLICY_DATA,
  reducers: {},
});

export const selectReturnPolicyData = state => state.dashboard.revenue.returnPolicy;
export default returnPolicy.reducer;
