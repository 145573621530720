/**@format */
import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import moment from 'moment-timezone';

import { classNames, yearRanges } from 'helpers';

import { datetimePickerModeEnum } from '../../constants/enum';

const getDecadeObjectFromYear = year => ({
  start: yearRanges(year, 10),
  end: yearRanges(year, 10, 9),
});
const getCenturyObjectFromYear = year => ({
  start: yearRanges(year, 100),
  end: yearRanges(year, 100, 99),
});

export const YearPicker = ({
  year,
  pickerMode,
  onSelect,
  onSwitchMode,
  minDate,
  maxDate,
  positionDropdownStyle = {
    defaultClassName: '',
    positionTopInlineStyle: {},
  },
}) => {
  const [decadeState, setDecadeState] = useState(null);
  const [centuryState, setCenturyState] = useState(null);
  const minYear = minDate ? moment(+minDate).year() : undefined;
  const maxYear = maxDate ? moment(+maxDate).year() : undefined;

  const handleDecrease = () => {
    if (
      pickerMode === datetimePickerModeEnum.YEAR_DECADE_PICKER &&
      decadeState &&
      decadeState.start > 0
    ) {
      const newDecade = getDecadeObjectFromYear(decadeState.start - 1);
      setDecadeState(prevState => ({ ...prevState, ...newDecade }));
    } else if (
      pickerMode === datetimePickerModeEnum.YEAR_CENTURY_PICKER &&
      centuryState &&
      centuryState.start > 0
    ) {
      const newCentury = getCenturyObjectFromYear(centuryState.start - 1);
      setCenturyState(prevState => ({ ...prevState, ...newCentury }));
    }
  };
  const handleIncrease = () => {
    if (pickerMode === datetimePickerModeEnum.YEAR_DECADE_PICKER && decadeState) {
      const newDecade = getDecadeObjectFromYear(decadeState.end + 1);
      setDecadeState(prevState => ({ ...prevState, ...newDecade }));
    } else if (pickerMode === datetimePickerModeEnum.YEAR_CENTURY_PICKER) {
      const newCentury = getCenturyObjectFromYear(centuryState.end + 1);
      setCenturyState(prevState => ({ ...prevState, ...newCentury }));
    }
  };
  const handleYearSelect = value => () => {
    onSelect && onSelect(value);
  };
  const handleDecadeSelect = value => () => {
    onSwitchMode && onSwitchMode(datetimePickerModeEnum.YEAR_DECADE_PICKER);
    setDecadeState(prevState => ({ ...prevState, ...getDecadeObjectFromYear(value) }));
  };
  const handleSwitchCenturyMode = () => {
    setCenturyState(prevState => ({
      ...prevState,
      ...getCenturyObjectFromYear(decadeState.start),
    }));
    onSwitchMode && onSwitchMode(datetimePickerModeEnum.YEAR_CENTURY_PICKER);
  };

  let renderYearsArray =
    decadeState &&
    Array.from({ length: 12 }, (_, i) => {
      if (i === 0)
        return { value: decadeState.start > 0 ? decadeState.start - 1 : '', disabled: true };
      else if (i === 11) return { value: decadeState.end + 1, disabled: true };
      else {
        const value = decadeState.start + i - 1;
        const disabled = value > maxYear || value < minYear;
        return { value, disabled };
      }
    });

  let renderDecadesArray =
    centuryState &&
    Array.from({ length: 12 }, (_, i) => {
      let decade = { start: null, end: null };
      if (i === 0) {
        decade.start = centuryState.start > 0 ? yearRanges(centuryState.start - 1, 10) : '';
        decade.end = centuryState.start > 0 ? yearRanges(centuryState.start - 1, 10, 9) : '';
      } else if (i === 11) {
        decade.start = yearRanges(centuryState.end + 1, 10);
        decade.end = yearRanges(centuryState.end + 1, 10, 9);
      } else {
        decade.start = yearRanges(centuryState.start + (i - 1) * 10, 10);
        decade.end = yearRanges(centuryState.start + (i - 1) * 10, 10, 9);
      }
      const isDecadeIncludedMinYear = minYear
        ? minYear >= decade.start && minYear <= decade.end
        : true;
      const isDecadeIncludedMaxYear = maxYear
        ? maxYear >= decade.start && maxYear <= decade.end
        : true;
      const isEnabled = isDecadeIncludedMinYear || isDecadeIncludedMaxYear;
      return {
        children: (
          <div className='flex flex-col'>
            <span>{decade.start}</span>
            <span>{decade.end}</span>
          </div>
        ),
        value: centuryState.start + (i - 1) * 10,
        disabled: i === 0 || i === 11 || !isEnabled,
      };
    });

  useEffect(() => {
    if (year && typeof year === 'number') {
      const newDecadeState = getDecadeObjectFromYear(year);
      const newCenturyState = getCenturyObjectFromYear(year);
      setDecadeState({ ...newDecadeState, selected: year });
      setCenturyState({ ...newCenturyState, selected: year });
    }
  }, [year]);

  return (
    <div
      className={classNames(
        'flex flex-col',
        'w-fit',
        'z-[9999]',
        'absolute',
        'border border-gray-300 border-solid rounded-lg',
        'px-5',
        'bg-white',
        positionDropdownStyle.defaultClassName
      )}
      style={positionDropdownStyle.positionTopInlineStyle}
    >
      <div
        className={classNames(
          'flex justify-between items-center',
          'py-3',
          'border-b border-gray-200'
        )}
      >
        {pickerMode === datetimePickerModeEnum.YEAR_DECADE_PICKER && (
          <button
            type='button'
            className='text-lg font-medium text-gray-800'
            onClick={handleSwitchCenturyMode}
          >
            {decadeState && `${decadeState.start}-${decadeState.end}`}
          </button>
        )}
        {pickerMode === datetimePickerModeEnum.YEAR_CENTURY_PICKER && (
          <div className='text-lg font-medium text-gray-800'>
            {centuryState && `${centuryState.start}-${centuryState.end}`}
          </div>
        )}
        <div className='flex justify-center items-center text-gray-400'>
          <button type='button' onClick={handleDecrease}>
            <ChevronLeftIcon className='w-6 h-6' />
          </button>
          <button type='button' onClick={handleIncrease}>
            <ChevronRightIcon className='w-6 h-6' />
          </button>
        </div>
      </div>
      <div className={classNames('py-4', 'grid grid-cols-[repeat(4,1fr)]', 'gap-4')}>
        {pickerMode === datetimePickerModeEnum.YEAR_DECADE_PICKER &&
          renderYearsArray?.map(item => (
            <button
              key={`year_picker_decade_${item.value}`}
              type='button'
              disabled={item.disabled}
              className={classNames('text-sm font-medium ', 'rounded-[4px]', 'px-5 py-4', {
                'bg-indigo-600 text-white hover:bg-indigo-600': item.value === decadeState.selected,
                'text-gray-400': item.disabled,
                'text-gray-700 hover:bg-gray-100':
                  !item.disabled && item.value !== decadeState.selected,
              })}
              onClick={handleYearSelect(item.value)}
            >
              {item.value}
            </button>
          ))}
        {pickerMode === datetimePickerModeEnum.YEAR_CENTURY_PICKER &&
          renderDecadesArray?.map(item => (
            <button
              key={`year_picker_century_${item.value}`}
              type='button'
              disabled={item.disabled}
              className={classNames('text-sm font-medium ', 'rounded-[4px]', 'px-5 py-4', {
                'bg-indigo-600 text-white hover:bg-indigo-600':
                  item.value === yearRanges(centuryState.selected, 10),
                'text-gray-400': item.disabled,
                'text-gray-700 hover:bg-gray-100':
                  !item.disabled && item.value !== yearRanges(centuryState.selected, 10),
              })}
              onClick={handleDecadeSelect(item.value)}
            >
              {item.children}
            </button>
          ))}
      </div>
    </div>
  );
};
