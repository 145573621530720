/**@format */

import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  REVENUE_TRANSACTION_EXCEPTIONS_TVM_DATA,
  REVENUE_TRANSACTION_EXCEPTIONS_MOBILE_DATA,
  REVENUE_TRANSACTION_EXCEPTIONS_HUB_DATA,
  REVENUE_TRANSACTION_EXCEPTIONS_WEB_DATA,
  REVENUE_TRANSACTION_EXCEPTIONS_FRONTDESK_DATA,
  REVENUE_TRANSACTION_EXCEPTIONS_HAWKEYE_DATA,
} from '../../../../data/data';

export const transactionExceptionsSlice = createSlice({
  name: 'transactionExceptions',
  initialState: {
    tvmList: REVENUE_TRANSACTION_EXCEPTIONS_TVM_DATA,
    mobileList: REVENUE_TRANSACTION_EXCEPTIONS_MOBILE_DATA,
    hubList: REVENUE_TRANSACTION_EXCEPTIONS_HUB_DATA,
    webList: REVENUE_TRANSACTION_EXCEPTIONS_WEB_DATA,
    frontdeskList: REVENUE_TRANSACTION_EXCEPTIONS_FRONTDESK_DATA,
    hawkeyeList: REVENUE_TRANSACTION_EXCEPTIONS_HAWKEYE_DATA,
  },
  reducers: {},
});

export const selectAllTvm = state => state.dashboard.revenue.transactionExceptions.tvmList;
export const selectAllMobile = state => state.dashboard.revenue.transactionExceptions.mobileList;
export const selectAllHub = state => state.dashboard.revenue.transactionExceptions.hubList;
export const selectAllWeb = state => state.dashboard.revenue.transactionExceptions.webList;
export const selectAllFrontdesk = state =>
  state.dashboard.revenue.transactionExceptions.frontdeskList;
export const selectAllHawkeye = state => state.dashboard.revenue.transactionExceptions.hawkeyeList;

export const selectTvmById = createSelector(
  [selectAllTvm, (_state, transactionId) => transactionId],
  (tvmData, transactionId) => tvmData?.find(item => item.id === transactionId ?? {})
);

export default transactionExceptionsSlice.reducer;
