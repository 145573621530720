/** @format */

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Menu, Popover, Transition } from '@headlessui/react';
import { BellIcon, LoginIcon, ShoppingBagIcon } from '@heroicons/react/outline';
import { ChevronDownIcon, LogoutIcon, UserCircleIcon, UserIcon } from '@heroicons/react/solid';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  selectFavoritesItemsDashboard,
  selectFeaturesItemsDashboard,
} from 'store/dashboard/dashboardSlice';
import { clearAuthToken } from 'store/auth/authSlice';
import {
  clearCartStorage,
  selectCartUUID,
  selectCartData,
  setCartData,
} from 'store/cart/cartSlice';

import { classNames, encodeBtoaStringParam, responseError, toTitleCase } from 'helpers';
import { useLogoutUserMutation } from 'services/bo/auth/auth.users';
import { useGetCartQuery } from 'services/bo/frontdeskWeb/frontdesk.cart';
import { useToast } from 'hooks/useToast';

import { CART_ADD_VALUE, CART_RELOAD_VALUE } from 'constants/Constants';
import Modal from 'components/Modal';

import LogAsModal from './Modals/MyAccount/LogAs/LogAsModal';
import PersonalDataModal from '../Header/Modals/MyAccount/PersonalData/PersonalDataModal';
import { Notifications } from './Components/Notifications';
import TopBanner from './TopBanner';
import Bag from './Components/Bag';

const Header = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const allFeaturesItemsDashboard = useSelector(selectFeaturesItemsDashboard);
  const favoritesItemsDashboard = useSelector(selectFavoritesItemsDashboard);
  const toastAddValueToBagParam = searchParams.get(CART_ADD_VALUE);
  const toastReloadValueToBagParam = searchParams.get(CART_RELOAD_VALUE);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const toast = useToast();
  const currentCartUUID = useSelector(selectCartUUID);
  const currentCartData = useSelector(selectCartData);
  const [expired, setExpired] = useState(null);

  const isShowBag = useMemo(
    () => location.pathname.startsWith('/dashboard/frontdesk-web'),
    [location]
  );

  const {
    data: { data: cartData = {} } = {},
    isFetching: isGettingCartData,
    isSuccess: isSuccessCartData,
    error: cartDataError,
  } = useGetCartQuery({ cartUuid: currentCartUUID }, { skip: !(isShowBag && currentCartUUID) });

  useEffect(() => {
    const actionsToasts = toastAddValueToBagParam || toastReloadValueToBagParam;
    switch (true) {
      case actionsToasts && !isGettingCartData && isSuccessCartData:
        const toastData = encodeBtoaStringParam(actionsToasts);
        if (Object.values(toastData)?.length > 0) {
          toast.success({
            title: [t(toastData?.title)],
            message: [t(toastData?.message)],
          });
          searchParams.delete(toastData?.id);
          setSearchParams(searchParams, { replace: true });
        }
        break;
      default:
        break;
    }
  }, [isGettingCartData, isSuccessCartData, toastAddValueToBagParam, toastReloadValueToBagParam]);

  useEffect(() => {
    if (isGettingCartData) dispatch(showLoading());
    if (!isGettingCartData && isSuccessCartData && cartData?.expired_at !== expired) {
      dispatch(setCartData(cartData));
      expired && setExpired(null);
    }
    if (cartDataError) {
      dispatch(clearCartStorage());
      responseError(cartDataError, toast, t, navigate, location);
    }
    return () => dispatch(hideLoading());
  }, [
    cartDataError,
    isGettingCartData,
    isSuccessCartData,
    cartData,
    dispatch,
    location,
    navigate,
    toast,
    t,
  ]);

  useEffect(() => {
    const cartExpired = currentCartData?.expired_at
      ? new Date(currentCartData?.expired_at).getTime()
      : 0;
    const intervalId = setInterval(() => {
      if (cartExpired < new Date().getTime()) {
        if (currentCartData?.item_count) {
          toast.info({
            title: t('Info'),
            message: t('The bag has expired'),
          });
          setExpired(currentCartData?.expired_at);
        }
        dispatch(clearCartStorage());
        clearInterval(intervalId);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [currentCartData]);

  const mergeDataDashboardArr =
    allFeaturesItemsDashboard.length !== 0 &&
    favoritesItemsDashboard.length !== 0 &&
    allFeaturesItemsDashboard.concat(favoritesItemsDashboard);

  const [isOpenPersonalDataModal, setIsOpenPersonalDataModal] = useState(false);
  const [isOpenLogAsModal, setIsOpenLogAsModal] = useState(false);
  // const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [isOpenNotifications, setIsOpenNotifications] = useState(false);
  const [isOpenBag, setIsOpenBag] = useState(false);

  /** Temporarily close this code as the search function is not ready for use at this time. May be added again in the future */
  // const handleOpenSearch = () => {
  //   setIsOpenSearch(isOpen => !isOpen);
  // };

  // const handleCloseSearch = () => {
  //   setIsOpenSearch(false);
  // };

  const handlePersonalData = () => {
    setIsOpenPersonalDataModal(true);
  };
  const handleLogAsModal = () => {
    setIsOpenLogAsModal(true);
  };
  const handleClosePersonalDataModal = () => {
    setIsOpenPersonalDataModal(false);
  };
  const handleCloseLogAsModal = () => {
    setIsOpenLogAsModal(false);
  };

  const handleOpenNotifications = () => {
    setIsOpenNotifications(true);
  };
  const handleCloseNotifications = () => {
    setIsOpenNotifications(false);
  };

  const handleOpenBag = () => {
    setIsOpenBag(true);
  };
  const handleCloseBag = () => {
    setIsOpenBag(false);
  };

  const [logoutUser] = useLogoutUserMutation();
  const handleLogoutUser = async () => {
    dispatch(showLoading());
    try {
      await logoutUser().unwrap();
      dispatch(hideLoading());
      dispatch(clearAuthToken());
      navigate('/');
    } catch (error) {
      responseError(error, toast, t, navigate, location);
      dispatch(hideLoading());
      navigate('/');
    }
  };

  /** Temporarily close this code as the search function is not ready for use at this time. May be added again in the future */
  const userNavigation = [
    // {
    //   name: t('Search'),
    //   type: 'button',
    //   icon: SearchIcon,
    //   cb: () => {
    //     handleOpenSearch();
    //   },
    //   isShow: true,
    //   className: classNames('md:hidden'),
    // },
    {
      name: t('Notifications'),
      type: 'button',
      icon: BellIcon,
      cb: handleOpenNotifications,
      isShow: true,
      className: classNames('md:hidden'),
    },
    {
      name: t('Bag'),
      type: 'button',
      icon: ShoppingBagIcon,
      cb: handleOpenBag,
      isShow: isShowBag,
      className: classNames('md:hidden'),
    },
    {
      name: t('Personal Data'),
      type: 'button',
      icon: UserIcon,
      cb: () => {
        handlePersonalData();
      },
      isShow: true,
    },
    {
      name: toTitleCase(t('Log as')),
      type: 'button',
      icon: LoginIcon,
      cb: () => {
        handleLogAsModal();
      },
      isShow: location.pathname === '/dashboard' || location.pathname === '/dashboard/welcome',
    },
    {
      name: t('Log out'),
      type: 'button',
      icon: LogoutIcon,
      cb: handleLogoutUser,
      isShow: true,
    },
  ];

  /** Temporarily close this code as the search function is not ready for use at this time. May be added again in the future */
  // const handleKeyboardShortcut = e => {
  //   if (e.code === 'Enter' || 'Space') handleOpenSearch();
  // };
  return (
    <>
      <header
        className={classNames(
          'flex relative flex-col items-center justify-center',
          'top-0 z-30',
          'shadow',
          'px-4 sm:px-6 py-2.5',
          'min-w-[375px]',
          'md:h-[50px]',
          'bg-white'
        )}
      >
        <Popover className='relative w-full bg-white'>
          <div className='flex items-center justify-between gap-2'>
            <div className='flex items-center flex-1'>{children}</div>
            <div className={classNames('flex items-center', 'md:space-x-5', 'pl-2')}>
              <Popover.Group as='nav' className='h-6 md:flex space-x-5 hidden'>
                {/** Temporarily close this code as the search function is not ready for use at this time. May be added again in the future */}
                {/* <Popover className='relative flex justify-center items-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                  <button
                    onKeyPress={handleKeyboardShortcut}
                    className='group bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    {!isOpenSearch ? (
                      <SearchIcon
                        onClick={handleOpenSearch}
                        className='shrink-0 h-6 w-6 text-gray-400 cursor-pointer'
                        aria-hidden='true'
                      />
                    ) : (
                      <XIcon
                        onClick={handleCloseSearch}
                        className='w-5 text-gray-400 cursor-pointer m-[4px]'
                      />
                    )}
                  </button>
                </Popover> */}
                <Popover className='relative'>
                  <button
                    onClick={handleOpenNotifications}
                    className='group bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    <BellIcon className='shrink-0 h-6 w-6 text-gray-400' aria-hidden='true' />
                  </button>
                </Popover>
                {isShowBag && (
                  <Popover className='relative'>
                    <button
                      onClick={handleOpenBag}
                      className='group relative bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-5'
                    >
                      <ShoppingBagIcon className='shrink-0 w-6 text-gray-400' aria-hidden='true' />
                      <span className='absolute top-0.5 left-full ml-1.5 text-black text-sm'>
                        {currentCartData?.item_count ?? 0}
                      </span>
                    </button>
                  </Popover>
                )}
              </Popover.Group>
              <div className='md:flex items-center md:border-l md:border-gray-300 md:space-x-3.5 md:pl-3.5'>
                <Fragment>
                  <Menu as='div' className='relative z-30'>
                    <>
                      <div>
                        <Menu.Button
                          className={classNames(
                            'max-w-xs text-gray-400 bg-white rounded-full flex items-center text-sm',
                            'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                            'rounded-lg',
                            'pl-1.5'
                          )}
                        >
                          <span
                            className={classNames(
                              'hidden md:flex',
                              'max-w-xs',
                              'md:pr-3',
                              'text-gray-900 text-sm font-medium'
                            )}
                          >
                            {t('My Account')}
                          </span>
                          <span className='sr-only'>Open user menu</span>
                          <UserCircleIcon className='w-7 rounded-full' />
                          <ChevronDownIcon className='ml-1 w-5 text-gray-500' />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items
                          className={classNames(
                            'absolute right-0 mt-2 w-48 rounded-md shadow-xl border border-gray-300 py-1 bg-white',
                            'origin-top-right focus:outline-none'
                          )}
                        >
                          {userNavigation
                            .filter(item => item.isShow)
                            .map(item => (
                              <Menu.Item key={item.name}>
                                {({ active }) =>
                                  item?.type !== 'button' ? (
                                    <Link
                                      to={item?.src}
                                      className={classNames(
                                        'flex px-4 py-2 text-sm text-gray-700 mb-0',
                                        { 'bg-gray-100': active },
                                        item?.className
                                      )}
                                    >
                                      <item.icon
                                        className='shrink-0 w-5 text-gray-400'
                                        aria-hidden='true'
                                      />
                                      <p className='ml-2.5'>{item.name}</p>
                                    </Link>
                                  ) : (
                                    <button
                                      type='button'
                                      className={classNames(
                                        'flex px-4 py-2 text-sm text-gray-700 mb-0 w-full text-left',
                                        { 'bg-gray-100': active },
                                        item?.className
                                      )}
                                      onClick={() => item.cb && item.cb()}
                                    >
                                      <item.icon
                                        className='shrink-0 w-5 text-gray-400'
                                        aria-hidden='true'
                                      />
                                      <p className='ml-2.5'>{item.name}</p>
                                    </button>
                                  )
                                }
                              </Menu.Item>
                            ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  </Menu>
                </Fragment>
              </div>
            </div>
          </div>
        </Popover>

        {/** Temporarily close this code as the search function is not ready for use at this time. May be added again in the future */}
        {/* <Search
          isOpen={isOpenSearch}
          isClose={handleCloseSearch}
          dataList={mergeDataDashboardArr}
          className='z-10 absolute top-full left-0 right-0'
          classNameContainer='border border-gray-200 bg-white px-7 py-4'
        /> */}

        <Notifications
          isShow={isOpenNotifications}
          onClose={handleCloseNotifications}
          className={classNames(
            'top-full right-5',
            { 'md:right-16': isShowBag },
            { 'md:right-0': !isShowBag },
            '-mt-1 sm:mt-1'
          )}
        />

        {isShowBag && (
          <Bag
            isShow={isOpenBag}
            onClose={handleCloseBag}
            cartItems={currentCartData?.items}
            total={currentCartData?.total?.formatted}
            isGettingData={isGettingCartData}
            className={classNames('top-full right-5', '-mt-1 sm:mt-1')}
          />
        )}
      </header>
      <TopBanner />
      {/* Personal data modal */}
      <Modal isShow={isOpenPersonalDataModal} onCloseWhenEsc={handleClosePersonalDataModal}>
        <PersonalDataModal onClose={handleClosePersonalDataModal} />
      </Modal>

      {/* Log as modal */}
      <Modal isShow={isOpenLogAsModal} onCloseWhenEsc={handleCloseLogAsModal}>
        <LogAsModal onClose={handleCloseLogAsModal} />
      </Modal>
    </>
  );
};

export default Header;
