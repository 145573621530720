/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';
import { TOPOLOGY as NAV_TOPOLOGY } from 'constants/Navigation';

import DashboardLayout from 'layout/DashboardLayout/Main';

const Networks = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Topology/Networks'))),
  t => [{ name: t('Topology'), url: '/dashboard/topology' }, { name: t('Networks') }]
);

const NetworkEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Topology/Networks/NetworkEdit'))),
  t => [{ name: t('Topology'), url: '/dashboard/topology' }, { name: t('Networks') }]
);

const Routes = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Topology/Routes'))),
  t => [{ name: t('Topology'), url: '/dashboard/topology' }, { name: t('Routes') }]
);

const RouteEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Topology/Routes/RouteEdit'))),
  t => [{ name: t('Topology'), url: '/dashboard/topology' }, { name: t('Routes') }]
);

const Zones = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Topology/Zones'))),
  t => [{ name: t('Topology'), url: '/dashboard/topology' }, { name: t('Zones') }]
);

const ZoneEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Topology/Zones/ZoneEdit'))),
  t => [{ name: t('Topology'), url: '/dashboard/topology' }, { name: t('Zones') }]
);

const Locations = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Topology/Locations'))),
  t => [{ name: t('Topology'), url: '/dashboard/topology' }, { name: t('Locations') }]
);

const LocationDetails = withHeaderBreadcrumb(
  withLazyComponent(
    lazy(() => import('pages/dashboard/Topology/Locations/Components/LocationsAllTabs'))
  ),
  t => [{ name: t('Topology'), url: '/dashboard/topology' }, { name: t('Locations') }]
);

const Fleets = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Topology/Fleets'))),
  t => [{ name: t('Topology'), url: '/dashboard/topology' }, { name: t('Fleets') }]
);

const FleetEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Topology/Fleets/FleetEdit'))),
  t => [{ name: t('Topology'), url: '/dashboard/topology' }, { name: t('Fleets') }]
);

const Vehicles = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Topology/Vehicles'))),
  t => [{ name: t('Topology'), url: '/dashboard/topology' }, { name: t('Vehicles') }]
);

const VehicleEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Topology/Vehicles/VehicleEdit'))),
  t => [{ name: t('Topology'), url: '/dashboard/topology' }, { name: t('Vehicles') }]
);

const TopologyRoutes = (
  <Route path='topology' element={<DashboardLayout nav={NAV_TOPOLOGY} />}>
    <Route index element={<Navigate to='/dashboard/topology/networks' replace />} />
    <Route path='networks' element={<Networks title='Networks' />} />
    <Route path='networks/:id' element={<NetworkEdit title='Network Edit' />} />
    <Route path='routes' element={<Routes title='Routes' />} />
    <Route path='routes/:id' element={<RouteEdit title='Routes Edit' />} />
    <Route path='zones' element={<Zones title='Zones' />} />
    <Route path='zones/:id' element={<ZoneEdit title='Zones Edit' />} />
    <Route path='locations' element={<Locations title='Locations' />} />
    <Route path='locations/:id' element={<LocationDetails title='Location Details' />} />
    <Route path='fleets' element={<Fleets title='Fleets' />} />
    <Route path='fleets/:id' element={<FleetEdit title='Fleet Edit' />} />
    <Route path='vehicles' element={<Vehicles title='Vehicles' />} />
    <Route path='vehicles/:id' element={<VehicleEdit title='Vehicle Edit' />} />
    <Route path='*' element={<Navigate to='/dashboard/topology' replace />} />
  </Route>
);

export default TopologyRoutes;
