/** @format */

import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { PERMISSIONS } from 'constants/Constants';
import { staticService } from 'services';
import { toCamelCase, getJsonData } from 'helpers';
import { history } from 'navigation/appHistory';

export const fetchPermissions = createAsyncThunk(
  'static/fetchPermissions',
  async function (_, { rejectWithValue, dispatch }) {
    try {
      dispatch(showLoading());
      const response = await staticService.getPermissions();
      dispatch(hideLoading());
      return response;
    } catch (error) {
      dispatch(hideLoading());
      if (error?.response?.status === 503) {
        return history.push('/maintenance');
      }
      return rejectWithValue(error?.response);
    }
  }
);

const permissionsAdapter = createEntityAdapter({
  selectId: item => item.id,
});
const emptyInitialState = permissionsAdapter.getInitialState();
const filledState = permissionsAdapter.upsertMany(
  emptyInitialState,
  getJsonData(PERMISSIONS) || []
);
const initialState = {
  permissionsList: filledState,
  status: null,
  statusCode: null,
  errorMessage: null,
};

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPermissions.pending]: state => {
      state.status = 'loading';
      state.errorMessage = null;
      permissionsAdapter.setAll(state.permissionsList, []);
    },
    [fetchPermissions.fulfilled]: (state, { payload, type }) => {
      const permissions = payload?.data?.data || [];
      localStorage.setItem(PERMISSIONS, JSON.stringify(permissions));
      permissionsAdapter.setAll(state.permissionsList, permissions);
      state.status = toCamelCase(type);
      state.statusCode = payload?.status;
    },
    [fetchPermissions.rejected]: (state, { payload, type }) => {
      state.status = toCamelCase(type);
      state.statusCode = payload?.status;
      state.errorMessage = payload?.data;
    },
  },
});

export const {
  selectById: selectPermissionById,
  selectIds: selectPermissionIds,
  selectEntities: selectPermissionEntities,
  selectAll: selectAllPermissions,
  selectTotal: selectTotalPermissions,
} = permissionsAdapter.getSelectors(state => state.static.permissionsList);

export const selectPermissionBySlug = createSelector(
  [selectAllPermissions, (_state, slug) => slug],
  (permissions, slug) => permissions.find(per => per.slug === slug)
);

export default permissionsSlice.reducer;
