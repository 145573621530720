/** @format */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const DocumentTitle = ({ title = '', children }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const APP_NAME = t('APP_NAME');
    const pageTitle = title ? `${title} - ${APP_NAME}` : APP_NAME;

    if (pageTitle !== document.title) {
      document.title = pageTitle;
    }

    return () => {
      document.title = APP_NAME;
    };
  }, [title]);

  return children;
};

export default DocumentTitle;
