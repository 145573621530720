/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { CLIENTS as NAV_CLIENTS } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const Clients = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Clients/Clients'))),
  t => [{ name: t('Clients') }]
);
const ClientEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Clients/ClientEdit'))),
  t => [{ name: t('Clients') }]
);

const ClientsRoutes = (
  <Route path='clients' element={<DashboardLayout nav={NAV_CLIENTS} />}>
    <Route index element={<Clients title='Clients page' />} />
    <Route path=':uuid' element={<ClientEdit title='Client page' />} />
    <Route path='*' element={<Navigate to='/dashboard/clients' replace />} />
  </Route>
);

export default ClientsRoutes;
