/** @format */
import { useRef, useEffect } from 'react';

const DEFAULT_RESET_TIME = 1000;

const useRenderCount = () => {
  const renderCount = useRef(0);
  const renderCountResetTimer = useRef(null);
  let renderCountLocal = renderCount.current;

  useEffect(() => {
    renderCount.current = renderCountLocal;
    renderCountResetTimer.current = setTimeout(() => {
      renderCount.current = 0;
    }, DEFAULT_RESET_TIME);
    return () => {
      clearTimeout(renderCountResetTimer.current);
    };
  });
  renderCountLocal++;
  return renderCount.current;
};

/**
 * Interceptor for detecting infinite recursion of component rendering. 
 * Currently only enabled in development mode.
 * @param {*} maxRenders
 */
export const useStopInfiniteRender = (maxRenders = 5000) => {
  const renderCount = useRenderCount();
  if (process.env.NODE_ENV === 'development' && renderCount > maxRenders)
    throw new Error(`Infinite Renders limit reached`);
};
