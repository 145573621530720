/**@format */

import React from 'react';
import { TrashIcon } from '@heroicons/react/solid';

import { classNames } from 'helpers';
import { COLOR_TRANSPARENT } from 'constants/Constants';

import Button from 'components/Button';

const CartItemsView = ({ value, onRemoveItem, operation }) => {
  const handleRemoveCartItem = id => {
    onRemoveItem && onRemoveItem(id);
  };
  return (
    <div className={classNames('pb-2', 'flex justify-between items-start', 'mb-2', 'text-sm')}>
      <div>
        <div className={classNames('text-gray-900 font-medium', 'mb-0.5')}>
          <div className={classNames('mb-1.5')}>{operation && operation}</div>
          <div className='flex'>
            <div>{value?.display_label ?? value?.type}</div>
            {value?.masked_id && (
              <span className={classNames('ml-2', 'text-gray-500')}>{value?.masked_id}</span>
            )}
          </div>
        </div>
        {/* 
        <div className={classNames('flex flex-col', 'text-gray-500 font-normal', 'mb-1.5')}>
          {value?.quantity ? (
            <span>
              {t('Number of media impacted')}:
              <span className='text-gray-700 font-medium pl-1'>{value?.quantity}</span>
            </span>
          ) : (
            ''
          )}
        </div>
         */}
        <div className={classNames('flex flex-col')}>
          <span className='text-gray-700'>{value?.total?.formatted}</span>
        </div>
      </div>
      <Button
        outline
        className={classNames(
          'rounded-full border-0',
          'w-5 h-5 p-0 mt-1',
          'hover:bg-transparent focus:rounded-full'
        )}
        onClick={() => handleRemoveCartItem(value?.cart_item_id)}
        color={COLOR_TRANSPARENT}
      >
        <TrashIcon className='fill-gray-400 shrink-0 w-5 mt-0.5' />
      </Button>
    </div>
  );
};

export default CartItemsView;
