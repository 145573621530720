/**@format */
import { api } from 'services/bo/api';

export const clientsSettingsApi = api.injectEndpoints({
  endpoints: build => ({
    getSettings: build.query({
      query: () => `settings/client`,
      providesTags: ['Settings'],
    }),
    updateSettings: build.mutation({
      query: ({ body }) => ({
        url: `settings/client`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Settings'],
    }),
  }),
});

export const { useGetSettingsQuery, useUpdateSettingsMutation, useLazyGetSettingsQuery } =
  clientsSettingsApi;
