/**@format */

import { createSlice } from '@reduxjs/toolkit';
import { TOKEN } from 'constants/Constants';

const initialState = {
  TOKEN: localStorage.getItem(TOKEN) || null,
};

export const authSlice = createSlice({
  name: 'AUTH',
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      localStorage.setItem(TOKEN, action.payload);
      state.TOKEN = action.payload;
    },
    clearAuthToken: state => {
      localStorage.removeItem(TOKEN);
      state.TOKEN = null;
    },
  },
});
export const { setAuthToken, clearAuthToken } = authSlice.actions;

export const selectToken = state => state.auth.TOKEN;

export default authSlice.reducer;
