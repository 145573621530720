/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { DEVICES as NAV_DEVICES } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const DeviceAgents = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Devices/Agents'))),
  t => [
    {
      name: t('Device Manager'),
      url: '/dashboard/devices',
    },
    {
      name: t('Agents'),
    },
  ]
);
const DeviceAgentsEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Devices/Agents/AgentsEdit'))),
  t => [
    {
      name: t('Device Manager'),
      url: '/dashboard/devices',
    },
    {
      name: t('Agents'),
    },
  ]
);
const DeviceAxio = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Devices/Axio'))),
  t => [
    {
      name: t('Device Manager'),
      url: '/dashboard/devices',
    },
    {
      name: t('Axio').toUpperCase(),
    },
  ]
);
const DeviceAxioEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Devices/Axio/AxioEdit/AxioEdit'))),
  t => [
    {
      name: t('Device Manager'),
      url: '/dashboard/devices',
    },
    {
      name: t('Axio').toUpperCase(),
    },
  ]
);
const DeviceAxioPV = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Devices/AxioPV/AxioPV'))),
  t => [
    {
      name: t('Device Manager'),
      url: '/dashboard/devices',
    },
    {
      name: t('AXIO PV'),
    },
  ]
);
const DeviceAxioPVEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Devices/AxioPV/EditAxioPv'))),
  t => [
    {
      name: t('Device Manager'),
      url: '/dashboard/devices',
    },
    {
      name: t('AXIO PV'),
    },
  ]
);
const DeviceHandhelds = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Devices/Handhelds'))),
  t => [
    {
      name: t('Device Manager'),
      url: '/dashboard/devices',
    },
    {
      name: t('Handhelds'),
    },
  ]
);
const DeviceHandheldEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Devices/Handhelds/EditHandheld'))),
  t => [
    {
      name: t('Device Manager'),
      url: '/dashboard/devices',
    },
    {
      name: t('Handhelds'),
    },
  ]
);
const DeviceTVM = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Devices/TVM'))),
  t => [
    {
      name: t('Device Manager'),
      url: '/dashboard/devices',
    },
    {
      name: t('TVM'),
    },
  ]
);
const DeviceTVMEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Devices/TVM/EditTvm/EditTvm'))),
  t => [
    {
      name: t('Device Manager'),
      url: '/dashboard/devices',
    },
    {
      name: t('TVM'),
    },
  ]
);
const DeviceSettings = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Devices/Settings'))),
  t => [
    { name: t('Device Manager'), url: '/dashboard/devices/settings' },
    { name: t('Device Settings') },
  ]
);
const DeviceTicket = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Devices/Ticket'))),
  t => [
    {
      name: t('Device Manager'),
      url: '/dashboard/devices',
    },
    {
      name: t('Ticket Office Machines'),
    },
  ]
);
const DeviceTicketEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Devices/Ticket/EditTicket'))),
  t => [
    {
      name: t('Device Manager'),
      url: '/dashboard/devices',
    },
    {
      name: t('Ticket Office Machines'),
    },
  ]
);

const DevicesRoutes = (
  <Route path='devices' element={<DashboardLayout nav={NAV_DEVICES} />}>
    <Route index element={<Navigate to='/dashboard/devices/settings' replace />} />
    <Route path='settings' element={<DeviceSettings title='Devices Settings' />} />
    <Route path='tvm' element={<DeviceTVM title='Devices TVM' />} />
    <Route path='tvm/:id' element={<DeviceTVMEdit title='Devices TVM Edit' />} />
    <Route path='axio' element={<DeviceAxio title='Devices Axio' />} />
    <Route path='axio/:id' element={<DeviceAxioEdit title='Devices Axio Edit' />} />
    <Route path='pv' element={<DeviceAxioPV title='Devices Axio PV' />} />
    <Route path='pv/:id' element={<DeviceAxioPVEdit title='Devices Axio PV Edit' />} />
    <Route path='ticket' element={<DeviceTicket title='Devices Ticket' />} />
    <Route path='ticket/:id' element={<DeviceTicketEdit title='Devices Ticket Edit' />} />
    <Route path='handhelds' element={<DeviceHandhelds title='Devices Handhelds' />} />
    <Route path='handhelds/:id' element={<DeviceHandheldEdit title='Devices Handheld Edit' />} />
    <Route path='agents' element={<DeviceAgents title='Devices Agents' />} />
    <Route path='agents/:id' element={<DeviceAgentsEdit title='Devices Agents Edit' />} />
    <Route path='*' element={<Navigate to='/dashboard/devices/settings' replace />} />
  </Route>
);

export default DevicesRoutes;
