/** @format */
export const creditCardTypeEnum = Object.freeze({
  MASTERCARD: 'master',
  AMERICAN_EXPRESS: 'amex',
  VISA: 'visa',
});

export const paginateDefaultValues = Object.freeze({
  CURRENT_PAGE: 1,
  ITEMS_PER_PAGE: 10,
  INFINITE_ITEMS_PER_PAGE: 5,
});

export const chipTypesEnum = Object.freeze({
  RECTANGLE: 'rectangle',
  CAPSULE: 'capsule',
});

export const chipSizeEnum = Object.freeze({
  SMALL: 'small',
  LARGE: 'large',
  MEDIUM: 'medium',
});

export const modalTypesEnum = Object.freeze({
  ADDNEW: 'addNew',
  EDIT: 'edit',
  REPLY: 'reply',
});

export const fileUploadEnum = Object.freeze({
  SIZE_LIMIT: 2097152, //2MiB
  ACCEPT_IMAGE_TYPE: ['.png', '.jpg', '.jpeg'],
});

export const tooltipTypeEnum = Object.freeze({
  ARROW_TYPE: 'arrow',
  CAPSULE_TYPE: 'capsule',
});
export const tooltipPositionEnum = Object.freeze({
  TOP: 'top',
  LEFT: 'left',
  BOTTOM: 'bottom',
  RIGHT: 'right',
  TOP_CENTER: 'top-center',
});

export const labelErrorPositionEnum = Object.freeze({
  INSIDE_TOP_RIGHT: 'inside_top_right',
  OUTSIDE_TOP_LEFT: 'outside_top_left',
  OUTSIDE_BOTTOM_LEFT: 'outside_bottom_left',
  OUTSIDE_TOP_RIGHT: 'outside_top_right',
  OUTSIDE_BOTTOM_RIGHT: 'outside_bottom_right',
});

export const selectOptionsPositionEnum = Object.freeze({
  TOP: 'top',
  BOTTOM: 'bottom',
});
export const reloadDefaultModeEnum = Object.freeze({
  SET_RELOAD: 'set_reload',
  ADD_CREDIT_CARD: 'add_credit_card',
});

export const searchingStateEnum = Object.freeze({
  SEARCHING: 'searching',
  NO_RESULT: 'noResult',
});

export const rolesStateEnum = Object.freeze({
  ADMIN: 'admin',
  USER: 'hub-user',
});

export const countriesNamesModeEnum = Object.freeze({
  US: {
    name: 'US',
    value: 'us',
  },
  ES: {
    name: 'ES',
    value: 'es',
  },
});

export const defaultIdsToastEnum = Object.freeze({
  INFO_ID: 'infoToastId',
  SUCCESS_ID: 'successToastId',
  WARNING_ID: 'warningToastId',
  ERROR_ID: 'errorToastId',
});

export const defaultThemesToastEnum = Object.freeze({
  DARK: 'dark',
  LIGHT: 'light',
  COLOR: 'color',
});

export const defaultPositionToastEnum = Object.freeze({
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  TOP_CENTER: 'top-center',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_CENTER: 'bottom-center',
});

export const passActivityTypeEnum = Object.freeze({
  STATUS_CHANGED: 'status_changed',
  PASS_EXPIRED: 'pass_expired',
  ONLINE_PURCHASE: 'online_purchase',
  CREATED: 'created',
  ACTIVATED: 'activated',
  PROGRAM_ASSIGNED: 'program_assigned',
  PROGRAM_REMOVED: 'program_removed',
  PROGRAM_UPDATED: 'program_updated',
  REGISTERED: 'registered',
  UNREGISTERED: 'unregistered',
  REGISTRATION_BONUS: 'registration_bonus',
  ONLINE_PAYMENT: 'online_payment',
  DELETED: 'deleted',
  APPROVED: 'approved',
  RELOAD: 'reload',
  AUTO_RELOAD: 'auto_reload',
  BONUS: 'bonus',
  DEBT_RECOVERY_REJECTED: 'debt_recovery_rejected',
  DEBT_RECOVERY_STARTED: 'debt_recovery_started',
  DECLINED: 'declined',
  INCOMING_TRANSFER: 'incoming_transfer',
  OUTGOING_TRANSFER: 'outgoing_transfer',
  OWNER_CHANGED: 'owner_changed',
  ISSUED: 'issued',
  MEDIA_INSPECTED: 'media_inspected',
  PASS_SUPPORT_ACTIVATED: 'pass_support_activated',
  PASS_SUPPORT_DEACTIVATED: 'pass_support_deactivated',
  FARE_CAP_RESET: 'fare_cap_reset',
  OFF_DENY_LIST: 'off_deny_list',
  ON_DENY_LIST: 'on_deny_list',
  VALIDATION: 'validation',
  ONLINE_VALIDATION: 'online_validation',
  OFFLINE_VALIDATION: 'offline_validation',
  AUTHORIZATION_ERROR: 'authorization_error',
  PASS_INSPECTED: 'pass_inspected',
  LINK_MEDIA: 'link_media',
  LINK_PASS: 'link_pass',
  UNLINK_MEDIA: 'unlink_media',
  UNLINK_PASS: 'unlink_pass',
  PREAUTHORIZATION_ACCEPTED: 'preauthorization_accepted',
  PREAUTHORIZATION_REQUEST: 'preauthorization_request',
  REDEEM_CANCELED: 'redeem_canceled',
  REDEEM_PENDING: 'redeem_pending',
  REDEEMED: 'redeemed',
  SCHEDULE_UPDATED: 'schedule_updated',
  TAP: 'tap',
  BALANCE_OVERRIDE: 'balance_override',
  TRANSFER_BALANCE: 'transfer_balance',
  TRANSFER_CREATED: 'transfer_created',
  UPDATED: 'updated',
  REWARDS_EARNED: 'rewards_earned',
  REWARDS_REDEEMED: 'rewards_redeemed',
  REWARDS_ADJUSTED: 'rewards_adjusted',
  REWARDS_DATE_ADJUSTED: 'rewards_date_adjusted',
});

export const textDirectionEnum = Object.freeze({
  CENTER: 'center',
  RIGHT: 'right',
});

export const chartDirectionEnum = Object.freeze({
  CENTER: 'center',
  RIGHT: 'right',
  LEFT: 'left',
  BOTTOM: 'bottom',
  TOP: 'top',
});

export const modalPositionEnum = Object.freeze({
  LEFT: 'left',
  RIGHT: 'right',
});

export const defaultGroupsPaginationEnum = prefix => ({
  PAGE_NAME: `${prefix}_page`,
  PER_PAGE_NAME: `${prefix}_per_page`,
});
