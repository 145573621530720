/**@format */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Pikaday from 'pikaday';

import { classNames, prettyDate } from 'helpers';

import { datetimePickerModeEnum, datetimePickerPosition } from '../../constants/enum';
import { MonthPicker } from './MonthPicker';
import { TimePicker } from './TimePicker';
import { YearPicker } from './YearPicker';

const clockIconHtmlString =
  '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>';
const prevIconHtmlString = ({
  className = '',
}) => `<svg class="${className}" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.2489 6.85147C15.7175 7.3201 15.7175 8.0799 15.2489 8.54853L11.2974 12.5L15.2489 16.4515C15.7175 16.9201 15.7175 17.6799 15.2489 18.1485C14.7803 18.6172 14.0205 18.6172 13.5519 18.1485L8.75186 13.3485C8.28323 12.8799 8.28323 12.1201 8.75186 11.6515L13.5519 6.85147C14.0205 6.38284 14.7803 6.38284 15.2489 6.85147Z"/>
</svg>
`;
const nextIconHtmlString = ({
  className = '',
}) => `<svg class="${className}" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.75186 18.1485C8.28323 17.6799 8.28323 16.9201 8.75186 16.4515L12.7033 12.5L8.75186 8.54853C8.28323 8.0799 8.28323 7.3201 8.75186 6.85147C9.22049 6.38284 9.98029 6.38284 10.4489 6.85147L15.2489 11.6515C15.7175 12.1201 15.7175 12.8799 15.2489 13.3485L10.4489 18.1485C9.98029 18.6172 9.22049 18.6172 8.75186 18.1485Z"/>
</svg>
`;

export const PikadayDropdown = React.forwardRef(
  (
    {
      id,
      onChange,
      value,
      maxDate,
      minDate,
      positionX,
      positionY,
      currentMode,
      onChangeCurrentMode,
      hasTimePicker,
      isEndDay,
      dateRange,
      timezone,
      ...props
    },
    textFieldRef
  ) => {
    const pikaContainerRef = useRef(null);
    let picker = useRef(null);
    const [startRange, endRange] = dateRange ?? [];
    const [timeString, setTimeString] = useState('12:00:00 AM');
    const textFieldHeight = (textFieldRef?.current?.offsetHeight ?? 0) + 'px';
    const positionDropdownStyle = {
      defaultClassName: classNames(
        positionY === datetimePickerPosition.TOP && 'mb-2',
        positionY === datetimePickerPosition.BOTTOM && 'top-0 mt-2',
        positionX === datetimePickerPosition.LEFT && 'left-0',
        positionX === datetimePickerPosition.RIGHT && 'right-0'
      ),
      positionTopInlineStyle:
        positionY === datetimePickerPosition.TOP ? { bottom: textFieldHeight } : {},
    };

    useEffect(() => {
      if (!value) {
        if (isEndDay) setTimeString('11:59:59 PM');
        else setTimeString('12:00:00 AM');
      }
      /* eslint-disable react-hooks/exhaustive-deps */
    }, [value]);

    const handleSwitchMode = useCallback(
      mode => {
        onChangeCurrentMode && onChangeCurrentMode(mode);
      },
      [onChangeCurrentMode]
    );
    const handleMonthPickerValue = ({ month, year }) => {
      handleSwitchMode(datetimePickerModeEnum.DATE_PICKER);
      picker.current?.gotoYear(year);
      picker.current?.gotoMonth(month);
    };
    const handleYearPickerValue = year => {
      handleSwitchMode(datetimePickerModeEnum.DATE_PICKER);
      picker.current?.gotoYear(year);
    };

    const renderCustomElementDatepicker = useCallback(
      thisPicker => {
        // #region Position for datepicker dropdown
        if (thisPicker?.el) {
          thisPicker.el.removeAttribute('style');
          if (!thisPicker.el.className.includes(positionDropdownStyle.defaultClassName))
            thisPicker.el.className += ` ${positionDropdownStyle.defaultClassName}`;
          positionY === 'top' && (thisPicker.el.style.bottom = textFieldHeight);
        }
        // #endregion Position for datepicker dropdown

        // #region Custom footer datepicker
        if (hasTimePicker) {
          const timeBtn = document.createElement('button');
          timeBtn.id = `${id}-time-btn`;
          timeBtn.className = classNames(
            'py-4 w-full bg-gray-50',
            'flex justify-center items-center',
            'text-base font-medium text-gray-500',
            'gap-2',
            'rounded-b-lg'
          );
          const timeBtnLabel = document.createElement('span');
          timeBtnLabel.innerText = 'Time';
          timeBtn.innerHTML += clockIconHtmlString;
          timeBtn.appendChild(timeBtnLabel);
          timeBtn.type = 'button';
          timeBtn.onclick = () => {
            handleSwitchMode(datetimePickerModeEnum.TIME_PICKER);
          };
          const isPickerHasTimeBtn = document.getElementById(timeBtn.id);
          !isPickerHasTimeBtn && thisPicker?.el?.appendChild(timeBtn);
        }
        // #endregion Custom footer datepicker

        // #region Custom header datepicker
        const calendarMonth = thisPicker?.calendars[0]?.month;
        const calendarYear = thisPicker?.calendars[0]?.year;
        const maxMonth = thisPicker?._o?.maxMonth;
        const maxYear = thisPicker?._o?.maxYear;
        const minMonth = thisPicker?._o?.minMonth;
        const minYear = thisPicker?._o?.minYear;
        const isDisableNext = calendarMonth >= maxMonth && calendarYear >= maxYear;
        const isDisableBack = calendarMonth <= (minMonth ?? 0) && calendarYear <= minYear;

        const headerPicker = thisPicker?.el?.querySelector('div.pika-title');
        headerPicker.className += classNames(' py-3 border-b border-gray-200');
        const monthLabel = document.createElement('button');
        monthLabel.type = 'button';
        monthLabel.className = 'pika-label';
        monthLabel.innerText = moment().month(thisPicker?.calendars[0]?.month).format('MMM');
        monthLabel.onclick = () => {
          handleSwitchMode(datetimePickerModeEnum.MONTH_PICKER);
        };
        const yearLabel = document.createElement('button');
        yearLabel.type = 'button';
        yearLabel.className = 'pika-label ml-1';
        yearLabel.innerText = moment().year(thisPicker?.calendars[0]?.year).format('YYYY');
        yearLabel.onclick = () => {
          handleSwitchMode(datetimePickerModeEnum.YEAR_DECADE_PICKER);
        };
        const prevBtn = document.createElement('button');
        prevBtn.type = 'button';
        prevBtn.className = classNames(
          'group flex justify-center items-center',
          'ml-auto',
          {
            'hover:bg-gray-100': !isDisableBack,
          },
          'w-8 h-8',
          'rounded-[4px]',
          'text-gray-400',
          'pika-prev'
        );
        prevBtn.innerHTML += prevIconHtmlString({
          className: classNames({
            'fill-gray-400 group-hover:fill-gray-500': !isDisableBack,
            'fill-gray-200': isDisableBack,
          }),
        });
        prevBtn.onclick = () => {
          if (!isDisableBack) {
            thisPicker?.prevMonth();
          }
        };
        prevBtn.disabled = isDisableBack;

        const nextButton = document.createElement('button');
        nextButton.type = 'button';
        nextButton.className = classNames(
          'group flex justify-center items-center',
          {
            'hover:bg-gray-100': !isDisableNext,
          },
          'w-8 h-8',
          'rounded-[4px]',
          'pika-next'
        );
        nextButton.innerHTML += nextIconHtmlString({
          className: classNames({
            'fill-gray-400 group-hover:fill-gray-500': !isDisableNext,
            'fill-gray-200': isDisableNext,
          }),
        });
        nextButton.onclick = () => {
          if (!isDisableNext) {
            thisPicker?.nextMonth();
          }
        };
        nextButton.disabled = isDisableNext;

        if (headerPicker) {
          headerPicker.innerHTML = '';
          headerPicker.appendChild(monthLabel);
          headerPicker.appendChild(yearLabel);
          headerPicker.appendChild(prevBtn);
          headerPicker.appendChild(nextButton);
        }
        // #endregion Custom header datepicker

        props.type === 'dateRange' && thisPicker.el?.classList?.add('date-range');
      },
      [
        handleSwitchMode,
        hasTimePicker,
        id,
        positionDropdownStyle.defaultClassName,
        positionY,
        props.type,
        textFieldHeight,
      ]
    );
    const handlePikaSelect = useCallback(
      (dateValue, inputTimeString) => {
        const combineDatetimeValue = `${moment(dateValue || new Date()).format('MM/DD/YYYY')} ${
          inputTimeString ?? timeString
        }`;
        const parsedChosenDay = timezone
          ? moment.tz(combineDatetimeValue, 'MM/DD/YYYY hh:mm:ss A', timezone)
          : moment(combineDatetimeValue, 'MM/DD/YYYY hh:mm:ss A');
        const timestampChosenDay = parsedChosenDay.valueOf().toString();
        const fakeEl = { target: { id, value: timestampChosenDay } };
        onChange && onChange(fakeEl);
      },
      [id, timeString, timezone]
    );
    const handleTimePickerValue = timeValue => {
      const formatedTime = timeValue
        ? `${timeValue.hour}:${timeValue.min}:${timeValue.second} ${timeValue.period}`
        : '';
      handlePikaSelect(+value, formatedTime);
      setTimeString(formatedTime);
      handleSwitchMode(datetimePickerModeEnum.DATE_PICKER);
    };

    useEffect(() => {
      if (value && value.length > 0 && hasTimePicker) {
        const defaultTimeString = timezone
          ? moment.tz(+value, timezone).format('hh:mm:ss A')
          : moment(+value).format('hh:mm:ss A');
        setTimeString(defaultTimeString);
      }
    }, [value, hasTimePicker, timezone]);
    useEffect(() => {
      picker.current = new Pikaday({
        showDaysInNextAndPreviousMonths: true,
        onSelect: value => {
          handlePikaSelect(value);
          handleSwitchMode(null);
        },
        maxDate: maxDate ? moment(+maxDate).toDate() : undefined,
        minDate: minDate ? moment(+minDate).toDate() : undefined,
        setDefaultDate: true,
        defaultDate:
          value?.length > 0
            ? timezone
              ? new Date(
                  moment(+value)
                    .tz(timezone)
                    .format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
                )
              : moment(+value).toDate()
            : null,
        i18n: {
          previousMonth: 'Previous Month',
          nextMonth: 'Next Month',
          months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
          weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        },
        bound: false,
        toString: date => {
          const formatedDate = prettyDate(
            date,
            props.type === 'dateRange' ? 'MM / DD / YY' : 'MM/DD/YYYY'
          );
          return hasTimePicker ? `${formatedDate} | ${timeString}` : formatedDate;
        },
        format: 'MM/DD/YYYY',
        startRange: startRange ? moment(+startRange).toDate() : null,
        endRange: endRange ? moment(+endRange).toDate() : null,
        onOpen: function () {
          renderCustomElementDatepicker(this);
        },
        onDraw: () => {
          picker.current && renderCustomElementDatepicker(picker.current);
        },
      });
      !pikaContainerRef.current.contains(picker.current.el) &&
        pikaContainerRef.current.appendChild(picker.current.el);

      props.type === 'date' && picker.current?.hide();
      return () => {
        picker.current?.destroy();
      };
    }, [
      hasTimePicker,
      maxDate,
      minDate,
      props.type,
      timeString,
      value,
      id,
      startRange,
      endRange,
      renderCustomElementDatepicker,
      handlePikaSelect,
      timezone,
    ]);
    useEffect(() => {
      if (currentMode === datetimePickerModeEnum.DATE_PICKER) {
        textFieldRef.current?.focus();
        picker.current?.show();
      } else picker.current?.isVisible() && picker.current?.hide();
    }, [currentMode, textFieldRef]);

    return (
      <div className='w-full relative' ref={pikaContainerRef}>
        {hasTimePicker && currentMode === datetimePickerModeEnum.TIME_PICKER && (
          <TimePicker
            defaultValue={timeString}
            onBottomButtonClick={handleTimePickerValue}
            positionDropdownStyle={positionDropdownStyle}
          />
        )}
        {currentMode === datetimePickerModeEnum.MONTH_PICKER && (
          <MonthPicker
            defaultValue={
              picker.current?.calendars[0]
                ? {
                    month: picker.current.calendars[0].month,
                    year: picker.current.calendars[0].year,
                  }
                : null
            }
            onSelect={handleMonthPickerValue}
            onSwitchMode={handleSwitchMode}
            maxDate={maxDate}
            minDate={minDate}
            positionDropdownStyle={positionDropdownStyle}
          />
        )}
        {(currentMode === datetimePickerModeEnum.YEAR_DECADE_PICKER ||
          currentMode === datetimePickerModeEnum.YEAR_CENTURY_PICKER) && (
          <YearPicker
            year={picker.current?.calendars[0]?.year}
            onSelect={handleYearPickerValue}
            pickerMode={currentMode}
            onSwitchMode={handleSwitchMode}
            maxDate={maxDate}
            minDate={minDate}
            positionDropdownStyle={positionDropdownStyle}
          />
        )}
      </div>
    );
  }
);

PikadayDropdown.propTypes = {
  currentMode: PropTypes.oneOf(Object.values(datetimePickerModeEnum)),
  dateRange: PropTypes.arrayOf(PropTypes.string),
  hasTimePicker: PropTypes.bool,
  id: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  onChange: PropTypes.func,
  onChangeCurrentMode: PropTypes.func,
  positionX: PropTypes.oneOf([datetimePickerPosition.LEFT, datetimePickerPosition.RIGHT]),
  positionY: PropTypes.oneOf([datetimePickerPosition.TOP, datetimePickerPosition.BOTTOM]),
  value: PropTypes.string,
  timezone: PropTypes.string,
};
