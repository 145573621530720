/** @format */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

import { classNames } from 'helpers';

import { labelErrorPositionEnum } from 'constants/enum';
import { Tooltip } from 'components/Tooltip';

import { datetimePickerPosition } from './constants/enum';
import { DatepickerTextField } from './components/DatepickerTextField';
import { DateRangePickerTextField } from './components/DateRangePickerTextField';
import { TimePickerTextField } from './components/TimePickerTextField';
import { TimeTextField } from './components/TimeTextField';
import { MonthPickerTextField } from './components/MonthPickerTextField';

const TextField = ({
  id,
  name,
  onClickInput,
  label = '',
  lableError = '',
  placeholder = '',
  value = '',
  onChange = () => {},
  onBlur = () => {},
  onValidate = () => {},
  type = 'text',
  className = '',
  classNameInput = '',
  classNameInputLabel = '',
  required = false,
  disabled = false,
  isError,
  leftComponent,
  rightComponent,
  children,
  mask,
  maxDate,
  autoComplete = 'off',
  labelErrorPosition = labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT,
  positionXDatePicker = '',
  isEndDay = false,
  ...props
}) => {
  const labelErrorRef = useRef(null);
  const [isLabelErrorTruncated, setIsLabelErrorTruncated] = useState(false);
  const [isPreloadDisabled, setIsPreloadDisabled] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsPreloadDisabled(false);
    }, 100);
  }, []);

  useEffect(() => {
    if (isError && lableError)
      setIsLabelErrorTruncated(
        labelErrorRef.current?.offsetWidth < labelErrorRef.current?.scrollWidth
      );
  }, [isError, lableError]);

  return (
    <div
      className={classNames(
        'flex relative w-full border rounded-md',
        {
          'border-gray-300': !isError && !disabled,
          'border-gray-200': disabled && !isError,
          'border-red-300': isError,
          'focus-within:border-1 focus-within:border-blue-600 ring-inset focus-within:ring-1 focus-within:ring-blue-600':
            type === 'textarea',
        },
        className
      )}
    >
      {leftComponent}
      <div className={classNames('relative w-full', { 'px-[1px]': type === 'textarea' })}>
        <label
          htmlFor={id}
          className={classNames(
            'text-gray-900 text-xs leading-4 font-medium truncate',
            {
              'absolute z-20 left-3 right-3 top-2': type !== 'textarea',
              'px-[11px] pt-2 pb-1 block rounded-tl-md rounded-tr-md': type === 'textarea',
            },
            classNameInputLabel
          )}
        >
          {label}
          {required && <span className='text-red-500'>*</span>}
        </label>

        {type === 'date' ? (
          <DatepickerTextField
            id={id}
            type={type}
            maxDate={maxDate}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            autoComplete='off'
            aria-autocomplete='none'
            placeholder={placeholder}
            disabled={disabled}
            className={classNames(classNameInput)}
            positionX={positionXDatePicker || datetimePickerPosition.LEFT}
            positionY={datetimePickerPosition.BOTTOM}
            isEndDay={isEndDay}
            {...props}
          />
        ) : type === 'dateRange' ? (
          <DateRangePickerTextField
            id={id}
            type={type}
            maxDate={maxDate}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            autoComplete='off'
            aria-autocomplete='none'
            placeholder={placeholder}
            disabled={disabled}
            className={classNames(classNameInput)}
            positionX={datetimePickerPosition.LEFT}
            positionY={datetimePickerPosition.BOTTOM}
            {...props}
          />
        ) : type === 'month' ? (
          <MonthPickerTextField
            id={id}
            value={value}
            maxDate={maxDate}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            autoComplete='off'
            aria-autocomplete='none'
            placeholder={placeholder}
            disabled={disabled}
            className={classNames(classNameInput)}
            positionX={datetimePickerPosition.LEFT}
            positionY={datetimePickerPosition.BOTTOM}
            {...props}
          />
        ) : type === 'time' ? (
          <TimeTextField
            id={id}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            autoComplete='off'
            aria-autocomplete='none'
            placeholder={placeholder}
            disabled={disabled}
            className={classNames(classNameInput)}
            {...props}
          />
        ) : type === 'timePicker' ? (
          <TimePickerTextField
            id={id}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            autoComplete='off'
            aria-autocomplete='none'
            placeholder={placeholder}
            disabled={disabled}
            className={classNames(classNameInput)}
            positionX={positionXDatePicker || datetimePickerPosition.LEFT}
            positionY={datetimePickerPosition.BOTTOM}
            {...props}
          />
        ) : type === 'textarea' ? (
          <textarea
            id={id}
            name={name || id}
            placeholder={placeholder}
            className={classNames('pt-0 px-[11px]', 'border-0', classNameInput)}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            disabled={disabled}
            autoComplete={autoComplete || 'off'}
            aria-autocomplete='none'
            {...props}
          />
        ) : !mask ? (
          <input
            id={id}
            type={type}
            name={name || id}
            placeholder={placeholder}
            className={classNames(classNameInput)}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            disabled={isPreloadDisabled || disabled}
            autoComplete={autoComplete || 'off'}
            aria-autocomplete='none'
            {...props}
          />
        ) : (
          <IMaskInput
            mask={mask}
            onClick={onClickInput}
            id={id}
            type={type}
            name={name || id}
            placeholder={placeholder}
            className={classNames(classNameInput)}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            disabled={isPreloadDisabled || disabled}
            autoComplete='off'
            aria-autocomplete='none'
            {...props}
          />
        )}
      </div>
      {isError && (
        <div
          className={classNames('flex items-center', 'w-full', 'absolute z-20', {
            'top-0 right-0 mt-2 mr-3':
              labelErrorPosition === labelErrorPositionEnum.INSIDE_TOP_RIGHT,
            '-bottom-5 left-0': labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_LEFT,
            '-bottom-5 right-0': labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT,
            '-top-5 left-0': labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_LEFT,
            '-top-5 right-0': labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_RIGHT,
          })}
        >
          <div className={classNames('relative', 'w-full', 'flex')}>
            <p
              ref={labelErrorRef}
              className={classNames(
                'grow',
                'w-full',
                'text-xs text-red-500 font-medium',
                'whitespace-nowrap overflow-hidden text-ellipsis',
                {
                  'text-right':
                    labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT ||
                    labelErrorPosition === labelErrorPositionEnum.INSIDE_TOP_RIGHT ||
                    labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_RIGHT,
                  'text-left': labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_LEFT,
                }
              )}
            >
              {lableError}
            </p>
            {isLabelErrorTruncated && (
              <Tooltip
                icon={<ExclamationCircleIcon className='w-4  fill-red-500' />}
                text={lableError}
              />
            )}
          </div>
        </div>
      )}
      {rightComponent}
    </div>
  );
};
TextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onValidate: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  classNameInput: PropTypes.string,
  classNameInputLabel: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isError: PropTypes.bool,
  isEndDay: PropTypes.bool,
  rightComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.bool]),
  leftComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.bool]),
  labelErrorPosition: PropTypes.oneOf([...Object.values(labelErrorPositionEnum)]),
  positionXDatePicker: PropTypes.string,
};

export default TextField;
