/** @format */

import React from 'react';

import { classNames } from '../../helpers';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer
      className={classNames(
        'w-full text-sm text-center text-gray-400 py-4',
        'md:flex md:items-center md:justify-center'
      )}
    >
      <p>&copy;{year} Flowbirdhub. All rights reserved.</p>
      <span className='inline-block w-1 h-1 mx-2 bg-gray-300 rounded-full' />
      <p className={'text-indigo-600 text-sm font-medium ml-1 hover:text-indigo-700'}>
        Terms of use
      </p>
      <span className='inline-block w-1 h-1 mx-2 bg-gray-300 rounded-full' />
      <p className={'text-indigo-600 text-sm font-medium ml-1 hover:text-indigo-700'}>
        Privacy policy
      </p>
    </footer>
  );
};

export default Footer;
