/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { USERS as NAV_USERS } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const UsersSettings = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Users/Settings'))),
  t => [{ name: t('Users'), url: '/dashboard/users' }, { name: t('Users settings') }]
);
const UsersInventory = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Users/Inventory'))),
  t => [{ name: t('Users'), url: '/dashboard/users' }, { name: t('Inventory') }]
);
const UsersInventoryEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Users/Inventory/EditUser'))),
  t => [{ name: t('Users'), url: '/dashboard/users' }, { name: t('Inventory') }]
);
const UsersProfiles = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Users/Profiles'))),
  t => [{ name: t('Users'), url: '/dashboard/users' }, { name: t('Profiles') }]
);
const UsersProfileEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('../pages/dashboard/Users/Profiles/EditProfile'))),
  t => [
    { name: t('Users'), url: '/dashboard/users' },
    { name: t('Profiles'), url: '/dashboard/users/profiles' },
  ]
);
const RolesPermissions = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Common/RolesPermissions'))),
  t => [{ name: t('Users'), url: '/dashboard/users' }, { name: t('Roles & Permissions') }]
);
const RolesPermissionsEdit = withHeaderBreadcrumb(
  withLazyComponent(
    lazy(() => import('pages/dashboard/Common/RolesPermissions/RolesPermissionsEdit'))
  ),
  t => [
    { name: t('Users'), url: '/dashboard/users' },
    { name: t('Roles & Permissions'), url: '/dashboard/users/permissions' },
  ]
);

const UsersRoutes = (
  <Route path='users' element={<DashboardLayout nav={NAV_USERS} />}>
    <Route index element={<Navigate to='/dashboard/users/inventory' replace />} />
    <Route path='settings' element={<UsersSettings title='Users Settings' />} />
    <Route path='inventory' element={<UsersInventory title='Users Inventory' />} />
    <Route path='inventory/:uuid' element={<UsersInventoryEdit title='Users Inventory Edit' />} />
    <Route path='profiles' element={<UsersProfiles title='Users Profiles' />} />
    <Route path='profiles/:id' element={<UsersProfileEdit title='Users Profile Edit' />} />
    <Route path='permissions' element={<RolesPermissions title='Roles & Permissions page' />} />
    <Route
      path='permissions/:roleId'
      element={<RolesPermissionsEdit title='Roles & Permissions Edit page' />}
    />
    <Route path='*' element={<Navigate to='/dashboard/users/inventory' replace />} />
  </Route>
);

export default UsersRoutes;
