/**@format */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { XIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import TextFieldPassword from 'components/TextFieldPassword';
import Modal from 'components/Modal';
import Button from 'components/Button';

import { useToast } from 'hooks/useToast';
import { classNames, responseError } from 'helpers';
import { closeConfirm } from 'store/dashboard/globalPassLockSlice';
import { useConfirmPasswordMutation } from 'services/bo/users/users.me.mfa';

export const PassLockModal = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isOpen, title, text } = useSelector(state => state.dashboard.globalPassLock);

  const [confirmPassword] = useConfirmPasswordMutation();

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const { password } = values;
    dispatch(showLoading());
    try {
      const body = {
        password,
      };
      await confirmPassword(body).unwrap();
      toast.success({
        title: [t('Success')],
        message: ['Feature has been unlocked. Please try again.'],
      });
      handleSetDefault();
    } catch (error) {
      responseError(error, toast, t, navigate, location);
    }
    dispatch(hideLoading());
    setSubmitting(false);
  };

  const handleSetDefault = () => {
    formik.resetForm();
    dispatch(closeConfirm());
  };

  const passwordConfirmationSchema = Yup.object().shape({
    password: Yup.string().label(t('Password')).required(),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: passwordConfirmationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal className='min-w-[460px]' isShow={isOpen} onCloseWhenEsc={handleSetDefault}>
      <div className={classNames('flex flex-col', 'w-full h-fit')}>
        <div className={classNames('flex', 'p-0')}>
          <div className={classNames('flex flex-col grow', 'gap-1')}>
            <h1 className={classNames('font-medium text-base text-gray-900')}>
              {title || t('Confirm password')}
            </h1>
            <div className={classNames('flex', 'font-normal text-sm text-gray-500', 'space-x-2')}>
              <span className={classNames('flex', 'gap-1')}>
                {text || t('Fill in the fields below.')}
              </span>
            </div>
          </div>
          <Button
            color='transparent'
            className={classNames(
              'h-fit p-0',
              'focus:rounded-md',
              'hover:bg-transparent',
              'focus:ring-offset-0'
            )}
            onClick={handleSetDefault}
          >
            <XIcon className={classNames('w-6 h-6', 'text-gray-400')} />
          </Button>
        </div>
        <form autoComplete='off' onSubmit={formik.handleSubmit}>
          <div className={classNames('flex flex-col grow', 'w-full', 'py-7', 'gap-7')}>
            <div className={classNames('flex flex-col', 'gap-2')}>
              <TextFieldPassword
                id={'password'}
                label={t('Password')}
                placeholder={t('Enter password')}
                {...formik.getFieldProps('password')}
              />
            </div>
          </div>
          <Modal.Footer>
            <Button
              title={t('Confirm password')}
              type='submit'
              disabled={!formik.dirty || formik.isSubmitting || !formik.isValid}
            />
          </Modal.Footer>
        </form>
      </div>
    </Modal>
  );
};
