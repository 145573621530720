/**@format */

import config from 'config';

export const COUNTRY_CODE_OPTIONS = config.countryCode.map(item => {
  const {
    countrycode: countryCode,
    display_label: displayLabel,
    phone_number_format: phoneNumberFormat,
    phone_number_length: phoneNumberLength,
  } = item;

  return {
    id: countryCode,
    name: displayLabel,
    value: countryCode,
    children: (
      <div className='flex items-center justify-between'>
        <span>+{countryCode}</span>
        <span>{displayLabel}</span>
      </div>
    ),
    format: phoneNumberFormat,
    phone_number_length: phoneNumberLength,
  };
});

export const DEFAULT_PHONE_COUNTRY_CODE = 1;
