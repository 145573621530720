/**@format */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/solid';
import { XCircleIcon } from '@heroicons/react/outline';

import { labelErrorPositionEnum, selectOptionsPositionEnum } from 'constants/enum';
import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_GREEN,
  COLOR_RED,
  COLOR_TRANSPARENT,
  COLOR_YELLOW,
  COLOR_LIGHT_YELLOW,
} from 'constants/Constants';
import { classNames } from 'helpers';

import { EllipseMarker } from 'components/EllipseMarker';
import Button from 'components/Button';
import { Tooltip } from 'components/Tooltip';

export const SingleSelect = ({
  id,
  classNameWraper,
  className,
  classNameField,
  classNameLabel,
  classNameOptions,
  classNameSelectedIcon,
  classNameBtnItemOption,
  components = {},
  defaultValue = null,
  disabled,
  isDisabledOpen = false,
  dropdownDirection = selectOptionsPositionEnum.BOTTOM,
  isClearable = false,
  isShowLabel = true,
  isColorStatusOption = false,
  isError,
  isShowSelectedIcon = true,
  label,
  labelErrorPosition = labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT,
  lableError,
  leftComponent,
  onBlur,
  onChange,
  onDropdownToggle,
  options = [],
  placeholder,
  required,
  rightComponent,
  ...props
}) => {
  const labelErrorRef = useRef(null);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(defaultValue);
  const [isLabelErrorTruncated, setIsLabelErrorTruncated] = useState(false);

  const handleListBoxChange = item => {
    setSelectedDropdownItem(item);
    onChange && onChange(item, id);
  };

  useEffect(() => {
    if (defaultValue) setSelectedDropdownItem(defaultValue);
    else setSelectedDropdownItem(null);
  }, [defaultValue]);

  useEffect(() => {
    if (isError)
      setIsLabelErrorTruncated(
        labelErrorRef.current?.offsetWidth < labelErrorRef.current?.scrollWidth
      );
  }, [isError]);

  const handleClearValue = e => {
    e.stopPropagation();
    setSelectedDropdownItem(null);
    onChange && onChange(null, id);
  };

  return (
    <Listbox value={selectedDropdownItem} onChange={handleListBoxChange} disabled={disabled}>
      {({ open }) => (
        <div className={classNames('relative w-full', classNameWraper)}>
          <Listbox.Button
            className={classNames(
              {
                'w-full': !className?.includes('w-'),
                'h-full': !className?.includes('h-'),
              },
              'border rounded-md',
              {
                'border-gray-300': !isError,
                'border-red-300': isError,
              },
              {
                'pt-6': !className?.includes('pt-') && !className?.includes('py-'),
                'pb-2': !className?.includes('pb-') && !className?.includes('py-'),
                'px-3': !className?.includes('px-'),
              },
              'flex',
              {
                'focus:ring-1': !className?.includes('focus:ring-'),
              },
              'ring-inset ring-blue-600 focus:border-blue-600 focus-visible:outline-none',
              'disabled:bg-gray-50',
              className
            )}
            onClick={() => {
              onDropdownToggle && onDropdownToggle(!open);
            }}
            onBlur={e => {
              !open && onBlur && onBlur({ ...e, target: { ...e.target, id } });
            }}
            {...props}
          >
            {leftComponent}
            {isShowLabel && (
              <Listbox.Label
                className={classNames(
                  'absolute top-2 left-3',
                  'text-xs font-medium text-gray-900',
                  { 'pl-[19px]': isColorStatusOption },
                  classNameLabel
                )}
              >
                {label}
                {required && <span className='text-red-500'>*</span>}
              </Listbox.Label>
            )}

            {rightComponent}
            <div
              className={classNames('flex', 'min-w-0', {
                'pl-2.5': isColorStatusOption,
                'w-[96%] justify-between relative': selectedDropdownItem?.customIcon,
              })}
            >
              {selectedDropdownItem?.customIcon && (
                <div className={classNames(classNameSelectedIcon)}>
                  {selectedDropdownItem?.customIcon}
                </div>
              )}
              {!selectedDropdownItem || selectedDropdownItem?.name?.length === 0 ? (
                <span
                  className={classNames(
                    'text-gray-400 text-sm text-start line-clamp-1',
                    classNameField
                  )}
                >
                  {placeholder}
                </span>
              ) : (
                <span
                  className={classNames(
                    'truncate text-sm leading-5 font-normal',
                    {
                      'text-transparent': selectedDropdownItem?.name?.length === 0,
                    },
                    classNameField
                  )}
                >
                  {selectedDropdownItem?.name}
                </span>
              )}
            </div>

            {!disabled && !isDisabledOpen ? (
              open ? (
                <ChevronUpIcon
                  className={classNames(
                    'w-5 h-5',
                    'absolute',
                    'inset-y-0',
                    'my-auto',
                    'right-3',
                    'fill-gray-400'
                  )}
                />
              ) : (
                <ChevronDownIcon
                  className={classNames(
                    'w-5 h-5',
                    'absolute',
                    'inset-y-0',
                    'my-auto',
                    'right-3',
                    'fill-gray-400'
                  )}
                />
              )
            ) : null}

            {isError && (
              <div
                className={classNames('flex items-center', 'w-full', 'absolute z-20', {
                  'top-0 right-0 mt-2 mr-3':
                    labelErrorPosition === labelErrorPositionEnum.INSIDE_TOP_RIGHT,
                  '-bottom-5 left-0 text-left':
                    labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_LEFT,
                  '-bottom-5 right-0':
                    labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT,
                  '-top-5 left-0': labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_LEFT,
                  '-top-5 right-0': labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_RIGHT,
                })}
              >
                <div className={classNames('relative', 'w-full', 'flex')}>
                  <p
                    ref={labelErrorRef}
                    className={classNames(
                      'grow',
                      'w-full',
                      'text-xs text-red-500 font-medium',
                      'whitespace-nowrap overflow-hidden text-ellipsis',
                      {
                        'text-right':
                          labelErrorPosition === labelErrorPositionEnum.INSIDE_TOP_RIGHT ||
                          labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT ||
                          labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_RIGHT,
                      }
                    )}
                  >
                    {lableError}
                  </p>
                  {isLabelErrorTruncated && (
                    <Tooltip
                      icon={<ExclamationCircleIcon className='w-4  fill-red-500' />}
                      text={lableError}
                    />
                  )}
                </div>
              </div>
            )}
          </Listbox.Button>
          {isClearable && selectedDropdownItem && (
            <Button
              className={classNames(
                'absolute top-[50%] mt-[-10px]',
                'p-0',
                'h-5 w-5',
                'rounded-full',
                {
                  'right-9': !isDisabledOpen,
                  'right-3': isDisabledOpen,
                }
              )}
              color={COLOR_TRANSPARENT}
              onClick={handleClearValue}
            >
              <XCircleIcon className={classNames('w-5 h-5', 'text-gray-400')} />
            </Button>
          )}
          {!isDisabledOpen && (
            <Transition
              as={React.Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                className={classNames(
                  'absolute left-0',
                  'w-full',
                  'py-[5px]',
                  'bg-white',
                  'border border-gray-300 rounded-md',
                  'shadow',
                  'max-h-96 overflow-y-auto',
                  'focus:outline-none',
                  'z-50',
                  {
                    'top-full mt-1.5': dropdownDirection === selectOptionsPositionEnum.BOTTOM,
                    'bottom-full mb-1.5': dropdownDirection === selectOptionsPositionEnum.TOP,
                  },
                  classNameOptions
                )}
              >
                {options?.length > 0 ? (
                  options.map((item, index) => (
                    <Listbox.Option
                      className='focus:outline-none'
                      key={`single_select${id}_${item.id || index}`}
                      value={item}
                    >
                      {({ selected, active }) => {
                        return components?.Option ? (
                          components?.Option({ selected, active, item })
                        ) : (
                          <button
                            type='button'
                            className={classNames(
                              'flex items-center text-sm leading-5 font-normal',
                              { 'justify-between': !isColorStatusOption },
                              { 'justify-start space-x-2': isColorStatusOption },
                              'text-left',
                              'w-full',
                              'px-3 py-2',
                              {
                                'bg-gray-50': active || selected,
                              },
                              classNameBtnItemOption
                            )}
                          >
                            {isColorStatusOption ? (
                              <EllipseMarker data-testid='ellipse-icon' color={item?.color} />
                            ) : (
                              ''
                            )}
                            {item.children ?? (
                              <span
                                className={classNames({
                                  'text-transparent': item.name.length === 0,
                                  'bg-green-50 text-green-600 rounded-md py-0.5 px-2 leading-4':
                                    item?.color === COLOR_GREEN && !isColorStatusOption,
                                  'bg-yellow-50 text-yellow-600 rounded-md py-0.5 px-2 leading-4':
                                    item?.color === COLOR_YELLOW && !isColorStatusOption,
                                  'bg-yellow-50 text-yellow-500 rounded-md py-0.5 px-2 leading-4':
                                    item?.color === COLOR_LIGHT_YELLOW && !isColorStatusOption,
                                  'bg-red-50 text-red-600 rounded-md py-0.5 px-2 leading-4':
                                    item?.color === COLOR_RED && !isColorStatusOption,
                                  'bg-blue-50 text-blue-600 rounded-md py-0.5 px-2 leading-4':
                                    item?.color === COLOR_BLUE && !isColorStatusOption,
                                  'bg-gray-50 text-gray-600 rounded-md py-0.5 px-2 leading-4':
                                    item?.color === COLOR_GRAY && !isColorStatusOption,
                                })}
                              >
                                {item.name.length > 0 ? item.name : 'null'}
                              </span>
                            )}
                            {isShowSelectedIcon && selected && item.name.length > 0 && (
                              <CheckIcon
                                data-testid='selected-icon'
                                className={classNames('h-5 w-5', 'fill-indigo-600')}
                              />
                            )}
                          </button>
                        );
                      }}
                    </Listbox.Option>
                  ))
                ) : (
                  <div className={classNames('w-full text-center text-xs text-gray-500')}>
                    The list is empty
                  </div>
                )}
              </Listbox.Options>
            </Transition>
          )}
        </div>
      )}
    </Listbox>
  );
};

SingleSelect.propTypes = {
  id: PropTypes.string,
  classNameWraper: PropTypes.string,
  className: PropTypes.string,
  classNameLabel: PropTypes.string,
  classNameField: PropTypes.string,
  classNameOptions: PropTypes.string,
  classNameSelectedIcon: PropTypes.string,
  classNameBtnItemOption: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  isError: PropTypes.bool,
  lableError: PropTypes.string,
  dropdownDirection: PropTypes.oneOf([...Object.values(selectOptionsPositionEnum)]),
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  isDisabledOpen: PropTypes.bool,
  components: PropTypes.shape({
    Option: PropTypes.func,
  }),
  labelErrorPosition: PropTypes.oneOf([...Object.values(labelErrorPositionEnum)]),
  onBlur: PropTypes.func,
  onDropdownToggle: PropTypes.func,
  isShowSelectedIcon: PropTypes.bool,
  isColorStatusOption: PropTypes.bool,
  isClearable: PropTypes.bool,
  isShowLabel: PropTypes.bool,
  leftComponent: PropTypes.element,
  rightComponent: PropTypes.element,
};
