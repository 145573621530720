/** @format */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useToast } from 'hooks';
import { clearSubUserData, selectSubUser } from 'store/subUser/subUserSlice';
import { classNames } from 'helpers';

const TopBanner = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const subUser = useSelector(selectSubUser);
  const toast = useToast();

  const handleSubUserLogOut = () => {
    toast.info({ title: t('Info'), message: t('You are logged out') });
    dispatch(clearSubUserData());
  };
  if (!subUser) return null;

  return (
    <>
      <div
        className={classNames(
          'flex justify-end items-center',
          'text-sm px-4 sm:px-6 py-2 space-x-1',
          'text-gray-500 bg-indigo-100 ',
          className
        )}
      >
        <p>{t('You are logged as {{label}}', { label: subUser?.name })}</p>
        <button type='button' className='text-indigo-600' onClick={handleSubUserLogOut}>
          {t('Log out')}
        </button>
      </div>
    </>
  );
};

export default TopBanner;
