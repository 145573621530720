/**@format */

import { configureStore } from '@reduxjs/toolkit';
import { loadingBarReducer } from 'react-redux-loading-bar';

import { api } from 'services/bo/api';

import { dashboardReducer } from './dashboard';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import subUserSlice from './subUser/subUserSlice';
import clientSlice from './client/clientSlice';
import cartSlice from './cart/cartSlice';
import translationsSlice from './static/translationsSlice';
import { staticReducer } from './static';

const setupStore = preloadedState => {
  return configureStore({
    preloadedState,
    reducer: {
      [api.reducerPath]: api.reducer,
      loadingBar: loadingBarReducer,
      auth: authReducer,
      user: userReducer,
      dashboard: dashboardReducer,
      subUser: subUserSlice,
      client: clientSlice,
      cart: cartSlice,
      static: staticReducer,
      translations: translationsSlice,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(api.middleware),
  });
};

export default setupStore;
