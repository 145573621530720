/** @format */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { EyeIcon, EyeOffIcon, CheckIcon } from '@heroicons/react/solid';

import TextField from 'components/TextField/TextField';
import { classNames } from 'helpers';

import { useTranslation } from 'react-i18next';

const TextFieldPassword = ({
  isDefaultShowPass = false,
  onIsShowPass = () => {},
  isShowConfirmIcon = false,
  ...props
}) => {
  const { t } = useTranslation();
  const [isPasswordRevealed, setIsPasswordRevealed] = useState(isDefaultShowPass);

  const handleToggleRevealPassword = () => {
    setIsPasswordRevealed(!isPasswordRevealed);
    onIsShowPass(!isPasswordRevealed);
  };
  const getPasswordAriaLabel = () => {
    if (isPasswordRevealed) {
      return t('Hide password');
    } else {
      return t('Show password');
    }
  };

  return (
    <TextField
      id={props.id}
      type={isPasswordRevealed ? 'text' : 'password'}
      classNameInput='pr-12'
      className={props?.className}
      rightComponent={
        <button
          type='button'
          aria-label={getPasswordAriaLabel()}
          onClick={handleToggleRevealPassword}
          className={classNames(
            'absolute right-0 top-0 h-full',
            'focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500 z-10',
            'flex items-center px-3 text-gray-400 rounded-md'
          )}
        >
          {!isShowConfirmIcon ? (
            <>
              {isPasswordRevealed ? (
                <EyeIcon className='w-5' aria-hidden='true' />
              ) : (
                <EyeOffIcon className='w-5' aria-hidden='true' />
              )}
            </>
          ) : (
            <CheckIcon className='w-5 text-green-500' aria-hidden='true' />
          )}
        </button>
      }
      {...props}
    />
  );
};

TextFieldPassword.propTypes = {
  isShowPass: PropTypes.oneOfType([PropTypes.bool]),
  onIsShowPass: PropTypes.func,
  isShowConfirmIcon: PropTypes.bool,
};

export default TextFieldPassword;
