/**@format */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ArrowRightIcon, CalendarIcon } from '@heroicons/react/solid';
import moment from 'moment-timezone';

import { classNames } from 'helpers';

import {
  dateRangePickerIdsEnum,
  datetimePickerModeEnum,
  datetimePickerPosition,
} from '../constants/enum';
import { PikadayDropdown } from './PickerComponents/PikadayDropdown';

export const DateRangePickerTextField = ({
  id,
  className,
  classNameCalendarIcon,
  placeholder,
  required,
  positionX,
  positionY,
  value = [],
  onStartRangeChange,
  onEndRangeChange,
  isDropdownOpen,
  onToggleDropDown,
  type,
  //#region unused props - remove from props
  onChange,
  minDate,
  maxDate,
  //#endregion
  ...props
}) => {
  const textFieldRef = useRef(null);
  const startRangeRef = useRef(null);
  const endRangeRef = useRef(null);
  const [currentMode, setCurrentMode] = useState(null);
  const [currentInputId, setCurrentInputId] = useState(null);
  const [calendarButtonCooldown, setCalendarButtonCooldown] = useState(null);
  const textFieldHeight = (textFieldRef?.current?.offsetHeight ?? 0) + 'px';
  const [startRangeValue, endRangeValue] = value;
  const dateRangeValueFormated =
    startRangeValue &&
    endRangeValue &&
    `${moment(+startRangeValue).format('MMM DD')} - ${moment(+endRangeValue).format('MMM DD')}`;
  const startRangeMaxDate =
    currentInputId === dateRangePickerIdsEnum.START_RANGE
      ? endRangeValue
        ? moment(+endRangeValue)
            .valueOf()
            .toString()
        : undefined
      : undefined;
  const endRangeMinDate =
    currentInputId === dateRangePickerIdsEnum.END_RANGE
      ? startRangeValue
        ? moment(+startRangeValue)
            .valueOf()
            .toString()
        : undefined
      : undefined;

  const handleSwitchMode = useCallback(mode => {
    if (type === 'dateRange') return;
    setCurrentMode(mode);
  }, []);
  const handleSetDefault = () => {
    setCurrentMode(null);
    setCurrentInputId(null);
  };
  const handleOpenDateRangePickDropdown = e => {
    if (!currentMode) {
      onToggleDropDown && onToggleDropDown(true);
    }
  };
  const handleFocusInputRange = e => {
    setCurrentInputId(e.target.id);
  };
  const handlePikadayChange = e => {
    if (e.target.id === dateRangePickerIdsEnum.START_RANGE) {
      onStartRangeChange && onStartRangeChange(e);
      setCurrentInputId(dateRangePickerIdsEnum.END_RANGE);
    } else if (e.target.id === dateRangePickerIdsEnum.END_RANGE) {
      onEndRangeChange && onEndRangeChange(e);
      onToggleDropDown && onToggleDropDown(false);
    }
  };
  const handleDateRangePickerBlur = e => {
    if (currentMode) {
      const wrapperEl = document.getElementById(`${id}-dropdown-wraper`);
      if (!wrapperEl?.contains(e.relatedTarget)) {
        handleSetDefault();
        /**Set calendar button disabled for a while
         * to prevent handleOpenDateRangePickDropdown() fired
         * if mouse pointed in this button when blur */
        setCalendarButtonCooldown(300);
        onToggleDropDown && onToggleDropDown(false);
      }
    }
  };

  useEffect(() => {
    if (typeof isDropdownOpen === 'boolean') {
      /**
       * @headless-ui/Listbox restores focus after closing Listbox options, to the Listbox.Button
       * By slightly delaying change currentMode, DateRangePicker dropdown's focus management won't be overruled.
       */
      setTimeout(() => {
        if (isDropdownOpen) {
          setCurrentMode(datetimePickerModeEnum.DATE_PICKER);
          setCurrentInputId(dateRangePickerIdsEnum.START_RANGE);
        } else handleSetDefault();
      }, 50);
    }
  }, [isDropdownOpen]);
  useEffect(() => {
    if (typeof calendarButtonCooldown === 'number') {
      setTimeout(() => {
        setCalendarButtonCooldown(null);
      }, calendarButtonCooldown);
    }
  }, [calendarButtonCooldown]);

  return (
    <>
      <input
        id={id}
        type='text'
        ref={textFieldRef}
        className={classNames(className)}
        placeholder={placeholder}
        required={required}
        readOnly
        value={dateRangeValueFormated}
        {...props}
      />
      <div
        className={classNames('w-full', 'relative', {
          'invisible': !currentMode,
        })}
      >
        <div
          className={classNames(
            'flex flex-col',
            'w-[336px] h-fit',
            'z-10',
            'absolute',
            {
              'mb-2': positionY === datetimePickerPosition.TOP,
              'top-0 mt-2': positionY === datetimePickerPosition.BOTTOM,
              'left-0': positionX === datetimePickerPosition.LEFT,
              'right-0': positionX === datetimePickerPosition.RIGHT,
            },
            'border border-gray-300 border-solid rounded-lg',
            'bg-white'
          )}
          style={{ bottom: positionY === datetimePickerPosition.TOP ? textFieldHeight : '' }}
          id={`${id}-dropdown-wraper`}
        >
          <div className={classNames('flex', 'gap-2', 'py-4 px-5')}>
            <input
              id={dateRangePickerIdsEnum.START_RANGE}
              ref={startRangeRef}
              value={startRangeValue ? moment(+startRangeValue).format('MM / DD / YY') : ''}
              readOnly
              onFocus={handleFocusInputRange}
              className={classNames(
                'border border-gray-300',
                'rounded-md',
                'w-1/2 h-8',
                'px-2.5 py-1',
                'shadown-sm',
                'focus-visible:border-2 focus-visible:border-indigo-600 focus-visible:outline-none focus-visible:ring-0'
              )}
              onBlur={handleDateRangePickerBlur}
            />
            <ArrowRightIcon className='w-4 fill-gray-400' />
            <input
              id={dateRangePickerIdsEnum.END_RANGE}
              ref={endRangeRef}
              value={endRangeValue ? moment(+endRangeValue).format('MM / DD / YY') : ''}
              readOnly
              onFocus={handleFocusInputRange}
              className={classNames(
                'border border-gray-300',
                'rounded-md',
                'w-1/2 h-8',
                'px-2.5 py-1',
                'shadown-sm',
                'focus-visible:border-2 focus-visible:border-indigo-600 focus-visible:outline-none focus-visible:ring-0'
              )}
              onBlur={handleDateRangePickerBlur}
            />
          </div>
          {currentInputId === dateRangePickerIdsEnum.START_RANGE && (
            <PikadayDropdown
              id={currentInputId}
              ref={startRangeRef}
              currentMode={currentMode}
              onChangeCurrentMode={handleSwitchMode}
              onChange={handlePikadayChange}
              value={startRangeValue}
              maxDate={startRangeMaxDate}
              dateRange={[startRangeValue, endRangeValue]}
              type={type}
            />
          )}
          {currentInputId === dateRangePickerIdsEnum.END_RANGE && (
            <PikadayDropdown
              id={currentInputId}
              ref={endRangeRef}
              currentMode={currentMode}
              onChangeCurrentMode={handleSwitchMode}
              onChange={handlePikadayChange}
              value={endRangeValue}
              minDate={endRangeMinDate}
              dateRange={[startRangeValue, endRangeValue]}
              type={type}
            />
          )}
        </div>
      </div>
      <button
        type='button'
        className={classNames(
          'absolute',
          'inset-y-0 right-3',
          'my-auto',
          'h-fit',
          'z-20',
          classNameCalendarIcon
        )}
        disabled={!!calendarButtonCooldown}
        onClick={handleOpenDateRangePickDropdown}
      >
        <CalendarIcon className={classNames('fill-gray-400', 'w-5 h-5', 'hover:fill-indigo-600')} />
      </button>
    </>
  );
};
