/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { TICKETING as NAV_TICKETING } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const TicketingSettings = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Ticketing/Settings'))),
  t => [{ name: t('Ticketing'), url: '/dashboard/ticketing' }, { name: t('Settings') }]
);
const TicketingNotifications = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Ticketing/Notifications'))),
  t => [{ name: t('Ticketing'), url: '/dashboard/ticketing' }, { name: t('Notifications') }]
);
const FareEdit = withHeaderBreadcrumb(
  withLazyComponent(
    lazy(() => import('pages/dashboard/Ticketing/Fares/FareEdit/TicketingRegularFareEdit'))
  ),
  t => [{ name: t('Ticketing'), url: '/dashboard/ticketing' }, { name: t('Fares') }]
);
const TicketingFares = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Ticketing/Fares/Fares'))),
  t => [{ name: t('Ticketing'), url: '/dashboard/ticketing' }, { name: t('Fares') }]
);
const TicketingProducts = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Ticketing/Products'))),
  t => [{ name: t('Ticketing'), url: '/dashboard/ticketing' }, { name: t('Products') }]
);
const TicketingProductEdit = withHeaderBreadcrumb(
  withLazyComponent(
    lazy(() => import('pages/dashboard/Ticketing/Products/ProductEdit/TicketingProductEdit'))
  ),
  t => [{ name: t('Ticketing'), url: '/dashboard/ticketing' }, { name: t('Products') }]
);
const TicketingConcessions = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Ticketing/Concessions/Concessions'))),
  t => [{ name: t('Ticketing'), url: '/dashboard/ticketing' }, { name: t('Concessions') }]
);
const TicketingConcessionsEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Ticketing/Concessions/ConcessionEdit'))),
  t => [{ name: t('Ticketing'), url: '/dashboard/ticketing' }, { name: t('Concessions') }]
);
const TicketingSchedules = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Ticketing/Schedules'))),
  t => [{ name: t('Ticketing'), url: '/dashboard/ticketing' }, { name: t('Schedules') }]
);
const TicketingLayouts = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Ticketing/Layouts/Layouts'))),
  t => [{ name: t('Ticketing'), url: '/dashboard/ticketing' }, { name: t('Layouts') }]
);
const TicketingSimulatorResults = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Ticketing/Simulator'))),
  t => [
    { name: t('Ticketing'), url: '/dashboard/ticketing' },
    { name: t('Simulator'), url: '/dashboard/ticketing/simulator/results' },
    { name: t('Results') },
  ]
);

const TicketingRoutes = (
  <Route path='ticketing' element={<DashboardLayout nav={NAV_TICKETING} />}>
    <Route index element={<Navigate to='/dashboard/ticketing/fares' replace />} />
    <Route path='settings' element={<TicketingSettings title='Ticketing Settings' />} />
    <Route
      path='notifications'
      element={<TicketingNotifications title='Ticketing Notifications' />}
    />
    <Route path='fares' element={<TicketingFares title='Ticketing Fares' />} />
    <Route path='fares/:id' element={<FareEdit title='Ticketing Fare Edit' />} />
    <Route path='products' element={<TicketingProducts title='Ticketing Products' />} />
    <Route path='products/:id' element={<TicketingProductEdit title='Ticketing Products Edit' />} />
    <Route path='concessions' element={<TicketingConcessions title='Ticketing Concessions' />} />
    <Route
      path='concessions/:id'
      element={<TicketingConcessionsEdit title='Ticketing Concessions Edit' />}
    />
    <Route path='schedules' element={<TicketingSchedules title='Ticketing Schedules' />} />
    <Route
      path='simulator/results'
      element={<TicketingSimulatorResults title='Ticketing Simulator' />}
    />
    <Route path='layouts' element={<TicketingLayouts title='Ticketing Layouts' />} />
    <Route path='*' element={<Navigate to='/dashboard/ticketing/fares' replace />} />
  </Route>
);

export default TicketingRoutes;
