/**@format */

import React from 'react';
import { useSelector } from 'react-redux';
import { XIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { classNames } from 'helpers';
import { useConfirm } from 'hooks';

import TextFieldPassword from 'components/TextFieldPassword';
import Modal from 'components/Modal';
import Button from 'components/Button';

export const ConfirmModal = () => {
  const { t } = useTranslation();
  const { onConfirm, onCancel } = useConfirm();
  const {
    type,
    isOpen,
    title,
    // titleIcon,
    text,
    modalClassName,
    primaryBtnText,
    secondaryBtnText,
    primaryBtnColor,
    secondaryBtnColor,
    primaryBtnClassName,
    secondaryBtnClassName,
    titleClassName,
  } = useSelector(state => state.dashboard.globalConfirm);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    handleSetDefault();
    setSubmitting(false);
    onConfirm(values);
  };
  const handleSetDefault = () => {
    formik.resetForm();
  };
  const passwordConfirmationSchema = Yup.object().shape({
    password: Yup.string().label(t('Password')).required(),
  });
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: passwordConfirmationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal
      className={classNames(
        {
          'max-w-[420px]': !modalClassName?.includes('max-w-'),
        },
        modalClassName
      )}
      isShow={isOpen ?? false}
      onCloseWhenEsc={onCancel}
    >
      <div className={classNames('flex flex-col', 'w-full h-fit', 'gap-7')}>
        <div className='flex'>
          <div className={classNames('flex flex-col grow', 'gap-[3px]')}>
            {typeof title === 'string' ? (
              <h1 className={classNames('font-medium text-lg text-gray-900', titleClassName)}>
                {title}
              </h1>
            ) : (
              title
            )}
            <div className='font-normal text-sm text-gray-500'>{text}</div>
          </div>

          <Button
            color='transparent'
            className={classNames(
              'h-fit p-0',
              'focus:rounded-md',
              'hover:bg-transparent',
              'focus:ring-offset-0'
            )}
            onClick={onCancel}
          >
            <XIcon className={classNames('w-6 h-6', 'text-gray-400')} />
          </Button>
        </div>

        {type === 'password' ? (
          <form autoComplete='off' onSubmit={formik.handleSubmit}>
            <div className='mb-7'>
              <TextFieldPassword
                id={'password'}
                label={t('Password')}
                placeholder={t('Enter password')}
                {...formik.getFieldProps('password')}
              />
            </div>
            <Modal.Footer>
              <Button
                title={t('Confirm password')}
                type='submit'
                disabled={!formik.dirty || formik.isSubmitting || !formik.isValid}
              />
            </Modal.Footer>
          </form>
        ) : (
          <Modal.Footer>
            <Button
              title={secondaryBtnText}
              className={secondaryBtnClassName}
              outline
              color={secondaryBtnColor}
              onClick={onCancel}
            />
            <Button
              title={primaryBtnText}
              color={primaryBtnColor}
              className={primaryBtnClassName}
              onClick={onConfirm}
            />
          </Modal.Footer>
        )}
      </div>
    </Modal>
  );
};
