/** @format */
import { createSlice } from '@reduxjs/toolkit';
import {
  TICKETING_FARES_APP_DATA,
  TICKETING_FARES_DATA,
  TICKETING_FARES_DEVICES_DATA,
  TICKETING_FARES_GENERAL_DATA,
  TICKETING_FARES_LOCATIONS_DATA,
  TICKETING_FARES_NOTIFICATIONS_DATA,
  TICKETING_FARES_PRODUCTS_DATA,
  TICKETING_FARES_TRANSLATIONS_DATA,
} from 'data/data';

const initialState = {
  fareData: TICKETING_FARES_DATA,
  generalTabData: TICKETING_FARES_GENERAL_DATA,
  translationsTabData: TICKETING_FARES_TRANSLATIONS_DATA,
  productsTabData: TICKETING_FARES_PRODUCTS_DATA,
  notificationsTabData: TICKETING_FARES_NOTIFICATIONS_DATA,
  locationsTabData: TICKETING_FARES_LOCATIONS_DATA,
  deviceTabData: TICKETING_FARES_DEVICES_DATA,
  appTabData: TICKETING_FARES_APP_DATA,
};
export const faresSlice = createSlice({
  name: 'ticketingFares',
  initialState,
  reducers: {
    actionFare: (state, action) => {
      const addFareData = {
        ...action.payload,
        id: TICKETING_FARES_DATA[TICKETING_FARES_DATA.length - 1].id + 1,
      };
      state.fareData = [...state.fareData, addFareData];
    },
    editTranslation: (state, action) => {
      state.translationsTabData = state.translationsTabData.map(item =>
        item.id === action.payload.id ? { ...action.payload } : item
      );
    },
    addProduct: (state, action) => {
      const addProductData = {
        ...action.payload,
        id: TICKETING_FARES_PRODUCTS_DATA[TICKETING_FARES_PRODUCTS_DATA.length - 1].id + 1,
      };
      state.productsTabData = [...state.productsTabData, addProductData];
    },
    editProduct: (state, action) => {
      state.productsTabData = state.productsTabData.map(item =>
        item.id === action.payload.id ? { ...action.payload } : item
      );
    },
    editNotification: (state, action) => {
      state.notificationsTabData = state.notificationsTabData.map(item =>
        item.id === action.payload.id ? { ...action.payload } : item
      );
    },
    addNotifications: (state, action) => {
      const addNotificationData = {
        ...action.payload,
        id: state.notificationsTabData[state.notificationsTabData.length - 1].id + 1,
      };
      state.notificationsTabData = [...state.notificationsTabData, addNotificationData];
    },
    deleteNotifications: (state, action) => {
      state.notificationsTabData = state.notificationsTabData.filter(
        item => item.id !== action.payload
      );
    },
    deleteApp: (state, action) => {
      state.appTabData = state.appTabData.filter(item => item.id !== action.payload);
    },
    deleteLocation: (state, action) => {
      state.locationsTabData = state.locationsTabData.filter(item => item.id !== action.payload);
    },
    deleteDevice: (state, action) => {
      state.deviceTabData = state.deviceTabData.filter(item => item.id !== action.payload);
    },
    addDevice: (state, action) => {
      const addDeviceData = {
        ...action.payload,
        id: state.deviceTabData[state.deviceTabData.length - 1].id + 1,
      };
      state.deviceTabData = [...state.deviceTabData, addDeviceData];
    },
    addApp: (state, action) => {
      const addAppData = {
        ...action.payload,
        id: state.appTabData[state.appTabData.length - 1].id + 1,
      };
      state.appTabData = [...state.appTabData, addAppData];
    },
    addLocation: (state, action) => {
      const addLocationData = {
        ...action.payload,
        id: state.locationsTabData[state.locationsTabData.length - 1].id + 1,
      };
      state.locationsTabData = [...state.locationsTabData, addLocationData];
    },
    changeGeneralData: (state, { payload }) => {
      state.generalTabData = { ...payload };
    },
    removeLocationsGroup: (state, { payload }) => {
      state.locationsTabData = state.locationsTabData.filter(item => !payload.includes(item.id));
    },
    removeDeviceGroup: (state, { payload }) => {
      state.deviceTabData = state.deviceTabData.filter(item => !payload.includes(item.id));
    },
    removeAppGroup: (state, { payload }) => {
      state.appTabData = state.appTabData.filter(item => !payload.includes(item.id));
    },
  },
});
export const {
  actionFare,
  changeGeneralData,
  editTranslation,
  editProduct,
  addProduct,
  deleteNotifications,
  addNotifications,
  editNotification,
  deleteLocation,
  addLocation,
  deleteDevice,
  addDevice,
  deleteApp,
  addApp,
  removeLocationsGroup,
  removeDeviceGroup,
  removeAppGroup,
} = faresSlice.actions;
export const selectFaresData = state => state.dashboard.ticketing.fares.fareData;
export const selectProductsData = state => state.dashboard.ticketing.fares.productsTabData;
export const selectGeneralData = state => state.dashboard.ticketing.fares.generalTabData;
export const selectTranslationsData = state => state.dashboard.ticketing.fares.translationsTabData;
export const selectLocationsData = state => state.dashboard.ticketing.fares.locationsTabData;
export const selectDeviceData = state => state.dashboard.ticketing.fares.deviceTabData;
export const selectAppData = state => state.dashboard.ticketing.fares.appTabData;
export const selectNotificationsData = state =>
  state.dashboard.ticketing.fares.notificationsTabData;
export default faresSlice.reducer;
