/**@format */
import PropTypes from 'prop-types';
import React from 'react';
import { MinusIcon } from '@heroicons/react/solid';

import { classNames } from '../../helpers';

export const Checkbox = ({ id, className, checked, isMinus, onChange, ...props }) => {
  return (
    <div className='relative flex h-fit w-fit'>
      <input
        type='checkbox'
        id={id}
        checked={checked}
        className={classNames(
          'border border-gray-300 rounded-[4px]',
          'checked:text-indigo-600',
          '[&:disabled]:bg-gray-100',
          {
            'bg-indigo-600 border-indigo-600': isMinus && !checked,
          },
          className
        )}
        onChange={onChange}
        {...props}
      />
      {isMinus && !checked && (
        <label htmlFor={id} className='absolute z-50 top-0 left-0' data-testid='checkbox-label'>
          <MinusIcon className='w-4 h-4 fill-white' />
        </label>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isMinus: PropTypes.bool,
  onChange: PropTypes.func,
};
