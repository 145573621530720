/** @format */

import axios from 'axios';

import { getToken } from '../helpers';
import { api } from 'services/bo/api';

export const inventoryService = {
  getInventoryData,
};

function getInventoryData(params) {
  const requestOptions = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken(),
    },
  };
  return axios.get(
    `/api/v1/passes/inventory/${params?.type.replaceAll(/\s/g, '').toLowerCase()}`,
    requestOptions
  );
}

//!TODO Fake data
export const passesSettingsApi = api.injectEndpoints({
  endpoints: build => ({
    getPassesSettings: build.query({
      query: () => `passes/settings`,
      providesTags: ['PassesSettings'],
    }),
    updatePassesSettings: build.mutation({
      query: ({ body }) => ({
        url: `passes/settings`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PassesSettings'],
    }),
  }),
});

export const { useGetPassesSettingsQuery, useUpdatePassesSettingsMutation } = passesSettingsApi;
