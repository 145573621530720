/**@format */
export const datetimePickerModeEnum = {
  DATE_PICKER: 'datePicker',
  TIME_PICKER: 'timePicker',
  MONTH_PICKER: 'monthPicker',
  YEAR_DECADE_PICKER: 'yearDecadePicker',
  YEAR_CENTURY_PICKER: 'yearCenturyPicker',
};

export const datetimePickerPosition = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
};

export const dateRangePickerIdsEnum = Object.freeze({
  START_RANGE: 'startRange',
  END_RANGE: 'endRange',
});

export const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
