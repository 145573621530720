/**@format */
import { createSlice } from '@reduxjs/toolkit';

import { CRM_PATRONS_PASS_INFORMATION_DATA } from 'data/data';

const initialState = {
  passDetailsItem: CRM_PATRONS_PASS_INFORMATION_DATA,
};

export const passesSlice = createSlice({
  name: 'passes',
  initialState,
  reducers: {},
});

export const selectPassDetailsItem = state => state.dashboard.crm.patrons.passes.passDetailsItem;
export default passesSlice.reducer;
