/**@format */
import React from 'react';
import { BellIcon, XIcon } from '@heroicons/react/outline';

import { classNames } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useComponentVisible } from 'hooks';

export const Notifications = ({ isShow, onClose, className }) => {
  const { t } = useTranslation();
  const ref = useComponentVisible(onClose);

  return isShow ? (
    <div
      ref={ref}
      className={classNames(
        'absolute z-30 px-2 w-screen max-w-[360px] md:max-w-sm md:px-0 md:mr-5',
        className
      )}
    >
      <div className='absolute -mt-2 right-[26px] sm:right-[calc(8%+5px)] md:right-1/2'>
        <div className='w-4 h-4 bg-white transform rotate-45 mb-4 shadow-lg rounded-sm ring-1 ring-black ring-opacity-5 overflow-hidden'></div>
      </div>
      <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
        <div className='relative bg-white'>
          <div className='flex justify-between items-center border-b border-color-gray-200 p-4'>
            <h2 className='text-gray-500 text-2xl font-bold'>{t('Notifications')}</h2>
            <XIcon onClick={onClose} className='w-5 text-gray-400 cursor-pointer' />
          </div>
          <div className='p-4 flex flex-col justify-center items-center'>
            <BellIcon className='w-8 text-gray-400 my-4' />
            <p className='text-gray-700 text-lg mb-2'>{t('Your notifications list is empty')}</p>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
