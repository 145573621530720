/**@format */
import React, { useEffect, useState } from 'react';
import { CalendarIcon } from '@heroicons/react/outline';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import moment from 'moment-timezone';

import { classNames } from 'helpers';

import { RectangleSwitch } from 'components/RectangleSwitch';
import TextField from 'components/TextField/TextField';

export const TimePicker = ({
  defaultValue,
  bottomButtonChildren,
  onBottomButtonClick,
  positionDropdownStyle = {
    defaultClassName: '',
    positionTopInlineStyle: {},
  },
}) => {
  const [timeState, setTimeState] = useState({
    hour: '12',
    min: '00',
    second: '00',
    period: 'AM',
  });

  const handleInputChange = e => {
    const elementId = e?.target?.id;
    const elementValue = e?.target?.value;
    const formatedValue = +elementValue < 10 ? `0${+elementValue}` : +elementValue;
    switch (elementId) {
      case 'time-picker-hour':
        const isValidHour = +elementValue >= 1 && +elementValue <= 12;
        isValidHour && setTimeState(prevState => ({ ...prevState, hour: formatedValue }));
        break;
      case 'time-picker-min':
        const isValidMin = +elementValue >= 0 && +elementValue <= 59;
        isValidMin && setTimeState(prevState => ({ ...prevState, min: formatedValue }));
        break;
      case 'time-picker-second':
        const isValidSec = +elementValue >= 0 && +elementValue <= 59;
        isValidSec && setTimeState(prevState => ({ ...prevState, second: formatedValue }));
        break;

      default:
        break;
    }
  };
  const handleSwitchChange = value => {
    setTimeState(prevState => ({ ...prevState, period: value ? 'PM' : 'AM' }));
  };
  const handleBottomButtonClick = () => {
    onBottomButtonClick && onBottomButtonClick(timeState);
  };
  const handleIncreaseHour = () => {
    setTimeState(prevState => ({
      ...prevState,
      hour:
        prevState.hour === '12'
          ? '01'
          : moment()
              .hour(+prevState.hour + 1)
              .format('hh'),
    }));
  };
  const handleDecreaseHour = () => {
    setTimeState(prevState => ({
      ...prevState,
      hour:
        +prevState.hour === 1
          ? '12'
          : moment()
              .hour(+prevState.hour - 1)
              .format('hh'),
    }));
  };
  const handleIncreaseMin = () => {
    setTimeState(prevState => ({
      ...prevState,
      min:
        +prevState.min === 59
          ? '00'
          : moment()
              .minute(+prevState.min + 1)
              .format('mm'),
    }));
  };
  const handleDecreaseMin = () => {
    setTimeState(prevState => ({
      ...prevState,
      min:
        +prevState.min === 0
          ? '59'
          : moment()
              .minute(+prevState.min - 1)
              .format('mm'),
    }));
  };
  const handleIncreaseSec = () => {
    setTimeState(prevState => ({
      ...prevState,
      second:
        +prevState.second === 59
          ? '00'
          : moment()
              .second(+prevState.second + 1)
              .format('ss'),
    }));
  };
  const handleDecreaseSec = () => {
    setTimeState(prevState => ({
      ...prevState,
      second:
        +prevState.second === 0
          ? '59'
          : moment()
              .second(+prevState.second - 1)
              .format('ss'),
    }));
  };

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      const hour = defaultValue.slice(0, 2);
      const min = defaultValue.slice(3, 5);
      const second = defaultValue.slice(6, 8);
      const period = defaultValue.slice(9, 11);
      setTimeState({ hour, min, second, period });
    }
  }, [defaultValue]);

  return (
    <div
      className={classNames(
        'flex flex-col',
        'w-fit',
        'z-[9999]',
        'absolute',
        'min-w-[320px]',
        'border border-gray-300 border-solid rounded-lg',
        positionDropdownStyle.defaultClassName
      )}
      style={positionDropdownStyle.positionTopInlineStyle}
    >
      <div className={classNames('flex items-center', 'p-5', 'gap-2', 'bg-white', 'rounded-t-lg')}>
        <div className={classNames('flex', 'gap-1')}>
          <TextField
            id='time-picker-hour'
            className={classNames(
              'w-[62px] h-fit',
              'gap-1',
              'px-2',
              'focus-within:border-1 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600'
            )}
            classNameInput={classNames(
              'py-[5px] px-0',
              'border-none',
              'rounded-lg',
              'focus:outline-none focus:border-none focus:shadow-none focus:ring-0'
            )}
            value={timeState.hour}
            onChange={handleInputChange}
            rightComponent={
              <div className={classNames('flex flex-col justify-center')}>
                <button type='button' onClick={handleIncreaseHour}>
                  <ChevronUpIcon className='w-3 h-3' />
                </button>
                <button type='button' onClick={handleDecreaseHour}>
                  <ChevronDownIcon className='w-3 h-3' />
                </button>
              </div>
            }
          />
          <span>:</span>
          <TextField
            id='time-picker-min'
            className={classNames(
              'w-[62px] h-fit',
              'gap-1',
              'px-2',
              'focus-within:border-1 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600'
            )}
            classNameInput={classNames(
              'py-[5px] px-0',
              'border-none',
              'rounded-lg',
              'focus:outline-none focus:border-none focus:shadow-none focus:ring-0'
            )}
            value={timeState.min}
            onChange={handleInputChange}
            rightComponent={
              <div className={classNames('flex flex-col justify-center')}>
                <button type='button' onClick={handleIncreaseMin}>
                  <ChevronUpIcon className='w-3 h-3' />
                </button>
                <button type='button' onClick={handleDecreaseMin}>
                  <ChevronDownIcon className='w-3 h-3' />
                </button>
              </div>
            }
          />
          <span>:</span>
          <TextField
            id='time-picker-second'
            className={classNames(
              'w-[62px] h-fit',
              'gap-1',
              'px-2',
              'focus-within:border-1 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600'
            )}
            classNameInput={classNames(
              'py-[5px] px-0',
              'border-none',
              'rounded-lg',
              'focus:outline-none focus:border-none focus:shadow-none focus:ring-0'
            )}
            value={timeState.second}
            onChange={handleInputChange}
            rightComponent={
              <div className={classNames('flex flex-col justify-center')}>
                <button type='button' onClick={handleIncreaseSec}>
                  <ChevronUpIcon className='w-3 h-3' />
                </button>
                <button type='button' onClick={handleDecreaseSec}>
                  <ChevronDownIcon className='w-3 h-3' />
                </button>
              </div>
            }
          />
        </div>
        <div className='flex'>
          <RectangleSwitch
            isChecked={timeState.period === 'PM'}
            onChange={handleSwitchChange}
            leftSwitchChild='AM'
            rightSwitchChild='PM'
            leftSwitchClassName={classNames({
              'text-gray-900': timeState.period === 'AM',
            })}
            rightSwitchClassName={classNames({
              'text-gray-900': timeState.period === 'PM',
            })}
          />
        </div>
      </div>
      <button
        type='button'
        className={classNames(
          'flex justify-center items-center',
          'py-4',
          'bg-gray-50',
          'gap-2',
          'text-base font-medium text-gray-600',
          'rounded-b-lg'
        )}
        onClick={handleBottomButtonClick}
      >
        {bottomButtonChildren ?? (
          <>
            <CalendarIcon className='w-5 h-5' />
            <span>Calendar</span>
          </>
        )}
      </button>
    </div>
  );
};
