/**@format */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  title: null,
  text: null,
};

export const globalPassLockSlice = createSlice({
  name: 'globalPassLock',
  initialState: initialState,
  reducers: {
    openConfirm: (state, { payload }) => {
      const { title, text } = payload;
      state.isOpen = true;
      state.title = title;
      state.text = text;
    },
    closeConfirm: state => {
      state.isOpen = false;
    },
    resetConfirm: () => initialState,
  },
});

export const { openConfirm, closeConfirm, resetConfirm } = globalPassLockSlice.actions;

export default globalPassLockSlice.reducer;
