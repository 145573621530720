/** @format */

import React from 'react';
import { ToastContainer } from 'react-toastify';
import CloseButton from './CloseButton';

const Toast = () => {
  return (
    <ToastContainer
      autoClose={5000}
      newestOnTop
      hideProgressBar={true}
      closeButton={CloseButton}
      icon={false}
      style={{ width: '424px' }}
    />
  );
};

export default Toast;
