/** @format */

import React, { Fragment, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Dialog, Transition } from '@headlessui/react';
import { classNames } from 'helpers';

const Modal = ({
  isShow,
  onClose,
  className = '',
  children,
  onCloseWhenEsc,
  onResetForm = () => {},
  delay = 200,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleKeyDown = useCallback(
    e => {
      if (e && e.code === 'Escape') onCloseWhenEsc && onCloseWhenEsc();
    },
    [onCloseWhenEsc]
  );

  useEffect(() => {
    /**
     * @CHEAT :V
     * @headless-ui/menu restores focus after closing a menu, to the button that opened it.
     * By slightly delaying opening the modal, react-modal's focus management won't be overruled.
     * {@link https://github.com/tailwindlabs/headlessui/issues/259}
     */
    setTimeout(() => setIsOpen(isShow), 50);
  }, [isShow]);

  useEffect(() => {
    if (isOpen && !onClose) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, isOpen, onClose]);

  useEffect(() => {
    if (isShow) {
      setTimeout(async () => onResetForm && onResetForm(), 50);
    }
    return () => {
      if (!isOpen) {
        setTimeout(async () => onResetForm && onResetForm(), delay);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, isOpen]);
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-50 overflow-y-auto' onClose={handleClose}>
        <div className='min-h-screen px-4 text-center'>
          {/* Overlay */}
          <div className='fixed inset-0 bg-black/20' />

          <span className='inline-block h-screen align-middle' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel
              data-testid='dialog-panel'
              className={classNames(
                'inline-block w-full text-left align-middle',
                'transition-all transform bg-white shadow-xl rounded-md',
                'px-6 py-5',
                {
                  'max-w-[420px]': !className?.includes('max-w-'),
                },
                className
              )}
            >
              <div className='min-h-full w-full flex-1'>{children}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

Modal.Footer = ({ className, children }) => {
  return (
    <div
      className={classNames(
        'flex justify-end items-center',
        'w-[calc(100%+48px)] h-16',
        'bg-gray-50',
        'gap-2.5',
        '-mx-6 -mb-6 px-6',
        'rounded-b-md',
        className
      )}
    >
      {children}
    </div>
  );
};

Modal.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  onCloseWhenEsc: PropTypes.func,
  onResetForm: PropTypes.func,
  delay: PropTypes.number,
};

export default Modal;
