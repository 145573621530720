/**@format */

import { createSlice } from '@reduxjs/toolkit';
import { UUID_CART, CART_DATA } from 'constants/Constants';

const initialState = {
  cartUUID: localStorage.getItem(UUID_CART) || null,
  cartData:
    localStorage.getItem(UUID_CART) && localStorage.getItem(CART_DATA)
      ? JSON.parse(localStorage.getItem(CART_DATA))
      : null,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartUUID: (state, action) => {
      localStorage.setItem(UUID_CART, action.payload);
      state.cartUUID = action.payload;
    },
    setCartData: (state, action) => {
      localStorage.setItem(CART_DATA, JSON.stringify(action.payload));
      state.cartData = action.payload;
    },
    clearCartStorage: state => {
      localStorage.removeItem(UUID_CART);
      localStorage.removeItem(CART_DATA);
      state.cartUUID = null;
      state.cartData = null;
    },
  },
});
export const { setCartUUID, setCartData, clearCartStorage } = cartSlice.actions;

export const selectCartUUID = state => state.cart.cartUUID;
export const selectCartData = state => state.cart.cartData;

export default cartSlice.reducer;
