/** @format */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { setTranslBundle } from 'translation/i18n';
import { TRANSLATIONS } from 'constants/Constants';
import { toCamelCase, getJsonData } from 'helpers';
import { generalService } from 'services';
import { history } from 'navigation/appHistory';

export const fetchTranslations = createAsyncThunk(
  'static/fetchTranslations',
  async function (_, { rejectWithValue, dispatch }) {
    try {
      dispatch(showLoading());
      const response = await generalService.getTranslations();
      dispatch(hideLoading());
      return response;
    } catch (error) {
      dispatch(hideLoading());
      if (error?.response?.status === 503) {
        return history.push('/maintenance');
      }
      return rejectWithValue(error?.response);
    }
  }
);

const initialState = {
  data: getJsonData(TRANSLATIONS) ?? {},
  status: null,
  statusCode: null,
  errorMessage: null,
};

export const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  extraReducers: {
    [fetchTranslations.pending]: state => {
      state.data = {};
      state.status = 'loading';
      state.errorMessage = null;
    },
    [fetchTranslations.fulfilled]: (state, { payload, type }) => {
      const translations = payload?.data?.data || {};
      setTranslBundle(translations);
      state.data = translations;
      localStorage.setItem(TRANSLATIONS, JSON.stringify(translations));
      state.status = toCamelCase(type);
      state.statusCode = payload?.status;
    },
    [fetchTranslations.rejected]: (state, { payload, type }) => {
      state.data = {};
      state.status = toCamelCase(type);
      state.statusCode = payload?.status;
      state.errorMessage = payload?.data;
    },
  },
});
export default translationsSlice.reducer;
