/** @format */

import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import PublicLayout from 'layout/PublicLayout/Main';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withLazyComponent } from 'helpers';

import Loader from 'components/Loader';

import welcomeRoutes from './WelcomeRoutes';
import crmRoutes from './CrmRoutes';
import mediaRoutes from './MediaRoutes';
import devicesRoutes from './DevicesRoutes';
import clientsRoutes from './ClientsRoutes';
import revenueRoutes from './RevenueRoutes';
import ticketingRoutes from './TicketingRoutes';
import vouchersRoutes from './VouchersRoutes';
import usersRoutes from './UsersRoutes';
import passesRoutes from './PassesRoutes';
import monitoringRoutes from './MonitoringRoutes';
import partnersRoutes from './PartnersRoutes';
import frontdeskRoutes from './FrontdeskRoutes';
import frontdeskWebRoutes from './FrontdeskWebRoutes';
import judgePortalRoutes from './JudgePortalRoutes';
import JobsRoutes from './JobsRoutes';
import FaresRoutes from './FaresRoutes';
import OpenPaymentsRoutes from './OpenPaymentsRoutes';
import DeploymentsRoutes from './DeploymentsRoutes';
import ControlListsRoutes from './ControlListsRoutes';
import EventsRoutes from './EventsRoutes';
import TopologyRoutes from './TopologyRoutes';
import HawkeyeRoutes from './HawkeyeRoutes';
import MobileWebRoutes from './MobileWebRoutes';

const EnterCode = withLazyComponent(lazy(() => import('pages/public/Lock/Mfa/EnterCode')));
const FactorAuth = withLazyComponent(lazy(() => import('pages/public/Lock/Mfa')));
const Login = withLazyComponent(lazy(() => import('pages/public/Login')));
const PasswordChange = withLazyComponent(lazy(() => import('pages/public/Lock/PasswordChange')));
const PasswordConfirmation = withLazyComponent(
  lazy(() => import('pages/public/Lock/PasswordConfirmation'))
);
const ResetUserPassword = withLazyComponent(
  lazy(() => import('pages/public/ResetPassword/ResetUserPassword'))
);

const Control = withLazyComponent(lazy(() => import('pages/dashboard/Control')));
const OffStreet = withLazyComponent(lazy(() => import('pages/dashboard/OffStreet/OffStreet')));
const RMA = withLazyComponent(lazy(() => import('pages/dashboard/RMA')));

const MaintenancePage = withLazyComponent(lazy(() => import('pages/general/MaintenancePage')));
const ClearStoragePage = withLazyComponent(lazy(() => import('pages/general/ClearStoragePage')));
const NewVersionAppPage = withLazyComponent(lazy(() => import('pages/general/NewVersionAppPage')));
const SessionErrorPage = withLazyComponent(lazy(() => import('pages/general/SessionErrorPage')));
const SessionExpiredPage = withLazyComponent(
  lazy(() => import('pages/general/SessionExpiredPage'))
);
const AccessDeniedPage = withLazyComponent(lazy(() => import('pages/general/AccessDeniedPage')));

const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route path='/' element={<PublicLayout />}>
          <Route
            index
            element={
              <Suspense fallback={<Loader />}>
                <Login title='Login page' />
              </Suspense>
            }
          />
          <Route
            path='login'
            element={
              <Suspense fallback={<Loader />}>
                <Login title='Login page' />
              </Suspense>
            }
          />
          <Route
            path='maintenance'
            element={
              <Suspense fallback={<Loader />}>
                <MaintenancePage title='Maintenance' />
              </Suspense>
            }
          />
          <Route
            path='factor-auth'
            element={
              <Suspense fallback={<Loader />}>
                <FactorAuth title='Factor Auth page' />
              </Suspense>
            }
          />
          <Route
            path='factor-auth/code'
            element={
              <Suspense fallback={<Loader />}>
                <EnterCode title='Enter Code Page' />
              </Suspense>
            }
          />
          <Route
            path='needs-password-change'
            element={
              <Suspense fallback={<Loader />}>
                <PasswordChange title='Password change' />
              </Suspense>
            }
          />
          <Route
            path='needs-password-confirmation'
            element={
              <Suspense fallback={<Loader />}>
                <PasswordConfirmation title='Password confirmation' />
              </Suspense>
            }
          />
          <Route
            path='reset'
            element={
              <Suspense fallback={<Loader />}>
                <ResetUserPassword title='Factor Auth page' />
              </Suspense>
            }
          />
          <Route
            path='clear-storage'
            element={
              <Suspense fallback={<Loader />}>
                <ClearStoragePage title='Clear storage' />
              </Suspense>
            }
          />
          <Route
            path='new-version'
            element={
              <Suspense fallback={<Loader />}>
                <NewVersionAppPage title='New version app' />
              </Suspense>
            }
          />
          <Route
            path='session-error'
            element={
              <Suspense fallback={<Loader />}>
                <SessionErrorPage title='Session Error' />
              </Suspense>
            }
          />
          <Route
            path='session-expired'
            element={
              <Suspense fallback={<Loader />}>
                <SessionExpiredPage title='Session Expired' />
              </Suspense>
            }
          />
          <Route
            path='access-denied'
            element={
              <Suspense fallback={<Loader />}>
                <AccessDeniedPage title='Access Denied' />
              </Suspense>
            }
          />
        </Route>

        {/* Dashboard routes */}
        <Route path='dashboard'>
          <Route index element={<Navigate to='/dashboard/welcome' replace />} />

          {welcomeRoutes}
          {crmRoutes}
          {mediaRoutes}
          {devicesRoutes}
          {clientsRoutes}
          {revenueRoutes}
          {ticketingRoutes}
          {vouchersRoutes}
          {usersRoutes}
          {passesRoutes}
          {monitoringRoutes}
          {partnersRoutes}
          {frontdeskRoutes}
          {frontdeskWebRoutes}
          {judgePortalRoutes}
          {JobsRoutes}
          {FaresRoutes}
          {OpenPaymentsRoutes}
          {DeploymentsRoutes}
          {ControlListsRoutes}
          {EventsRoutes}
          {TopologyRoutes}
          {HawkeyeRoutes}
          {MobileWebRoutes}

          <Route path='control' element={<DashboardLayout nav={[]} />}>
            <Route index element={<Control title='Control page' />} />
            <Route path='*' element={<Navigate to='/dashboard/control' replace />} />
          </Route>

          <Route path='imports' element={<Navigate to='/dashboard/jobs/import' replace />} />

          <Route path='offstreet' element={<DashboardLayout nav={[]} />}>
            <Route index element={<OffStreet title='OffStreet page' />} />
            <Route path='*' element={<Navigate to='/dashboard/offstreet' replace />} />
          </Route>

          <Route path='rma' element={<DashboardLayout nav={[]} />}>
            <Route index element={<RMA title='RMA page' />} />
            <Route path='*' element={<Navigate to='/dashboard/rma' replace />} />
          </Route>
          <Route path='*' element={<Navigate to='/dashboard/welcome' replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
