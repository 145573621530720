/**@format */
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { DocumentSearchIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/solid';

import { classNames } from 'helpers';
import Button from 'components/Button';
import { searchingStateEnum } from 'constants/enum';

const SearchingModal = ({ isOpen, searchState, onClose, onUpdateSearch, className }) => {
  const { t } = useTranslation();
  let updateSearchBtnRef = useRef(null);

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);
  const handleKeyDown = useCallback(
    e => {
      if (e && e.code === 'Escape' && searchState === searchingStateEnum.NO_RESULT) handleClose();
    },
    [handleClose, searchState]
  );
  const handleUpdateSearch = () => {
    onUpdateSearch ? onUpdateSearch() : handleClose();
  };

  useEffect(() => {
    if (searchState === searchingStateEnum.NO_RESULT) {
      updateSearchBtnRef.current && updateSearchBtnRef.current.focus();
    }
  }, [searchState]);
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, isOpen, onClose]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog onClose={() => {}} as='div' className='fixed inset-0 z-50 overflow-y-auto'>
        <div className='min-h-screen px-4 text-center'>
          {/* Overlay */}
          <div className='fixed inset-0 bg-black/20' />

          <span className='inline-block h-screen align-middle' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel
              className={classNames(
                'inline-block w-full max-w-[400px] text-left align-middle',
                'transition-all transform bg-white shadow-xl rounded-md',
                'px-6 py-5',
                className
              )}
            >
              {searchState === searchingStateEnum.NO_RESULT && (
                <Button
                  color='transparent'
                  className={classNames(
                    'h-fit p-0',
                    'focus:rounded-md',
                    'hover:bg-transparent',
                    'focus:ring-offset-0',
                    'ml-auto'
                  )}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <XIcon className={classNames('w-6 h-6', 'text-gray-400')} />
                </Button>
              )}

              <div
                className={classNames('flex flex-col items-center', {
                  'gap-4': searchState === searchingStateEnum.SEARCHING,
                  'gap-3': searchState === searchingStateEnum.NO_RESULT,
                })}
              >
                {/* Spinner */}
                {searchState === searchingStateEnum.SEARCHING && (
                  <div
                    className={classNames(
                      'border-4 border-gray-300 border-t-4 rounded-full border-t-indigo-600 w-10 h-10 animate-spin'
                    )}
                  />
                )}
                {searchState === searchingStateEnum.NO_RESULT && (
                  <DocumentSearchIcon className='w-12 stroke-gray-400' />
                )}
                <div className={classNames('flex flex-col', 'gap-1', 'w-[240px]')}>
                  <h5 className={classNames('text-center', 'text-base font-medium text-gray-900')}>
                    {searchState === searchingStateEnum.SEARCHING && t('Searching for results...')}
                    {searchState === searchingStateEnum.NO_RESULT && t('No results found')}
                  </h5>
                  <p className={classNames('text-center', 'text-base text-gray-500')}>
                    {searchState === searchingStateEnum.SEARCHING &&
                      t('Please do not leave or refresh the page.')}
                    {searchState === searchingStateEnum.NO_RESULT &&
                      t('There are no records matching your research.')}
                  </p>
                </div>
              </div>
              {searchState === searchingStateEnum.NO_RESULT && (
                <Button
                  /**Focus to this button when NO_RESULT */
                  ref={updateSearchBtnRef}
                  className={classNames('mx-auto', 'mt-6 mb-3')}
                  onClick={handleUpdateSearch}
                  title={t('Update my search')}
                />
              )}

              {/* Default focus element */}
              {searchState !== searchingStateEnum.NO_RESULT && (
                <button className={classNames('absolute bottom-0', 'opacity-0')} />
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

SearchingModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdateSearch: PropTypes.func,
  searchState: PropTypes.oneOf([...Object.values(searchingStateEnum), null]),
};

export default SearchingModal;
