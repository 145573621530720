/** @format */

import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { MOBILE_WEB as NAV_MOBILE_WEB } from 'constants/Navigation';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';
import DashboardLayout from 'layout/DashboardLayout/Main';

const MobileWeb = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/MobileWebService/MobileWeb'))),
  t => [{ name: t('Mobile & Web') }]
);
const MobileWebEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/MobileWebService/MobileWeb/EditVersion'))),
  t => [{ name: t('Mobile & Web') }]
);

const MobileWebRoutes = (
  <Route path='apps' element={<DashboardLayout nav={NAV_MOBILE_WEB} />}>
    <Route index element={<Navigate to='/dashboard/apps/mobile-web' replace />} />
    <Route path='mobile-web' element={<MobileWeb title='Mobile & Web' />} />
    <Route path='mobile-web/:id' element={<MobileWebEdit title='Mobile & Web Edit Page' />} />
    <Route path='*' element={<Navigate to='/dashboard/apps/mobile-web' replace />} />
  </Route>
);

export default MobileWebRoutes;
