/** @format */

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { ArrowNarrowLeftIcon, CheckIcon } from '@heroicons/react/solid';
import { Transition } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { classNames, responseError, toTitleCase } from 'helpers';
import { useToast } from 'hooks';
import { useChangePasswordMutation } from 'services/bo/users/users.me.mfa';
import { COLOR_GRAY, COLOR_TRANSPARENT } from 'constants/Constants';

import RequirementsField from 'components/RequirementsField';
import TextFieldPassword from 'components/TextFieldPassword';
import TextField from 'components/TextField';
import Button from 'components/Button';
import Modal from 'components/Modal';

import ResetPasswordLoaderView from './ResetPasswordLoaderView';

const ResetPasswordView = ({ onClose = () => {}, onBack = () => {} }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isAllRequirementsPass, setIsAllRequirementsPass] = useState(false);
  const [isHaveRequirements, setIsHaveRequirements] = useState(false);
  const [isShowPass, setIsShowPass] = useState(false);
  const [changePass, { isLoading: isResetPasswordLoading }] = useChangePasswordMutation();

  const handleChangeIsShowPass = isShow => setIsShowPass(isShow);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const { password, confirmPassword, currentPassword } = values;
    const changePassData = {
      current_password: currentPassword,
      password,
      password_confirmation: confirmPassword,
    };

    dispatch(showLoading());
    try {
      const { status } = await changePass(changePassData).unwrap();
      switch (status) {
        case 200:
        case 201:
        case 203:
          toast.success({
            title: t('Success'),
            message: t('The password was successfully updated.'),
          });
          break;
        default:
          break;
      }
      onClose();
    } catch (error) {
      responseError(error, toast, t, navigate, location);
    }
    dispatch(hideLoading());
    setSubmitting(false);
  };

  const handleCancel = () => {
    onClose && onClose();
  };

  const isShowConfirmPassword = () => {
    return isHaveRequirements && isAllRequirementsPass;
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required(t('{{label}} is a required field', { label: toTitleCase(t('Current password')) }))
      .label(toTitleCase(t('Current password'))),
    password: Yup.string()
      .required(t('{{label}} is a required field', { label: t('New Password') }))
      .label(t('New Password')),
    confirmPassword: Yup.string()
      .label(toTitleCase(t('Confirm new password')))
      .oneOf([Yup.ref('password'), null], t('Passwords don’t match'))
      .required(
        t('{{label}} is a required field', { label: toTitleCase(t('Confirm new password')) })
      ),
  });
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <h1 className={classNames('text-gray-900 text-lg font-medium', 'mb-1')}>
        {t('Change password')}
      </h1>
      <p className='text-sm text-gray-500'>{t('Fill in all the fields below.')}</p>
      {isResetPasswordLoading && <ResetPasswordLoaderView />}
      {!isResetPasswordLoading && (
        <>
          <div className='space-y-7 mt-7'>
            <TextFieldPassword
              id='currentPassword'
              label={toTitleCase(t('Current password'))}
              placeholder={t('Enter your current password')}
              {...formik.getFieldProps('currentPassword')}
              isError={formik.touched.currentPassword && !!formik.errors.currentPassword}
              lableError={formik.errors.currentPassword}
            />
            <TextFieldPassword
              id='password'
              className={classNames({ 'rounded-b-none': isAllRequirementsPass })}
              isDefaultShowPass={isShowPass}
              onIsShowPass={handleChangeIsShowPass}
              label={t('New Password')}
              placeholder={t('Create new password')}
              {...formik.getFieldProps('password')}
              isError={formik.touched.password && !!formik.errors.password}
              lableError={formik.errors.password}
            />
          </div>
          <Transition as={Fragment} show={isShowConfirmPassword()}>
            <Transition.Child
              enter='transition-all transform duration-500 delay-500'
              enterFrom='opacity-0 -translate-y-4'
              enterTo='opacity-100 translate-y-0'
              leave='transition--all transform duration-500'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 -translate-y-4'
            >
              <TextField
                className='border-t-0 rounded-t-none'
                type={isShowPass ? 'text' : 'password'}
                id='confirmPassword'
                label={toTitleCase(t('Confirm new password'))}
                lableError={formik.errors.confirmPassword}
                placeholder={t('Confirm new password')}
                {...formik.getFieldProps('confirmPassword')}
                isError={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
                rightComponent={
                  <div
                    className={classNames(
                      'absolute right-0 inset-y-0 z-20 flex items-center px-3',
                      {
                        'hidden': !(formik.values.confirmPassword && formik.isValid),
                      }
                    )}
                  >
                    <CheckIcon className='w-5 text-green-500' aria-hidden='true' />
                  </div>
                }
              />
            </Transition.Child>
          </Transition>
          <RequirementsField
            show={!!formik.values.password}
            password={formik.values.password}
            onInit={() => setIsHaveRequirements(true)}
            onIsAllRequirementsPass={data => setIsAllRequirementsPass(data)}
            className='mt-4'
          />
        </>
      )}
      <div className='mt-7'>
        <Modal.Footer>
          <Button color={COLOR_TRANSPARENT} type='button' onClick={onBack}>
            <ArrowNarrowLeftIcon className='w-5 text-gray-400 mr-2' aria-hidden='true' />
            <span>{t('Back')}</span>
          </Button>
          <Button color={COLOR_GRAY} outline onClick={handleCancel} title={t('Cancel')} />
          <Button
            type='submit'
            disabled={!formik.dirty || formik.isSubmitting || !formik.isValid}
            title={t('Update password')}
          />
        </Modal.Footer>
      </div>
    </form>
  );
};

ResetPasswordView.PropsTypes = {
  onClose: PropTypes.func,
  onBack: PropTypes.func,
};

export default ResetPasswordView;
