/**@format */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CalendarIcon } from '@heroicons/react/solid';
import { IMaskInput, IMask } from 'react-imask';
import moment from 'moment-timezone';

import { classNames, setDateTimeToLocalTime, prettyDate } from 'helpers';

import { MONTHS_SHORT, datetimePickerModeEnum, datetimePickerPosition } from '../constants/enum';
import { MonthPicker } from './PickerComponents/MonthPicker';
import { YearPicker } from './PickerComponents/YearPicker';

export const MonthPickerTextField = ({
  id,
  className,
  required,
  placeholder,
  value,
  maxDate,
  minDate,
  onChange,
  onBlur,
  positionX = datetimePickerPosition.LEFT,
  positionY = datetimePickerPosition.BOTTOM,
  ...props
}) => {
  const maskTextFieldRef = useRef(null);
  const textFieldRef = useRef(null);
  const [picker, setPicker] = useState(null);
  const [currentMode, setCurrentMode] = useState(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const textFieldHeight = (maskTextFieldRef?.current?.offsetHeight ?? 0) + 'px';
  const iMaskPattern = 'MMM, YYYY';

  const defaultValue = useMemo(() => {
    let year = +prettyDate(+value || new Date(), 'YYYY');
    let month = value ? +prettyDate(+value, 'MM') - 1 : '';
    if (picker && picker !== year) {
      month = '';
      year = picker;
    }
    return { month, year };
  }, [value, picker]);

  const iMaskDateBlocks = {
    MMM: {
      mask: IMask.MaskedEnum,
      enum: MONTHS_SHORT,
      placeholderChar: 'M',
    },
    YYYY: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 9999,
      placeholderChar: 'Y',
    },
  };

  const positionDropdownStyle = {
    defaultClassName: classNames(
      positionY === datetimePickerPosition.TOP && 'mb-2',
      positionY === datetimePickerPosition.BOTTOM && 'top-0 mt-2',
      positionX === datetimePickerPosition.LEFT && 'left-0',
      positionX === datetimePickerPosition.RIGHT && 'right-0'
    ),
    positionTopInlineStyle:
      positionY === datetimePickerPosition.TOP ? { bottom: textFieldHeight } : {},
  };

  const handleChange = e => {
    onChange && onChange(e);
  };

  const handleBlur = e => {
    const wrapperEl = document.getElementById(`${id}-month-picker-wrapper`);
    if (
      isPickerOpen &&
      !wrapperEl.contains(e.relatedTarget) &&
      e.relatedTarget?.id !== `${id}-month-picker-button`
    ) {
      handleToggleMonthPicker();
      setPicker(null);
    }
    onBlur && onBlur(e);
  };

  const handleToggleMonthPicker = () => {
    if (!isPickerOpen) textFieldRef?.current?.focus();
    setIsPickerOpen(prevState => !prevState);
  };

  const handleSelectMonthPicker = dateTime => {
    const timestampChosenDay = setDateTimeToLocalTime(moment(dateTime));
    handleChange({ target: { id, value: timestampChosenDay } });
    handleToggleMonthPicker();
    setPicker(null);
    textFieldRef?.current?.focus();
  };

  const handleSelectYearPicker = year => {
    handleSwitchMode(datetimePickerModeEnum.MONTH_PICKER);
    setPicker(year);
  };

  const handleSwitchMode = mode => {
    setCurrentMode(mode);
    handleToggleMonthPicker();
  };

  const handleIMaskAccept = (_, mask) => {
    !mask.typedValue && !mask.unmaskedValue && handleChange({ target: { id, value: '' } });
  };

  const handleIMaskValidate = (value, mask) => {
    const limitLength = 6;
    const parsedDate = moment(value, iMaskPattern);
    let isInputValid = true;
    if (mask.unmaskedValue?.length === limitLength) {
      isInputValid = parsedDate.isValid();
    }
    return isInputValid;
  };

  const handleIMaskComplete = (_, mask) => {
    if (mask.typedValue) {
      const timestampChosenDay = setDateTimeToLocalTime(moment(mask.typedValue));
      handleChange({ target: { id, value: timestampChosenDay } });
    }
  };

  useEffect(() => {
    if (value) {
      const newMaskValue = prettyDate(+value, iMaskPattern);
      maskTextFieldRef.current.maskValue !== newMaskValue &&
        (maskTextFieldRef.current.maskValue = newMaskValue);
    } else if (value?.length === 0) {
      maskTextFieldRef.current.maskValue = '';
    }
  }, [iMaskPattern, value]);

  return (
    <>
      <IMaskInput
        id={id}
        type='text'
        mask={Date}
        pattern={iMaskPattern}
        ref={maskTextFieldRef}
        inputRef={el => {
          textFieldRef.current = el;
        }}
        lazy={false}
        blocks={iMaskDateBlocks}
        format={date => prettyDate(date, iMaskPattern)}
        parse={str => moment(str, iMaskPattern)}
        onAccept={handleIMaskAccept}
        onComplete={handleIMaskComplete}
        className={classNames({ 'text-gray-400': !value }, className)}
        placeholder={placeholder}
        required={required}
        onBlur={handleBlur}
        validate={handleIMaskValidate}
        {...props}
      />

      <div id={`${id}-month-picker-wrapper`} className='w-full relative'>
        {isPickerOpen && (
          <MonthPicker
            id={id}
            defaultValue={defaultValue}
            onSelect={handleSelectMonthPicker}
            maxDate={maxDate}
            minDate={minDate}
            onSwitchMode={handleSwitchMode}
            positionDropdownStyle={positionDropdownStyle}
          />
        )}
        {currentMode === datetimePickerModeEnum.YEAR_DECADE_PICKER && (
          <YearPicker
            year={defaultValue?.year}
            onSelect={handleSelectYearPicker}
            pickerMode={currentMode}
            onSwitchMode={handleSwitchMode}
            maxDate={maxDate}
            minDate={minDate}
            positionDropdownStyle={positionDropdownStyle}
          />
        )}
      </div>
      <button
        id={`${id}-month-picker-button`}
        type='button'
        className={classNames('absolute', 'inset-y-0 right-3', 'my-auto', 'h-fit', 'z-20')}
        onClick={handleToggleMonthPicker}
      >
        <CalendarIcon className={classNames('fill-gray-400', 'w-5 h-5', 'hover:fill-indigo-600')} />
      </button>
    </>
  );
};

MonthPickerTextField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  positionX: PropTypes.string,
  positionY: PropTypes.string,
};
