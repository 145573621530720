/** @format */

import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { HAWKEYE as NAV_HAWKEYE } from 'constants/Navigation';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';
import DashboardLayout from 'layout/DashboardLayout/Main';

const HawkeyeSettings = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Hawkeye/Settings'))),
  t => [{ name: t('Hawkeye'), url: '/dashboard/hawkeye' }, { name: t('Settings') }]
);

const HawkeyeMonitoring = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Hawkeye/Monitoring'))),
  t => [{ name: t('Hawkeye'), url: '/dashboard/hawkeye' }, { name: t('Monitoring') }]
);

const HawkeyeUsers = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Hawkeye/Users'))),
  t => [{ name: t('Hawkeye'), url: '/dashboard/hawkeye' }, { name: t('Users') }]
);

const HawkeyeEditUser = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Hawkeye/Users/EditUser'))),
  t => [
    { name: t('Hawkeye'), url: '/dashboard/hawkeye' },
    { name: t('Users'), url: '/dashboard/hawkeye/users' },
  ]
);

const HawkeyeHandhelds = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Hawkeye/Handhelds'))),
  t => [{ name: t('Hawkeye'), url: '/dashboard/hawkeye' }, { name: t('Handhelds') }]
);

const InspectionSessions = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Hawkeye/InspectionSessions'))),
  t => [{ name: t('Hawkeye'), url: '/dashboard/hawkeye' }, { name: t('Inspection Sessions') }]
);

const HawkeyeAuditTrail = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Hawkeye/AuditTrail'))),
  t => [{ name: t('Hawkeye'), url: '/dashboard/hawkeye' }, { name: t('Audit Trail') }]
);

const HawkeyeRoutes = (
  <Route path='hawkeye' element={<DashboardLayout nav={NAV_HAWKEYE} />}>
    <Route index element={<Navigate to='/dashboard/hawkeye/users' replace />} />
    <Route path='settings' element={<HawkeyeSettings title='Settings' />} />
    <Route path='monitoring' element={<HawkeyeMonitoring title='Hawkeye Monitoring' />} />
    <Route path='users' element={<HawkeyeUsers title='Hawkeye Users' />} />
    <Route path='users/:uuid' element={<HawkeyeEditUser title='Hawkeye Edit User' />} />
    <Route path='handhelds' element={<HawkeyeHandhelds title='Hawkeye Handhelds' />} />
    <Route path='audit-trail' element={<HawkeyeAuditTrail title='Hawkeye Audit Trail' />} />
    <Route
      path='inspection-sessions'
      element={<InspectionSessions title='Inspection Sessions' />}
    />
    <Route path='*' element={<Navigate to='/dashboard/hawkeye/users' replace />} />
  </Route>
);

export default HawkeyeRoutes;
