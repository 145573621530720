/**@format */
import { api } from '../api';

const transformResponse = (response, meta, arg) => {
  return {
    status: meta?.response?.status,
    statusText: meta?.response?.statusText,
    data: response,
  };
};

export const mfaApi = api.injectEndpoints({
  endpoints: build => ({
    sendMfa: build.mutation({
      query: body => ({
        url: `users/me/mfa/send`,
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 0.0001,
      transformResponse,
    }),
    confirmMfa: build.mutation({
      query: body => ({
        url: `users/me/mfa/confirm`,
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 0.0001,
      transformResponse,
    }),
    addPhoneNubmer: build.mutation({
      query: body => ({
        url: `users/me/mfa/phone-number`,
        method: 'PUT',
        body,
      }),
      keepUnusedDataFor: 0.0001,
      transformResponse,
    }),
    changePassword: build.mutation({
      query: body => ({
        url: `users/me/password/change`,
        method: 'PUT',
        body,
      }),
      keepUnusedDataFor: 0.0001,
      transformResponse,
    }),
    confirmPassword: build.mutation({
      query: body => ({
        url: `users/me/password/confirm`,
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 0.0001,
      transformResponse,
    }),
  }),
});

export const {
  useSendMfaMutation,
  useConfirmMfaMutation,
  useAddPhoneNubmerMutation,
  useChangePasswordMutation,
  useConfirmPasswordMutation,
} = mfaApi;
