/**@format */
import React, { useRef } from 'react';

import { Switch } from '@headlessui/react';
import { classNames } from '../../helpers';

const SwitchItem = React.forwardRef(({ className, children }, ref) => (
  <span
    className={classNames(
      'flex items-center',
      {
        /**Default padding */
        'py-1.5': !className?.includes('py-'),
        'px-2': !className?.includes('px-'),
      },
      className
    )}
    ref={ref}
  >
    {children}
  </span>
));

export const RectangleSwitch = ({
  className,
  leverClassName,
  isChecked,
  onChange,
  leftSwitchChild,
  leftSwitchClassName,
  rightSwitchChild,
  rightSwitchClassName,
  isVertical = false,
  disabled = false,
}) => {
  const leftSwitchRef = useRef(null);
  const leftSwitchTranslatedX = leftSwitchRef.current?.offsetWidth
    ? /**offsetWidth + Switch gap */
      leftSwitchRef.current?.offsetWidth + 2
    : 0;
  const topSwitchTranslatedY = leftSwitchRef.current?.offsetHeight
    ? /**offsetHeight + Switch gap */
      leftSwitchRef.current?.offsetHeight + 2
    : 0;
  return (
    <Switch
      disabled={disabled}
      checked={isChecked}
      onChange={onChange}
      className={classNames(
        'relative',
        'font-medium text-gray-500',
        'bg-gray-200',
        'gap-0.5',
        'flex',
        { 'flex-col': isVertical },
        'h-fit w-fit',
        {
          /**Default padding */
          'p-0.5': !className?.includes('p-'),
          /**Default radius */
          'rounded-md': !className?.includes('rounded-'),
          /**Default fontSize & leading */
          'text-xs': !className?.includes('text-'),
        },
        className
      )}
    >
      <div
        data-testid='wrap-switch-item'
        className={classNames(
          'bg-white',
          'h-fit w-fit',
          'absolute',
          'transform transition ease-in-out duration-200',
          {
            'rounded-[4px]': !leverClassName?.includes('rounded-'),
          },
          leverClassName
        )}
        style={{
          transform: isVertical
            ? isChecked
              ? `translateY(${topSwitchTranslatedY}px)`
              : `translateY(0)`
            : isChecked
            ? `translateX(${leftSwitchTranslatedX}px)`
            : `translateX(0)`,
        }}
      >
        {!isChecked ? (
          <SwitchItem className={classNames('invisible', leftSwitchClassName)}>
            {leftSwitchChild}
          </SwitchItem>
        ) : (
          <SwitchItem className={classNames('invisible', rightSwitchClassName)}>
            {rightSwitchChild}
          </SwitchItem>
        )}
      </div>
      <SwitchItem ref={leftSwitchRef} className={classNames('z-10', leftSwitchClassName)}>
        {leftSwitchChild}
      </SwitchItem>
      <SwitchItem className={classNames('z-10', rightSwitchClassName)}>
        {rightSwitchChild}
      </SwitchItem>
    </Switch>
  );
};
