/**@format */
import { createSlice } from '@reduxjs/toolkit';

const defaultConfirmState = {
  isOpen: false,
  title: null,
  text: null,
  type: null,
};

export const globalConfirmSlice = createSlice({
  name: 'globalConfirm',
  initialState: defaultConfirmState,
  reducers: {
    openConfirm: (state, { payload }) => {
      const {
        type,
        title,
        text,
        modalClassName,
        primaryBtnText,
        secondaryBtnText,
        primaryBtnColor,
        secondaryBtnColor,
        primaryBtnClassName,
        secondaryBtnClassName,
        titleClassName,
      } = payload;
      state.type = type;
      state.isOpen = true;
      state.title = title;
      state.text = text;
      state.modalClassName = modalClassName;
      state.primaryBtnText = primaryBtnText;
      state.secondaryBtnText = secondaryBtnText;
      state.primaryBtnColor = primaryBtnColor;
      state.secondaryBtnColor = secondaryBtnColor;
      state.primaryBtnClassName = primaryBtnClassName;
      state.secondaryBtnClassName = secondaryBtnClassName;
      state.titleClassName = titleClassName;
    },
    closeConfirm: state => {
      state.isOpen = false;
    },
    resetConfirm: () => defaultConfirmState,
  },
});

export const { openConfirm, closeConfirm, resetConfirm } = globalConfirmSlice.actions;
export const isOpenConfirm = state => state.dashboard.globalConfirm.isOpen;
export default globalConfirmSlice.reducer;
