/** @format */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Disclosure } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { LoaderNavigationItemsView } from 'layout/DashboardLayout/Navigation/Loaders';
import { classNames } from 'helpers';

const Navigation = ({ nav = [], basicPageCount, isGettingSettings }) => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <aside
      className={classNames(
        'sm:fixed z-50 left-0 top-0 sm:bottom-0 right-0 sm:right-auto',
        'gap-4 sm:gap-8',
        'flex sm:flex-col items-center',
        'sm:w-16',
        'bg-indigo-600',
        'px-4 py-4 sm:py-4 sm:px-2.5'
      )}
    >
      <Disclosure as='nav' className='w-full'>
        {({ open }) => (
          <>
            <div className='max-w-7xl mx-auto'>
              <div className={classNames('relative', 'flex items-center justify-between')}>
                <div
                  className={classNames(
                    nav.length !== 0
                      ? 'absolute inset-y-0 left-0 flex items-center sm:hidden'
                      : 'hidden'
                  )}
                >
                  {/* Mobile menu button*/}
                  <Disclosure.Button
                    className={classNames(
                      'inline-flex items-center justify-center',
                      'p-2',
                      'rounded-md',
                      'text-white',
                      'focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                    )}
                  >
                    <span className='sr-only'>{t('Open main menu')}</span>
                    {open ? (
                      <XIcon className='block h-6 w-6' aria-hidden='true' />
                    ) : (
                      <MenuIcon className='block h-6 w-6' aria-hidden='true' />
                    )}
                  </Disclosure.Button>
                </div>
                <div className='flex-1 flex flex-col items-center justify-center sm:items-stretch sm:justify-start'>
                  <div className='flex-shrink-0 flex flex-col items-center justify-center'>
                    <div className='text-white text-xl font-medium'>hub</div>
                  </div>
                  <div className='hidden sm:block sm:py-5'>
                    {isGettingSettings ? (
                      <LoaderNavigationItemsView loadCount={basicPageCount} />
                    ) : (
                      <div className={classNames('flex flex-col', 'space-y-3')}>
                        {nav.map((item, index) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              'tooltip relative',
                              location.pathname.startsWith(item.href)
                                ? 'bg-indigo-800'
                                : 'hover:bg-indigo-700',
                              'inline-flex items-center justify-center',
                              'text-white',
                              'rounded-md',
                              'w-11 h-11',
                              'p-1',
                              'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                              {
                                'hidden': item.accessDenied,
                              }
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            <item.icon className={classNames('w-6', item.classNameIcon)} />
                            <p
                              className={classNames(
                                'invisible absolute z-30 opacity-0',
                                'py-2 px-3',
                                'rounded-md',
                                'bg-gradient-to-r from-blue-500 to-blue-600 text-white',
                                'ease-in duration-300',
                                'top-[calc(50% - 20px)] left-[140%]',
                                'tooltiptext'
                              )}
                            >
                              {item.name}
                              <span
                                className={classNames(
                                  'absolute top-[50%] right-[100%]',
                                  'mt-[-6px]',
                                  'border-1.5 border-solid border-transparent border-r-blue-500'
                                )}
                              ></span>
                            </p>
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className='sm:hidden'>
              {isGettingSettings ? (
                <LoaderNavigationItemsView loadCount={basicPageCount} isDisclosure />
              ) : (
                <div className='py-3 space-y-1'>
                  {nav.map(item => (
                    <Disclosure.Button
                      key={item.name}
                      as='a'
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-indigo-800' : 'hover:bg-indigo-700 hover:text-gray-300',
                        'block px-3 py-2 rounded-md text-base font-medium  text-white',
                        {
                          'hidden': item.accessDenied,
                        }
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </aside>
  );
};
Navigation.PropsTypes = {
  nav: PropTypes.array,
  isGettingSettings: PropTypes.bool,
};
export default Navigation;
