/** @format */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/solid';

import ComboboxSelectList from 'components/ComboboxSelectList';
import Button from 'components/Button';
import Modal from 'components/Modal';

import { setSubUserData } from 'store/subUser/subUserSlice';
import { clearSubUserData } from 'store/subUser/subUserSlice';
import { useToast } from 'hooks';
import { classNames, toTitleCase } from 'helpers';
import { COLOR_TRANSPARENT } from 'constants/Constants';

const people = [
  {
    id: 1,
    name: 'Wade Cooper',
    avatar:
      'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 2,
    name: 'Arlene Mccoy',
    avatar: '',
  },
  {
    id: 3,
    name: 'Devon Webb',
  },
  {
    id: 4,
    name: 'Tom Cook',
    avatar:
      'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 5,
    name: 'Tanya Fox',
    avatar:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 6,
    name: 'Hellen Schmidt',
    avatar:
      'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 7,
    name: 'Caroline Schultz',
    avatar:
      'https://images.unsplash.com/photo-1568409938619-12e139227838?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 8,
    name: 'Mason Heaney',
    avatar:
      'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 9,
    name: 'Claudie Smitham',
    avatar:
      'https://images.unsplash.com/photo-1584486520270-19eca1efcce5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 10,
    name: 'Emil Schaefer',
    avatar:
      'https://images.unsplash.com/photo-1561505457-3bcad021f8ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
];

const LogAs = ({ onClose }) => {
  const [loginName, setLoginName] = useState(null);
  const toast = useToast();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSetLoginName = data => {
    setLoginName(data);
  };

  const handleSubUserLogOut = () => {
    toast.info({ title: t('Info'), message: t('You are logged out') });
    dispatch(clearSubUserData());
  };

  const handleLogIn = e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setSubUserData(loginName));
    toast.success({
      title: t('Success'),
      message: (
        <div className='flex flex-col gap-2'>
          <div className='text-sm'>
            {t('You are logged as {{label}}', { label: loginName?.name })}
          </div>
          <Button
            color={COLOR_TRANSPARENT}
            className={classNames('p-0', 'hover:bg-transparent', 'h-auto w-fit')}
            onClick={handleSubUserLogOut}
          >
            <div className='text-indigo-600 text-sm font-medium'>{t('Log out')}</div>
          </Button>
        </div>
      ),
    });
    onClose();
  };

  return (
    <>
      <Button
        color='transparent'
        className={classNames(
          'h-fit p-0',
          'focus:rounded-md',
          'hover:bg-transparent',
          'focus:ring-offset-0 absolute right-5 top-5'
        )}
        onClick={onClose}
      >
        <XIcon className={classNames('w-6 h-6', 'text-gray-400')} />
      </Button>
      <h1 className='text-gray-900 text-lg font-medium mb-1'>{t('Log as')}</h1>
      <p className='text-sm text-gray-500 mb-7'>{t('Select a user to log in.')}</p>
      <form onSubmit={handleLogIn}>
        <ComboboxSelectList
          onClose={onClose}
          dataList={people}
          onSelectValue={handleSetLoginName}
          label={toTitleCase(t('Log as'))}
          placeholder={t('Select user')}
        />
        <Modal.Footer className='mt-7'>
          <Button title={t('Cancel')} outline color='gray' type='button' onClick={onClose} />
          <Button type='submit' disabled={!loginName} title={t('Log in')} />
        </Modal.Footer>
      </form>
    </>
  );
};

export default LogAs;
