/** @format */

import React from 'react';
import InternalServer from './components/Errors/InternalServer';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({ hasError: true });

    // TODO: need to add log error messages to an error reporting service here.
    // It can help to watch for crash app.
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <InternalServer />;
    }
    return children;
  }
}

export default ErrorBoundary;
