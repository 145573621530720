/**@format */
import { api } from '../api';

export const usersSettingsApi = api.injectEndpoints({
  endpoints: build => ({
    getUsersSettings: build.query({
      query: () => 'users/settings/client',
      providesTags: ['UsersSettings'],
    }),
    updateUsersSettings: build.mutation({
      query: ({ body }) => ({
        url: 'users/settings/client',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['UsersSettings'],
    }),
  }),
});

export const {
  useGetUsersSettingsQuery,
  useLazyGetUsersSettingsQuery,
  useUpdateUsersSettingsMutation,
} = usersSettingsApi;
