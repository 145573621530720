/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { MONITORING as NAV_MONITORING } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const Monitoring = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Monitoring/Monitoring'))),
  t => [{ name: t('Monitoring') }]
);
const MonitoringAlarmDetails = withHeaderBreadcrumb(
  withLazyComponent(
    lazy(() => import('pages/dashboard/Monitoring/Alarms/Components/AlarmDetails'))
  ),
  t => [
    {
      name: t('Monitoring'),
      url: '/dashboard/monitoring',
    },
    { name: t('Alarms') },
  ]
);
const MonitoringAlarms = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Monitoring/Alarms/Alarms'))),
  t => [
    {
      name: t('Monitoring'),
      url: '/dashboard/monitoring',
    },
    { name: t('Alarms') },
  ]
);
const MonitoringConsumables = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Monitoring/Consumables'))),
  t => [{ name: t('Monitoring'), url: '/dashboard/monitoring' }, { name: t('Consumables') }]
);
const MonitoringControls = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Monitoring/Controls'))),
  t => [{ name: t('Monitoring'), url: '/dashboard/monitoring' }, { name: t('Controls') }]
);
const MonitoringEvents = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Monitoring/Events'))),
  t => [
    {
      name: t('Monitoring'),
      url: '/dashboard/monitoring',
    },
    { name: t('Events') },
  ]
);
const MonitoringVitals = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Monitoring/Vitals'))),
  t => [{ name: t('Monitoring'), url: '/dashboard/monitoring' }, { name: t('Vitals') }]
);
const MonitoringNotifications = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Monitoring/Notifications/Notifications'))),
  t => [
    {
      name: t('Monitoring'),
      url: '/dashboard/monitoring',
    },
    { name: t('Notification Groups') },
  ]
);
const MonitoringEditNotifications = withHeaderBreadcrumb(
  withLazyComponent(
    lazy(() => import('pages/dashboard/Monitoring/Notifications/Components/EditNotification'))
  ),
  t => [
    {
      name: t('Monitoring'),
      url: '/dashboard/monitoring',
    },
    { name: t('Notification Groups') },
  ]
);

const MonitoringRoutes = (
  <Route path='monitoring' element={<DashboardLayout nav={NAV_MONITORING} />}>
    <Route index element={<Navigate to='/dashboard/monitoring/monitoring' replace />} />
    <Route path='monitoring' element={<Monitoring title='Monitoring page' />} />
    <Route path='events' element={<MonitoringEvents title='Monitoring Events' />} />
    <Route path='controls' element={<MonitoringControls title='Monitoring Controls' />} />
    <Route path='alarms' element={<MonitoringAlarms title='Monitoring Alarms' />} />
    <Route
      path='alarms/:uuid'
      element={<MonitoringAlarmDetails title='Monitoring Alarm Details' />}
    />
    <Route
      path='notifications'
      element={<MonitoringNotifications title='Monitoring Notifications' />}
    />
    <Route
      path='notifications/:uuid'
      element={<MonitoringEditNotifications title='Monitoring Notification Details' />}
    />
    <Route path='consumables' element={<MonitoringConsumables title='Monitoring Consumables' />} />
    <Route path='vitals' element={<MonitoringVitals title='Monitoring Vitals' />} />
    <Route path='*' element={<Navigate to='/dashboard/monitoring/monitoring' replace />} />
  </Route>
);

export default MonitoringRoutes;
