/**@format */
import { api } from 'services/bo/api';

export const frontdeskSettingsApi = api.injectEndpoints({
  endpoints: build => ({
    getFDSettings: build.query({
      query: () => `frontdesk/web/settings/client`,
      providesTags: ['FrontdeskWebSettings'],
    }),
    updateFDSettings: build.mutation({
      query: ({ body }) => ({
        url: `frontdesk/web/settings/client`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['FrontdeskWebSettings'],
    }),
  }),
});

export const { useGetFDSettingsQuery, useUpdateFDSettingsMutation, useLazyGetFDSettingsQuery } =
  frontdeskSettingsApi;
