/**@format */

import React, { Fragment, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';

import { classNames } from 'helpers';
import { modalPositionEnum } from 'constants/enum';

export const SideModal = ({
  isShow,
  onClose,
  onCloseWhenEsc,
  className,
  children,
  positionX = modalPositionEnum.RIGHT,
  isOverlay = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleKeyDown = useCallback(
    e => {
      if (e && e.code === 'Escape') onCloseWhenEsc && onCloseWhenEsc();
    },
    [onCloseWhenEsc]
  );

  useEffect(() => {
    /**
     * @CHEAT :V
     * @headless-ui/menu restores focus after closing a menu, to the button that opened it.
     * By slightly delaying opening the modal, react-modal's focus management won't be overruled.
     * {@link https://github.com/tailwindlabs/headlessui/issues/259}
     */
    setTimeout(() => {
      setIsOpen(isShow);
    }, 50);
  }, [isShow]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, isOpen]);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className={classNames('fixed top-0 z-50', {
          'right-0': positionX === modalPositionEnum.RIGHT,
          'left-0': positionX === modalPositionEnum.LEFT,
        })}
        onClose={handleClose}
      >
        <div className='min-h-screen'>
          {isOverlay && <div className='fixed inset-0 bg-black/20' />}
          <Transition.Child
            as={Fragment}
            enter='transition-all duration-500'
            enterFrom={classNames('transform', {
              'translate-x-full': positionX === modalPositionEnum.RIGHT,
              '-translate-x-full': positionX === modalPositionEnum.LEFT,
            })}
            enterTo={classNames('transform translate-x-0')}
            leave='transition-all duration-500'
            leaveFrom={classNames('transform translate-x-0')}
            leaveTo={classNames('transform', {
              'translate-x-full': positionX === modalPositionEnum.RIGHT,
              '-translate-x-full': positionX === modalPositionEnum.LEFT,
            })}
          >
            <Dialog.Panel
              data-testid='dialog-panel'
              className={classNames(
                'flex',
                'bg-white shadow-xl',
                'w-screen sm:w-[470px] h-screen',
                {
                  'px-6': !className?.includes('px-'),
                  'py-6': !className?.includes('py-'),
                },
                className
              )}
            >
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

SideModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onCloseWhenEsc: PropTypes.func,
  isOverlay: PropTypes.bool,
  positionX: PropTypes.oneOf([modalPositionEnum.LEFT, modalPositionEnum.RIGHT]),
};
