/** @format */

const dev = {
  passwordValidation: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})',
  usernameValidation:
    '^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$',
  apiGoogleMapKey: 'AIzaSyC0Lo24HraAyCXQ4eAgWLXdf2ovFPi0mzw',
  password_rules: [
    {
      labels: {
        es: 'Al menos 10, máximo 64 caracteres',
        'en-US': 'At least 10, max 64 characters',
        fr: 'Au moins 10, max 64 caractères',
      },
      pattern: '^[^\\s]{10,64}$',
    },
    {
      labels: {
        es: 'Una letra mayúscula',
        'en-US': 'An uppercase letter',
        fr: 'Une lettre majuscule',
      },
      pattern: '^(?=.*?[A-Z]).{1,}$',
    },
    {
      labels: {
        es: 'Una letra minúscula',
        'en-US': 'A lowercase letter',
        fr: 'Une lettre minuscule',
      },
      pattern: '^(?=.*?[a-z]).{1,}$',
    },
    {
      labels: {
        es: 'Un número y un carácter especial (! @ # % ^)',
        'en-US': 'A number and special character (! @ # % ^)',
        fr: 'Un chiffre et un caractère spécial (! @ # % ^)',
      },
      pattern:
        '^(.*?([\\!\\@\\#\\$\\%\\^\\?]+.*?[0-9]+)|(.*?[0-9]+.*?[\\!\\@\\#\\$\\%\\^\\?]+).*?)$',
    },
  ],
  uuid_rules: {
    labels: {
      es: 'El UUID no es válido',
      'en-US': 'UUID is not valid',
      fr: "UUID n'est pas valide",
    },
    pattern: /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/,
  },
  countries: {
    CA: {
      states: {
        AB: 'Alberta',
        BC: 'British Columbia',
        MB: 'Manitoba',
        NB: 'New Brunswick',
        NL: 'Newfoundland and Labrador',
        NS: 'Nova Scotia',
        NT: 'Northwest Territories',
        NU: 'Nunavut',
        ON: 'Ontario',
        PE: 'Prince Edward Island',
        QC: 'Quebec',
        SK: 'Saskatchewan',
        YT: 'Yukon',
      },
      is_default: true,
      countrycode: 1,
      display_label: 'Canada',
      phone_number_format: '(###) ###-####',
      phone_number_length: 10,
    },
    FR: {
      states: {
        10: 'Ain',
        13: 'Bouches du Rhône',
        25: 'Doubs',
      },
      countrycode: 33,
      display_label: 'France',
      phone_number_format: '##########',
      phone_number_length: 10,
    },
    US: {
      states: {
        AK: 'Alaska',
        AL: 'Alabama',
        AR: 'Arkansas',
        AZ: 'Arizona',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DC: 'District of Columbia',
        DE: 'Delaware',
        FL: 'Florida',
        GA: 'Georgia',
        IA: 'Iowa',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        MA: 'Massachusetts',
        MD: 'Maryland',
        ME: 'Maine',
        MI: 'Michigan',
        MN: 'Minnesota',
        MO: 'Missouri',
        MS: 'Mississippi',
        MT: 'Montana',
        NC: 'North Carolina',
        ND: 'North Dakota',
        NE: 'Nebraska',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NV: 'Nevada',
        NY: 'New York',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VA: 'Virginia',
        VT: 'Vermont',
        WA: 'Washington',
        WI: 'Wisconsin',
        WV: 'West Virginia',
        WY: 'Wyoming',
      },
      is_default: true,
      countrycode: 1,
      display_label: 'United States',
      phone_number_format: '(###) ###-####',
      phone_number_length: 10,
    },
    // FR: {
    //   countrycode: 33,
    //   display_label: 'France',
    //   phone_number_format: '##########',
    //   phone_number_length: 10,
    // },
  },
  languages: [
    { id: 1, name: 'English', lng: 'en-US' },
    { id: 2, name: 'Spanish', lng: 'es' },
    { id: 3, name: 'French', lng: 'fr' },
  ],
  parameters: { timezone: 'EST5EDT' },
  countryCode: [
    {
      countrycode: 1,
      display_label: 'Canada, United States',
      phone_number_format: '(###) ###-####',
      phone_number_length: 10,
    },
    {
      countrycode: 33,
      display_label: 'France',
      phone_number_format: '##########',
      phone_number_length: 10,
    },
  ],
};

const prod = {
  passwordValidation: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})',
  usernameValidation:
    '^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$',
  apiGoogleMapKey: 'AIzaSyC0Lo24HraAyCXQ4eAgWLXdf2ovFPi0mzw',
  password_rules: [
    {
      labels: {
        es: 'Al menos 10, máximo 64 caracteres',
        'en-US': 'At least 10, max 64 characters',
        fr: 'Au moins 10, max 64 caractères',
      },
      pattern: '^[^\\s]{10,64}$',
    },
    {
      labels: {
        es: 'Una letra mayúscula',
        'en-US': 'An uppercase letter',
        fr: 'Une lettre majuscule',
      },
      pattern: '^(?=.*?[A-Z]).{1,}$',
    },
    {
      labels: {
        es: 'Una letra minúscula',
        'en-US': 'A lowercase letter',
        fr: 'Une lettre minuscule',
      },
      pattern: '^(?=.*?[a-z]).{1,}$',
    },
    {
      labels: {
        es: 'Un número y un carácter especial (! @ # % ^)',
        'en-US': 'A number and special character (! @ # % ^)',
        fr: 'Un chiffre et un caractère spécial (! @ # % ^)',
      },
      pattern:
        '^(.*?([\\!\\@\\#\\$\\%\\^\\?]+.*?[0-9]+)|(.*?[0-9]+.*?[\\!\\@\\#\\$\\%\\^\\?]+).*?)$',
    },
  ],
  uuid_rules: {
    labels: {
      es: 'El UUID no es válido',
      'en-US': 'UUID is not valid',
      fr: "UUID n'est pas valide",
    },
    pattern: /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/,
  },
  countries: {
    CA: {
      states: {
        AB: 'Alberta',
        BC: 'British Columbia',
        MB: 'Manitoba',
        NB: 'New Brunswick',
        NL: 'Newfoundland and Labrador',
        NS: 'Nova Scotia',
        NT: 'Northwest Territories',
        NU: 'Nunavut',
        ON: 'Ontario',
        PE: 'Prince Edward Island',
        QC: 'Quebec',
        SK: 'Saskatchewan',
        YT: 'Yukon',
      },
      is_default: true,
      countrycode: 1,
      display_label: 'Canada',
      phone_number_format: '(###) ###-####',
      phone_number_length: 10,
    },
    FR: {
      states: {
        10: 'Ain',
        13: 'Bouches du Rhône',
        25: 'Doubs',
      },
      countrycode: 33,
      display_label: 'France',
      phone_number_format: '##########',
      phone_number_length: 10,
    },
    US: {
      states: {
        AK: 'Alaska',
        AL: 'Alabama',
        AR: 'Arkansas',
        AZ: 'Arizona',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DC: 'District of Columbia',
        DE: 'Delaware',
        FL: 'Florida',
        GA: 'Georgia',
        IA: 'Iowa',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        MA: 'Massachusetts',
        MD: 'Maryland',
        ME: 'Maine',
        MI: 'Michigan',
        MN: 'Minnesota',
        MO: 'Missouri',
        MS: 'Mississippi',
        MT: 'Montana',
        NC: 'North Carolina',
        ND: 'North Dakota',
        NE: 'Nebraska',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NV: 'Nevada',
        NY: 'New York',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VA: 'Virginia',
        VT: 'Vermont',
        WA: 'Washington',
        WI: 'Wisconsin',
        WV: 'West Virginia',
        WY: 'Wyoming',
      },
      is_default: true,
      countrycode: 1,
      display_label: 'United States',
      phone_number_format: '(###) ###-####',
      phone_number_length: 10,
    },
  },
  languages: [
    { id: 1, name: 'English', lng: 'en-US' },
    { id: 2, name: 'Spanish', lng: 'es' },
    { id: 3, name: 'French', lng: 'fr' },
  ],
  parameters: { timezone: 'EST5EDT' },
  countryCode: [
    {
      countrycode: 1,
      display_label: 'Canada, United States',
      phone_number_format: '(###) ###-####',
      phone_number_length: 10,
    },
    {
      countrycode: 33,
      display_label: 'France',
      phone_number_format: '##########',
      phone_number_length: 10,
    },
  ],
};

const config = process.env.NODE_ENV === 'production' ? prod : dev;

export default {
  ...config,
};
