/** @format */

import Modal from './Modal';
export * from './SideModal';
export * from './ConfirmModal';
export * from './PassLockModal';
export * from './FilterModal';
export * from './SearchingModal';
export * from './AccessModal';

export default Modal;
