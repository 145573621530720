/** @format */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  useGetSettingsQuery as useGetClientsSettingsQuery,
  useLazyGetSettingsQuery,
} from 'services/bo/client/settings';
import {
  useGetFDSettingsQuery,
  useLazyGetFDSettingsQuery,
} from 'services/bo/frontdeskWeb/frontdesk.settings';
import {
  useGetUsersSettingsQuery,
  useLazyGetUsersSettingsQuery,
} from 'services/bo/users/users.settings';
import {
  useGetVouchersSettingsQuery,
  useLazyGetVouchersSettingsQuery,
} from 'services/bo/vouchers/vouchers.settings';
import { meFeatures, selectUser, setUserData } from 'store/user/userSlice';
import { selectedClient, setClientData } from 'store/client/clientSlice';
import { usePermission, useToast } from 'hooks';
import { encodeBtoaStringParam, getMeVersion, getToken, responseError } from 'helpers';

import Toast from 'components/Toast';
import { ConfirmModal, PassLockModal } from 'components/Modal';

import {
  useLazyGetUserClientsDataQuery,
  useLazyGetUserPersonalDataQuery,
} from 'services/bo/users/users.me';

import Navigation from './Navigation';

const Main = ({ nav = [] }) => {
  const [navMenu, setNavMenu] = useState([]);
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { data: user } = useSelector(selectUser);
  const client = useSelector(selectedClient);
  const features = useSelector(meFeatures);

  const hasPermissionToViewFDWSettings = usePermission('frontdesk.web.settings.access');
  const isFrontdeskWebModule = useMemo(
    () => location.pathname.startsWith('/dashboard/frontdesk-web'),
    [location.pathname]
  );
  const isUsersModule = useMemo(
    () => location.pathname.startsWith('/dashboard/users'),
    [location.pathname]
  );
  const isVouchersModule = useMemo(
    () => location.pathname.startsWith('/dashboard/vouchers'),
    [location.pathname]
  );

  const [getMe] = useLazyGetUserPersonalDataQuery();
  const [getMeClients] = useLazyGetUserClientsDataQuery();
  const handleFetchAllUserData = async () => {
    try {
      const getMeParams = getMeVersion();
      const [responseMe, responseClients] = await Promise.all([getMe(getMeParams), getMeClients()]);
      dispatch(setUserData(responseMe?.data));
      dispatch(setClientData(responseClients?.data?.data));
    } catch (error) {
      responseError(error, toast, t, navigate, location);
    }
  };

  useEffect(() => {
    if (!getToken()) {
      return navigate('/');
    } else {
      location.state?.prevPath !== '/' && handleFetchAllUserData();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const [triggerGetClientsSettings] = useLazyGetSettingsQuery();
  const [triggerGetFDSettings] = useLazyGetFDSettingsQuery();
  const [triggerGetUsersSettings] = useLazyGetUsersSettingsQuery();
  const [triggerGetVouchersSettings] = useLazyGetVouchersSettingsQuery();

  /**
   * Currently cannot get menu settings after switching to another module.
   * Refetch and show settings menu after navigating to other modules.
   */
  useEffect(() => {
    switch (true) {
      case isFrontdeskWebModule && hasPermissionToViewFDWSettings:
        triggerGetFDSettings();
        break;
      case isUsersModule:
        triggerGetUsersSettings();
        break;
      case isVouchersModule:
        triggerGetVouchersSettings();
        break;
      default:
        triggerGetClientsSettings();
        break;
    }
  }, [isFrontdeskWebModule, hasPermissionToViewFDWSettings, isUsersModule, isVouchersModule]);

  /**
   * Temporarily use useGetClientsSettingsQuery to get the menu list of Frontdesk Web Settings and show it to users without permission 'frontdesk.web.settings.access'.
   * In the future, useGetClientsSettingsQuery (used to get root menu settings) will no longer be used.
   * Instead of each module, there will be a separate endpoint to get the settings menu list.
   */
  const useGetSettingsQuery = useMemo(() => {
    switch (true) {
      case isFrontdeskWebModule && hasPermissionToViewFDWSettings:
        return useGetFDSettingsQuery;
      case isUsersModule:
        return useGetUsersSettingsQuery;
      case isVouchersModule:
        return useGetVouchersSettingsQuery;
      default:
        return useGetClientsSettingsQuery;
    }
  }, [location?.pathname]);

  const {
    data: { data: usersSettings = [] } = {},
    isFetching: isGettingUsersSettings,
    isSuccess: isSuccessGettingUsersSettings,
    error: isSettingsError,
  } = useGetSettingsQuery();

  useEffect(() => {
    if (isSettingsError) responseError(isSettingsError, toast, t, navigate, location);
    if (isGettingUsersSettings) dispatch(showLoading());
    return () => dispatch(hideLoading());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isSettingsError, isGettingUsersSettings, location, navigate, t]);

  const checkAccessDenied = useCallback(
    item => {
      if (client?.external_id && user?.permissionsBySlug && usersSettings?.length > 0) {
        const permissionsAccess = item?.permissionSlug
          ? user?.permissionsBySlug[client?.external_id]?.includes(item?.permissionSlug)
          : true;
        let isAllowedSetting = usersSettings?.find(i => i.key === item?.settingKey)?.value ?? true;
        return !(permissionsAccess && (isAllowedSetting === null || isAllowedSetting));
      }
    },
    [client?.external_id, usersSettings, user?.permissionsBySlug]
  );

  useEffect(() => {
    if (!isGettingUsersSettings && isSuccessGettingUsersSettings) {
      const checkNavMenu = nav?.map(item => ({
        ...item,
        accessDenied: checkAccessDenied(item),
      }));
      const isAccessDeniedAll = checkNavMenu?.find(item => item?.accessDenied === false);
      if (
        !isAccessDeniedAll &&
        features?.length === 1 &&
        location.pathname.startsWith(`/dashboard/${features[0]?.slug}`)
      ) {
        /** Redirect to '/access-denied' when account has only 1 feature and doesn't have permissions to any sub tabs */
        navigate('/access-denied');
      }
      setNavMenu(checkNavMenu);
      const settingsLink = checkNavMenu.find(
        item => item.href.lastIndexOf('settings') !== -1
      )?.href;
      const prevCurrentMenu = checkNavMenu.filter(item => !item.href.includes('settings'));
      const currentMenu = prevCurrentMenu?.find(item => item.href === location.pathname);
      const firstMenuAccessible = prevCurrentMenu?.find(item => !item.accessDenied)?.href;
      if (currentMenu && currentMenu.accessDenied) {
        if (firstMenuAccessible) {
          navigate(firstMenuAccessible, { replace: true });
        } else if (settingsLink) {
          navigate(settingsLink, { replace: true });
        } else {
          const toastMessage = { title: 'Error', message: 'Permission error.' };
          navigate(
            `/dashboard/welcome?toastPermissionError=${window.btoa(JSON.stringify(toastMessage))}`
          );
        }
      }
    }
  }, [
    checkAccessDenied,
    isGettingUsersSettings,
    isSuccessGettingUsersSettings,
    location.pathname,
    navigate,
    nav,
  ]);

  const toastPermissionErrorParam = searchParams.get('toastPermissionError');
  useEffect(() => {
    if (toastPermissionErrorParam) {
      const toastData = encodeBtoaStringParam(toastPermissionErrorParam);
      if (toastData) {
        toast.error({
          title: [t(toastData?.title)],
          message: [t(toastData?.message)],
        });
        searchParams.delete('toastPermissionError');
        setSearchParams(searchParams);
      }
    }
  }, [searchParams, setSearchParams, t, toast, toastPermissionErrorParam]);

  return (
    <div className='sm:pl-16 font-inter'>
      <Navigation
        nav={navMenu}
        basicPageCount={nav?.length}
        isGettingSettings={isGettingUsersSettings}
      />
      <main
        className='flex-1 flex flex-col h-screen-main-container sm:h-screen scrollbar'
        style={{
          backgroundColor: '#FFFFFF',
        }}
      >
        {user && client && isSuccessGettingUsersSettings && <Outlet />}
      </main>
      <Toast />
      <ConfirmModal />
      <PassLockModal />
    </div>
  );
};

export default Main;
