/** @format */
import { createSlice } from '@reduxjs/toolkit';
import {
  TICKETING_CONCESSION_GENERAL_DATA,
  TICKETING_CONCESSION_PRODUCTS_DATA,
  TICKETING_CONCESSION_TICKETS_DATA,
  TICKETING_CONCESSION_TRANSLATIONS_DATA,
  TICKETING_CONCESSION_VALIDITY_DATA,
  TICKETING_CONCESSIONS_DATA,
} from 'data/data';

const initialState = {
  concessionsData: TICKETING_CONCESSIONS_DATA,
  generalTabData: TICKETING_CONCESSION_GENERAL_DATA,
  translationsTabData: TICKETING_CONCESSION_TRANSLATIONS_DATA,
  validityTabData: TICKETING_CONCESSION_VALIDITY_DATA,
  ticketsTabData: TICKETING_CONCESSION_TICKETS_DATA,
  productsTabData: TICKETING_CONCESSION_PRODUCTS_DATA,
};
export const concessionsSlice = createSlice({
  name: 'ticketingConcessions',
  initialState,
  reducers: {
    addConcession: (state, action) => {
      const addFareData = {
        ...action.payload,
        id: TICKETING_CONCESSIONS_DATA[TICKETING_CONCESSIONS_DATA.length - 1].id + 1,
      };
      state.concessionsData = [...state.concessionsData, addFareData];
    },
    addProduct: (state, action) => {
      const addProductData = {
        ...action.payload,
        id:
          TICKETING_CONCESSION_PRODUCTS_DATA[TICKETING_CONCESSION_PRODUCTS_DATA.length - 1].id + 1,
      };
      state.productsTabData = [...state.productsTabData, addProductData];
    },
    deleteProduct: (state, action) => {
      state.productsTabData = state.productsTabData.filter(item => {
        return item.id !== action.payload;
      });
    },
    editTranslation: (state, action) => {
      state.translationsTabData = state.translationsTabData.map(item =>
        item.id === action.payload.id ? { ...action.payload } : item
      );
    },
    editProduct: (state, action) => {
      state.productsTabData = state.productsTabData.map(item =>
        item.id === action.payload.id ? { ...action.payload } : item
      );
    },
    editValidity: (state, action) => {
      state.validityTabData = { ...action.payload };
    },
    editTickets: (state, action) => {
      state.ticketsTabData = { ...action.payload };
    },
    editGeneralData: (state, { payload }) => {
      state.generalTabData = { ...payload };
    },
    duplicateConcession: (state, action) => {
      const addProductData = {
        ...action.payload,
        id:
          TICKETING_CONCESSION_PRODUCTS_DATA[TICKETING_CONCESSION_PRODUCTS_DATA.length - 1].id + 1,
      };
      state.concessionsData = [...state.concessionsData, addProductData];
    },
  },
});
export const {
  addConcession,
  duplicateConcession,
  editGeneralData,
  editTranslation,
  editValidity,
  editProduct,
  addProduct,
  deleteProduct,
  editTickets,
} = concessionsSlice.actions;
export const selectConcessionsData = state => state.dashboard.ticketing.concessions.concessionsData;
export const selectGeneralData = state => state.dashboard.ticketing.concessions.generalTabData;
export const selectValidityTabData = state => state.dashboard.ticketing.concessions.validityTabData;
export const selectTicketsTabData = state => state.dashboard.ticketing.concessions.ticketsTabData;
export const selectTranslationsData = state =>
  state.dashboard.ticketing.concessions.translationsTabData;
export const selectProductsData = state => state.dashboard.ticketing.concessions.productsTabData;
export default concessionsSlice.reducer;
