/**@format */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { closeConfirm, openConfirm } from '../store/dashboard/globalConfirmSlice';
import { COLOR_GRAY, COLOR_RED } from '../constants/Constants';

let resolveCallback = () => {};
export const useConfirm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onConfirm = useCallback(
    data => {
      dispatch(closeConfirm());
      resolveCallback(data || true);
    },
    [dispatch]
  );
  const onCancel = useCallback(() => {
    dispatch(closeConfirm());
    resolveCallback(false);
  }, [dispatch]);

  const confirm = ({
    title = t('Are you sure?'),
    text = t('Are you sure you want to delete this item?'),
    modalClassName = '',
    primaryBtnText = t('Yes'),
    secondaryBtnText = t('No'),
    primaryBtnColor = COLOR_RED,
    secondaryBtnColor = COLOR_GRAY,
    primaryBtnClassName = '',
    secondaryBtnClassName = '',
    titleClassName = '',
    type = null,
  } = {}) => {
    dispatch(
      openConfirm({
        type,
        title,
        text,
        modalClassName,
        primaryBtnText,
        secondaryBtnText,
        primaryBtnColor,
        secondaryBtnColor,
        primaryBtnClassName,
        secondaryBtnClassName,
        titleClassName,
      })
    );
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };
  return { confirm, onConfirm, onCancel };
};
