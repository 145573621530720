/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';
import { EVENTS as NAV_EVENTS } from 'constants/Navigation';

import DashboardLayout from 'layout/DashboardLayout/Main';

const EventsCategories = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Events/Categories'))),
  t => [{ name: t('Categories') }]
);
const EventsNotifications = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Events/Notifications'))),
  t => [{ name: t('Events'), url: '/dashboard/events/events' }, { name: t('Event Notifications') }]
);

const EventsVenues = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Events/Venues'))),
  t => [{ name: t('Venues') }]
);

const EventsList = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Events/EventsList'))),
  t => [{ name: t('List of Events') }]
);
const EventDetails = withHeaderBreadcrumb(
  withLazyComponent(
    lazy(() => import('pages/dashboard/Events/EventsList/Components/EventsAllTabs'))
  ),
  t => [{ name: t('List of Events'), url: '/dashboard/events/events' }]
);
const EventEditTicket = withHeaderBreadcrumb(
  withLazyComponent(
    lazy(() =>
      import(
        'pages/dashboard/Events/EventsList/Components/ShowTimesTab/EditTicket/EditTicketAllTabs'
      )
    )
  ),
  t => [{ name: t('List of Events'), url: '/dashboard/events/events' }]
);
const EventsRoutes = (
  <Route path='events' element={<DashboardLayout nav={NAV_EVENTS} />}>
    <Route index element={<Navigate to='/dashboard/events/categories' replace />} />
    <Route path='categories' element={<EventsCategories title='Categories' />} />
    <Route path='notifications' element={<EventsNotifications title='Notifications' />} />
    <Route path='venues' element={<EventsVenues title='Venues' />} />
    <Route path='events' element={<EventsList title='List of Events' />} />
    <Route path='events/:id' element={<EventDetails title='Event Details' />} />
    <Route
      path='events/:id/details/:ticketId'
      element={<EventEditTicket title='Event Details' />}
    />
    <Route path='*' element={<Navigate to='/dashboard/events' replace />} />
  </Route>
);

export default EventsRoutes;
