/**@format */
import { api } from 'services/bo/api';

//!TODO Fake data
export const vouchersSettingsApi = api.injectEndpoints({
  endpoints: build => ({
    getVouchersSettings: build.query({
      query: () => `vouchers/settings/client`,
      providesTags: ['VouchersSettings'],
    }),
    updateVouchersSettings: build.mutation({
      query: ({ body }) => ({
        url: `vouchers/settings/client`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['VouchersSettings'],
    }),
  }),
});

export const {
  useGetVouchersSettingsQuery,
  useLazyGetVouchersSettingsQuery,
  useUpdateVouchersSettingsMutation,
} = vouchersSettingsApi;
