import { createSlice } from '@reduxjs/toolkit'

export const subUserSlice = createSlice({
    name: 'subUser',
    initialState: {
        data: null
    },
    reducers: {
        setSubUserData: (state, action) => {
            state.data = action.payload
        },
        updateSubUserData: (state, action) => {
            state.data = { ...(state.data || {}), ...action.payload }
        },
        clearSubUserData: (state) => {
            state.data = null
        },
    },
})
export const { updateSubUserData, setSubUserData, clearSubUserData } = subUserSlice.actions

export const selectSubUser = (state) => state.subUser.data

export default subUserSlice.reducer
