import { combineReducers } from "@reduxjs/toolkit";
import creditCardsReducer from "./CreditCards/creditCardsSlice";
import profilesReducer from "./Profiles/profilesSlice";
import passesReducer from "./Passes/passesSlice";
import patronReducer from "./General/patronSlice";

export const patronsReducer = combineReducers({
    creditCards: creditCardsReducer,
    profiles: profilesReducer,
    passes: passesReducer,
    patron: patronReducer,
})