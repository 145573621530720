/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { FARES as NAV_FARES } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const FaresSettings = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Fares/Settings'))),
  t => [{ name: t('Fare Manager'), url: '/dashboard/fares' }, { name: t('Settings') }]
);
const FaresManager = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Fares/Fares'))),
  t => [{ name: t('Fare Manager'), url: '/dashboard/fares' }, { name: t('Fares') }]
);
const FareEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Fares/Fares/FareEdit'))),
  t => [{ name: t('Fare Manager'), url: '/dashboard/fares' }, { name: t('Fares') }]
);

const FaresRoutes = (
  <Route path='fares' element={<DashboardLayout nav={NAV_FARES} />}>
    <Route index element={<Navigate to='/dashboard/fares/settings' replace />} />
    <Route path='settings' element={<FaresSettings title='Settings' />} />
    <Route path='fares' element={<FaresManager title='Fares' />} />
    <Route path='fares/:fareId' element={<FareEdit title='Fare Edit' />} />
    <Route path='*' element={<Navigate to='/dashboard/fares/settings' replace />} />
  </Route>
);

export default FaresRoutes;
