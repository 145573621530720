/**@format */
import { combineReducers } from '@reduxjs/toolkit';
import { crmReducer } from './CRM';
import welcomeReducer from './dashboardSlice';
import globalConfirmReducer from './globalConfirmSlice';
import globalPassLockReducer from './globalPassLockSlice';
import { passesReducer } from './Passes';
import { revenueReducer } from 'store/dashboard/Revenue';
import { ticketingReducer } from './Ticketing';

export const dashboardReducer = combineReducers({
  welcome: welcomeReducer,
  crm: crmReducer,
  globalConfirm: globalConfirmReducer,
  globalPassLock: globalPassLockReducer,
  passes: passesReducer,
  revenue: revenueReducer,
  ticketing: ticketingReducer,
});
