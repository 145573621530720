/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { setTranslBundle } from 'translation/i18n';

import { LAST_TIME_TRANSLATIONS, PRELOAD_SRC_LIST, TRANSLATIONS } from 'constants/Constants';
import { fetchPermissions } from 'store/static/permissionsSlice';
import { fetchTranslations } from 'store/static/translationsSlice';

import { useImagePreloader } from 'hooks';
import { isJsonString, getActiveLangLabel } from 'helpers';
import { commonService } from 'services';

import Navigation from './navigation';
import ErrorBoundary from './ErrorBoundary';

import en from './translation/yupMessage_en.json';
import es from './translation/yupMessage_es.json';
import fr from './translation/yupMessage_fr.json';

const PLUGIN_LOCALES = {
  'en-US': en,
  es,
  fr,
};

// NOTE: We use this component so that ErrorBoundary will catch the error
const CatchErrorTranslations = ({ isError = false, children }) => {
  useEffect(() => {
    if (isError) {
      throw 'Error loading transaction keys';
    }
  }, [isError]);
  return children;
};

const Flowbird = () => {
  useImagePreloader(PRELOAD_SRC_LIST);
  const dispatch = useDispatch();
  const [isTranslationsReady, setIsTranslationsReady] = useState(false);
  const [isCatchError, setIsCatchError] = useState(false);

  const setTranslations = async () => {
    return dispatch(fetchTranslations()).then(res => {
      if (!res.error) {
        setIsTranslationsReady(true);
        const currentLng = getActiveLangLabel();
        Yup.setLocale(PLUGIN_LOCALES[currentLng]);
      } else {
        setIsCatchError(true);
      }
    });
  };

  const handleMissingTranslationKey = event => {
    const lastUpdateTime = localStorage.getItem(LAST_TIME_TRANSLATIONS);
    const updateInterval = 24 * 3600000; // 24 hours
    if (lastUpdateTime && Number(Date.now()) - Number(lastUpdateTime) < updateInterval) {
      console.error('MISSING TRANSLATION KEY:', event.detail);
    } else {
      setTimeout(() => {
        setIsTranslationsReady(false);
        setIsCatchError(false);
        setTranslations().then(() => {
          localStorage.setItem(LAST_TIME_TRANSLATIONS, Date.now());
        });
      });
    }
  };

  useEffect(() => {
    const fetchPermissionsAndTranslations = async () => {
      await dispatch(fetchPermissions());
      if (
        localStorage.getItem(TRANSLATIONS) === null ||
        !isJsonString(localStorage.getItem(TRANSLATIONS)) ||
        !JSON.parse(localStorage.getItem(TRANSLATIONS))?.['en']
      ) {
        setTranslations();
        // NOTE: If the translations are not in the cache, we do not need to make a duplicate request to the server.
        localStorage.setItem(LAST_TIME_TRANSLATIONS, Date.now());
      } else {
        setTranslBundle(JSON.parse(localStorage.getItem(TRANSLATIONS)));
        const currentLng = getActiveLangLabel();
        Yup.setLocale(PLUGIN_LOCALES[currentLng]);
        setIsTranslationsReady(true);
      }
    };
    fetchPermissionsAndTranslations();
    window.addEventListener('onMissingTranslationKey', handleMissingTranslationKey);

    commonService
      .getAppVersion()
      .then(data => {
        // Print App version to console
        console.info(
          `%cWelcome to FLOWBIRD HUB\n\n%cBuild: ${data?.data?.appVersion}`,
          'font-size: 20px; font-weight: 500;',
          'font-size: 14px;'
        );
      })
      .catch(err => {
        console.error(err);
      });

    return () => {
      window.removeEventListener('onMissingTranslationKey', handleMissingTranslationKey);
    };
  }, []);

  return (
    <ErrorBoundary>
      <CatchErrorTranslations isError={isCatchError}>
        {isTranslationsReady && <Navigation />}
      </CatchErrorTranslations>
    </ErrorBoundary>
  );
};

export default Flowbird;
