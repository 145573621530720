/**@format */

import React, { useCallback, useEffect, useState } from 'react';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { ShoppingBagIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';
import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useDeleteCartMutation } from 'services/bo/frontdeskWeb/frontdesk.cart';
import { isReduxShowLoadingTrigger } from 'store/dashboard/globalSelectors';
import { clearCartStorage, selectCartUUID } from 'store/cart/cartSlice';
import { useComponentVisible, useConfirm, useToast } from 'hooks';
import { classNames, responseError } from 'helpers';
import { COLOR_GRAY } from 'constants/Constants';

import Button from 'components/Button';

import BodyCartView from './Components/BodyCartView';
import LoaderCartItemView from './Components/Loaders/LoaderCartItemView';

const Bag = ({
  isShow = false,
  onClose = () => {},
  className,
  total,
  cartItems = {},
  isGettingData = false,
}) => {
  const [isRemovedItem, setIsRemovedItem] = useState(false);
  const currentCartUUID = useSelector(selectCartUUID);
  const isLoadingData = useSelector(isReduxShowLoadingTrigger);
  const { confirm } = useConfirm();
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch();
  const location = useLocation();
  const ref = useComponentVisible(onClose);
  const navigate = useNavigate();

  const [deleteAllItemsCart, { isSuccess: isSuccessDeleting, reset: isResetDeleting }] =
    useDeleteCartMutation();
  const handleDeleteAllItems = async () => {
    const isConfirmed = await confirm({
      text: t('Are you sure you want to delete those items?'),
    });
    try {
      dispatch(showLoading());
      if (isConfirmed) {
        await deleteAllItemsCart({ cartUuid: currentCartUUID }).unwrap();
      }
    } catch (err) {
      responseError(err, toast, t, navigate, location);
    }
    dispatch(hideLoading());
  };

  const handleRemoveItemAction = useCallback(() => setIsRemovedItem(prev => !prev), []);

  useEffect(() => {
    if (isSuccessDeleting && !isGettingData) {
      toast.success({
        title: t('Success'),
        message: t('All Items removed successfully'),
      });
      isResetDeleting();
      onClose && onClose();
    } else if (isRemovedItem && !isGettingData) {
      toast.success({
        title: t('Success'),
        message: t('Item removed successfully'),
      });
      !cartItems && onClose && onClose();
      setIsRemovedItem(false);
    }
  }, [cartItems, isGettingData, isRemovedItem, isSuccessDeleting, t, toast]);

  return isShow ? (
    <div
      ref={ref}
      className={classNames(
        'absolute z-30',
        'px-2',
        'w-screen max-w-[360px] md:max-w-sm',
        'md:px-0 md:mr-5',
        className
      )}
    >
      <div
        className={classNames(
          'absolute',
          '-mt-2',
          'right-[26px] sm:right-[calc(8%+5px)] md:right-1/2'
        )}
      >
        <div
          className={classNames(
            'w-4 h-4 ',
            'bg-white',
            'transform rotate-45',
            'mb-4',
            'shadow-lg',
            'rounded-sm',
            'ring-1 ring-black ring-opacity-5',
            'overflow-hidden'
          )}
        ></div>
      </div>
      <div
        className={classNames(
          'rounded-lg',
          'shadow-lg',
          'ring-1 ring-black ring-opacity-5',
          'overflow-hidden'
        )}
      >
        <div className={classNames('relative', 'bg-white')}>
          <div className={classNames('flex justify-between items-center', 'px-4 py-3')}>
            <div>
              <h2 className={classNames('text-gray-900 text-lg font-medium')}>{t('Bag')}</h2>
              <h3 className={classNames('text-gray-600 text-sm')}>
                {t('Sub-total')}: <span className='text-gray-700 font-medium'>{total}</span>
              </h3>
            </div>
            <XIcon onClick={onClose} className='w-5 text-gray-400 cursor-pointer' />
          </div>
          {cartItems && Object?.keys(cartItems)?.length > 0 ? (
            <>
              <div
                className={classNames(
                  'flex flex-col',
                  'max-h-[305px] min-h-[305px]',
                  'scrollbar',
                  'p-4',
                  'text-sm'
                )}
              >
                {isGettingData || isLoadingData ? (
                  <LoaderCartItemView />
                ) : (
                  <BodyCartView cartList={cartItems} onRemovedItem={handleRemoveItemAction} />
                )}
              </div>
              <div className={classNames('flex justify-end gap-2.5', 'px-6 py-3', 'bg-gray-50')}>
                <Button
                  outline
                  color={COLOR_GRAY}
                  title={t('Delete all items')}
                  onClick={handleDeleteAllItems}
                  disabled={isGettingData || isLoadingData}
                />
                <Button type='button' disabled={isGettingData || isLoadingData}>
                  <Link to='/dashboard/frontdesk-web/order-checkout'>{t('Checkout')}</Link>
                </Button>
              </div>
            </>
          ) : (
            <div className={classNames('p-4', 'flex flex-col items-center', 'min-h-[188px]')}>
              <ShoppingBagIcon
                className={classNames('shrink-0', 'w-14', 'text-gray-300')}
                aria-hidden='true'
              />
              <p className={classNames('text-gray-500 font-medium', 'my-3')}>{t('Bag is empty')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

Bag.PropsTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  cartItems: PropTypes.object,
  isGettingData: PropTypes.bool,
};

export default Bag;
