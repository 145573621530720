/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';
import { OPEN_PAYMENTS as NAV_OPEN_PAYMENTS } from 'constants/Navigation';

import DashboardLayout from 'layout/DashboardLayout/Main';

const OpenPaymentsStatistics = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/OpenPayments/Statistics'))),
  t => [{ name: t('Open Payments'), url: '/dashboard/open-payments' }, { name: t('Statistics') }]
);
const OpenPaymentsTaps = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/OpenPayments/Taps'))),
  t => [{ name: t('Open Payments'), url: '/dashboard/open-payments' }, { name: t('Taps') }]
);
const OpenPaymentsCases = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/OpenPayments/Cases'))),
  t => [{ name: t('Open Payments'), url: '/dashboard/open-payments' }, { name: t('Cases') }]
);

const OpenPaymentsCaseManage = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/OpenPayments/Cases/CaseManage'))),
  t => [{ name: t('Open Payments'), url: '/dashboard/open-payments' }, { name: t('Cases') }]
);

const OpenPaymentsPayments = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/OpenPayments/Payments'))),
  t => [{ name: t('Open Payments'), url: '/dashboard/open-payments' }, { name: t('Payments') }]
);

const OpenPaymentsInventory = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/OpenPayments/Inventory'))),
  t => [{ name: t('Open Payments'), url: '/dashboard/open-payments' }, { name: t('Inventory') }]
);

const OpenPaymentsInventorydManage = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/OpenPayments/Inventory/CreditCardManage'))),
  t => [
    { name: t('Open Payments'), url: '/dashboard/open-payments' },
    { name: t('Credit Card Details') },
  ]
);

const OpenPaymentsRoutes = (
  <Route path='open-payments' element={<DashboardLayout nav={NAV_OPEN_PAYMENTS} />}>
    <Route index element={<Navigate to='/dashboard/open-payments/taps' replace />} />
    <Route
      path='statistics'
      element={<OpenPaymentsStatistics title='Open Payments Statistics' />}
    />
    <Route path='taps' element={<OpenPaymentsTaps title='Open Payments Taps' />} />
    <Route path='cases' element={<OpenPaymentsCases title='Open Payments Cases' />} />
    <Route
      path='cases/:caseId'
      element={<OpenPaymentsCaseManage title='Open Payments Cases Manage' />}
    />
    <Route path='*' element={<Navigate to='/dashboard/open-payments/taps' replace />} />
    <Route path='payments' element={<OpenPaymentsPayments title='Payments' />} />
    <Route path='inventory' element={<OpenPaymentsInventory title='Inventory' />} />
    <Route
      path='inventory/:cardId'
      element={<OpenPaymentsInventorydManage title='Credit Card Details' />}
    />
  </Route>
);

export default OpenPaymentsRoutes;
