/** @format */

import React, { useState } from 'react';
import { XIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { classNames } from 'helpers';

import PersonalDataView from './Components/PersonalDataView';
import ResetPasswordView from './Components/ResetPasswordView';
import { personalDataViewModeEnum } from '../PersonalData/Constants/enum';

const PersonalDataModal = ({ onClose = () => {} }) => {
  const [view, seView] = useState(personalDataViewModeEnum.PATRON);

  return (
    <>
      <Button
        color='transparent'
        className={classNames(
          'h-fit p-0',
          'focus:rounded-md',
          'hover:bg-transparent',
          'focus:ring-offset-0 absolute right-5 top-5'
        )}
        onClick={onClose}
      >
        <XIcon className={classNames('w-6 h-6', 'text-gray-400')} />
      </Button>

      {view === personalDataViewModeEnum.PATRON && (
        <PersonalDataView
          onClose={onClose}
          onChangePassword={() => seView(personalDataViewModeEnum.RESET)}
        />
      )}
      {view === personalDataViewModeEnum.RESET && (
        <ResetPasswordView
          onClose={onClose}
          onBack={() => seView(personalDataViewModeEnum.PATRON)}
        />
      )}
    </>
  );
};

PersonalDataModal.PropsTypes = {
  onClose: PropTypes.func,
};
export default PersonalDataModal;
