/** @format */
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';
import { JUDGE_PORTAL as NAV_JUDGE_PORTAL } from 'constants/Navigation';

const JudgePortal = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/JudgePortal'))),
  t => [
    {
      name: t('Judge Portal'),
    },
  ]
);
const JudgePortalResult = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/JudgePortal/Results'))),
  t => [
    {
      name: t('Judge Portal'),
      url: '/dashboard/judge',
    },
    {
      name: t('Results'),
    },
  ]
);
const JudgePortalResultDetail = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/JudgePortal/ResultDetail'))),
  t => [
    {
      name: t('Judge Portal'),
      url: '/dashboard/judge',
    },
    {
      name: t('Results'),
    },
  ]
);

const JudgePortalRoutes = (
  <Route path='judge' element={<DashboardLayout nav={NAV_JUDGE_PORTAL} />}>
    <Route index element={<JudgePortal title='Transaction Lookup' />} />
    <Route path='results' element={<JudgePortalResult title='Transactions Lookup Results' />} />
    <Route
      path='results/:transactionId'
      element={<JudgePortalResultDetail title='Transactions Lookup Result Detail' />}
    />
  </Route>
);

export default JudgePortalRoutes;
