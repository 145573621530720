/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { PASSES as NAV_PASSES } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const PassesSettings = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Passes/Settings'))),
  t => [{ name: t('Passes'), url: '/dashboard/passes' }, { name: t('Passes settings') }]
);
const PassesStatistics = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Passes/Statistics'))),
  t => [{ name: t('Passes'), url: '/dashboard/passes' }, { name: t('Statistics') }]
);
const PassesInventory = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Passes/Inventory'))),
  t => [
    {
      name: t('Passes'),
      url: '/dashboard/passes',
    },
    { name: t('Inventory') },
  ]
);
const PassInformation = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Passes/Inventory/PassInformation'))),
  t => [
    {
      name: t('Passes'),
      url: '/dashboard/passes',
    },
    { name: t('Inventory') },
  ]
);

const PassesRoutes = (
  <Route path='passes' element={<DashboardLayout nav={NAV_PASSES} />}>
    <Route index element={<Navigate to='/dashboard/passes/settings' replace />} />
    <Route path='settings' element={<PassesSettings title='Passes Settings' />} />
    <Route path='statistics' element={<PassesStatistics title='Passes Statistics' />} />
    <Route path='inventory' element={<PassesInventory title='Passes Inventory' />} />
    <Route path='inventory/:uuid' element={<PassInformation title='Pass information page' />} />
    <Route path='*' element={<Navigate to='/dashboard/passes/settings' replace />} />
  </Route>
);

export default PassesRoutes;
