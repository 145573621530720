/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { REVENUE as NAV_REVENUE } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const RevenueStatistics = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Revenue/Statistics'))),
  t => [{ name: t('Revenue Manager'), url: '/dashboard/revenue' }, { name: t('Statistics') }]
);
const RevenueCashCollection = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Revenue/CashCollection'))),
  t => [{ name: t('Revenue Manager'), url: '/dashboard/revenue' }, { name: t('Cash Collection') }]
);
const RevenueCashContentAnalysis = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Revenue/CashContentAnalysis'))),
  t => [
    { name: t('Revenue Manager'), url: '/dashboard/revenue' },
    { name: t('Cash Content Analysis') },
  ]
);
const RevenueDailyRevenue = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Revenue/DailyRevenue'))),
  t => [{ name: t('Revenue Manager'), url: '/dashboard/revenue' }, { name: t('Daily Revenue') }]
);
const RevenueReturnPolicy = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Revenue/ReturnPolicy'))),
  t => [{ name: t('Revenue Manager'), url: '/dashboard/revenue' }, { name: t('Return Policy') }]
);
const RevenueTransactionExceptions = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Revenue/TransactionExceptions'))),
  t => [
    { name: t('Revenue Manager'), url: '/dashboard/revenue' },
    { name: t('Transaction Exceptions') },
  ]
);
const RevenueTransactions = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Revenue/Transactions'))),
  t => [{ name: t('Revenue Manager'), url: '/dashboard/revenue' }, { name: t('Transactions') }]
);

const RevenueRoutes = (
  <Route path='revenue' element={<DashboardLayout nav={NAV_REVENUE} />}>
    <Route index element={<Navigate to='/dashboard/revenue/statistics' replace />} />
    <Route path='statistics' element={<RevenueStatistics title='Revenue Statistics' />} />
    <Route path='daily-revenue' element={<RevenueDailyRevenue title='Daily Revenue' />} />
    <Route
      path='cash-content-analysis'
      element={<RevenueCashContentAnalysis title='Revenue Cash Content Analysis' />}
    />
    <Route
      path='cash-collection'
      element={<RevenueCashCollection title='Revenue Cash Collection' />}
    />
    <Route path='transactions' element={<RevenueTransactions title='Revenue Transactions' />} />
    <Route
      path='transaction-exceptions'
      element={<RevenueTransactionExceptions title='Revenue Transaction Exceptions' />}
    />
    <Route path='return-policy' element={<RevenueReturnPolicy title='Revenue Return Policy' />} />
    <Route path='*' element={<Navigate to='/dashboard/revenue/statistics' replace />} />
  </Route>
);

export default RevenueRoutes;
