/**@format */
import PropTypes from 'prop-types';
import React from 'react';

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_GREEN,
  COLOR_LIGHT_GREEN,
  COLOR_ORANGE,
  COLOR_RED,
  COLOR_YELLOW,
  COLOR_LIGHT_YELLOW,
  COLOR_AMBER,
} from 'constants/Constants';
import { classNames } from 'helpers';

export const EllipseMarker = ({ className, color = 'gray', ...props }) => {
  return (
    <span
      className={classNames(
        'rounded-full',
        {
          'w-2': !className?.includes('w-'),
          'h-2': !className?.includes('h-'),
        },
        {
          'bg-green-600': color === COLOR_GREEN,
          'bg-green-500': color === COLOR_LIGHT_GREEN,
          'bg-yellow-600': color === COLOR_YELLOW,
          'bg-yellow-500': color === COLOR_LIGHT_YELLOW,
          'bg-blue-600': color === COLOR_BLUE,
          'bg-red-600': color === COLOR_RED,
          'bg-gray-400': color === COLOR_GRAY,
          'bg-orange-400': color === COLOR_ORANGE,
          'bg-amber-500': color === COLOR_AMBER,
        },
        className
      )}
      {...props}
    ></span>
  );
};

EllipseMarker.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    COLOR_BLUE,
    COLOR_GRAY,
    COLOR_GREEN,
    COLOR_LIGHT_GREEN,
    COLOR_RED,
    COLOR_YELLOW,
    COLOR_LIGHT_YELLOW,
    COLOR_ORANGE,
    COLOR_AMBER,
  ]),
};
