/**@format */
import React from 'react';

import { classNames } from 'helpers';

const LoaderCartItemView = () => (
  <div className={classNames('flex flex-col', 'pl-4 pr-4', 'gap-4')}>
    <div className={classNames('flex')}>
      <div className={classNames('flex flex-col grow', 'py-1', 'gap-2', 'animate-pulse')}>
        <span className={classNames('w-[180px] h-[18px]', 'rounded-md', 'bg-gray-100')} />
        <span className={classNames('w-[220px] h-[18px]', 'rounded-md', 'bg-gray-100')} />
        <span className={classNames('w-[50px] h-[18px]', 'rounded-md', 'bg-gray-100')} />
      </div>
      <span className={classNames('w-[25px] h-[18px]', 'rounded-md', 'bg-gray-100')} />
    </div>
    <div className={classNames('flex')}>
      <div className={classNames('flex flex-col grow', 'py-1', 'gap-2', 'animate-pulse')}>
        <span className={classNames('w-[180px] h-[18px]', 'rounded-md', 'bg-gray-100')} />
        <span className={classNames('w-[220px] h-[18px]', 'rounded-md', 'bg-gray-100')} />
        <span className={classNames('w-[50px] h-[18px]', 'rounded-md', 'bg-gray-100')} />
      </div>
      <span className={classNames('w-[25px] h-[18px]', 'rounded-md', 'bg-gray-100')} />
    </div>
    <div className={classNames('flex')}>
      <div className={classNames('flex flex-col grow', 'py-1', 'gap-2', 'animate-pulse')}>
        <span className={classNames('w-[180px] h-[18px]', 'rounded-md', 'bg-gray-100')} />
        <span className={classNames('w-[220px] h-[18px]', 'rounded-md', 'bg-gray-100')} />
        <span className={classNames('w-[50px] h-[18px]', 'rounded-md', 'bg-gray-100')} />
      </div>
      <span className={classNames('w-[25px] h-[18px]', 'rounded-md', 'bg-gray-100')} />
    </div>
  </div>
);

export default LoaderCartItemView;
