/** @format */
import { createSlice } from '@reduxjs/toolkit';

import { getLocalStorageByKey } from 'helpers/store-helpers';
import { CLIENT, STORE_CLIENTS } from 'constants/Constants';

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    clientsList: getLocalStorageByKey(STORE_CLIENTS),
    selectedClient: getLocalStorageByKey(CLIENT),
  },
  reducers: {
    setClientData: (state, action) => {
      const clients = action.payload;
      if (clients?.length > 0) {
        const storageClient = getLocalStorageByKey(CLIENT);
        const clientsExists = clients.find(client => client.name === storageClient?.name);
        const selectedClient =
          !clientsExists || !storageClient || clients?.length === 1 ? clients[0] : clientsExists;
        localStorage.setItem(CLIENT, JSON.stringify(selectedClient));
        localStorage.setItem(STORE_CLIENTS, JSON.stringify(clients));
        state.selectedClient = selectedClient;
        state.clientsList = clients;
      }
    },
    setSelectedClient: (state, action) => {
      localStorage.setItem(CLIENT, JSON.stringify(action.payload));
      state.selectedClient = action.payload;
    },
    clearClientData: state => {
      state.clientsList = null;
    },
  },
});
export const { setClientData, clearClientData, setSelectedClient } = clientSlice.actions;

export const selectClient = state => state.client.clientsList;
export const selectedClient = state => state.client.selectedClient;

export default clientSlice.reducer;
