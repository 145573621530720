/** @format */

import { history } from 'navigation/appHistory';

export const getLocalStorageByKey = key => {
  try {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
  } catch (e) {
    history.push('/session-expired');
  }
};
