/**@format */
import { createSlice } from '@reduxjs/toolkit';
import { CMR_PATRONS_PROFILES_DATA } from '../../../../../data/data';
import { toOnlyDate } from '../../../../../helpers';

const initialState = {
  profilesList: CMR_PATRONS_PROFILES_DATA,
};

export const profilesSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    deleteProfile: (state, { payload }) => {
      state.profilesList = state.profilesList.filter(item => item.id !== payload);
    },
    addNewProfile: (state, { payload }) => {
      state.profilesList.push({
        ...payload,
        id: state.profilesList[state.profilesList.length - 1].id + 1,
      });
    },
    editProfile: (state, { payload }) => {
      return {
        profilesList: state.profilesList.map(item => {
          if (item.id === payload.id)
            return {
              ...item,
              ...payload,
            };
          return item;
        }),
      };
    },
    resetProfilesData: () => initialState,
    filterProfilesData: (state, action) => {
      const { startDate, endDate, media, program, isValid, partner } = action.payload;
      if (startDate || endDate || media || program || isValid || partner) {
        const isValidStatus = isValid === 'Valid';
        state.profilesList = initialState.profilesList?.filter(item => {
          const startDefaultDate = toOnlyDate(+item?.startDate);
          const startDateType = toOnlyDate(+startDate);
          const startDateFilter = startDate ? startDateType <= startDefaultDate : true;
          const endDateFilter = endDate ? toOnlyDate(+item?.endDate) <= toOnlyDate(+endDate) : true;

          const mediaFilter = media ? item?.media.some(key => key.name.includes(media)) : true;
          const programFilter = program ? item?.program.name.includes(program) : true;
          const partnerFilter = partner ? item?.partner.name.includes(partner) : true;
          const isValidFilter = isValid ? item?.isPartnerValid === isValidStatus : true;

          return (
            startDateFilter &&
            endDateFilter &&
            mediaFilter &&
            programFilter &&
            isValidFilter &&
            partnerFilter
          );
        });
      }
    },
  },
});

export const { deleteProfile, addNewProfile, editProfile, filterProfilesData, resetProfilesData } =
  profilesSlice.actions;
export const selectProfileListData = state => state.dashboard.crm.patrons.profiles.profilesList;
export default profilesSlice.reducer;
