/**@format */
import React, { useEffect, useRef, useState } from 'react';
import { IMaskInput, IMask } from 'react-imask';

import { classNames } from 'helpers';

import { RectangleSwitch } from 'components/RectangleSwitch';

const getPeriodTime = time => {
  let period = time?.split(' ')?.[1];
  return ['AM', 'PM'].includes(time?.split(' ')[1]) ? period : 'AM';
};

export const TimeTextField = ({
  id,
  className,
  placeholder,
  required,
  value,
  onChange,
  onBlur,
  overwrite,
  isVertical = true,
  disabled = false,
  showSecond = false,
  ...props
}) => {
  const maskTextFieldRef = useRef(null);
  const textFieldRef = useRef(null);
  const [period, setPeriod] = useState(getPeriodTime(value));

  const iMaskPattern = showSecond ? 'hh:mm:ss' : 'hh:mm';
  const iMaskTimeBlocks = showSecond
    ? {
        hh: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 12,
          placeholderChar: 'H',
        },
        mm: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
          placeholderChar: 'M',
        },
        ss: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
          placeholderChar: 'S',
        },
      }
    : {
        hh: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 12,
          placeholderChar: 'H',
        },
        mm: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
          placeholderChar: 'M',
        },
      };

  const handleSwitchChange = item => {
    const periodValue = item ? 'PM' : 'AM';
    setPeriod(periodValue);
    if (value.length > 0) {
      handleChange({ target: { id, value: `${value.split(' ')[0]} ${periodValue}` } });
    }
  };
  const handleChange = e => {
    onChange && onChange(e);
  };
  const handleIMaskAccept = (_, mask) => {
    if (!mask.typedValue && !mask.unmaskedValue) handleChange({ target: { id, value: '' } });
  };

  const handleIMaskComplete = (_, mask) => {
    if (mask.typedValue) {
      const newValue = `${mask.typedValue} ${period}`;
      handleChange({ target: { id, value: newValue } });
    }
  };

  useEffect(() => {
    if (value && maskTextFieldRef.current.maskValue !== value) {
      maskTextFieldRef.current.maskValue = value;
    } else if (value?.length === 0) {
      maskTextFieldRef.current.maskValue = '';
    }
  }, [iMaskPattern, value]);

  return (
    <div className='relative'>
      <IMaskInput
        id={id}
        type='text'
        mask={Date}
        pattern={iMaskPattern}
        ref={maskTextFieldRef}
        inputRef={el => {
          textFieldRef.current = el;
        }}
        lazy={false}
        blocks={iMaskTimeBlocks}
        format={date => date}
        parse={str => str}
        onAccept={handleIMaskAccept}
        onComplete={handleIMaskComplete}
        onBlur={onBlur}
        className={classNames({ 'text-gray-400': !value }, className)}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        overwrite={overwrite ?? 'shift'}
        {...props}
      />

      <div className={classNames('absolute top-0 bottom-0 right-1 z-30', 'flex items-center')}>
        <RectangleSwitch
          disabled={disabled}
          isChecked={period === 'PM'}
          isVertical={isVertical}
          onChange={handleSwitchChange}
          className='w-8 items-center'
          leftSwitchChild='AM'
          rightSwitchChild='PM'
          leftSwitchClassName={classNames('px-1 py-0.5', {
            'text-gray-900': period === 'AM',
          })}
          rightSwitchClassName={classNames('px-1 py-0.5', {
            'text-gray-900': period === 'PM',
          })}
        />
      </div>
    </div>
  );
};
