/** @format */

import axios from 'axios';

export const generalService = {
  checkManifest,
  getArtifact,
  getTranslations,
};

function checkManifest($body) {
  const requestOptions = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return axios.post(`/api/v1/manifests`, $body, requestOptions);
}
function getArtifact(id) {
  const requestOptions = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return axios.get(`/api/v1/artifacts/${id}`, requestOptions);
}

function getTranslations() {
  const requestOptions = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return axios.get(`/api/translations`, requestOptions);
}
