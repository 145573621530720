/**@format */
import React, { useEffect, useRef, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

import { classNames } from 'helpers';
import { labelErrorPositionEnum } from 'constants/enum';

import { Checkbox } from './Checkbox';
import { Tooltip } from '../Tooltip';

export const CheckBoxGroup = ({
  className,
  groupLabel,
  groupItems,
  isRequired,
  isError,
  labelError,
  onChange,
  onBlur,
  labelErrorPosition = labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT,
}) => {
  const labelErrorRef = useRef(null);
  const [isLabelErrorTruncated, setIsLabelErrorTruncated] = useState(false);

  useEffect(() => {
    if (isError)
      setIsLabelErrorTruncated(
        labelErrorRef.current?.offsetWidth < labelErrorRef.current?.scrollWidth
      );
  }, [isError]);

  return (
    <div className={classNames('flex flex-col', 'relative', className)}>
      <div className='flex'>
        <span className='text-sm text-gray-500 font-medium'>{groupLabel}</span>
        {isRequired && <span className='text-sm text-red-600 font-medium'>*</span>}
      </div>
      {isError && (
        <div
          className={classNames('flex items-center', 'w-full', 'absolute z-20', {
            'top-0 right-0 mt-2 mr-3':
              labelErrorPosition === labelErrorPositionEnum.INSIDE_TOP_RIGHT,
            '-bottom-5 left-0': labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_LEFT,
            '-bottom-5 right-0': labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT,
            '-top-5 left-0': labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_LEFT,
            '-top-5 right-0': labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_RIGHT,
          })}
        >
          <div className={classNames('relative', 'w-full', 'flex')}>
            <p
              ref={labelErrorRef}
              className={classNames(
                'grow',
                'w-full',
                'text-xs text-red-500 font-medium',
                'whitespace-nowrap',
                'overflow-hidden ',
                'text-ellipsis',
                {
                  'text-right':
                    labelErrorPosition === labelErrorPositionEnum.INSIDE_TOP_RIGHT ||
                    labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT ||
                    labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_RIGHT,
                }
              )}
            >
              {labelError}
            </p>
            {isLabelErrorTruncated && (
              <Tooltip
                icon={<ExclamationCircleIcon className='w-4 fill-red-500' />}
                text={labelError}
              />
            )}
          </div>
        </div>
      )}
      <div className={classNames('flex flex-col', 'gap-5', 'mt-3.5')}>
        {groupItems?.map((item, idx) => (
          <div
            key={`cbox-group-item-${item.id ?? idx}`}
            className={classNames('flex items-center', 'gap-4')}
          >
            <Checkbox name={item.name} onChange={onChange} onBlur={onBlur} value={item.value} />
            <span className='text-sm text-gray-500 font-medium'>{item.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

CheckBoxGroup.propTypes = {
  groupItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  groupLabel: PropTypes.string,
  isError: PropTypes.bool,
  isRequired: PropTypes.bool,
  labelError: PropTypes.string,
  labelErrorPosition: PropTypes.oneOf([...Object.values(labelErrorPositionEnum)]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};
