/** @format */

import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    preload: ['en-US', 'es', 'fr'],
    supportedLngs: ['en-US', 'es', 'fr'],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    saveMissing: true,
    missingKeyHandler: (languages, namespace, key) => {
      const detail = { languages, namespace, key };
      const customEvent = new CustomEvent('onMissingTranslationKey', { detail });
      window.dispatchEvent(customEvent);
    },
  });
export default i18n;

export const setTranslBundle = data => {
  if (Object.keys(data).length > 0) {
    Object.keys(data).forEach(lang => {
      const lang_obj = {
        'en': 'en-US',
        'es': 'es',
        'fr': 'fr',
      };
      i18n.addResourceBundle(lang_obj[lang] ?? 'en-US', 'translation', data[lang]);
    });
  }
};
