/** @format */

import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { classNames } from 'helpers';
import { COLOR_GRAY } from 'constants/Constants';

import Modal from 'components/Modal';
import Button from 'components/Button';

export const FilterModal = ({
  isShow,
  onClose,
  className,
  classNameChildren,
  formik = {},
  formId,
  disabledFilter,
  title = 'Filter data using the following fields.',
  children,
}) => {
  const { t } = useTranslation();

  const getIsFilterDisabled = () => {
    if (disabledFilter !== undefined) {
      return disabledFilter;
    }
    return !formik.dirty || formik.isSubmitting || !formik.isValid;
  };

  return (
    <Modal
      className={classNames('w-full sm:min-w-[420px]', className)}
      isShow={isShow}
      onCloseWhenEsc={onClose}
      onResetForm={formik.resetForm}
    >
      <div className='flex flex-col w-full h-fit'>
        <div className='flex p-0'>
          <div className='flex flex-col grow gap-[3px]'>
            <h1 className='text-lg font-medium text-gray-900'>{t('Custom filters')}</h1>
            <p className='text-sm text-gray-500'>{t(title)}</p>
          </div>

          <Button
            color='transparent'
            className='h-fit p-0 hover:bg-transparent focus:rounded-md focus:ring-offset-0'
            onClick={onClose}
          >
            <XIcon className='w-6 text-gray-400' />
          </Button>
        </div>

        <form id={formId} autoComplete='off' onSubmit={formik.handleSubmit}>
          <div
            className={classNames(
              'flex flex-col grow w-full',
              'gap-7',
              {
                'pt-7': !classNameChildren?.includes('pt-'),
                'pb-7': !classNameChildren?.includes('pb-'),
              },
              classNameChildren
            )}
          >
            {children}
          </div>
          <Modal.Footer>
            <Button onClick={onClose} color={COLOR_GRAY} outline>
              {t('Cancel')}
            </Button>
            <Button type='submit' form={formId} disabled={getIsFilterDisabled()}>
              {t('Apply')}
            </Button>
          </Modal.Footer>
        </form>
      </div>
    </Modal>
  );
};

FilterModal.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.string,
  classNameChildren: PropTypes.string,
  formik: PropTypes.object,
  formId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
  ]),
  disabledFilter: PropTypes.bool,
};
