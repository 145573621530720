/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';
import { CONTROL_LISTS as NAV_CONTROL_LISTS } from 'constants/Navigation';

import DashboardLayout from 'layout/DashboardLayout/Main';

const ControlListsSettings = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/ControlLists/Settings'))),
  t => [
    { name: t('Control Lists'), url: '/dashboard/control-lists/list-content' },
    { name: t('Configuration') },
  ]
);

const ControlListsListContent = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/ControlLists/ListContent'))),
  t => [
    { name: t('Control Lists'), url: '/dashboard/control-lists/list-content' },
    { name: t('List Content') },
  ]
);

const ControlListsAuditTrial = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/ControlLists/AuditTrial'))),
  t => [
    { name: t('Control Lists'), url: '/dashboard/control-lists/list-content' },
    { name: t('Audit Trial') },
  ]
);

const ControlListsSupervision = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/ControlLists/Supervision'))),
  t => [
    { name: t('Control Lists'), url: '/dashboard/control-lists/list-content' },
    { name: t('Supervision') },
  ]
);

const ControlListsListGeneration = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/ControlLists/ListGeneration'))),
  t => [
    { name: t('List Generation'), url: '/dashboard/control-lists/list-generation' },
    { name: t('Supervision') },
  ]
);

const ControlListsRoutes = (
  <Route path='control-lists' element={<DashboardLayout nav={NAV_CONTROL_LISTS} />}>
    <Route index element={<Navigate to='/dashboard/control-lists/list-content' replace />} />
    <Route path='settings' element={<ControlListsSettings title='Configuration page' />} />
    <Route path='list-content' element={<ControlListsListContent title='Deployments page' />} />
    <Route path='audit-trial' element={<ControlListsAuditTrial title='Audit Trial' />} />
    <Route path='supervision' element={<ControlListsSupervision title='Supervision page' />} />
    <Route
      path='list-generation'
      element={<ControlListsListGeneration title='List Generation' />}
    />
    <Route path='*' element={<Navigate to='/dashboard/control-lists/list-content' replace />} />
  </Route>
);

export default ControlListsRoutes;
