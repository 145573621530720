/** @format */
import { useSelector } from 'react-redux';

import { selectedClient } from 'store/client/clientSlice';
import { selectUserPermissionsBySlug } from 'store/user/userSlice';

export const usePermission = slug => {
  const userPermissionsBySlug = useSelector(selectUserPermissionsBySlug);
  const currentClient = useSelector(selectedClient);
  const isAllow = userPermissionsBySlug[currentClient?.external_id]?.includes(slug);
  return isAllow;
};
