/**@format */
import React, { useEffect, useRef, useState } from 'react';
import { ClockIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

import { classNames } from 'helpers';

import { TimePicker } from './PickerComponents/TimePicker';
import { datetimePickerPosition } from '../constants/enum';

export const TimePickerTextField = ({
  id,
  className,
  required,
  placeholder,
  value,
  onChange,
  onBlur,
  positionX = datetimePickerPosition.LEFT,
  positionY = datetimePickerPosition.BOTTOM,
}) => {
  const { t } = useTranslation();
  const textFieldRef = useRef(null);
  const textFieldHeight = (textFieldRef?.current?.offsetHeight ?? 0) + 'px';

  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);

  const positionDropdownStyle = {
    defaultClassName: classNames(
      positionY === datetimePickerPosition.TOP && 'mb-2',
      positionY === datetimePickerPosition.BOTTOM && 'top-0 mt-2',
      positionX === datetimePickerPosition.LEFT && 'left-0',
      positionX === datetimePickerPosition.RIGHT && 'right-0'
    ),
    positionTopInlineStyle:
      positionY === datetimePickerPosition.TOP ? { bottom: textFieldHeight } : {},
  };

  const handleToggleTimepicker = () => {
    setIsTimePickerOpen(prevState => !prevState);
  };
  const handleChange = timeObj => {
    const timeString = `${timeObj.hour}:${timeObj.min}:${timeObj.second} ${timeObj.period}`;
    onChange && onChange({ target: { id, value: timeString } });
    handleToggleTimepicker();
  };

  useEffect(() => {
    if (value) {
      textFieldRef.current.value = value;
    } else {
      textFieldRef.current.value = '';
    }
  }, [value]);

  return (
    <>
      <input
        id={id}
        type='text'
        ref={textFieldRef}
        className={className}
        placeholder={placeholder}
        required={required}
        readOnly
        onBlur={onBlur}
      />
      <div className='w-full relative'>
        {isTimePickerOpen && (
          <TimePicker
            defaultValue={value}
            onBottomButtonClick={handleChange}
            positionDropdownStyle={positionDropdownStyle}
            bottomButtonChildren={
              <>
                <ClockIcon className='w-5 stroke-gray-400' />
                {t('Ok')}
              </>
            }
          />
        )}
      </div>
      <button
        type='button'
        className={classNames('absolute', 'inset-y-0 right-3', 'my-auto', 'h-fit', 'z-20')}
        onClick={handleToggleTimepicker}
      >
        <ClockIcon
          className={classNames('stroke-gray-400', 'w-5 h-5', 'hover:stroke-indigo-600')}
        />
      </button>
    </>
  );
};
