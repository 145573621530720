/** @format */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import { classNames } from 'helpers';
import { COLOR_GRAY } from 'constants/Constants';
import ComboboxSelectList from 'components/ComboboxSelectList';
import { selectedClient, setSelectedClient } from 'store/client/clientSlice';
import { useComponentVisible, useToast } from 'hooks';
import { api } from 'services/bo/api';

const SwitchClientModal = ({ onClose = () => {}, showModal = false, clients = [] }) => {
  const [client, setClient] = useState(null);
  const { t } = useTranslation();
  const defaultClient = useSelector(selectedClient);
  const dataList = clients?.filter(item => item.name !== defaultClient?.name) || [];
  const toast = useToast();
  const ref = useComponentVisible(onClose);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSetLoginName = data => {
    setClient(data);
  };

  const handleSubmitSwitchClient = event => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(api.util.resetApiState());
    dispatch(setSelectedClient(client));
    toast.success({
      title: t('Success'),
      message: `Switch ${client?.name}`,
    });
    navigate('/dashboard/welcome');
    onClose();
  };

  return (
    <div
      ref={showModal ? ref : null}
      className={classNames(
        'absolute max-w-[404px] w-full h-[154px] bg-white',
        'left-0 top-[70px] sm:top-9 shadow-xl border border-solid border-gray-200 rounded-lg p-4'
      )}
    >
      <form onSubmit={handleSubmitSwitchClient}>
        <ComboboxSelectList
          onSelectValue={handleSetLoginName}
          dataList={dataList}
          placeholder={defaultClient?.name ?? t('Select Client')}
          label={t('Switch Client')}
        />
        <div className='mt-5 flex justify-end space-x-2.5'>
          <Button title={t('Cancel')} outline color={COLOR_GRAY} type='button' onClick={onClose} />
          <Button type='submit' disabled={!client} title={t('Switch')} />
        </div>
      </form>
    </div>
  );
};

SwitchClientModal.PropsTypes = {
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default SwitchClientModal;
