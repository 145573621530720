/** @format */
import moment from 'moment-timezone';
import classnames from 'classnames';
import React, { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parsePhoneNumberFromString from 'libphonenumber-js';
import FileSaver from 'file-saver';
import Papa from 'papaparse';

import { paginateDefaultValues } from 'constants/enum';
import { openConfirm } from 'store/dashboard/globalPassLockSlice';
import { CLIENT, ME, TOKEN } from 'constants/Constants';
import Header from 'layout/DashboardLayout/Header';

import {
  COUNTRY_CODE_OPTIONS,
  DEFAULT_PHONE_COUNTRY_CODE,
} from 'components/TextFieldNumberPhone/Constants/Constants';
import DocumentTitle from 'components/DocumentTitle';
import Breadcrumb from 'components/Breadcrumb';
import Loader from 'components/Loader';

export const classNames = (...classes) => classnames(...classes);

export function prettyDate(date, formatString, timezone) {
  return date
    ? timezone
      ? moment(date).tz(timezone).format(formatString)
      : moment(date).format(formatString)
    : null;
}
export const formatDateToUTC = date => {
  return date ? moment(+date).utc().format() : null;
};
export function getClientId() {
  return localStorage.getItem(CLIENT)
    ? JSON.parse(localStorage.getItem(CLIENT))?.external_id
    : null;
}
export function getCurrencyCode() {
  return localStorage.getItem(CLIENT)
    ? JSON.parse(localStorage.getItem(CLIENT))?.currency_code
    : 'USD';
}
export function getCurrencyCodeFormatted() {
  const currencyCode = getCurrencyCode();
  if (currencyCode === 'CAD') {
    return 'CA$';
  }
  return '$';
}
export function getPartnerId() {
  return localStorage.getItem(CLIENT) ? JSON.parse(localStorage.getItem(CLIENT))?.id : null;
}

export function convertAmountObject(amount) {
  const currencyCodeFormatted = getCurrencyCodeFormatted();
  return {
    amount: amount,
    currency: getCurrencyCode(),
    formatted: amount
      ? `${currencyCodeFormatted}${(amount / 100).toFixed(2)}`
      : `${currencyCodeFormatted}0.00`,
  };
}

export function getToken() {
  return localStorage.getItem(TOKEN);
}

export const getActiveLangLabel = () => {
  return localStorage.getItem('i18nextLng') || 'en-US';
};

export const getCurrentLang = () => {
  const storageLang = localStorage.getItem('i18nextLng') || 'en';
  return storageLang === 'en-US' ? 'en' : storageLang;
};

export const getMeVersion = () => {
  const version = localStorage.getItem(ME) ? JSON.parse(localStorage.getItem(ME))?.version : null;
  return version ? { version } : null;
};

export const responseError = (error, toast, t, navigate, location, dispatch) => {
  if (typeof error === 'object') {
    const { status, data } = error;
    console.error('error.response', `${status} ${data?.message}`);
    switch (status) {
      case 423:
        const lockType = data['lock-type'] || data?.data['lock-type'];
        switch (lockType) {
          case 'needs-mfa':
            return navigate({
              pathname: '/factor-auth',
              search: `?redirectPath=${window.btoa(location.pathname + location.search)}`,
            });
          case 'needs-password-confirmation':
            if (dispatch) {
              dispatch(openConfirm({}));
              toast &&
                toast.info({
                  title: [t('Info')],
                  message: [
                    'The feature requires a password for confirmation. Please enter password',
                  ],
                });
              break;
            }
            return navigate({
              pathname: '/needs-password-confirmation',
              search: `?redirectPath=${window.btoa(location.pathname + location.search)}`,
            });
          case 'needs-password-change':
            return navigate({
              pathname: '/needs-password-change',
              search: `?redirectPath=${window.btoa(location.pathname + location.search)}`,
            });
          default:
            toast &&
              toast.error({
                title: [t('Error')],
                message: [data?.message],
              });
            break;
        }
        break;
      case 401:
        return navigate({
          pathname: '/',
          search: `?redirectPath=${window.btoa(location.pathname + location.search)}`,
        });
      case 403:
        toast &&
          toast.error({
            title: [t('Error')],
            message: [data?.message],
          });
        break;
      case 503:
        return navigate('/maintenance');
      default:
        toast &&
          toast.error({
            title: [t('Error')],
            message: [data?.message],
          });
    }
  } else {
    console.error('error.code', error);
  }
};

export const hidePhoneNumber = phone => {
  if (phone && phone.length !== 0) {
    const splitNumber = phone.split('');
    const numberLength = splitNumber.length;
    const sliceNumber = splitNumber.slice(numberLength - 4).join('');
    const stars = '******';
    return stars + sliceNumber;
  }
};

export const hideEmail = email => {
  if (email && email.length !== 0) {
    const partUserName = email.split('@');
    const firstSymbol = partUserName[0].split('')[0];
    const lastSymbol = partUserName[0].split('')[partUserName[0].length - 1];
    const middleForStar = partUserName[0].slice(1, -1);
    const star = '*';
    const starString = new Array(middleForStar.length).fill(star).join('');
    return partUserName[0].length > 2
      ? firstSymbol + starString + lastSymbol + '@' + partUserName[1]
      : firstSymbol + '***@' + partUserName[1];
  }
};

export const hideCreditCardNumber = (cardNumber = '0000 0000 0000 0000') => {
  try {
    if (cardNumber.length !== 19) throw new Error('Credit card number format invalid!');
    return '** ' + cardNumber.slice(-4);
  } catch (err) {
    throw err;
  }
};

export const convertPhoneNumber = phone => {
  const { countryCode, phoneNumber } = phone;
  if (!countryCode || !phoneNumber) return '';
  return `+${countryCode}${phoneNumber?.replace(/\D/g, '')}`;
};

export const toTitleCase = string => {
  if (string && typeof string === 'string') {
    let sentence = string?.toLowerCase().split(' ');
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(' ');
  }
};

export const yearRanges = (year, rule, sum = 0) => Math.floor(year / rule) * rule + sum;

export const toCamelCase = string => {
  return string
    .split('/')
    .slice(1)
    .map((str, i) => (i === 0 ? str : str.charAt(0).toUpperCase() + str.slice(1)))
    .join('');
};

export const toUpperCaseFirstCharacter = str => {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
};

export const getPaginationQuery = (
  searchParams,
  { pageName = 'page', perPageName = 'perPage', sortName = 'sort' } = {}
) => {
  const page = searchParams.get(pageName)
    ? Number(searchParams.get(pageName))
    : paginateDefaultValues.CURRENT_PAGE;
  const perPage = searchParams.get(perPageName)
    ? Number(searchParams.get(perPageName))
    : paginateDefaultValues.ITEMS_PER_PAGE;
  const sortKeyParam = searchParams.get(sortName);

  return {
    page,
    perPage,
    sortKeyParam,
  };
};

export const getPaginatedFromRawData = (data = [], itemsPerPage = 10, currentPage = 1) => {
  return data.filter((_, index) => {
    const fromItem = itemsPerPage * currentPage + 1 - itemsPerPage;
    const toItem =
      itemsPerPage * currentPage > data.length ? data.length : itemsPerPage * currentPage;
    return index + 1 >= fromItem && index + 1 <= toItem;
  });
};

export const sortDataTable = (param, data = []) => {
  let newSortData = new Array(...data);
  if (param) {
    if (!param.includes('-')) {
      newSortData = newSortData.sort((a, b) => {
        const parsedA = a[param]?.value ?? a[param]?.name ?? a[param];
        const parsedB = b[param]?.value ?? b[param]?.name ?? b[param];
        return parsedA > parsedB ? 1 : parsedB > parsedA ? -1 : 0;
      });
    } else {
      const clearParam = param.split('').slice(1).join('');
      newSortData = newSortData.sort((a, b) => {
        const parsedA = a[clearParam]?.value ?? a[clearParam]?.name ?? a[clearParam];
        const parsedB = b[clearParam]?.value ?? b[clearParam]?.name ?? b[clearParam];
        return parsedA < parsedB ? 1 : parsedB < parsedA ? -1 : 0;
      });
    }
  }
  return newSortData;
};

export const sortDataList = (dataList, key) => {
  if (dataList?.length > 0 && key) {
    return new Array(...dataList)?.sort((a, b) => {
      if (a[key] > b[key]) return 1;
      if (a[key] < b[key]) return -1;
      return 0;
    });
  } else return [];
};

export const toOnlyDate = date => {
  return new Date(date).setHours(0, 0, 0, 0);
};

export const yupCheckPhoneNumberIsValid = value => {
  const { phoneNumber, countryCode } = value;
  if (!phoneNumber) return true;
  return (
    `${phoneNumber}`?.match(/\d/g)?.length ===
    COUNTRY_CODE_OPTIONS.find(item => item.value === countryCode)?.phone_number_length
  );
};

export const isEqual = (a, b) => {
  if (a === b) return true;
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b;
  if (a.prototype !== b.prototype) return false;
  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) return false;
  return keys.every(k => isEqual(a[k], b[k]));
};

export const increaseFakeIdByOne = (id = '0000 0000 0000 0000', incIdx = id.length - 1) => {
  try {
    if (incIdx < 0) throw new Error('Last ID was max value');
    if ([4, 9, 14].includes(incIdx)) return increaseFakeIdByOne(id, incIdx - 1);
    if (+id[incIdx] < 9)
      return id.substring(0, incIdx) + (+id[incIdx] + 1) + id.substring(incIdx + 1);
    else {
      return increaseFakeIdByOne(
        id.substring(0, incIdx) + '0' + id.substring(incIdx + 1),
        incIdx - 1
      );
    }
  } catch (err) {
    throw err;
  }
};

export const converterFormatE164PhoneNumber = string => {
  if (!string) return;
  const parsePhoneNumb = parsePhoneNumberFromString(string);
  const findCountry = COUNTRY_CODE_OPTIONS.find(
    i => i.value?.toString() === parsePhoneNumb?.countryCallingCode
  );
  if (!findCountry) return string;
  if (parsePhoneNumb.isValid()) {
    return `+${parsePhoneNumb.countryCallingCode} ${parsePhoneNumb.formatNational()}`;
  } else {
    if (!parsePhoneNumb.nationalNumber || !findCountry.format) return '';
    const numberArr = parsePhoneNumb.nationalNumber.split('');
    const formatArr = findCountry.format.split('');
    let newFormat = [`+${parsePhoneNumb.countryCallingCode} `];
    let count = 0;
    for (let i = 0; i < formatArr.length; i++) {
      if (formatArr[i] === '#' && numberArr[count]) {
        newFormat.push(numberArr[count]);
        count++;
      } else newFormat.push(formatArr[i]);
    }
    return newFormat.join('');
  }
};

export const providesList = (resultsWithIds, tagType) => {
  return resultsWithIds
    ? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
    : [{ type: tagType, id: 'LIST' }];
};

export const queryBuilder = url => {
  return ({ filter, paginate, sort, complete = 0 } = {}) => {
    let queryString = '?';
    if (complete) {
      queryString += `complete=${complete}`;
    }

    if (paginate) {
      queryString += `page=${paginate?.page ?? paginateDefaultValues.CURRENT_PAGE}&perPage=${
        paginate?.perPage ?? paginateDefaultValues.ITEMS_PER_PAGE
      }`;
    }
    if (sort) queryString += `&sort=${sort}`;
    if (typeof filter === 'object') {
      Object.keys(filter).forEach(filterKey => {
        queryString += queryString === '?' ? '' : '&';
        queryString += `filter[${filterKey}]=${filter[filterKey]}`;
      });
    } else if (typeof filter === 'string') {
      queryString = queryString ? `${queryString}&${filter}` : `?${filter}`;
    }
    if (queryString.length > 1) {
      return { url: url + queryString };
    }
    return { url };
  };
};

export const queryBuilderForDownload = ({ url, fileName, ...props }) => {
  return {
    url,
    responseHandler: async response => {
      const blob = new Blob([await response.blob()]);
      FileSaver.saveAs(blob, fileName);
    },
    cache: 'no-cache',
    ...props,
  };
};

export const isZipCodeValid = (value, countryCode) => {
  let isValidZip = true;
  switch (countryCode) {
    case 'CA':
      isValidZip = /^(?!.*[DFIOQU])[A-VXY]\d[A-Z][ -]?\d[A-Z]\d$/i.test(value);
      break;
    case 'FR':
      isValidZip = /^\d{5}$/.test(value);
      break;
    case 'US':
      isValidZip = /^\d{5}$/.test(value);
      break;
    // TODO: Add more country's validate regex pattern in future
    default:
      break;
  }
  return isValidZip;
};

//Splits an array of filter options to send to BO. Used in filter modal
export const separateParams = data => {
  if (data?.length > 0) {
    const separateIds = data.map(item => item.id);
    const toStrIds = separateIds.join(',');
    const separateNames = data.map(item => item.name?.toLowerCase());
    const toStrNames = separateNames.join(',');
    const separateValues = data.map(item => item?.value?.toLowerCase());
    const toStrValues = separateValues?.join(',');
    return {
      names: toStrNames,
      values: toStrValues,
      ids: toStrIds,
    };
  }
};

//Used in any filterQueryString.
//Shows a readable name etiquette when filtering the parameter by name.
//Example result: "Daily, Weekly, Monthly".
export const displayCorrectLabelNamesFilterOptions = originalText => {
  const convertTextToArr = originalText?.split(',');
  return convertTextToArr?.map((text, index) =>
    index !== convertTextToArr?.length - 1 ? toTitleCase(text) + ', ' : toTitleCase(text)
  );
};

//Used in any filterQueryString.
//Converting filter option Ids into readable labels
export const converterIDSFilterLabels = (originalText, options, key = 'id') => {
  if (originalText && options?.length > 0) {
    const arrayIds = originalText?.split(',');
    const optimizedArrayIds = arrayIds.map(item => item.trim());
    const displayOptions = options?.filter(item =>
      optimizedArrayIds.includes(item[key]?.toString()?.toLowerCase())
    );
    return displayOptions.map(item => item?.name).join(', ');
  }
};

//Used in formik initialValues. Sets the selected multi-select filter values by id
export const setDefaultIdsValuesMultiSelect = (filter, data, key = 'id') => {
  if (!(filter && data)) return [];
  const filterArr = filter.split(',');
  return data.filter(item => filterArr?.includes(item?.[key]?.toString()?.toLowerCase()));
};

//Get datetime according to client's timezone(not local)
//Used in formik initialValues
export const setDefaultClientsDateTimeTimezone = (utcDate, timezone) => {
  if (utcDate) {
    // const dateTimeTimeZone = prettyDate(date, 'YYYY-MM-DD h:mm A', timezone);
    // return moment(dateTimeTimeZone).valueOf().toString();
    return moment.utc(utcDate).tz(timezone).valueOf().toString();
  } else return '';
};

export const setDefaultDateValues = (utcDate, timezone) => {
  if (utcDate) return moment.utc(utcDate).tz(timezone).startOf('day').valueOf().toString();
  else return '';
};

export const setFilterStartDateParam = (key, date, searchParams) => {
  if (date?.length > 0) {
    searchParams.set(key, moment(+date).utc().format());
  } else {
    searchParams.delete(key);
  }
};
export const setFilterEndDateParam = (key, date, searchParams) => {
  if (date?.length > 0) {
    searchParams.set(key, moment(+date).add(1, 'd').subtract(1, 's').utc().format());
  } else {
    searchParams.delete(key);
  }
};

/**
 * Used to process when submitting formik.
 * For use case with <TextField type='date' hasTimePicker />
 * @param {*} date
 * @param {*} timezone
 */
export const setDateTimePickerUTC = (date, timezone) => {
  return date ? moment.tz(+date, timezone).utc().format() : '';
};
//Sets the start date in UTC for BO.
//Used in formik submit action
export const setDateTimeUTC = (date, timezone) =>
  date &&
  moment.tz(moment(+date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS), timezone).utc().format();

//Sets the end of date in UTC for BO.
//Used in formik submit action
export const setEndOfDateTimeUTC = (endDate, timezone) =>
  endDate &&
  moment
    .tz(moment(+endDate).endOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS), timezone)
    .utc()
    .format();

/**
 * Convert datetime to local time
 * @param {*} date
 * @returns
 */
export const setDateTimeToLocalTime = date => {
  return moment(date || new Date())
    .utc()
    .valueOf()
    .toString();
};

export const setMonthPickerUTC = (date, timezone) => {
  return date
    ? moment
        .tz(moment(date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS), timezone)
        .utc()
        .format('MMM, YYYY')
    : '';
};

export const setFilterMonthParam = (key, date, searchParams) => {
  if (date?.length > 0) {
    searchParams.set(key, moment(+date).format('YYYY-MM'));
  } else {
    searchParams.delete(key);
  }
};

export const setDefaultPhoneNumber = phone => {
  switch (true) {
    case phone?.startsWith('+1'):
      return {
        phoneNumber: phone.replace('+1', ''),
        countryCode: 1,
      };
    case phone?.startsWith('+33'):
      return {
        phoneNumber: phone.replace('+33', ''),
        countryCode: 33,
      };
    default:
      return {
        phoneNumber: '',
        countryCode: DEFAULT_PHONE_COUNTRY_CODE,
      };
  }
};

export const setDefaultDateWithSubValues = (date, timezone, subDate = 0) => {
  return moment
    .utc(date || new Date())
    .tz(timezone)
    .startOf('day')
    .subtract(subDate, 'd')
    .valueOf()
    .toString();
};

export const withHeaderBreadcrumb = (Component, getDefaultBreadcrumb) => props => {
  const { t } = useTranslation();

  const defaultBreadcrumb = useMemo(
    () => (getDefaultBreadcrumb ? getDefaultBreadcrumb(t) : []),
    [t]
  );

  const [breadcrumb, setBreadcrumb] = useState([]);

  return (
    <DocumentTitle title={props.title}>
      <Header>
        <Breadcrumb path={breadcrumb.length === 0 ? defaultBreadcrumb : breadcrumb} />
      </Header>
      <Component
        {...props}
        setBreadcrumb={setBreadcrumb}
        getDefaultBreadcrumb={getDefaultBreadcrumb}
      />
    </DocumentTitle>
  );
};

export const withLazyComponent = (LazyComponent, fallback) => {
  return props => (
    <Suspense fallback={fallback ?? <Loader />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export const format16charsId = id => {
  if (!id) return '';
  const mediaId = id.toString();
  return `${mediaId.slice(0, 4)} ${mediaId.slice(4, 8)} ${mediaId.slice(8, 12)} ${mediaId.slice(
    12
  )}`;
};

export const loadScript = (body, callback) => {
  return new Promise((resolved, rejected) => {
    const existingScript = document.getElementById(body.id);
    if (existingScript) {
      existingScript.parentNode.removeChild(existingScript);
    }
    if (body.src) {
      const script = document.createElement('script');
      script.src = body.src;
      script.id = body.id;
      document.body.appendChild(script);

      script.onload = () => {
        resolved();
      };
      script.onerror = () => {
        rejected();
      };
    }
  });
};
export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const showLastFourSymbolsMediaID = mediaID =>
  mediaID ? '** ' + mediaID.toString().slice(-4) : '';

export const encodeBtoaStringParam = param => {
  try {
    const decode = window.atob(param);
    return JSON.parse(decode);
  } catch (e) {
    return {};
  }
};

export const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    console.error(e);
    return false;
  }
  return true;
};

export const getJsonData = key => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return null;
  }
};

/**
 * Get the data needed in the status options to display on a select status
 * @param {Array} statusList
 * @returns
 */
export const getStatusOptions = statusList => {
  return statusList.map(option => ({
    id: option?.id ?? null,
    name: option?.name ?? '',
    value: option?.value ?? '',
    color: option?.color ?? '',
  }));
};

/**
 * Get data by value in data list
 * @param {Array} dataList
 * @param {String || Number} value
 * @param {String} key
 * @returns data in list or Null
 */
export const getDataByValue = (dataList = [], value, key = 'value') => {
  if (!value) return null;
  return dataList.find(item => item[key] === value) || null;
};

export const defaultCurrencyBalanceValueObj = filterParam => {
  const isNegativeValue = filterParam?.split('')[0] === '-';
  return {
    formatted: filterParam
      ? `${isNegativeValue ? '-' : ''}$${Math.abs(+filterParam / 100)?.toFixed(2)}`
      : '',
    value: filterParam ? filterParam.replace(/[^\d.-]+/g, '') : 0,
  };
};

export const currencyFilterViewValue = amount => {
  if (typeof amount === 'string' && amount?.length > 0) {
    const isNegativeValue = amount?.split('')[0] === '-';
    return `${isNegativeValue ? '-' : ''}$${(+(Math.abs(+amount) / 100).toFixed(2)).toLocaleString(
      'en-US',
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    )}`;
  } else return '';
};

export const convertAmount = amount => {
  return {
    formatted: +amount ? `$${(amount / 100).toFixed(2)}` : '',
    value: +amount || 0,
  };
};

/**
 * Join label with character
 * @param Array list
 * @param String char
 * @returns String
 */
export const joiLabelWithCharacter = (list, char = ', ') => {
  return list.filter(element => element).join(char);
};

export const checkPermissions = (permissions = [], keyPermissionsArr = []) => {
  if (permissions?.length > 0) {
    return !keyPermissionsArr?.some(perm => permissions?.includes(perm));
  } else return false;
};

export const checkSettings = (settings = [], key) => {
  if (settings?.length > 0) {
    let isAllowed;
    if (key && settings?.find(i => i.key === key))
      isAllowed = settings?.find(i => i.key === key)?.value;
    else isAllowed = true;
    return isAllowed === null || isAllowed;
  } else return true;
};

export const renderActionsAvailable = (actionList = []) => {
  return actionList
    .filter(action => !action?.accessDenied && !action?.isHidden)
    .map(({ accessDenied, ...action }) => action);
};

export const durationTime = (expiredDate, timezone) => {
  const currentUTCData = moment().utc().format();
  const currentDataTimeZone = setDefaultClientsDateTimeTimezone(currentUTCData, timezone);
  const activatedAtTimeZone = setDefaultClientsDateTimeTimezone(expiredDate, timezone);
  const currentDataMoment = moment(+currentDataTimeZone);
  const activatedDataMoment = moment(+activatedAtTimeZone);
  const durationsDates = activatedDataMoment.diff(currentDataMoment);
  const mls = moment.duration(durationsDates, 'milliseconds');
  const months = mls.months() + 'm ';
  const days = mls.days() + 'd ';
  const hours = mls.hours() + 'h ';
  const min = mls.minutes() + 'min';
  if (+mls > 0) {
    return months + days + hours + min;
  } else return '0h 0min';
};

export const durationWidth = (activate, expired) => {
  const currentValue = moment().valueOf();
  const activateAt = moment(activate).valueOf();
  const expiredAt = moment(expired).valueOf();
  const fullWidth = expiredAt - activateAt;
  const duringTimeCurrentTime = currentValue - expiredAt;
  const setPartFromFull = fullWidth - Math.abs(duringTimeCurrentTime);
  const countWidth = (setPartFromFull / fullWidth) * 100;
  return `${countWidth}%`;
};

export const getNumberOfMediaFromCsv = file => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: function (results) {
        const countNumber = results?.data?.reduce((accumulator, currentValue) => {
          const convertToNumber = Number(currentValue[0]?.toString()?.replace(/\s/g, ''));
          return accumulator + (Math.ceil(Math.log10(convertToNumber + 1)) === 16);
        }, 0);
        resolve(countNumber);
      },
      error: error => {
        reject(error);
      },
    });
  });
};

export const convertDataToCsv = (titles = [], data = []) => {
  return Papa.unparse({
    fields: titles,
    data,
  });
};

export const setDefaultSingleSelectFilterValue = (filterName = '', dataList, key = 'name') => {
  return filterName ? dataList && dataList?.find(item => item[key] === filterName) : '';
};

/**
 * Divide a number by a thousand
 * @returns String | Number
 * @param count
 */
export const addCommaIfOverThousand = count => {
  if (count && Number(+count)) {
    if (+count > 1000 && count % 1 === 0) {
      return count?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return count.toString();
    }
  } else return 0;
};

/**
 * Check for dirty phone numbers in the filter
 * @param {Object} initialObject
 * @param {Object} currentValues
 * @param {String} key
 * @returns boolean
 */
export const isDirtyPhoneNumber = (initialObject, currentValues, key = 'phone') => {
  const data = { ...currentValues };
  const keyValues = Object.keys(data);
  const dirtyValues = keyValues.filter(keyValue => data[keyValue] !== initialObject[keyValue]);
  keyValues.forEach(key => {
    if (!dirtyValues.includes(key)) delete data[key];
  });
  if (
    Object.keys(data)?.length === 1 &&
    !data?.[key]?.phoneNumber &&
    data?.[key]?.phoneNumber === initialObject?.[key]?.phoneNumber &&
    data?.[key]?.countryCode !== initialObject?.[key]?.countryCode
  ) {
    return true;
  }
  return undefined;
};

/**
 * Check exists substring
 * @param {string} str
 * @param {*} subStr
 * @returns Boolean
 */
export const isExistsSubString = (str, subStr) => {
  return str?.toString().toLowerCase()?.includes(subStr?.toString()?.toLowerCase());
};

/**
 * Get days in month
 * @param {*} defaultMonth
 * @returns
 * @returns Int
 */
export const daysInMonth = defaultMonth => {
  let date = new Date();
  let year = date.getFullYear();
  const month = defaultMonth ?? date.getMonth() + 1;
  return new Date(year, month, 0).getDate();
};
