/**@format */
import React, { useState } from 'react';

import { classNames } from 'helpers';

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_GREEN,
  COLOR_RED,
  COLOR_TRANSPARENT,
  COLOR_YELLOW,
} from 'constants/Constants';
import { tooltipPositionEnum, tooltipTypeEnum } from 'constants/enum';

export const Tooltip = ({
  icon,
  title = '',
  text = '',
  color = COLOR_RED,
  classNameTootipWrap,
  classNameButton,
  classNameWrap,
  className,
  type = tooltipTypeEnum.ARROW_TYPE,
  position = tooltipPositionEnum.LEFT,
  ...props
}) => {
  const [isTooltipShowed, setIsTooltipShowed] = useState(false);
  return (
    <div className={classNameTootipWrap ?? classNames('relative', 'flex items-center')}>
      <span
        className={classNames('p-0 h-fit', classNameButton)}
        color={COLOR_TRANSPARENT}
        role='button'
        onPointerEnter={() => {
          setIsTooltipShowed(true);
        }}
        onPointerLeave={() => {
          setIsTooltipShowed(false);
        }}
        {...props}
      >
        {icon || title}
      </span>
      {isTooltipShowed && (
        <div
          className={classNames(
            'absolute',
            'px-2 py-1',
            'whitespace-nowrap',
            { 'rounded-lg': !classNameWrap?.includes('rounded') },
            { 'text-white': !classNameWrap?.includes('text-') },
            { 'w-fit': !classNameWrap?.includes('w-') },
            {
              'left-[130%] -top-2 -right-4': position === tooltipPositionEnum.RIGHT,
              '-top-8 -left-4': position === tooltipPositionEnum.TOP,
              'top-7 -left-4': position === tooltipPositionEnum.BOTTOM,
              '-top-1 right-7': position === tooltipPositionEnum.LEFT,
              'left-1/2 -top-8 -translate-x-[50%]': position === tooltipPositionEnum.TOP_CENTER,
            },
            {
              'bg-green-600': color === COLOR_GREEN,
              'bg-red-600': color === COLOR_RED,
              'bg-yellow-600': color === COLOR_YELLOW,
              'bg-blue-600': color === COLOR_BLUE,
              'bg-gray-600': color === COLOR_GRAY,
            },
            classNameWrap
          )}
        >
          {type === tooltipTypeEnum.ARROW_TYPE && (
            <div
              className={classNames(
                'border-l-4 border-r-4 border-b-8 border-l-transparent border-r-transparent',
                'w-0 h-0',
                'absolute z-0',
                {
                  '-right-2 top-[10px] rotate-90': position === tooltipPositionEnum.LEFT,
                  '-left-1.5 top-3 -rotate-90': position === tooltipPositionEnum.RIGHT,
                  'left-[23px] top-[27px] -rotate-180': position === tooltipPositionEnum.TOP,
                  'left-[23px] -top-2 -rotate-270': position === tooltipPositionEnum.BOTTOM,
                  'left-1/2 top-[27px] -rotate-180 -translate-x-[50%]':
                    position === tooltipPositionEnum.TOP_CENTER,
                },
                {
                  'border-b-green-600': color === COLOR_GREEN,
                  'border-b-red-600': color === COLOR_RED,
                  'border-b-yellow-600': color === COLOR_YELLOW,
                  'border-b-blue-600': color === COLOR_BLUE,
                  'border-b-gray-600': color === COLOR_GRAY,
                },
                className
              )}
            />
          )}
          <p className={classNames('z-10 text-sm')}>{text}</p>
        </div>
      )}
    </div>
  );
};
