/**@format */
import PropTypes from 'prop-types';
import React from 'react';
import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_GREEN,
  COLOR_LIGHT_YELLOW,
  COLOR_ORANGE,
  COLOR_RED,
  COLOR_YELLOW,
  COLOR_AMBER,
} from '../../constants/Constants';
import { chipSizeEnum, chipTypesEnum } from '../../constants/enum';

import { classNames } from '../../helpers';

export const Chip = ({
  className = '',
  classNameText = '',
  leftIcon = null,
  rightIcon = null,
  color = '',
  text = '',
  type = chipTypesEnum.CAPSULE,
  size,
}) => {
  return (
    <span
      className={classNames(
        'w-fit flex justify-around items-center',
        type === chipTypesEnum.RECTANGLE && !className?.includes('rounded-') && 'rounded-md',
        type === chipTypesEnum.CAPSULE && !className?.includes('rounded-') && 'rounded-full',
        { 'space-x-1': leftIcon || rightIcon },
        { 'text-sm py-0.5 px-3 leading-5': size === chipSizeEnum.LARGE },
        { 'text-sm py-1 px-2 leading-5': size === chipSizeEnum.MEDIUM },
        { 'text-xs py-0.5 px-2 leading-4': size === chipSizeEnum.SMALL },
        {
          'bg-green-50 text-green-600': type === chipTypesEnum.RECTANGLE && color === COLOR_GREEN,
          'bg-yellow-50 text-yellow-500':
            type === chipTypesEnum.RECTANGLE && color === COLOR_LIGHT_YELLOW,
          'bg-yellow-50 text-yellow-600':
            type === chipTypesEnum.RECTANGLE && color === COLOR_YELLOW,
          'bg-amber-50 text-amber-600': type === chipTypesEnum.RECTANGLE && color === COLOR_AMBER,
          'bg-blue-50 text-blue-600': type === chipTypesEnum.RECTANGLE && color === COLOR_BLUE,
          'bg-red-50 text-red-600': type === chipTypesEnum.RECTANGLE && color === COLOR_RED,
          'bg-gray-50 text-gray-600': type === chipTypesEnum.RECTANGLE && color === COLOR_GRAY,
          'bg-orange-50 text-orange-600':
            type === chipTypesEnum.RECTANGLE && color === COLOR_ORANGE,
        },
        {
          'bg-green-50 text-green-600': type === chipTypesEnum.CAPSULE && color === COLOR_GREEN,
          'bg-red-50 text-red-600': type === chipTypesEnum.CAPSULE && color === COLOR_RED,
          'bg-yellow-50 text-yellow-500':
            type === chipTypesEnum.CAPSULE && color === COLOR_LIGHT_YELLOW,
          'bg-amber-50 text-amber-600': type === chipTypesEnum.CAPSULE && color === COLOR_AMBER,
          'bg-yellow-50 text-yellow-600': type === chipTypesEnum.CAPSULE && color === COLOR_YELLOW,
          'bg-blue-50 text-blue-600': type === chipTypesEnum.CAPSULE && color === COLOR_BLUE,
          'bg-gray-50 text-gray-600': type === chipTypesEnum.CAPSULE && color === COLOR_GRAY,
          'bg-orange-50 text-orange-600': type === chipTypesEnum.CAPSULE && color === COLOR_ORANGE,
        },
        className
      )}
    >
      {leftIcon}
      <span
        className={classNames(
          { 'whitespace-nowrap': !classNameText.includes('whitespace') },
          {
            'font-medium': !classNameText?.includes('font-'),
          },
          classNameText
        )}
      >
        {text}
      </span>
      {rightIcon}
    </span>
  );
};

Chip.propTypes = {
  className: PropTypes.string,
  classNameText: PropTypes.string,
  color: PropTypes.oneOf([
    COLOR_BLUE,
    COLOR_GRAY,
    COLOR_GREEN,
    COLOR_RED,
    COLOR_YELLOW,
    COLOR_ORANGE,
    COLOR_AMBER,
    undefined,
  ]),
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf([...Object.values(chipTypesEnum), undefined]),
  size: PropTypes.oneOf([...Object.values(chipSizeEnum), undefined]),
};
