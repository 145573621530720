/** @format */

import React, { Suspense } from 'react';
// import ReactDOM from 'react-dom'; //react 17

import ReactDOM from 'react-dom/client';
import './index.css';
import Flowbird from './Flowbird';
import reportWebVitals from './reportWebVitals';
import setupStore from './store/configureStore';
import { Provider } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';

import 'react-toastify/dist/ReactToastify.css';
import './translation/i18n';

const container = document.getElementById('root');
const store = setupStore();

// React 18
const root = ReactDOM.createRoot(container);
root.render(
  // <React.StrictMode>
  <Suspense fallback={<div>Loading...</div>}>
    <Provider store={store}>
      <LoadingBar
        showFastActions
        updateTime={100}
        maxProgress={99}
        progressIncrease={10}
        style={{ backgroundColor: '#4f46e5', height: '5px', zIndex: 100 }}
      />
      <Flowbird />
    </Provider>
  </Suspense>
  // </React.StrictMode>
);

// react 17
// ReactDOM.render(
//   <React.StrictMode>
//    <Provider store={store}>
//     <Flowbird />
//    </Provider>
//   </React.StrictMode>,
//   container
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
