/** @format */
import {
  AdjustmentsIcon,
  AnnotationIcon,
  BellIcon,
  BookOpenIcon,
  BriefcaseIcon,
  CashIcon,
  ChartPieIcon,
  ClipboardListIcon,
  ClockIcon,
  CogIcon,
  CollectionIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  DesktopComputerIcon,
  DeviceMobileIcon,
  DocumentDuplicateIcon,
  DownloadIcon,
  ExclamationIcon,
  EyeIcon,
  FolderIcon,
  LockOpenIcon,
  PrinterIcon,
  ReceiptRefundIcon,
  ReplyIcon,
  SwitchHorizontalIcon,
  TicketIcon,
  TrendingUpIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
  PuzzleIcon,
  CubeTransparentIcon,
  TemplateIcon,
  SearchIcon,
  DocumentTextIcon,
  CalendarIcon,
  LocationMarkerIcon,
  MapIcon,
  ViewGridAddIcon,
  ChartSquareBarIcon,
  KeyIcon,
} from '@heroicons/react/solid';
import { LibraryIcon } from '@heroicons/react/outline';

import { ReactComponent as IconVitals } from '../assets/icons/IconVitals.svg';
import { ReactComponent as IconDeviceAgents } from '../assets/icons/IconDeviceAgents.svg';
import { ReactComponent as IconDeviceAxioPV } from '../assets/icons/IconDeviceAxioPV.svg';
import { ReactComponent as IconDeviceManagerAxio } from '../assets/icons/IconDeviceManagerAxio.svg';
import { ReactComponent as IconDeviceManagerTVM } from '../assets/icons/IconDeviceManagerTVM.svg';
import { ReactComponent as IconDeviceTicket } from '../assets/icons/IconDeviceTicket.svg';
import { ReactComponent as IconMedia } from '../assets/icons/IconMedia.svg';
import { ReactComponent as IconArrowClock } from '../assets/icons/IconArrowClock.svg';
import { ReactComponent as IconImport } from '../assets/icons/IconImport.svg';
import { ReactComponent as IconExport } from '../assets/icons/IconExport.svg';
import { ReactComponent as IconCardCurrency } from '../assets/icons/IconCardCurrency.svg';
import { ReactComponent as IconCustom } from '../assets/icons/CustomIcon.svg';
import { ReactComponent as IconNetwork } from '../assets/icons/IconNetwork.svg';
import { ReactComponent as IconFleet } from '../assets/icons/IconFleet.svg';
import { ReactComponent as IconVehicle } from '../assets/icons/IconVehicle.svg';
import { ReactComponent as IconHardware } from '../assets/icons/IconHardware.svg';
import { ReactComponent as IconMobileWeb } from '../assets/icons/IconMobileWeb.svg';

export const WELCOME = [
  { name: 'Welcome', icon: TemplateIcon, href: '/dashboard/welcome' },
  // { name: 'Release Notes', icon: NewspaperIcon, href: '/dashboard/release-notes' },
];
export const CRM = [
  {
    name: 'Settings',
    icon: AdjustmentsIcon,
    href: '/dashboard/crm/settings',
  },
  {
    name: 'Statistics',
    icon: ChartPieIcon,
    href: '/dashboard/crm/statistics',
    permissionSlug: 'patron.statistics',
    settingKey: 'show_crm_statistics_menu',
  },
  {
    name: 'Patrons',
    icon: UserGroupIcon,
    href: '/dashboard/crm/patrons',
    permissionSlug: 'patron.list',
  },
  {
    name: 'Notifications',
    icon: AnnotationIcon,
    href: '/dashboard/crm/notifications',
    permissionSlug: 'crm.notifications.view',
    settingKey: 'show_crm_notifications_menu',
  },
];
export const MONITORING = [
  { name: 'Monitoring', icon: DesktopComputerIcon, href: '/dashboard/monitoring/monitoring' },
  { name: 'Events', icon: ExclamationIcon, href: '/dashboard/monitoring/events' },
  { name: 'Controls', icon: TicketIcon, href: '/dashboard/monitoring/controls' },
  { name: 'Alarms', icon: BellIcon, href: '/dashboard/monitoring/alarms' },
  { name: 'Notification Groups', icon: ReplyIcon, href: '/dashboard/monitoring/notifications' },
  { name: 'Consumables', icon: PrinterIcon, href: '/dashboard/monitoring/consumables' },
  { name: 'Vitals', icon: IconVitals, href: '/dashboard/monitoring/vitals' },
];

export const PASSES = [
  { name: 'Settings', icon: AdjustmentsIcon, href: '/dashboard/passes/settings' },
  { name: 'Statistics', icon: ChartPieIcon, href: '/dashboard/passes/statistics' },
  { name: 'Inventory', icon: TicketIcon, href: '/dashboard/passes/inventory' },
];
export const VOUCHERS = [
  {
    name: 'Settings',
    icon: AdjustmentsIcon,
    href: '/dashboard/vouchers/settings',
  },
  {
    name: 'Users',
    icon: UsersIcon,
    href: '/dashboard/users/inventory',
  },
  {
    name: 'Inventory',
    icon: TicketIcon,
    href: '/dashboard/vouchers/inventory',
  },
  {
    name: 'Schedules',
    icon: ClockIcon,
    href: '/dashboard/vouchers/schedules',
    settingKey: 'show_voucher_schedule_menu',
  },
  {
    name: 'Audit Trial',
    icon: BookOpenIcon,
    href: '/dashboard/vouchers/audit-trial',
    settingKey: 'show_voucher_audit_trail_menu',
  },
];
export const CLIENTS = [{ name: 'Clients', icon: UserGroupIcon, href: '/dashboard/clients' }];

export const HAWKEYE = [
  { name: 'Settings', icon: AdjustmentsIcon, href: '/dashboard/hawkeye/settings' },
  { name: 'Monitoring', icon: ChartPieIcon, href: '/dashboard/hawkeye/monitoring' },
  { name: 'Users', icon: UsersIcon, href: '/dashboard/hawkeye/users' },
  { name: 'Handhelds', icon: DeviceMobileIcon, href: '/dashboard/hawkeye/handhelds' },
  {
    name: 'Inspection Sessions',
    icon: ClipboardListIcon,
    href: '/dashboard/hawkeye/inspection-sessions',
  },
  { name: 'Audit Trail', icon: BookOpenIcon, href: '/dashboard/hawkeye/audit-trail' },
];

export const FRONT_DESK_WEB = [
  {
    name: 'Settings',
    icon: AdjustmentsIcon,
    href: '/dashboard/frontdesk-web/settings',
    permissionSlug: 'frontdesk.web.settings.access',
  },
  {
    name: 'Roles & Permissions',
    icon: KeyIcon,
    href: '/dashboard/frontdesk-web/permissions',
    permissionSlug: 'frontdesk.web.permissions.access',
  },
  {
    name: 'Customer Lookup',
    icon: UserIcon,
    href: '/dashboard/frontdesk-web/customer-lookup',
    settingKey: 'show_frontdesk_web_customer_lookup',
    permissionSlug: 'frontdesk.web.customer.access',
  },
  {
    name: 'Media Lookup',
    icon: IconMedia,
    href: '/dashboard/frontdesk-web/media-lookup',
    classNameIcon: 'fill-white',
    settingKey: 'show_frontdesk_web_media_lookup',
    permissionSlug: 'frontdesk.web.media.access',
  },
  {
    name: 'Bulk Actions',
    icon: CogIcon,
    href: '/dashboard/frontdesk-web/bulk-actions',
    settingKey: 'show_frontdesk_web_bulk_actions',
    permissionSlug: 'frontdesk.web.bulk.access',
  },
  {
    name: 'Operations History',
    icon: IconArrowClock,
    href: '/dashboard/frontdesk-web/operations-history',
    settingKey: 'show_frontdesk_web_operations_history',
    permissionSlug: 'frontdesk.web.operations.access',
  },
  {
    name: 'Statements',
    icon: ClipboardListIcon,
    href: '/dashboard/frontdesk-web/statements',
    settingKey: 'show_frontdesk_web_statements',
    permissionSlug: 'frontdesk.web.statements.access',
  },
];

export const FRONT_DESK = [
  { name: 'Settings', icon: AdjustmentsIcon, href: '/dashboard/frontdesk/settings' },
  { name: 'Hardware Inventory', icon: IconHardware, href: '/dashboard/frontdesk/hardware' },
  { name: 'Sales Analysis', icon: ChartSquareBarIcon, href: '/dashboard/frontdesk/sales-analysis' },
  { name: 'Sessions', icon: ClockIcon, href: '/dashboard/frontdesk/sessions' },
  { name: 'Statements', icon: ClipboardListIcon, href: '/dashboard/frontdesk/statements' },
];

export const CONTROL_LISTS = [
  { name: 'Configuration', icon: AdjustmentsIcon, href: '/dashboard/control-lists/settings' },
  { name: 'List Content', icon: ClipboardListIcon, href: '/dashboard/control-lists/list-content' },
  { name: 'Audit Trial', icon: SearchIcon, href: '/dashboard/control-lists/audit-trial' },
  { name: 'Supervision', icon: IconCustom, href: '/dashboard/control-lists/supervision' },
  {
    name: 'List Generation',
    icon: DocumentTextIcon,
    href: '/dashboard/control-lists/list-generation',
  },
];

export const OPEN_PAYMENTS = [
  { name: 'Statistics', icon: ChartPieIcon, href: '/dashboard/open-payments/statistics' },
  { name: 'Taps', icon: CreditCardIcon, href: '/dashboard/open-payments/taps' },
  { name: 'Cases', icon: FolderIcon, href: '/dashboard/open-payments/cases' },
  { name: 'Inventory', icon: ClipboardListIcon, href: '/dashboard/open-payments/inventory' },
  { name: 'Payments', icon: IconCardCurrency, href: '/dashboard/open-payments/payments' },
];

export const DEPLOYMENTS = [
  { name: 'Artifacts', icon: PuzzleIcon, href: '/dashboard/deployments/artifacts' },
  { name: 'Deployments', icon: CubeTransparentIcon, href: '/dashboard/deployments/deployments' },
];

export const DEVICES = [
  { name: 'Settings', icon: AdjustmentsIcon, href: '/dashboard/devices/settings' },
  { name: 'TVM', icon: IconDeviceManagerTVM, href: '/dashboard/devices/tvm' },
  { name: 'AXIO', icon: IconDeviceManagerAxio, href: '/dashboard/devices/axio' },
  { name: 'AXIO PV', icon: IconDeviceAxioPV, href: '/dashboard/devices/pv' },
  { name: 'Ticket Office Machines', icon: IconDeviceTicket, href: '/dashboard/devices/ticket' },
  { name: 'Handhelds', icon: DeviceMobileIcon, href: '/dashboard/devices/handhelds' },
  { name: 'Agents', icon: IconDeviceAgents, href: '/dashboard/devices/agents' },
];

export const MEDIA_MANAGER = [
  { name: 'Settings', icon: AdjustmentsIcon, href: '/dashboard/media/settings' },
  { name: 'Statistics', icon: ChartPieIcon, href: '/dashboard/media/statistics' },
  {
    name: 'Media Manager',
    icon: UserGroupIcon,
    href: '/dashboard/media/media',
    permissionSlug: 'media.view',
  },
  {
    name: 'Fare Caps',
    icon: DownloadIcon,
    classNameIcon: '-scale-y-100',
    href: '/dashboard/media/fare-caps',
  },
];

export const USERS = [
  { name: 'Settings', icon: AdjustmentsIcon, href: '/dashboard/users/settings' },
  {
    name: 'Roles & Permissions',
    icon: KeyIcon,
    href: '/dashboard/users/permissions',
    permissionSlug: 'nest-users.permissions.access',
  },
  {
    name: 'Inventory',
    icon: UserGroupIcon,
    href: '/dashboard/users/inventory',
    permissionSlug: 'nest-users.access',
  },
  { name: 'Profiles', icon: LockOpenIcon, href: '/dashboard/users/profiles' },
];

export const PARTNERS = [
  { name: 'Partners & Programs', icon: BriefcaseIcon, href: '/dashboard/partners' },
];

export const REVENUE = [
  { name: 'Statistics', icon: ChartPieIcon, href: '/dashboard/revenue/statistics' },
  { name: 'Daily Revenue', icon: TrendingUpIcon, href: '/dashboard/revenue/daily-revenue' },
  {
    name: 'Cash Content Analysis',
    icon: CashIcon,
    classNameIcon: '-scale-y-100',
    href: '/dashboard/revenue/cash-content-analysis',
  },
  { name: 'Cash Collection', icon: CurrencyDollarIcon, href: '/dashboard/revenue/cash-collection' },
  {
    name: 'Transactions',
    icon: SwitchHorizontalIcon,
    classNameIcon: '-scale-y-100',
    href: '/dashboard/revenue/transactions',
  },
  {
    name: 'Transaction Exceptions',
    icon: ExclamationIcon,
    href: '/dashboard/revenue/transaction-exceptions',
  },
  { name: 'Return Policy', icon: ReceiptRefundIcon, href: '/dashboard/revenue/return-policy' },
];
export const TICKETING = [
  { name: 'Notifications', icon: AnnotationIcon, href: '/dashboard/ticketing/notifications' },
  { name: 'Fares', icon: ClipboardListIcon, href: '/dashboard/ticketing/fares' },
  { name: 'Products', icon: CollectionIcon, href: '/dashboard/ticketing/products' },
  { name: 'Concessions', icon: UsersIcon, href: '/dashboard/ticketing/concessions' },
  { name: 'Schedules', icon: ClockIcon, href: '/dashboard/ticketing/schedules' },
  {
    name: 'Layouts',
    icon: TicketIcon,
    href: '/dashboard/ticketing/layouts',
  },
  {
    name: 'Simulator',
    icon: DocumentDuplicateIcon,
    href: '/dashboard/ticketing/simulator/results',
  },
];

export const JUDGE_PORTAL = [
  { name: 'Transaction lookup', icon: EyeIcon, href: '/dashboard/judge' },
];

export const JOBS = [
  { name: 'Import', icon: IconImport, href: '/dashboard/jobs/import' },
  { name: 'Export', icon: IconExport, href: '/dashboard/jobs/export' },
];

export const FARES = [
  { name: 'Settings', icon: AdjustmentsIcon, href: '/dashboard/fares/settings' },
  { name: 'Fares', icon: TicketIcon, href: '/dashboard/fares/fares' },
];

export const EVENTS = [
  { name: 'Categories', icon: ClipboardListIcon, href: '/dashboard/events/categories' },
  { name: 'Venues', icon: LibraryIcon, href: '/dashboard/events/venues' },
  { name: 'Events', icon: CalendarIcon, href: '/dashboard/events/events' },
  { name: 'Notifications', icon: AnnotationIcon, href: '/dashboard/events/notifications' },
];

export const TOPOLOGY = [
  { name: 'Networks', icon: IconNetwork, href: '/dashboard/topology/networks' },
  { name: 'Routes', icon: MapIcon, href: '/dashboard/topology/routes' },
  { name: 'Zones', icon: ViewGridAddIcon, href: '/dashboard/topology/zones' },
  { name: 'Locations', icon: LocationMarkerIcon, href: '/dashboard/topology/locations' },
  { name: 'Fleet Management', icon: IconFleet, href: '/dashboard/topology/fleets' },
  { name: 'Vehicles', icon: IconVehicle, href: '/dashboard/topology/vehicles' },
];

export const MOBILE_WEB = [
  { name: 'Mobile & Web', icon: IconMobileWeb, href: '/dashboard/apps/mobile-web' },
];
