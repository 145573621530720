/**@format */
import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'helpers';

const TextFieldLoaderView = ({ className, rightComponent, leftComponent }) => {
  return (
    <div
      className={classNames(
        'flex justify-between items-center',
        'px-2',
        {
          'w-full': !className?.includes('w-'),
          'h-[54px]': !className?.includes('h-'),
        },
        'rounded-md border border-gray-300',
        'gap-4',
        'animate-pulse',
        className
      )}
    >
      {leftComponent && <span className={classNames('rounded bg-gray-100', leftComponent.size)} />}
      <div className={classNames('gap-2', 'flex grow flex-col')}>
        <span className={classNames('rounded bg-gray-100', 'w-20 h-3')} />
        <span className={classNames('rounded bg-gray-100', 'w-full h-3')} />
      </div>
      {rightComponent && (
        <span className={classNames('rounded bg-gray-100', rightComponent.size)} />
      )}
    </div>
  );
};

TextFieldLoaderView.PropsTypes = {
  className: PropTypes.string,
  leftComponent: PropTypes.object,
  rightComponent: PropTypes.object,
};

export default TextFieldLoaderView;
