/** @format */

import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

import SwitchClientModal from 'layout/DashboardLayout/Header/Modals/SwitchClient/SwitchClientModal';
import { selectClient, selectedClient } from 'store/client/clientSlice';
import { classNames } from 'helpers';

import { CollapsedBreadCrumb } from './components/CollapsedBreadCrumb';
import { FullBreadCrumb } from './components/FullBreadCrumb';

const AdminSwitchView = ({ onToggleModal, clientName, isOpen }) => {
  return (
    <div className='flex'>
      <button className='box-border flex items-center' onClick={onToggleModal}>
        <div
          className={classNames(
            'flex items-center ',
            { 'text-gray-700 bg-gray-200': !isOpen },
            { 'bg-indigo-100 text-indigo-600': isOpen },
            'py-1.5 px-2 text-sm rounded max-w-md sm:w-max'
          )}
        >
          <span className='truncate--text-line'>{clientName}</span>
          {!isOpen ? (
            <ChevronDownIcon className={'text-gray-400 ml-1 w-4 min-w-[16px]'} />
          ) : (
            <ChevronUpIcon
              className={`${!isOpen} ? 'text-gray-400' : 'text-indigo-500 ml-1 w-4 min-w-[16px]`}
            />
          )}
        </div>
      </button>
    </div>
  );
};

const NonAdminSwitchView = ({ clientName }) => (
  <div className='flex'>
    <span className='box-border flex items-center'>
      <div className='flex items-center text-gray-700 bg-gray-200 py-1.5 px-2 text-sm rounded max-w-md sm:w-max'>
        <span className='truncate--text-line '>{clientName}</span>
      </div>
    </span>
  </div>
);

const Breadcrumb = ({ path }) => {
  const [showModal, setShowModal] = useState(false);
  const clientsData = useSelector(selectClient);
  const client = useSelector(selectedClient);

  const handleTurnOnOffModal = useCallback(() => {
    setShowModal(!showModal);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <div className={classNames('flex flex-col sm:flex-row md:items-center', 'gap-y-1 sm:gap-x-2')}>
      {clientsData?.length > 1 ? (
        <AdminSwitchView
          clientName={client?.name}
          isOpen={showModal}
          onToggleModal={handleTurnOnOffModal}
        />
      ) : (
        <NonAdminSwitchView clientName={client?.name} />
      )}
      {path?.length > 2 ? (
        <>
          <CollapsedBreadCrumb path={path} className={classNames('flex lg:hidden')} />
          <FullBreadCrumb path={path} className={classNames('hidden lg:flex')} />
        </>
      ) : (
        <FullBreadCrumb path={path} className={classNames('flex')} />
      )}
      {showModal && (
        <SwitchClientModal onClose={handleCloseModal} showModal={showModal} clients={clientsData} />
      )}
    </div>
  );
};

export default Breadcrumb;
