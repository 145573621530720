/**@format */

import React from 'react';
import PropTypes from 'prop-types';
import { Disclosure } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { ChevronUpIcon, TrashIcon } from '@heroicons/react/solid';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  useDeleteAllItemsCartOneOperationMutation,
  useDeleteCartItemMutation,
} from 'services/bo/frontdeskWeb/frontdesk.cart';
import { selectCartUUID } from 'store/cart/cartSlice';
import { classNames, responseError } from 'helpers';
import { useConfirm, useToast } from 'hooks';

import CartItemsView from './CartItemsView';
import { DEFAULT_OPERATIONS_TO_BAG } from '../Constatns/Constants';

const BodyCartView = ({
  cartList = {},
  onRemovedItem = () => {},
  isOpen = false,
  className,
  onRemovingItem = () => {},
}) => {
  const currentCartUUID = useSelector(selectCartUUID);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { confirm } = useConfirm();
  const toast = useToast();

  const [deleteCartItem] = useDeleteCartItemMutation();
  const handleDeleteCartItem = async cartItemId => {
    const isConfirmed = await confirm({
      title: t('Delete item'),
    });
    try {
      dispatch(showLoading());
      onRemovingItem(true);
      if (isConfirmed) {
        await deleteCartItem({
          cartUuid: currentCartUUID,
          cartItemId,
        }).unwrap();
        onRemovedItem && onRemovedItem();
      }
    } catch (err) {
      responseError(err, toast, t, navigate, location);
    }
    onRemovingItem(false);
    dispatch(hideLoading());
  };

  const [deleteAllItemsOperation] = useDeleteAllItemsCartOneOperationMutation();
  const handleRemoveAllItemsOneOperation = async (operation, e) => {
    e.stopPropagation();
    e.preventDefault();
    const isConfirmed = await confirm({
      title: [t('Delete operation')],
      text: [t('Are you sure you want to delete this operation?')],
    });
    try {
      dispatch(showLoading());
      if (isConfirmed) {
        await deleteAllItemsOperation({ cartUuid: currentCartUUID, operation }).unwrap();
        onRemovedItem && onRemovedItem();
      }
    } catch (err) {
      responseError(err, toast, t, navigate, location);
    }
    dispatch(hideLoading());
  };

  const renderCartItemsView = () => {
    const sortArrItems = [];
    Object.entries(cartList).map(i =>
      i[1]?.length > 1 ? sortArrItems.unshift(i) : sortArrItems.push(i)
    );
    return sortArrItems.map(([key, value], i) => {
      const findNameOperation = DEFAULT_OPERATIONS_TO_BAG?.find(op => op?.id === key)?.name;
      return (
        <div key={`disclosure-item-${value?.cart_item_id}${key}${i}`}>
          {value?.length === 1 ? (
            <div className={classNames('pt-2')}>
              <CartItemsView
                value={value[0]}
                onRemoveItem={handleDeleteCartItem}
                operation={findNameOperation}
              />
            </div>
          ) : (
            <Disclosure defaultOpen={isOpen}>
              {({ open }) => {
                return (
                  <>
                    <Disclosure.Button
                      className={classNames(
                        'flex justify-between',
                        'w-full',
                        'rounded-lg',
                        'bg-gray-100',
                        'disabled:cursor-not-allowed',
                        'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        'text-left text-sm font-medium text-gray-900',
                        'px-2 py-2'
                      )}
                    >
                      <span>{findNameOperation}</span>
                      <div className={classNames('flex items-center', 'space-x-3')}>
                        <div
                          tabIndex='0'
                          className={classNames(
                            'rounded-full border-0',
                            'flex',
                            'w-5 h-5 p-0',
                            'hover:bg-transparent focus:rounded-full',
                            'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                          )}
                          onClick={e => handleRemoveAllItemsOneOperation(key, e)}
                        >
                          <TrashIcon className='fill-gray-400 shrink-0 w-5' />
                        </div>
                        <ChevronUpIcon
                          className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-500`}
                        />
                      </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className='px-4 pt-4 pb-2 text-sm text-gray-500'>
                      <ul className={classNames(className)}>
                        {value?.map((opn, idx) => {
                          return (
                            <li key={`disclosure-item-${opn?.cart_item_id}${idx}`}>
                              <CartItemsView value={opn} onRemoveItem={handleDeleteCartItem} />
                            </li>
                          );
                        })}
                      </ul>
                    </Disclosure.Panel>
                  </>
                );
              }}
            </Disclosure>
          )}
        </div>
      );
    });
  };
  return <div className={classNames('space-y-3')}>{renderCartItemsView()}</div>;
};

BodyCartView.PropsTypes = {
  cartList: PropTypes.array,
  onRemovedItem: PropTypes.func,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onRemovingItem: PropTypes.func,
};
export default BodyCartView;
