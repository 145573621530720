/** @format */

import axios from 'axios';

export const staticService = {
  getPermissions,
};

function getPermissions() {
  const requestOptions = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return axios.get(`/api/permissions`, requestOptions);
}
