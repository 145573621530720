/**@format */
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { getClientId, getCurrentLang, getToken } from 'helpers';

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: '/api/',
  prepareHeaders: (headers, { getState }) => {
    const token = getToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    const clientId = getClientId();
    if (clientId) {
      headers.set('Flowbird-Client-Id', `${clientId}`);
    }
    const currentLng = getCurrentLang();
    if (currentLng) {
      headers.set('Accept-Language', `${currentLng}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const api = createApi({
  reducerPath: 'flowbirdApi',
  baseQuery: baseQueryWithRetry,
  keepUnusedDataFor: 10,
  tagTypes: [
    'UserClients',
    'UsersSettings',
    'UsersInventory',
    'UsersInventoryAuditTrail',
    'PermissionProfilesDropdown',
    'PermissionProfiles',
    'Permissions',
    'Translations',
    'ClientsManager',
    'ClientsGeneral',
    'ClientsPartners',
    'ClientsFeatures',
    'ClientsUsers',
    'ClientsLanguages',
    'ClientsLoyaltyProgram',
    'DevicesManagerSettings',
    'ClientsDropdownTimezones',
    'DevicesManagerAxioManifest',
    'DevicesManagerAxioControlLists',
    'DevicesManagerAxioPvManifest',
    'DevicesManagerHandheldManifest',
    'DevicesManagerTomAgents',
    'DevicesManagerTomHardware',
    'DevicesManagerTomManifest',
    'DevicesManagerTvmCommands',
    'DevicesManagerTvmGeneral',
    'DevicesManagerTvmHOO',
    'DevicesManagerTvmHOODropdown',
    'DevicesManagerTvmManifest',
    'DevicesManagerTvmVitals',
    'DevicesMangerAxio',
    'DevicesMangerAxioGeneral',
    'DevicesMangerAxioPv',
    'DevicesMangerAxioPvGeneral',
    'DevicesMangerHandheldGeneral',
    'DevicesMangerHandhelds',
    'DevicesMangerTomGeneral',
    'DevicesMangerTOMs',
    'DevicesMangerTvm',
    'DevicesMangerAgents',
    'DevicesMangerAgentGeneral',
    'DevicesMangerAgentPermissions',
    'EditPpEditProgramEnrollmentPendingAp',
    'EditPpEditProgramGeneral',
    'EditPpEditProgramPreapprovedPatrons',
    'EditPpEditProgramProfiles',
    'EditPpMedia',
    'EditPpPrograms',
    'EventsList',
    'EventGeneralData',
    'EventDetailsData',
    'EventShowTimesData',
    'EventShowTimesGeneralData',
    'EventShowTimesProductsData',
    'EventGeneralDetails',
    'EventsNotifications',
    'FaresNamesList',
    'Features',
    'ReleaseNoteSetting',
    'ReleaseNotes',
    'FrontdeskHardware',
    'FrontdeskAgentsHistory',
    'FrontdeskCartSettingsClient',
    'FrontdeskCart',
    'FrontdeskCartUUID',
    'FrontdeskCustomerComments',
    'FrontdeskLookupMediaLinked',
    'MediaLookupPatronInfoOwner',
    'MediaLookupPointOfContact',
    'FrontdeskMediaPasses',
    'FrontdeskOperations',
    'FrontdeskOperationsHistory',
    'FrontdeskWebSettings',
    'FrontdeskWebStatements',
    'FrontdeskPaymentHistory',
    'FrontdeskLookupMediaDetails',
    'FrontdeskStatementsPaymentHistory',
    'FrontdeskCustomerPassDetails',
    'FrontdeskCustomerPasses',
    'FrontdeskCustomerAccount',
    'FrontdeskCustomerAccountHistory',
    'FrontdeskCustomerAddresses',
    'FrontdeskCustomerLoyalty',
    'FrontdeskCustomerMediaProfiles',
    'FrontdeskCustomerPassProfiles',
    'FrontdeskCustomerMediaOwnedPatron',
    'FrontdeskCustomerLookup',
    'FrontdeskCustomerFareCards',
    'FrontdeskCustomerOpenPayments',
    'FrontdeskCustomerPaymentCards',
    'FrontdeskPaymentMeans',
    'FrontdeskCartRedeemVouchers',
    'FrontdeskBulkActionsHistory',
    'FrontdeskListImports',
    'FrontdeskImportDetails',
    'FrontdeskRoles',
    'FrontdeskRolesDetails',
    'FrontdeskPermissions',
    'FrontdeskPermissionsDetails',
    'FrontdeskSalesAnalysisPosMobile',
    'FrontdeskSalesAnalysisWeb',
    'FrontdeskSalesAnalysisAgents',
    'FrontdeskSessionsPos',
    'FrontdeskSessionsMobile',
    'FrontdeskSessionsDetail',
    'FrontdeskStatements',
    'BOApiUrl',
    'GoogleMapApiKey',
    'JudgePortalParkingTickets',
    'JudgePortalTicketOrders',
    'HawkeyeHandhelds',
    'HawkeyeInspectionSessions',
    'HawkeyeInspectionSessionsDetails',
    'HawkeyeMonitoring',
    'HawkeyeAuditTrail',
    'HawkeyeMonitoring',
    'HawkeyeUsers',
    'HawkeyeUserDevices',
    'HistoryOperationsList',
    'HistorySourcesList',
    'MediaStatistics',
    'MediaFareCaps',
    'MediaLookupNoPatron',
    'MediaLookupHistory',
    'MediaLookupPasses',
    'MediaLookupPassDetails',
    'MediaLookupProfiles',
    'MediaLookupComments',
    'MediaManagerIdManagePartners',
    'MediaManagerManageCitations',
    'MediaManagerManageGeneral',
    'MediaManagerManageGeneralPartners',
    'MediaManagerManageHistory',
    'MediaManagerManageLinkedAccounts',
    'MediaManagerManageNonActivePasses',
    'MediaManagerManagePasses',
    'MediaManagerManagePatronInfoOwner',
    'MediaManagerManagePatronInfoPoc',
    'MediaManagerManageProfiles',
    'MediaManagerManageProfilesPrograms',
    'MediaManagerMedia',
    'MediaLookupSearch',
    'MediaSetting',
    'MonitoringNotificationDevices',
    'MonitoringVitalsTVM',
    'MonitoringEventsAxio',
    'MonitoringEventsAxioPV',
    'MonitoringEventsTVM',
    'MonitoringEventsAssets',
    'MonitoringControls',
    'MonitoringConsumables',
    'MonitoringNotifications',
    'MonitoringNotificationAlarms',
    'MonitoringNotificationSubscribers',
    'MonitoringNotificationsDevicesDropdown',
    'MonitoringNotificationExporters',
    'MonitoringNotificationSchedules',
    'MonitoringNotificationEvents',
    'MonitoringDropdownCategories',
    'MonitoringDropdownVehicles',
    'MonitoringDropdownEvents',
    'MonitoringDropdownAssets',
    'MonitoringDropdownResultsTypes',
    'MonitoringDropdownListTypes',
    'MonitoringDropdownInputSources',
    'MonitoringDropdownResultStatuses',
    'MonitoringDropdownLocations',
    'MonitoringDropdownDevices',
    'MonitoringDropdownLine',
    'MonitoringDropdownAlarmAXIOCategory',
    'MonitoringDropdownAlarmLevel',
    'MonitoringDropdownGroupNames',
    'MonitoringDropdownSoftware',
    'MonitoringDropdownParameter',
    'MonitoringDropdownEncryptionKey',
    'MonitoringDropdownAlarm',
    'MonitoringDropdownCategory',
    'MonitoringAlarmDetailAxio',
    'MonitoringAlarmDetailsTVM',
    'MonitoringDropdownAlarmTVMCategory',
    'MonitoringDropdownAlarmAXIOCategory',
    'MonitoringDropdownSoftware',
    'MonitoringDropdownParameter',
    'MonitoringDropdownDevicesIds',
    'MonitoringDropdownPaperStatuses',
    'MonitoringDropdownGSMStatuses',
    'MonitoringDropdownBatteryStatuses',
    'Notifications',
    'OpenPaymentsStatistics',
    'OpenPaymentsTodayStatistics',
    'OpenPaymentsTaps',
    'OpenPaymentsCases',
    'OpenPaymentsCasesGeneral',
    'OpenPaymentsCaseHistory',
    'OpenPaymentsPayments',
    'OpenPaymentsInventory',
    'OpenPaymentsInventoryGeneral',
    'OpenPaymentsInventoryHistory',
    'OpenPaymentsInventoryPatron',
    'OpenPaymentsInventoryPatronOwner',
    'OpenPaymentsInventoryPatronPoc',
    'OpenPaymentsInventoryCases',
    'Partners',
    'PartnersPrograms',
    'PartnersProgramsManager',
    'PartnerDetailsUsers',
    'PartnerDetailsCreditCards',
    'PartnerDetailsStatements',
    'PartnerDetailsActivity',
    'PassesStatistics',
    'PassConcessionsList',
    'PassesAssets',
    'PassesInventory',
    'PassesSettings',
    'PassFaresList',
    'PassInformation',
    'PassActivity',
    'PassMediaList',
    'PassPatronsList',
    'PassProductsList',
    'PassProgramsSchedulesList',
    'PatronComments',
    'PatronPaymentCards',
    'Patrons',
    'SearchPatrons',
    'PatronsAllMedia',
    'PatronsCitations',
    'PatronsCitationsCategories',
    'PatronsCitationsTypes',
    'PatronsGeneral',
    'PatronsGeneralAccountHistory',
    'PatronsGeneralAddresses',
    'PatronsGeneralLoyalty',
    'PatronsAvatar',
    'PatronsOpenPayments',
    'PatronsPasses',
    'PatronsMediaProfiles',
    'PatronsPassProfiles',
    'PatronsScanTap',
    'PatronsStatistics',
    'PermissionsRoles',
    'PermissionsRolesDropdown',
    'Programs',
    'Settings',
    'TicketingConcessions',
    'TicketingProducts',
    'TopologyLocationsPasses',
    'UserData',
    'UserClients',
    'UsersSettings',
    'VouchersSettings',
    'VouchersInventoryAmountBased',
    'VouchersInventoryTimeBased',
    'VouchersInventorySchedules',
    'VouchersInventoryAudit',
    'VouchersInventoryUsageRestrictions',
    'VouchersAuditTrial',
    'VouchersSchedules',
    'VouchersSchedulesGroups',
    'VouchersSchedulesGroupsUsage',
    'JobsImport',
    'JobsExport',
    'FaresSettings',
    'FaresManager',
    'FaresGeneral',
    'FareTable',
    'FareRoutes',
    'FareAssets',
    'DeploymentsArtifacts',
    'DeploymentsArtifactDetail',
    'DeploymentListingApp',
    'DeploymentListingAxio',
    'DeploymentListingAxioPV',
    'DeploymentListingAxioFrontdesk',
    'DeploymentListingAxioHawkeye',
    'DeploymentListingAxioTvm',
    'DeploymentListingAxioWeb',
    'DeploymentsTargetList',
    'ControlListsSetting',
    'ControlListsSetting',
    'ControlListsListContentGreen',
    'ControlListsListContentRed',
    'ControlListsListContentGrey',
    'ControlListsSchedule',
    'ControlListsAuditTrial',
    'ControlListsSupervisionCurrent',
    'ControlListsSupervisionAssets',
    'ControlListsSupervisionHistory',
    'EventsCategoriesEvents',
    'EventsCategoriesVenues',
    'ControlListsListGeneration',
    'RevenueCashContentAnalysis',
    'RevenueTransactions',
    'RevenueTransactionDetail',
    'RevenueDailyRevenue',
    'RevenueCashCollection',
    'RevenueCashCollectionDetail',
    'TopologyNetworks',
    'TopologyNetworksRoutes',
    'TopologyLocations',
    'TopologyLocationsWebsite',
    'TopologyLocationsAddress',
    'TopologyLocationsRoutes',
    'TopologyLocationsAssets',
    'TopologyLocationsZones',
    'TopologyFleets',
    'TopologyFleetsVehicles',
    'EventsVenuesLocations',
    'EventsVenues',
    'TopologyRoutes',
    'TopologyRouteStops',
    'TopologyRouteNetworks',
    'TopologyStops',
    'TopologyZones',
    'TopologyZonesLocations',
    'TopologyVehicles',
    'TopologyVehiclesAssets',
    'TicketingNotifications',
    'TicketingSchedules',
    'TicketingSchedulesUsages',
    'TicketingSchedulesGroups',
    'TicketingSchedulesGroupsUsages',
    'TicketingLayouts',
    'TicketingLayoutUsage',
    'TicketingDropdownContext',
    'TicketingProductsTranslations',
    'TicketingProductsValidity',
    'TicketingProductsConcessions',
    'TicketingProductsTickets',
    'UsersPermissionsDetails',
    'UsersPermissions',
    'UsersRoles',
    'UsersRolesDetails',
    'FrontdeskSettings',
    'HawkeyeSettings',
    'MobileWebAndroid',
    'MobileWebIos',
    'MobileWebWeb',
    'MobileWebMaas',
    'MobileWebDetails',
    'AllFeatures',
  ],
  endpoints: () => ({}),
});

export const enhancedApi = api.enhanceEndpoints({
  endpoints: () => ({
    getApiVersion: () => '0.0.0',
  }),
});
