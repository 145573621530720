/** @format */
import { combineReducers } from '@reduxjs/toolkit';

import notificationsReducer from './Notifications/notificationsSlice';
import { patronsReducer } from './Patrons';

export const crmReducer = combineReducers({
  patrons: patronsReducer,
  notifications: notificationsReducer,
});
