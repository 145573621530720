/** @format */

import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getLocalStorageByKey } from 'helpers/store-helpers';
import { ME, PERMISSIONS, SLUGS } from 'constants/Constants';

const initialState = {
  data: getLocalStorageByKey(ME),
  features: [],
};
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, { payload }) => {
      const userData = payload || JSON.parse(localStorage.getItem(ME));
      const permissions = JSON.parse(localStorage.getItem(PERMISSIONS)) || [];
      let slugMappedUserPermission = {};
      Object.keys(userData.permissions).forEach(clientId => {
        slugMappedUserPermission[clientId] = userData.permissions[clientId]?.map(perId => {
          return permissions.find(per => per.id === perId)?.slug ?? perId;
        });
      });
      localStorage.setItem(SLUGS, JSON.stringify(slugMappedUserPermission));
      const meData = { ...userData, permissionsBySlug: slugMappedUserPermission };
      localStorage.setItem(ME, JSON.stringify(meData));
      state.data = meData;
    },
    updateUserPhoneData: (state, { payload }) => {
      state.data.phone_number = payload?.phone_number;
      state.data.phone_country = payload?.phone_country;
    },
    setMeFeatures: (state, { payload }) => {
      state.features = payload;
    },
  },
});
export const { setUserData, updateUserPhoneData, setMeFeatures } = userSlice.actions;
export const meData = state => state.user.data;
export const meFeatures = state => state.user.features;
export const selectUser = state => state.user;

export const selectUserPermissionsBySlug = createSelector(
  [selectUser],
  user =>
    user.data?.permissionsBySlug ||
    (localStorage.getItem(ME)?.permissionsBySlug
      ? JSON.parse(localStorage.getItem(ME)?.permissionsBySlug)
      : {})
);

export const selectClientUserPermissions = createSelector(
  [selectUserPermissionsBySlug, (state, clientId) => clientId],
  (permissions, clientId) => permissions?.[clientId]
);

export default userSlice.reducer;
