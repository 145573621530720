/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { VOUCHERS as NAV_VOUCHERS } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const VouchersAuditTrial = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Vouchers/AuditTrial'))),
  t => [{ name: t('Vouchers'), url: '/dashboard/vouchers' }, { name: t('Audit Trial') }]
);
const VouchersInventory = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Vouchers/Inventory/Inventory'))),
  t => [{ name: t('Vouchers'), url: '/dashboard/vouchers' }, { name: t('Inventory') }]
);
const VouchersInventoryEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Vouchers/Inventory/EditVoucher'))),
  t => [{ name: t('Vouchers'), url: '/dashboard/vouchers' }, { name: t('Inventory') }]
);
const VouchersSchedules = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Vouchers/Schedules'))),
  t => [{ name: t('Vouchers'), url: '/dashboard/vouchers' }, { name: t('Schedules') }]
);
const VouchersSettings = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Vouchers/Settings'))),
  t => [{ name: t('Vouchers'), url: '/dashboard/vouchers' }, { name: t('Vouchers settings') }]
);

const VouchersRoutes = (
  <Route path='vouchers' element={<DashboardLayout nav={NAV_VOUCHERS} />}>
    <Route index element={<Navigate to='/dashboard/vouchers/settings' replace />} />
    <Route path='settings' element={<VouchersSettings title='Vouchers Settings' />} />
    <Route path='inventory' element={<VouchersInventory title='Vouchers Inventory' />} />
    <Route
      path='inventory/:uuid'
      element={<VouchersInventoryEdit title='Vouchers Inventory Edit' />}
    />
    <Route path='schedules' element={<VouchersSchedules title='Vouchers Schedules' />} />
    <Route path='audit-trial' element={<VouchersAuditTrial title='Vouchers Audit Trial' />} />
    <Route path='*' element={<Navigate to='/dashboard/vouchers/settings' replace />} />
  </Route>
);

export default VouchersRoutes;
