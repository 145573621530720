/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { FRONT_DESK_WEB } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const CustomerLookup = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/FrontdeskWeb/CustomerLookup'))),
  t => [
    { name: t('Frontdesk Web'), url: '/dashboard/frontdesk-web' },
    { name: t('Customer Lookup') },
  ]
);
const CustomerLookupPatron = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/FrontdeskWeb/CustomerLookup/Patron'))),
  t => [
    {
      name: t('Frontdesk Web'),
      url: '/dashboard/frontdesk-web',
    },
    { name: t('Customer Lookup'), url: '/dashboard/frontdesk-web/customer-lookup' },
  ]
);
const CustomerLookupPatronGeneral = withLazyComponent(
  lazy(() => import('pages/dashboard/Manager/Patrons/Patron/General'))
);
const CustomerLookupPatronProfiles = withLazyComponent(
  lazy(() => import('pages/dashboard/Manager/Patrons/Patron/Profiles'))
);
const CustomerLookupPatronCreditCards = withLazyComponent(
  lazy(() => import('pages/dashboard/Manager/Patrons/Patron/CreditCards'))
);
const CustomerLookupPatronPasses = withLazyComponent(
  lazy(() => import('pages/dashboard/Manager/Patrons/Patron/Passes'))
);
const CustomerLookupPatronFareCards = withLazyComponent(
  lazy(() => import('pages/dashboard/Manager/Patrons/Patron/StoredValueCards'))
);
const CustomerLookupPatronComments = withLazyComponent(
  lazy(() => import('pages/dashboard/Manager/Patrons/Patron/Comments'))
);
const MediaLookup = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/FrontdeskWeb/MediaLookup'))),
  t => [{ name: t('Frontdesk Web'), url: '/dashboard/frontdesk-web' }, { name: t('Media') }]
);
/** #Region MEDIA LOOKUP */
const MediaLookupInfo = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/FrontdeskWeb/MediaLookup/MediaLookupInfo'))),
  t => [
    {
      name: t('Frontdesk Web'),
      url: '/dashboard/frontdesk-web',
    },
    { name: t('Media Lookup') },
  ]
);
const MediaLookupGeneral = withLazyComponent(
  lazy(() => import('pages/dashboard/FrontdeskWeb/MediaLookup/MediaLookupInfo/General'))
);
const MediaLookupHistory = withLazyComponent(
  lazy(() =>
    import(
      'pages/dashboard/FrontdeskWeb/MediaLookup/MediaLookupInfo/General/Components/MediaHistory'
    )
  )
);

const MediaLookupPatronInformation = withLazyComponent(
  lazy(() => import('pages/dashboard/FrontdeskWeb/MediaLookup/MediaLookupInfo/PatronInformation'))
);
const MediaLookupLinkedAccounts = withLazyComponent(
  lazy(() => import('pages/dashboard/FrontdeskWeb/MediaLookup/MediaLookupInfo/LinkedAccounts'))
);
const MediaLookupPasses = withLazyComponent(
  lazy(() => import('pages/dashboard/FrontdeskWeb/MediaLookup/MediaLookupInfo/Passes'))
);
const MediaLookupProfiles = withLazyComponent(
  lazy(() => import('pages/dashboard/FrontdeskWeb/MediaLookup/MediaLookupInfo/Profiles'))
);
const MediaLookupComments = withLazyComponent(
  lazy(() => import('pages/dashboard/FrontdeskWeb/MediaLookup/MediaLookupInfo/Comments'))
);
/** #End Region MEDIA LOOKUP */
const BulkActions = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/FrontdeskWeb/BulkActions'))),
  t => [{ name: t('Frontdesk Web'), url: '/dashboard/frontdesk-web' }, { name: t('Bulk Actions') }]
);
const BulkActionsHistory = withHeaderBreadcrumb(
  withLazyComponent(
    lazy(() => import('pages/dashboard/FrontdeskWeb/BulkActions/BulkActionsHistory'))
  ),
  t => [
    { name: t('Frontdesk Web'), url: '/dashboard/frontdesk-web' },
    { name: t('Bulk Actions'), url: '/dashboard/frontdesk-web/bulk-actions' },
    { name: t('Bulk Actions History') },
  ]
);
const OperationsHistory = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/FrontdeskWeb/OperationsHistory'))),
  t => [
    { name: t('Frontdesk Web'), url: '/dashboard/frontdesk-web' },
    { name: t('Operations History') },
  ]
);
const OrderCheckout = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/FrontdeskWeb/OrderCheckout'))),
  t => [
    { name: t('Frontdesk Web'), url: '/dashboard/frontdesk-web' },
    { name: t('Order Checkout') },
  ]
);
const Settings = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/FrontdeskWeb/Settings'))),
  t => [{ name: t('Frontdesk Web'), url: '/dashboard/frontdesk-web' }, { name: t('Settings') }]
);
const Statements = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/FrontdeskWeb/Statements'))),
  t => [{ name: t('Frontdesk Web'), url: '/dashboard/frontdesk-web' }, { name: t('Statements') }]
);
const RolesPermissions = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Common/RolesPermissions'))),
  t => [
    { name: t('Frontdesk Web'), url: '/dashboard/frontdesk-web' },
    { name: t('Roles & Permissions') },
  ]
);
const RolesPermissionsEdit = withHeaderBreadcrumb(
  withLazyComponent(
    lazy(() => import('pages/dashboard/Common/RolesPermissions/RolesPermissionsEdit'))
  ),
  t => [
    { name: t('Frontdesk Web'), url: '/dashboard/frontdesk-web' },
    { name: t('Roles & Permissions'), url: '/dashboard/frontdesk-web/permissions' },
  ]
);

const FrontdeskWebRoutes = (
  <Route path='frontdesk-web' element={<DashboardLayout nav={FRONT_DESK_WEB} />}>
    <Route index element={<Navigate to='/dashboard/frontdesk-web/customer-lookup' replace />} />
    <Route path='settings' element={<Settings title='Settings page' />} />
    <Route path='statements' element={<Statements title='Statements page' />} />
    <Route path='permissions' element={<RolesPermissions title='Roles & Permissions page' />} />
    <Route
      path='permissions/:roleId'
      element={<RolesPermissionsEdit title='Roles & Permissions Edit page' />}
    />
    <Route path='customer-lookup' element={<CustomerLookup title='Frontdesk Web page' />} />
    <Route
      path='customer-lookup/:patronId'
      element={<CustomerLookupPatron title='Customer lookup page' />}
    >
      <Route
        path='general'
        element={<CustomerLookupPatronGeneral title='Customer Lookup Patron General' />}
      />
      <Route
        path='profiles'
        element={<CustomerLookupPatronProfiles title='Customer Lookup Patron Profiles' />}
      />
      <Route
        path='cc'
        element={<CustomerLookupPatronCreditCards title='Customer Lookup Patron Credit Cards' />}
      />
      <Route
        path='passes'
        element={<CustomerLookupPatronPasses title='Customer Lookup Patron Passes' />}
      />
      <Route
        path='fc'
        element={<CustomerLookupPatronFareCards title='Customer Lookup Patron Fare Cards' />}
      />
      <Route
        path='comments'
        element={<CustomerLookupPatronComments title='Customer Lookup Patron Comment' />}
      />
      <Route
        path='*'
        element={<Navigate to='/dashboard/frontdesk-web/customer-lookup' replace />}
      />
    </Route>
    <Route path='media-lookup' element={<MediaLookup title='Media lookup page' />} />
    <Route path='media-lookup/:uuid' element={<MediaLookupInfo title='Media lookup page' />}>
      <Route
        path='media-history'
        element={<MediaLookupHistory title='Media lookup History page' />}
      />
      <Route path='general' element={<MediaLookupGeneral title='Media lookup General' />} />
      <Route
        path='patron-information'
        element={<MediaLookupPatronInformation title='Media lookup Patron Information' />}
      />
      <Route
        path='linked-accounts'
        element={<MediaLookupLinkedAccounts title='Media lookup Linked Accounts' />}
      />
      <Route path='passes' element={<MediaLookupPasses title='Media lookup Passes' />} />
      <Route path='profiles' element={<MediaLookupProfiles title='Media lookup Profiles' />} />
      <Route path='comments' element={<MediaLookupComments title='Media lookup Comments' />} />
      <Route path='*' element={<Navigate to='/dashboard/frontdesk-web/media-lookup' replace />} />
    </Route>
    <Route
      path='operations-history'
      element={<OperationsHistory title='Operations history page' />}
    />
    <Route path='bulk-actions' element={<BulkActions title='Bulk actions page' />} />
    <Route
      path='bulk-actions/bulk-actions-history'
      element={<BulkActionsHistory title='Bulk Actions History page' />}
    />
    <Route path='order-checkout' element={<OrderCheckout title='Order checkout page' />} />
    <Route path='*' element={<Navigate to='/dashboard/frontdesk-web/customer-lookup' replace />} />
  </Route>
);

export default FrontdeskWebRoutes;
