/** @format */

import React from 'react';
import { Outlet } from 'react-router-dom';

import Toast from 'components/Toast';
import Footer from './Footer';

const Main = () => {
  return (
    <div className='flex h-full flex-col items-stretch'>
      <main className='relative flex-1 flex flex-col'>
        <Outlet />
      </main>
      <Footer />
      <Toast />
    </div>
  );
};

export default Main;
