/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { JOBS } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const JobsImport = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Jobs/Import'))),
  t => [
    {
      name: t('Jobs'),
      url: '/dashboard/jobs',
    },
    { name: t('Import') },
  ]
);
const JobsExport = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Jobs/Export'))),
  t => [
    {
      name: t('Jobs'),
      url: '/dashboard/jobs',
    },
    { name: t('Export') },
  ]
);

const JobsRoutes = (
  <Route path='jobs' element={<DashboardLayout nav={JOBS} />}>
    <Route index element={<Navigate to='/dashboard/jobs/import' replace />} />
    <Route path='import' element={<JobsImport title='Import page' />} />
    <Route path='export' element={<JobsExport title='Export page' />} />
    <Route path='*' element={<Navigate to='/dashboard/jobs/import' replace />} />
  </Route>
);

export default JobsRoutes;
