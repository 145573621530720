/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { WELCOME as NAV_WELCOME } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const Welcome = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Dashboard/Welcome'))),
  () => []
);

const ReleaseNotes = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Dashboard/ReleaseNotes'))),
  () => []
);

const WelcomeRoutes = (
  <Route path='' element={<DashboardLayout nav={NAV_WELCOME} />}>
    <Route index element={<Navigate to='/dashboard/welcome' replace />} />
    <Route path='welcome' element={<Welcome title='Welcome' />} />
    <Route path='release-notes' element={<ReleaseNotes title='Release Notes' />} />
    <Route path='*' element={<Navigate to='/dashboard/welcome' replace />} />
  </Route>
);

export default WelcomeRoutes;
