/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { FRONT_DESK } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const FrondeskSettings = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Frontdesk/Settings'))),
  t => [{ name: t('Frontdesk'), url: '/dashboard/frontdesk' }, { name: t('Settings') }]
);

const FrondeskHardware = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Frontdesk/HardwareInventory'))),
  t => [{ name: t('Frontdesk'), url: '/dashboard/frontdesk' }, { name: t('Hardware Inventory') }]
);

const FrontdeskSalesAnalysis = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Frontdesk/SalesAnalysis'))),
  t => [{ name: t('Frontdesk'), url: '/dashboard/frontdesk' }, { name: t('Sales Analysis') }]
);

const FrontdeskSessions = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Frontdesk/Sessions'))),
  t => [{ name: t('Frontdesk'), url: '/dashboard/frontdesk' }, { name: t('Sessions') }]
);

const FrontdeskStatements = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Frontdesk/Statements'))),
  t => [{ name: t('Frontdesk'), url: '/dashboard/frontdesk' }, { name: t('Statements') }]
);

const FrontdeskRoutes = (
  <Route path='frontdesk' element={<DashboardLayout nav={FRONT_DESK} />}>
    <Route index element={<Navigate to='/dashboard/frontdesk/sessions' replace />} />
    <Route path='settings' element={<FrondeskSettings title='Frontdesk Settings page' />} />
    <Route path='hardware' element={<FrondeskHardware title='Hardware Inventory page' />} />
    <Route path='sales-analysis' element={<FrontdeskSalesAnalysis title='Sales Analysis page' />} />
    <Route path='sessions' element={<FrontdeskSessions title='Frontdesk page' />} />
    <Route path='statements' element={<FrontdeskStatements title='Statements page' />} />
    <Route path='*' element={<Navigate to='/dashboard/frontdesk/sessions' replace />} />
  </Route>
);

export default FrontdeskRoutes;
