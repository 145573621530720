/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';
import { DEPLOYMENTS as NAV_DEPLOYMENTS } from 'constants/Navigation';

import DashboardLayout from 'layout/DashboardLayout/Main';

const Deployments = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/DeploymentsService/Deployments'))),
  t => [{ name: t('Deployments') }]
);

const DeploymentsArtifacts = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/DeploymentsService/Artifacts'))),
  t => [
    { name: t('Deployments'), url: '/dashboard/deployments/deployments' },
    { name: t('Artifacts') },
  ]
);
const ArtifactEdit = withHeaderBreadcrumb(
  withLazyComponent(
    lazy(() => import('pages/dashboard/DeploymentsService/Artifacts/ArtifactEdit'))
  ),
  t => [
    { name: t('Deployments'), url: '/dashboard/deployments/deployments' },
    { name: t('Artifacts'), url: '/dashboard/deployments/artifacts' },
  ]
);

const DeploymentsEdit = withHeaderBreadcrumb(
  withLazyComponent(
    lazy(() => import('pages/dashboard/DeploymentsService/Deployments/DeploymentEdit'))
  ),
  t => [
    { name: t('Deployments'), url: '/dashboard/deployments/deployments' },
    { name: t('Deployment') },
  ]
);

const DeploymentsRoutes = (
  <Route path='deployments' element={<DashboardLayout nav={NAV_DEPLOYMENTS} />}>
    <Route index element={<Navigate to='/dashboard/deployments/deployments' replace />} />
    <Route path='deployments' element={<Deployments title='Deployments page' />} />
    <Route path='deployments/:id' element={<DeploymentsEdit title='Deployments Edit page' />} />
    <Route path='artifacts' element={<DeploymentsArtifacts title='Deployments Artifacts' />} />
    <Route path='artifacts/:id' element={<ArtifactEdit title='Artifact information page' />} />
    <Route path='*' element={<Navigate to='/dashboard/deployments/artifacts' replace />} />
  </Route>
);

export default DeploymentsRoutes;
