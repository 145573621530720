/** @format */

import React from 'react';
import { XIcon } from '@heroicons/react/solid';

const CloseButton = ({ closeToast }) => (
  <button onClick={closeToast} type='button' aria-label='close' className='self-start'>
    <XIcon className='w-5 text-gray-400' />
  </button>
);

export default CloseButton;
