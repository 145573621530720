/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import DocumentTitle from '../DocumentTitle';

const LOGO_FLOWBIRDHUB = '/assets/icons/logo_flowbirdhub.svg';

const InternalServer = () => {
  const { t } = useTranslation();
  const DOCUMENT_TITLE = 'Internal Server Error';

  const handleClick = () => {
    localStorage.clear();
    window.location.reload(false);
  };

  return (
    <DocumentTitle title={DOCUMENT_TITLE}>
      <main className='h-full flex flex-col items-center justify-center text-center'>
        <img className='mx-auto h-[44px] w-[180px] mb-24' src={LOGO_FLOWBIRDHUB} alt='Workflow' />

        <h1 className='mb-2 text-3xl font-bold text-red-600'>{t('Sorry!')}</h1>

        <p
          className='text-sm text-gray-500 mb-6 mx-6 sm:mx-0'
          dangerouslySetInnerHTML={{
            __html: t(
              'Looks like you caught us at a bad time. <br />We are busy updating the system. We’ll be back soon and we apologize for the inconvenience.'
            ),
          }}
        />

        <Button title={t('Reload')} onClick={handleClick} />
      </main>
    </DocumentTitle>
  );
};

export default InternalServer;
