/**@format */
import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import moment from 'moment-timezone';

import { classNames } from 'helpers';

import { datetimePickerModeEnum } from '../../constants/enum';

const defaultMonthArray = [
  {
    shortName: 'Jan',
    value: 0,
  },
  {
    shortName: 'Feb',
    value: 1,
  },
  {
    shortName: 'Mar',
    value: 2,
  },
  {
    shortName: 'Apr',
    value: 3,
  },
  {
    shortName: 'May',
    value: 4,
  },
  {
    shortName: 'Jun',
    value: 5,
  },
  {
    shortName: 'July',
    value: 6,
  },
  {
    shortName: 'Aug',
    value: 7,
  },
  {
    shortName: 'Sep',
    value: 8,
  },
  {
    shortName: 'Oct',
    value: 9,
  },
  {
    shortName: 'Nov',
    value: 10,
  },
  {
    shortName: 'Dec',
    value: 11,
  },
];

export const MonthPicker = ({
  defaultValue,
  onSelect,
  onSwitchMode,
  minDate,
  maxDate,
  positionDropdownStyle = {
    defaultClassName: '',
    positionTopInlineStyle: {},
  },
}) => {
  const [monthPickerState, setMonthPickerState] = useState({
    month: null,
    year: null,
  });
  const maxMonth = maxDate ? moment(+maxDate).month() : undefined;
  const maxYear = maxDate ? moment(+maxDate).year() : undefined;
  const minMonth = minDate ? moment(+minDate).month() : undefined;
  const minYear = minDate ? moment(+minDate).year() : undefined;
  const isDisableNextYear = monthPickerState.year >= maxYear;
  const isDisablePrevYear = monthPickerState.year <= minYear;

  const handleSelect = value => () => {
    onSelect && onSelect({ year: monthPickerState.year, month: value });
  };
  const handleIncreaseYear = () => {
    if (isDisableNextYear) return;
    setMonthPickerState(prevState => ({ ...prevState, year: prevState.year + 1 }));
  };
  const handleDecreaseYear = () => {
    if (isDisablePrevYear) return;
    setMonthPickerState(prevState => ({ ...prevState, year: prevState.year - 1 }));
  };
  const handleSwitchToYearMode = () => {
    onSwitchMode && onSwitchMode(datetimePickerModeEnum.YEAR_DECADE_PICKER);
  };

  useEffect(() => {
    if (defaultValue && defaultValue.toString().length > 0) {
      setMonthPickerState({ month: defaultValue.month, year: defaultValue.year });
    } else setMonthPickerState({ month: moment().month(), year: moment().year() });
  }, [defaultValue]);

  return (
    <div
      className={classNames(
        'flex flex-col',
        'w-fit',
        'z-[9999]',
        'absolute',
        'border border-gray-300 border-solid rounded-lg',
        'px-5',
        'bg-white',
        positionDropdownStyle.defaultClassName
      )}
      style={positionDropdownStyle.positionTopInlineStyle}
    >
      <div
        className={classNames(
          'flex justify-between items-center',
          'py-3',
          'border-b border-gray-200'
        )}
      >
        <button
          type='button'
          className='text-lg font-medium text-gray-800'
          onClick={handleSwitchToYearMode}
        >
          {monthPickerState.year}
        </button>
        <div className='flex justify-center items-center text-gray-400'>
          <button type='button' onClick={handleDecreaseYear} disabled={isDisablePrevYear}>
            <ChevronLeftIcon
              className={classNames('w-6 h-6', {
                'fill-gray-200': isDisablePrevYear,
              })}
            />
          </button>
          <button type='button' onClick={handleIncreaseYear} disabled={isDisableNextYear}>
            <ChevronRightIcon
              className={classNames('w-6 h-6', {
                'fill-gray-200': isDisableNextYear,
              })}
            />
          </button>
        </div>
      </div>
      <div className={classNames('py-4', 'grid grid-cols-[repeat(4,1fr)]', 'gap-4')}>
        {defaultMonthArray.map(month => {
          const isDisabledNextMonth =
            monthPickerState.year > maxYear
              ? true
              : monthPickerState.year === maxYear
              ? month.value > maxMonth
              : false;
          const isDisabledPrevMonth =
            monthPickerState.year < minYear
              ? true
              : monthPickerState.year === minYear
              ? month.value < minMonth
              : false;
          const isDisabled = isDisabledPrevMonth || isDisabledNextMonth;
          return (
            <button
              key={`month_picker_${month.value}`}
              type='button'
              className={classNames(
                'text-sm font-medium ',
                'rounded-[4px]',
                'px-5 py-4',
                {
                  'text-gray-700 hover:bg-gray-100':
                    !isDisabled &&
                    !(
                      month.value === monthPickerState.month &&
                      monthPickerState.year === defaultValue?.year
                    ),
                  'text-gray-400': isDisabled,
                },
                {
                  'bg-indigo-600 text-white hover:bg-indigo-600':
                    month.value === monthPickerState.month &&
                    monthPickerState.year === defaultValue?.year,
                }
              )}
              onClick={handleSelect(month.value)}
              disabled={isDisabled}
            >
              {month.shortName}
            </button>
          );
        })}
      </div>
    </div>
  );
};
