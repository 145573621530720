/**@format */
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ChevronRightIcon } from '@heroicons/react/solid';

import { classNames } from '../../../helpers';

export const FullBreadCrumb = ({ path, className }) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        'items-center',
        'text-gray-500 text-sm font-medium',
        'lg:gap-[15px]',
        className
      )}
    >
      <Link to='/dashboard/welcome' className='text-gray-500 text-sm font-medium'>
        {t('Dashboard')}
      </Link>

      {path?.map(item => (
        <Fragment key={item.name}>
          <div className='flex'>
            <ChevronRightIcon className='w-5 h-5 text-gray-400' />
          </div>

          {item.url ? (
            <div className='flex'>
              <Link className='whitespace-nowrap' to={item.url}>
                {item.name}
              </Link>
            </div>
          ) : (
            /**Sample trick to handle text-ellipsis for div/td width in percent
             * In future we need to make it show full value by popover or something similar
             */
            <div className='table table-fixed w-full'>
              <span
                className={classNames('table-cell whitespace-nowrap overflow-hidden text-ellipsis')}
              >
                {item.name}
              </span>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};
