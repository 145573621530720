/** @format */

import React, { useEffect, useRef, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ChevronDownIcon, ExclamationCircleIcon, XIcon } from '@heroicons/react/solid';

import { classNames, isEqual } from 'helpers';
import {
  chipSizeEnum,
  chipTypesEnum,
  labelErrorPositionEnum,
  selectOptionsPositionEnum,
} from 'constants/enum';

import Button from 'components/Button';
import { COLOR_TRANSPARENT } from 'constants/Constants';
import { Chip } from 'components/Chip';
import { Checkbox } from 'components/Checkbox';
import { Tooltip } from 'components/Tooltip';

const DEFAULT_SELECT_ALL_OPTION = { id: null, name: 'selectAll' };

const MultipleSelectWithSearch = ({
  id,
  className,
  classNameSelectedText,
  classNameHeadersWrap,
  classNameItemSubtitle,
  required,
  label,
  placeholder,
  defaultValues = [],
  options = [],
  isError,
  labelError,
  onChange = () => {},
  labelErrorPosition = labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT,
  onBlur = () => {},
  disabled,
  dropdownDirection = selectOptionsPositionEnum.BOTTOM,
  isServerSideSearch = false,
  isLoadingDataList = false,
  onSelectedValue = () => {},
  limit,
  maxLength,
  pattern,
  patternPasteEvent,
  autoFocus = true,
  ...props
}) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const selectWrapperRef = useRef(null);
  const labelErrorRef = useRef(null);
  const [selectedDropdownItems, setSelectedDropdownItems] = useState([]);
  const [isShowOptions, setIsShowOptions] = useState(false);
  const [isLabelErrorTruncated, setIsLabelErrorTruncated] = useState(false);
  const [query, setQuery] = useState('');

  const handleSelectOnchange = selectedOptions => {
    if (selectedOptions.find(item => isEqual(item, DEFAULT_SELECT_ALL_OPTION))) {
      setSelectedDropdownItems(prevState => {
        const newSelectedOptions = prevState.length > 0 ? [] : options;
        onChange(newSelectedOptions, id);
        return newSelectedOptions;
      });
    } else {
      let currentSelectedOptions = selectedOptions;
      if (currentSelectedOptions?.length > selectedDropdownItems?.length) {
        const lastItem = currentSelectedOptions[currentSelectedOptions?.length - 1];
        const isExists = !!selectedDropdownItems?.find(item => item?.id === lastItem?.id);
        if (isExists) {
          currentSelectedOptions = currentSelectedOptions.filter(item => item?.id !== lastItem?.id);
        }
      }
      setSelectedDropdownItems(currentSelectedOptions);
      onChange(currentSelectedOptions, id);
    }
    inputRef.current && inputRef.current.focus();
  };

  const handleRemoveSelectedItem = (e, itemToRemove) => {
    e.stopPropagation();
    const newItems = selectedDropdownItems.filter(item => !isEqual(item, itemToRemove));
    setSelectedDropdownItems(newItems);
    onChange && onChange(newItems, id);
    inputRef.current && inputRef.current.focus();
  };
  const handleChangeInput = event => {
    setIsShowOptions(true);
    setQuery(event.target.value);
    onSelectedValue(event);
  };
  const handleToggleOptionsDropdown = () => {
    setIsShowOptions(prevState => !prevState);
  };
  const handleInputBlur = e => {
    if (!selectWrapperRef.current?.contains(e.relatedTarget)) {
      setQuery('');
      setIsShowOptions(false);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };
  const handleInputKeyDown = e => {
    e.stopPropagation();
    if (pattern && e.code !== 'Backspace') {
      const digitsArray = e.key.match(pattern);
      if (digitsArray) {
        setQuery(digitsArray.join(''));
      } else if (
        !(
          ((e.ctrlKey || e.metaKey) && ['KeyA', 'KeyV', 'KeyC'].includes(e.code)) ||
          ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Enter'].includes(e.code)
        )
      ) {
        e.preventDefault();
      }
    }

    if (e.code === 'Escape') {
      if (isShowOptions) {
        setIsShowOptions(false);
      }
      if (query.length) {
        setQuery('');
        inputRef.current.value = '';
        onSelectedValue(e);
      }
    }
  };

  const handlePasteInput = e => {
    if (patternPasteEvent) {
      e.preventDefault();
      const text = e.clipboardData.getData('Text');
      const replaceText = text.replace(patternPasteEvent, '');
      const { selectionStart, selectionEnd } = e.target;
      inputRef.current.setRangeText(replaceText, selectionStart, selectionEnd, 'end');
      const currentValue = inputRef.current.value;
      inputRef.current.value = maxLength ? currentValue?.slice(0, maxLength - 1) : currentValue;
      setQuery(inputRef.current.value);
      setIsShowOptions(!!inputRef.current.value.length);
      onSelectedValue(e);
    }
  };

  const handleDownIconKeyDown = e => {
    e.stopPropagation();
    if (!isShowOptions && filteredItems?.length && e.code === 'ArrowDown') {
      setIsShowOptions(true);
    }
  };

  const filteredItems = options?.filter(option => {
    if (options?.length > 0) {
      if (query === '') return option;
      else if (isServerSideSearch) return true;
      else return option?.name?.toLowerCase()?.includes(query?.toLowerCase());
    } else return [];
  });

  useEffect(() => {
    if (isError)
      setIsLabelErrorTruncated(
        labelErrorRef.current?.offsetWidth < labelErrorRef.current?.scrollWidth
      );
  }, [isError]);

  useEffect(() => {
    if (!isServerSideSearch) {
      setSelectedDropdownItems(
        options?.filter(item =>
          defaultValues && defaultValues.length > 0
            ? defaultValues.map(df => df.id).includes(item.id)
            : false
        )
      );
    } else setSelectedDropdownItems(defaultValues ?? []);
  }, [defaultValues, isServerSideSearch, options]);

  const isShowDropdownButton = disabled
    ? false
    : isServerSideSearch
    ? selectedDropdownItems?.length > 0 || defaultValues?.length > 0
    : true;
  const isShowMoreItems = limit && selectedDropdownItems?.length > limit;
  const maxItem = isShowMoreItems ? limit : selectedDropdownItems?.length;

  return (
    <Combobox
      value={selectedDropdownItems}
      onChange={handleSelectOnchange}
      multiple
      disabled={disabled}
    >
      <div ref={selectWrapperRef} className={classNames('relative w-full')}>
        <Combobox.Label
          className={classNames(
            'absolute top-2 left-3',
            'text-xs font-medium text-gray-900',
            'z-20'
          )}
        >
          {label}
          {required && <span className='text-red-500'>*</span>}
        </Combobox.Label>
        <div
          as='div'
          className={classNames(
            'w-full',
            'border rounded-md',
            {
              'border-gray-300': !isError,
              'border-red-300': isError,
            },
            'pb-2 px-3',
            selectedDropdownItems?.length === 0 ? 'pt-6' : 'pt-7',
            'flex flex-col',
            'group',
            {
              'focus-within:ring-1': !className?.includes('focus:ring-'),
            },
            'ring-inset ring-blue-600 focus-within:border-blue-600 focus-visible:outline-none',
            { 'bg-gray-50': disabled },
            className
          )}
          onBlur={e => {
            !isShowOptions && onBlur && onBlur({ ...e, target: { ...e.target, id } });
          }}
          {...props}
        >
          <span
            className={classNames('flex flex-wrap', 'gap-1.5', {
              'max-w-[360px]': isServerSideSearch,
            })}
          >
            {selectedDropdownItems?.slice(0, maxItem)?.map((item, itemIdx) => (
              <Chip
                key={`${itemIdx}-selected-chip-${item.id ?? itemIdx}`}
                className={classNames('bg-gray-200 text-gray-700', 'rounded-[4px]', 'inline-flex')}
                size={chipSizeEnum.MEDIUM}
                type={chipTypesEnum.RECTANGLE}
                classNameText={classNameSelectedText}
                text={item?.maskedId ? item?.maskedId : item?.name}
                rightIcon={
                  <Button
                    onKeyDown={e => {
                      e.stopPropagation();
                      (e.code === 'Space' || e.code === 'Enter') &&
                        handleRemoveSelectedItem(e, item);
                    }}
                    onClick={e => {
                      handleRemoveSelectedItem(e, item);
                    }}
                    color={COLOR_TRANSPARENT}
                    className={classNames('p-0', 'w-fit h-fit', 'm-0')}
                  >
                    <XIcon className={classNames('w-4 h-4', 'fill-gray-400')} />
                  </Button>
                }
              />
            ))}
            {isShowMoreItems && (
              <span className={classNames('text-sm', 'text-gray-500', 'leading-7')}>
                ... +{selectedDropdownItems?.length - limit} {t('more')}
              </span>
            )}
          </span>
          {(isShowOptions || selectedDropdownItems.length === 0) && (
            <Combobox.Input
              autoFocus={autoFocus}
              ref={inputRef}
              placeholder={selectedDropdownItems.length === 0 ? placeholder : ''}
              className={classNames(
                'p-0',
                'm-0',
                'focus:border-none focus:ring-0 border-none ring-0 rounded-none disabled:bg-transparent'
              )}
              onChange={handleChangeInput}
              onKeyDown={handleInputKeyDown}
              onPaste={handlePasteInput}
              onBlur={handleInputBlur}
              maxLength={maxLength}
            />
          )}
          {isShowDropdownButton && (
            <Button
              color={COLOR_TRANSPARENT}
              onClick={handleToggleOptionsDropdown}
              className={classNames(
                'absolute right-3 top-1/2 -translate-y-1/2',
                'hover:bg-transparent',
                'w-fit h-fit',
                'my-auto',
                'p-0'
              )}
              onKeyDown={handleDownIconKeyDown}
            >
              <ChevronDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
            </Button>
          )}
          {isError && (
            <div
              className={classNames('flex items-center', 'w-full', 'absolute z-20', {
                'top-0 right-0 mt-2 mr-3':
                  labelErrorPosition === labelErrorPositionEnum.INSIDE_TOP_RIGHT,
                '-bottom-5 left-0':
                  labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_LEFT,
                '-bottom-5 right-0':
                  labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT,
                '-top-5 left-0': labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_LEFT,
                '-top-5 right-0': labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_RIGHT,
              })}
            >
              <div className={classNames('relative', 'w-full', 'flex')}>
                <p
                  ref={labelErrorRef}
                  className={classNames(
                    'grow',
                    'w-full',
                    'text-xs text-red-500 font-medium',
                    'whitespace-nowrap overflow-hidden text-ellipsis',
                    {
                      'text-right':
                        labelErrorPosition === labelErrorPositionEnum.INSIDE_TOP_RIGHT ||
                        labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT ||
                        labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_RIGHT,
                    }
                  )}
                >
                  {labelError}
                </p>
                {isLabelErrorTruncated && (
                  <Tooltip
                    icon={<ExclamationCircleIcon className='w-4  fill-red-500' />}
                    text={labelError}
                  />
                )}
              </div>
            </div>
          )}
        </div>

        {isShowOptions && (
          <Combobox.Options
            static
            className={classNames(
              'absolute z-40 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base',
              'ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm',
              { 'hidden': !isLoadingDataList && !filteredItems?.length && query === '' },
              {
                'top-full mt-1.5': dropdownDirection === selectOptionsPositionEnum.BOTTOM,
                'bottom-full mb-1.5': dropdownDirection === selectOptionsPositionEnum.TOP,
              }
            )}
          >
            {isLoadingDataList ? (
              <div className={classNames('flex justify-center items-center')}>
                <span
                  className={classNames(
                    'w-5 h-5',
                    'border-4 border-gray-300 border-t-4 rounded-full border-t-indigo-600',
                    'animate-spin'
                  )}
                />
              </div>
            ) : !filteredItems?.length && query !== '' ? (
              <div className='cursor-default select-none relative py-2 px-4 text-gray-700'>
                {t('No match found')}
              </div>
            ) : (
              <>
                {filteredItems?.length && (
                  <Combobox.Option
                    id={`${id}-select-all-options-li`}
                    className={({ active }) =>
                      classNames(
                        { 'text-white bg-indigo-600': active },
                        { 'text-gray-400': !active },
                        'py-2.5 pl-3'
                      )
                    }
                    value={DEFAULT_SELECT_ALL_OPTION}
                  >
                    {({ active }) => (
                      <div className={classNames('flex items-center', 'gap-3')}>
                        <Checkbox
                          id={`${id}-select-all-options-checkbox`}
                          className={classNames('ring-white', { 'ring-1': active })}
                          checked={selectedDropdownItems.length === options?.length}
                          isMinus={selectedDropdownItems.length > 0}
                          readOnly
                        />
                        <span className={classNames('block truncate', 'select-none')}>
                          {selectedDropdownItems.length > 0 ? t('Deselect all') : t('Select all')}
                        </span>
                      </div>
                    )}
                  </Combobox.Option>
                )}
                {filteredItems.map((item, idx) => (
                  <Combobox.Option
                    key={`${idx}-select-option-key-${item.id ?? idx}`}
                    className={({ active }) =>
                      classNames(
                        { 'text-white bg-indigo-600': active },
                        { 'text-gray-700': !active },
                        'py-2.5 pl-3'
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <div className={classNames('flex items-center', 'gap-3')}>
                        <Checkbox
                          id={`${id}-select-option-${item.id ?? idx}`}
                          className={classNames('ring-white', { 'ring-1': active })}
                          checked={
                            selected ||
                            !!selectedDropdownItems.find(
                              selectedItem => selectedItem?.id === item?.id
                            )
                          }
                          readOnly
                        />
                        <div
                          className={classNames(
                            'flex',
                            { 'gap-2': !classNameHeadersWrap?.includes('gap-') },
                            { 'flex-row': !classNameHeadersWrap?.includes('flex-col') },
                            classNameHeadersWrap
                          )}
                        >
                          <span className={classNames('block truncate', 'select-none')}>
                            {item?.name}
                          </span>
                          {item?.subTitle && (
                            <span
                              className={classNames(
                                { 'text-white': active },
                                { 'text-gray-400': !classNameItemSubtitle?.includes('text-') },
                                { 'italic': !classNameItemSubtitle?.includes('not-italic') },
                                classNameItemSubtitle
                              )}
                            >
                              {item?.subTitle}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </Combobox.Option>
                ))}
              </>
            )}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

MultipleSelectWithSearch.propTypes = {
  className: PropTypes.string,
  classNameSelectedText: PropTypes.string,
  classNameHeadersWrap: PropTypes.string,
  classNameItemSubtitle: PropTypes.string,
  defaultValues: PropTypes.array,
  dropdownDirection: PropTypes.oneOf([
    selectOptionsPositionEnum.TOP,
    selectOptionsPositionEnum.BOTTOM,
  ]),
  id: PropTypes.string,
  isError: PropTypes.bool,
  label: PropTypes.string,
  labelErrorPosition: PropTypes.oneOf([...Object.values(labelErrorPositionEnum)]),
  labelError: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onSelectedValue: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  pattern: PropTypes.instanceOf(RegExp),
  patternPasteEvent: PropTypes.instanceOf(RegExp),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  isLoadingDataList: PropTypes.bool,
  isServerSideSearch: PropTypes.bool,
  limit: PropTypes.number,
  autoFocus: PropTypes.bool,
};

export default MultipleSelectWithSearch;
