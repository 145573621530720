/** @format */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { CheckIcon, ChevronDownIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { XCircleIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

import { classNames, sortDataList } from 'helpers';
import { labelErrorPositionEnum } from 'constants/enum';
import { COLOR_TRANSPARENT } from 'constants/Constants';
import { Tooltip } from 'components/Tooltip';
import Button from 'components/Button';

const ComboboxSelectList = ({
  id,
  disabled,
  dataList = [],
  defaultValue = undefined,
  onSelectValue,
  label = '',
  placeholder = '',
  sortKeys = [],
  required,
  isError,
  lableError,
  labelErrorPosition = labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT,
  onBlur,
  value,
  onChange,
  isLoadingDataList,
  isServerSideSearch,
  onClear,
  ...props
}) => {
  const { t } = useTranslation();
  const labelErrorRef = useRef(null);
  const [selected, setSelected] = useState(null);
  const [query, setQuery] = useState('');
  const [isFocusInputBox, setIsFocusInputBox] = useState(false);
  const [isLabelErrorTruncated, setIsLabelErrorTruncated] = useState(false);

  const handleChangeSetSelectName = event => {
    setSelected(event);
    setQuery(event.name);
    setIsFocusInputBox(false);
    if (typeof onSelectValue === 'function') {
      onSelectValue(event, id);
    }
  };
  const handleClearValue = e => {
    e.stopPropagation();
    setSelected(null);
    setQuery('');
    onClear && onClear(e, id);
  };

  const sortItemsList = useMemo(() => sortDataList(dataList, 'name'), [dataList]);

  const filteredItems = sortItemsList?.filter(person => {
    if (dataList && sortKeys.length > 0) {
      for (let i = 0; i < sortKeys.length; i++) {
        if (person[sortKeys[i]]) {
          const personKey = person[sortKeys[i]];
          const results = personKey.toLowerCase().includes(query.toLowerCase());
          if (results) return results;
        }
      }
    }
    return isServerSideSearch ? true : person.name.toLowerCase().includes(query.toLowerCase());
  });

  useEffect(() => {
    if (isError)
      setIsLabelErrorTruncated(
        labelErrorRef.current?.offsetWidth < labelErrorRef.current?.scrollWidth
      );
  }, [isError]);
  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <div className='space-y-7'>
      <div className='relative'>
        <Combobox onChange={handleChangeSetSelectName} value={selected} disabled={disabled}>
          {({ open }) => (
            <>
              <Combobox.Label
                className={classNames(
                  'text-gray-900 text-xs leading-4 font-medium absolute z-20 left-3 top-2'
                )}
              >
                {label}
                {required && <span className='text-red-500'>*</span>}
              </Combobox.Label>
              <Combobox.Input
                type='text'
                autoComplete='off'
                onChange={event => {
                  setQuery(event.target.value);
                  onChange && onChange({ ...event, target: { ...event.target, id } });
                }}
                displayValue={person => person?.name}
                onFocus={() => {
                  setIsFocusInputBox(true);
                }}
                onBlur={e => {
                  !open && onBlur && onBlur({ ...e, target: { ...e.target, id } });
                }}
                placeholder={placeholder}
                className={classNames(
                  'caret-indigo-600 relative rounded-md mb-0 w-full pt-6 pb-2 px-3 border border-gray-300 placeholder-gray-400 text-gray-900 focus:z-10 text-sm',
                  {
                    'focus:border-red-600 focus:ring-red-600':
                      filteredItems.length === 0 && query !== '',
                  }
                )}
                {...props}
              />

              <span className='ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <ChevronDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
              </span>
              {isError && (
                <div
                  className={classNames('flex items-center', 'w-full', 'absolute z-20', {
                    'top-0 right-0 mt-2 mr-3':
                      labelErrorPosition === labelErrorPositionEnum.INSIDE_TOP_RIGHT,
                    '-bottom-5 left-0':
                      labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_LEFT,
                    '-bottom-5 right-0':
                      labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT,
                    '-top-5 left-0': labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_LEFT,
                    '-top-5 right-0':
                      labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_RIGHT,
                  })}
                >
                  <div className={classNames('relative', 'w-full', 'flex')}>
                    <p
                      ref={labelErrorRef}
                      className={classNames(
                        'grow',
                        'w-full',
                        'text-xs text-red-500 font-medium',
                        'whitespace-nowrap overflow-hidden text-ellipsis',
                        {
                          'text-right':
                            labelErrorPosition === labelErrorPositionEnum.INSIDE_TOP_RIGHT ||
                            labelErrorPosition === labelErrorPositionEnum.OUTSIDE_BOTTOM_RIGHT ||
                            labelErrorPosition === labelErrorPositionEnum.OUTSIDE_TOP_RIGHT,
                        }
                      )}
                    >
                      {lableError}
                    </p>
                    {isLabelErrorTruncated && (
                      <Tooltip
                        icon={<ExclamationCircleIcon className='w-4  fill-red-500' />}
                        text={lableError}
                      />
                    )}
                  </div>
                </div>
              )}
              {onClear && selected && (
                <Button
                  className={classNames(
                    'absolute right-11 top-[50%] mt-[-10px]',
                    'p-0',
                    'h-5 w-5',
                    'rounded-full'
                  )}
                  color={COLOR_TRANSPARENT}
                  onClick={handleClearValue}
                >
                  <XCircleIcon className={classNames('w-5 h-5', 'text-gray-400')} />
                </Button>
              )}
              <Transition
                as={React.Fragment}
                show={open || (isFocusInputBox && query === '')}
                enter='transition duration-100 ease-out'
                enterFrom='transform scale-95 opacity-0'
                enterTo='transform scale-100 opacity-100'
                leave='transition duration-75 ease-out'
                leaveFrom='transform scale-100 opacity-100'
                leaveTo='transform scale-95 opacity-0'
              >
                <Combobox.Options
                  className={classNames(
                    'absolute z-50 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base',
                    'ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm',
                    { 'hidden': !isLoadingDataList && filteredItems.length === 0 && query === '' }
                  )}
                >
                  {isLoadingDataList ? (
                    <div className={classNames('flex justify-center items-center')}>
                      <span
                        className={classNames(
                          'w-5 h-5',
                          'border-4 border-gray-300 border-t-4 rounded-full border-t-indigo-600',
                          'animate-spin'
                        )}
                      />
                    </div>
                  ) : filteredItems.length === 0 && query !== '' ? (
                    <div className='cursor-default select-none relative py-2 px-4 text-gray-700'>
                      {t('No match found')}
                    </div>
                  ) : (
                    filteredItems.map((person, idx) => (
                      <Combobox.Option
                        key={`${idx} - ${person?.external_id || idx}`}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9'
                          )
                        }
                        value={person}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className='flex items-center'>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {person.name}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className='h-5 w-5 text-green-500' aria-hidden='true' />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </>
          )}
        </Combobox>
      </div>
    </div>
  );
};

ComboboxSelectList.PropsTypes = {
  dataList: PropTypes.array.isRequired,
  onSelectValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  sortKeys: PropTypes.arrayOf(PropTypes.string),
  isLoadingDataList: PropTypes.bool,
  isServerSideSearch: PropTypes.bool,
};

export default ComboboxSelectList;
