/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { PARTNERS as NAV_PARTNERS } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const Partners = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Partners/Partners'))),
  t => [{ name: t('Partners & Programs'), url: '/dashboard/partners' }]
);
const PartnersEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Partners/PartnersEdit'))),
  t => [{ name: t('Partners & Programs'), url: '/dashboard/partners' }]
);
const ProgramEdit = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Partners/PartnersEdit/ProgramEdit'))),
  t => [{ name: t('Partners & Programs'), url: '/dashboard/partners' }]
);

const PartnersRoutes = (
  <Route path='partners' element={<DashboardLayout nav={NAV_PARTNERS} />}>
    <Route index element={<Partners title='Partners page' />} />
    <Route path=':id'>
      <Route index element={<PartnersEdit title='Partners Edit page' />} />
      <Route
        path='program/:programId'
        element={<ProgramEdit title="Partners's Program Edit Page" />}
      />
    </Route>
    <Route path='*' element={<Navigate to='/dashboard/partners' replace />} />
  </Route>
);

export default PartnersRoutes;
