/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { MEDIA_MANAGER as NAV_MEDIA_MANAGER } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const MediaManagerSettings = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/MediaManager/Settings'))),
  t => [{ name: t('Media Manager'), url: '/dashboard/media' }, { name: t('Media Settings') }]
);
const MediaManagerStatistics = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/MediaManager/Statistics'))),
  t => [{ name: t('Media Manager'), url: '/dashboard/media' }, { name: t('Statistics') }]
);
const MediaManagerFareCaps = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/MediaManager/FareCaps'))),
  t => [{ name: t('Media Manager'), url: '/dashboard/media' }, { name: t('Fare Caps') }]
);
const MediaManager = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/MediaManager/MediaManager'))),
  t => [{ name: t('Media Manager') }]
);
const MediaManagerManage = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/MediaManager/MediaManager/Manage'))),
  t => [{ name: t('Media Manager') }]
);

const MediaRoutes = (
  <Route path='media' element={<DashboardLayout nav={NAV_MEDIA_MANAGER} />}>
    <Route index element={<Navigate to='/dashboard/media/media' replace />} />
    <Route path='media' element={<MediaManager title='Media Manager page' />} />
    <Route path='media/:uuid' element={<MediaManagerManage title='Media manager manage page' />} />
    <Route path='fare-caps' element={<MediaManagerFareCaps title='Media Manager Fare Caps' />} />
    <Route path='settings' element={<MediaManagerSettings title='Media Manager Settings' />} />
    <Route
      path='statistics'
      element={<MediaManagerStatistics title='Media Manager Statistics' />}
    />
    <Route path='*' element={<Navigate to='/dashboard/media/media' replace />} />
  </Route>
);

export default MediaRoutes;
