/**@format */
import React, { useEffect, useState } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { ExclamationIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

import { classNames } from 'helpers';

import { COLOR_GRAY, COLOR_TRANSPARENT } from 'constants/Constants';
import Modal from 'components/Modal';
import Button from 'components/Button';

export const AccessModal = ({
  isShow = false,
  data,
  onClose = () => {},
  onContinue = () => {},
  title = '',
  children,
  continueButtonTitle = 'Continue',
  classNameChildren = '',
}) => {
  const { t } = useTranslation();
  const [dataState, setDataState] = useState({ accessTitle: '', continueBtnTitle: '' });

  useEffect(() => {
    if (isShow) setDataState({ accessTitle: title, continueBtnTitle: continueButtonTitle });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isShow]);

  const handlingContinue = () => {
    onClose();
    onContinue(data);
  };
  return (
    <Modal isShow={isShow} onCloseWhenEsc={onClose}>
      <div className={classNames('flex flex-col', 'w-full h-fit')}>
        <div className={classNames('flex', 'p-0')}>
          <div className={classNames('flex flex-col grow', 'mb-6')}>
            <div className='flex'>
              <ExclamationIcon className={classNames('min-w-6 w-6', 'text-red-700')} />
              <h1 className={classNames('font-medium text-lg text-gray-900', 'ml-2.5')}>
                {dataState?.accessTitle}
              </h1>
            </div>
            <div className={classNames('my-1', classNameChildren)}>{children}</div>
          </div>
          <Button
            color={COLOR_TRANSPARENT}
            className={classNames(
              'h-fit p-0',
              'focus:rounded-md',
              'hover:bg-transparent',
              'focus:ring-offset-0'
            )}
            onClick={onClose}
          >
            <XIcon className={classNames('w-6 h-6', 'text-gray-400')} />
          </Button>
        </div>
        <Modal.Footer>
          <Button onClick={onClose} color={COLOR_GRAY} outline>
            {t('Cancel')}
          </Button>
          <Button onClick={handlingContinue}>
            {dataState?.continueBtnTitle ? t(dataState?.continueBtnTitle) : ''}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
AccessModal.PropsTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
  title: PropTypes.string,
  continueButtonTitle: PropTypes.string,
  classNameChildren: PropTypes.string,
  children: PropTypes.element,
};
