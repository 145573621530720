/** @format */
import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { CRM as NAV_CRM } from 'constants/Navigation';
import DashboardLayout from 'layout/DashboardLayout/Main';
import { withHeaderBreadcrumb, withLazyComponent } from 'helpers';

const CrmStatistics = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Manager/Statistics'))),
  t => [
    {
      name: t('CRM'),
      url: '/dashboard/crm/patrons',
    },
    { name: t('Statistics') },
  ]
);
const CrmNotifications = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Manager/Notifications'))),
  t => [
    {
      name: t('CRM'),
      url: '/dashboard/crm/patrons',
    },
    { name: t('Notifications') },
  ]
);
const CrmPatrons = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Manager/Patrons'))),
  t => [
    {
      name: t('CRM'),
      url: '/dashboard/crm/patrons',
    },
    { name: t('Patrons') },
  ]
);
const CrmPatron = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Manager/Patrons/Patron'))),
  t => [
    {
      name: t('CRM'),
      url: '/dashboard/crm/patrons',
    },
    { name: t('Patrons') },
  ]
);
const CrmPatronGeneral = withLazyComponent(
  lazy(() => import('pages/dashboard/Manager/Patrons/Patron/General'))
);
const CrmPatronProfiles = withLazyComponent(
  lazy(() => import('pages/dashboard/Manager/Patrons/Patron/Profiles'))
);
const CrmPatronCitations = withLazyComponent(
  lazy(() => import('pages/dashboard/Manager/Patrons/Patron/Citations'))
);
const CrmPatronComments = withLazyComponent(
  lazy(() => import('pages/dashboard/Manager/Patrons/Patron/Comments'))
);
const CrmPatronCreditCards = withLazyComponent(
  lazy(() => import('pages/dashboard/Manager/Patrons/Patron/CreditCards'))
);
const CrmPatronMessages = withLazyComponent(
  lazy(() => import('pages/dashboard/Manager/Patrons/Patron/Messages'))
);
const CrmPatronPasses = withLazyComponent(
  lazy(() => import('pages/dashboard/Manager/Patrons/Patron/Passes'))
);
const CrmPatronStoredValueCards = withLazyComponent(
  lazy(() => import('pages/dashboard/Manager/Patrons/Patron/StoredValueCards'))
);
const CrmSettings = withHeaderBreadcrumb(
  withLazyComponent(lazy(() => import('pages/dashboard/Manager/Settings'))),
  t => [{ name: t('CRM'), url: '/dashboard/crm/patrons' }, { name: t('Settings') }]
);

const CrmRoutes = (
  <Route path='crm' element={<DashboardLayout nav={NAV_CRM} />}>
    <Route index element={<Navigate to='/dashboard/crm/settings' replace />} />
    <Route path='settings' element={<CrmSettings title='CRM Settings' />} />
    <Route path='patrons' element={<CrmPatrons title='CRM Patrons' />} />
    <Route path='patrons/:patronId' element={<CrmPatron title='CRM Patron' />}>
      <Route path='general' element={<CrmPatronGeneral title='CRM Patron General' />} />
      <Route path='profiles' element={<CrmPatronProfiles title='CRM Patron Profiles' />} />
      <Route
        path='svc'
        element={<CrmPatronStoredValueCards title='CRM Patron Stored Value Cards' />}
      />
      <Route path='passes' element={<CrmPatronPasses title='CRM Patron Passes' />} />
      <Route path='cc' element={<CrmPatronCreditCards title='CRM Patron Credit Cards' />} />
      <Route path='messages' element={<CrmPatronMessages title='CRM Patron Messages' />} />
      <Route path='citations' element={<CrmPatronCitations title='CRM Patron Citations' />} />
      <Route path='comments' element={<CrmPatronComments title='CRM Patron Comments' />} />
      <Route path='*' element={<Navigate to='/dashboard/crm/patrons' replace />} />
    </Route>
    <Route path='statistics' element={<CrmStatistics title='CRM Statistics' />} />
    <Route path='notifications' element={<CrmNotifications title='CRM Notifications' />} />
    <Route path='*' element={<Navigate to='/dashboard/crm/settings' replace />} />
  </Route>
);

export default CrmRoutes;
