/**@format */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

import { inventoryService } from 'services';
import { toCamelCase } from 'helpers';

export const fetchInventoryData = createAsyncThunk(
  'inventory/fetchInventoryData',
  async function (params, { rejectWithValue, dispatch }) {
    try {
      dispatch(showLoading());
      const response = await inventoryService.getInventoryData(params);
      dispatch(hideLoading());
      return response;
    } catch (error) {
      dispatch(hideLoading());
      return rejectWithValue(error?.response);
    }
  }
);
const initialState = {
  inventoryDataList: [],
  status: null,
  statusCode: null,
  errorMessage: null,
};
const setError = (state, { payload, type }) => {
  state.status = toCamelCase(type);
  state.statusCode = payload?.status;
  state.errorMessage = payload?.data;
};
const setCompleted = (state, { payload, type }) => {
  state.status = toCamelCase(type);
  state.statusCode = payload?.status;
  state.inventoryDataList = payload?.data;
};
export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    addInventoryData: (state, action) => {
      state.inventoryDataList.push(action.payload);
    },
    clearInventoryData: () => {
      return initialState;
    },
  },
  extraReducers: {
    [fetchInventoryData.pending]: state => {
      state.status = 'loading';
      state.errorMessage = null;
    },
    [fetchInventoryData.rejected]: setError,
    [fetchInventoryData.fulfilled]: setCompleted,
  },
});
export const { addInventoryData, clearInventoryData } = inventorySlice.actions;
export const selectInventoryList = state => state.dashboard.passes.inventory.inventoryDataList;
export const selectInventoryStatus = state => state.dashboard.passes.inventory.status;
export default inventorySlice.reducer;
