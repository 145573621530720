/** @format */

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { CheckIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import config from 'config';
import { api } from 'services/bo/api';
import {
  classNames,
  convertPhoneNumber,
  responseError,
  toTitleCase,
  yupCheckPhoneNumberIsValid,
} from 'helpers';
import { useToast } from 'hooks';
import {
  useEditUserPersonalDataMutation,
  useGetUserPersonalDataQuery,
} from 'services/bo/users/users.me';
import { COLOR_GRAY, COLOR_TRANSPARENT } from 'constants/Constants';
import { ReactComponent as IconChangeLock } from 'assets/icons/iconChangeLock.svg';

import { DEFAULT_PHONE_COUNTRY_CODE } from 'components/TextFieldNumberPhone/Constants/Constants';
import TextFieldNumberPhone from 'components/TextFieldNumberPhone/TextFieldNumberPhone';
import { SingleSelect } from 'components/ListBox';
import TextField from 'components/TextField';
import Button from 'components/Button';
import TextFieldLoaderView from 'components/Loaders/TextFieldLoaderView';
import Modal from 'components/Modal';

import es from 'translation/yupMessage_es.json';
import en from 'translation/yupMessage_en.json';
import fr from 'translation/yupMessage_fr.json';

const PLUGIN_LOCALES = {
  'en-US': en,
  es,
  fr,
};

export const SingleSelectOption = ({ selected, active, item }) => (
  <button
    type='button'
    className={classNames(
      'flex items-center justify-start',
      'gap-x-2',
      'text-sm leading-5 font-normal',
      'text-left',
      'w-full',
      'px-3 py-2',
      {
        'bg-gray-50': active,
      }
    )}
  >
    <img src={`/assets/flag-${item?.lng}.svg`} alt='' aria-hidden='true' />
    <span
      className={classNames({
        'font-semibold': selected,
        'text-transparent': item.name.length === 0,
      })}
    >
      {item.name.length > 0 ? item.name : 'null'}
    </span>
    {selected && item.name.length > 0 && (
      <CheckIcon className={classNames('absolute right-2', 'h-5 w-5', 'fill-green-600')} />
    )}
  </button>
);

const PersonalDataModal = ({ onClose = () => {}, onChangePassword = () => {} }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const dispatch = useDispatch();

  const defaultLngValue = config.languages.find(item => item?.lng === i18n.language);

  const {
    data: getMeData = {},
    isFetching: isGettingMeData,
    error: getMeDataError,
  } = useGetUserPersonalDataQuery();

  const [editUserData, { isLoading: isLoadingEditUserData, isError: isErrorUpdatingUserData }] =
    useEditUserPersonalDataMutation();
  const handleSaveChanges = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const { personalFullName, personalPhone } = values;
    const body = {
      name: personalFullName,
      phone: convertPhoneNumber(personalPhone),
    };
    if (isLoadingEditUserData) return;
    dispatch(showLoading());
    try {
      await editUserData({ body }).unwrap();
      toast.success({
        title: t('Success'),
        message: t('Personal data successfully saved.'),
      });
    } catch (err) {
      responseError(err, toast, t, navigate, location);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    const isErrorUserData = getMeDataError || isErrorUpdatingUserData;
    if (isGettingMeData || isLoadingEditUserData) dispatch(showLoading());
    if (isErrorUserData) responseError(isErrorUserData, toast, t, navigate, location);
    return () => dispatch(hideLoading());
  }, [
    dispatch,
    getMeDataError,
    isErrorUpdatingUserData,
    isGettingMeData,
    isLoadingEditUserData,
    location,
    navigate,
    t,
    toast,
  ]);

  const handleLanguageChange = async value => {
    dispatch(showLoading());
    await i18n.changeLanguage(value?.lng);
    Yup.setLocale(PLUGIN_LOCALES[value?.lng]);
    dispatch(api.util.resetApiState());
    dispatch(hideLoading());
  };

  const handleChangePassword = () => {
    onChangePassword();
  };

  const handlePhoneNumberChange = (phoneObj, phoneId, isValidate) => {
    formik.setFieldValue(phoneId, phoneObj, isValidate);
  };

  const handleCancel = () => {
    onClose && onClose();
  };

  const PersonalDataSchema = Yup.object().shape({
    personalFullName: Yup.string(),
    personalPhone: Yup.object()
      .shape({
        phoneNumber: Yup.string(),
        countryCode: Yup.number(),
      })
      .test('isPhoneNumberValid', t('Incorrect phone number format'), yupCheckPhoneNumberIsValid),
  });

  const formik = useFormik({
    initialValues: {
      personalFullName: getMeData?.name ?? '',
      personalPhone: {
        phoneNumber: getMeData?.phone_number || '',
        countryCode: +getMeData?.phone_country || DEFAULT_PHONE_COUNTRY_CODE,
      },
    },
    onSubmit: handleSaveChanges,
    validationSchema: PersonalDataSchema,
    enableReinitialize: true,
  });

  const isWaitingDataResolution = formik.isSubmitting || isGettingMeData || isLoadingEditUserData;
  const isSaveChangesBtnDisabled = isWaitingDataResolution || !formik.dirty || !formik.isValid;

  return (
    <div>
      <h1 className={classNames('text-gray-900 text-lg font-medium', 'mb-[3px]')}>
        {t('Personal data')}
      </h1>
      <p className='text-sm text-gray-500'>{t('View and edit your personal data.')}</p>
      <form onSubmit={formik.handleSubmit}>
        <div className='space-y-7 my-7'>
          <div className={classNames('flex gap-3', 'text-sm')}>
            <span className='text-gray-500'>{t('Email Address')}:</span>
            {!isWaitingDataResolution ? (
              getMeData?.email
            ) : (
              <span className={classNames('rounded', 'bg-gray-100', 'w-52 h-5')} />
            )}
          </div>
          {isWaitingDataResolution && <TextFieldLoaderView rightComponent={{ size: 'w-6 h-6' }} />}
          {!isWaitingDataResolution && (
            <TextField
              id='personalFullName'
              label={t('Full Name')}
              disabled={isLoadingEditUserData}
              placeholder={t('Enter your full name')}
              {...formik.getFieldProps('personalFullName')}
              isError={formik.touched.personalFullName && !!formik.errors.personalFullName}
              lableError={formik.errors.personalFullName}
            />
          )}

          {isWaitingDataResolution && <TextFieldLoaderView rightComponent={{ size: 'w-6 h-6' }} />}
          {!isWaitingDataResolution && (
            <TextFieldNumberPhone
              id='personalPhone'
              defaultValue={formik.values.personalPhone}
              disabled={isLoadingEditUserData}
              onChange={handlePhoneNumberChange}
              onBlur={formik.handleBlur}
              isError={formik.touched.personalPhone && !!formik.errors.personalPhone}
              lableError={formik.errors.personalPhone}
            />
          )}
          <SingleSelect
            id='personal_data_patron_modal_language_select'
            label={t('Language')}
            disabled={isLoadingEditUserData}
            placeholder={t('Select language')}
            options={config.languages}
            defaultValue={defaultLngValue}
            onChange={handleLanguageChange}
            classNameLabel='pl-10'
            classNameField='pl-10'
            leftComponent={
              <img
                src={`/assets/flag-${i18n.language}.svg`}
                className='flex-none w-6 h-auto absolute top-[calc(50%-10px)]'
                alt=''
                aria-hidden='true'
              />
            }
            components={{
              Option: SingleSelectOption,
            }}
          />
          {Object.keys(getMeData)?.length > 0 && (
            <Button
              color={COLOR_TRANSPARENT}
              className={classNames(
                'shadow-none',
                'p-0 h-6',
                'text-sm font-medium leading-4 hover:bg-transparent focus:rounded-full',
                'flex gap-1.5'
              )}
              disabled={isLoadingEditUserData}
              onClick={handleChangePassword}
            >
              <IconChangeLock className='w-5 h-5 fill-indigo-600' />
              <span className='text-indigo-600'> {toTitleCase(t('Change password'))}</span>
            </Button>
          )}
        </div>

        <Modal.Footer>
          <Button color={COLOR_GRAY} outline onClick={handleCancel} title={t('Cancel')} />
          <Button type='submit' disabled={isSaveChangesBtnDisabled} title={t('Save changes')} />
        </Modal.Footer>
      </form>
    </div>
  );
};

PersonalDataModal.PropsTypes = {
  onClose: PropTypes.func,
  onChangePassword: PropTypes.func,
};

export default PersonalDataModal;
