/** @format */

import { toast } from 'react-toastify';
import { InformationCircleIcon, CheckIcon, ExclamationIcon } from '@heroicons/react/outline';

import { classNames } from 'helpers';
import { COLOR_BLUE, COLOR_GREEN, COLOR_RED, COLOR_YELLOW } from 'constants/Constants';
import {
  defaultIdsToastEnum,
  defaultPositionToastEnum,
  defaultThemesToastEnum,
} from 'constants/enum';

export const useToast = () => {
  const info = (data, settings) => {
    showToast({
      fn: toast.info,
      icon: <InformationCircleIcon />,
      color: COLOR_BLUE,
      settings: {
        toastId: defaultIdsToastEnum.INFO_ID,
        ...settings,
      },
      data,
    });
  };

  const success = (data, settings) => {
    showToast({
      fn: toast.success,
      icon: <CheckIcon />,
      color: COLOR_GREEN,
      settings: {
        toastId: defaultIdsToastEnum.SUCCESS_ID,
        ...settings,
      },
      data,
    });
  };

  const warning = (data, settings) => {
    showToast({
      fn: toast.warning,
      icon: <ExclamationIcon />,
      color: COLOR_YELLOW,
      settings: {
        toastId: defaultIdsToastEnum.WARNING_ID,
        ...settings,
      },
      data,
    });
  };

  const error = (data, settings) => {
    showToast({
      fn: toast.error,
      icon: <ExclamationIcon />,
      color: COLOR_RED,
      settings: {
        toastId: defaultIdsToastEnum.ERROR_ID,
        ...settings,
      },
      data,
    });
  };

  const showToast = ({
    fn,
    icon,
    color,
    data,
    settings = {
      position: defaultPositionToastEnum.TOP_RIGHT,
      theme: defaultThemesToastEnum.LIGHT,
    },
  }) => {
    fn(
      <div className='flex items-start gap-3'>
        {(data.icon || icon) && (
          <div
            className={classNames(
              'flex flex-none items-center justify-center',
              'w-10 h-10',
              'rounded-full',
              'overflow-hidden',
              {
                // Requires to define classes for Tailwind v3
                [`bg-green-50 text-green-700`]: color === COLOR_GREEN,
                [`bg-blue-50 text-blue-700`]: color === COLOR_BLUE,
                [`bg-yellow-50 text-yellow-700`]: color === COLOR_YELLOW,
                [`bg-red-50 text-red-700`]: color === COLOR_RED,
              }
            )}
          >
            <div className='w-6'>{data.icon || icon}</div>
          </div>
        )}
        <div>
          {data.title || data.message ? (
            <>
              {data.title && (
                <p
                  className={classNames(' text-sm font-semibold', 'mb-1', {
                    'text-gray-900': settings?.theme !== defaultThemesToastEnum.DARK,
                    'text-white': settings?.theme === defaultThemesToastEnum.DARK,
                  })}
                >
                  {data.title}
                </p>
              )}
              {data.message && <div>{data.message}</div>}
            </>
          ) : (
            data
          )}
        </div>
      </div>,
      {
        ...settings,
      }
    );
  };

  return {
    info,
    success,
    warning,
    warn: warning,
    error,
  };
};
