/**@format */
import { combineReducers } from '@reduxjs/toolkit';

import permissionsReducer from './permissionsSlice';
import translationsReducer from './translationsSlice';

export const staticReducer = combineReducers({
  permissions: permissionsReducer,
  translations: translationsReducer,
});
