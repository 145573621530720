/** @format */

import axios from 'axios';

export const commonService = {
  getAppVersion,
};

function getAppVersion() {
  const requestOptions = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return axios.get(`/app/version`, requestOptions);
}
