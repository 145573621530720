/**@format */
import React from 'react';

import { classNames } from 'helpers';

import TextFieldLoaderView from 'components/Loaders/TextFieldLoaderView';

const ResetPasswordLoaderView = () => (
  <div className={classNames('flex flex-col', 'w-full', 'divide-y', 'mt-7', 'animate-pulse')}>
    <div className={classNames('flex flex-col', 'w-full')}>
      <div className='space-y-7'>
        <div className='flex w-full'>
          <TextFieldLoaderView rightComponent={{ size: 'w-6 h-6' }} />
        </div>
        <div className='flex w-full'>
          <TextFieldLoaderView rightComponent={{ size: 'w-6 h-6' }} />
        </div>
      </div>
    </div>
  </div>
);

export default ResetPasswordLoaderView;
